import React, { Fragment } from 'react';
import { bool, func, number } from 'prop-types';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';

// eden
import { Info } from '@churchofjesuschrist/eden-alert';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import Toast from 'src/utils/componentFunctions/toast';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { black } from '@churchofjesuschrist/eden-style-constants';

// styles
import { AlertClickBlocker } from 'src/utils/appCSS/appStyles';

// queries
import { setManagerRefetchVariables } from 'src/utils/graphQL_Queries/queryVariableFormats';
import { publishStoryMutation } from 'src/utils/graphQL_Queries/appMutations';

// constants
import {
  userType,
} from 'src/utils/appConstants/propTypesConstants';

export const ConfirmPublishModal = ({
  isActive,
  handleClose,
  handleCompletion,
  unitType,
  user,
}: {
  isActive?
  handleClose?
  handleCompletion?
  unitType
  user?
}) => {
  const { t } = useTranslation();
  const refetchList = setManagerRefetchVariables(user);

  return (
    <Fragment>
      {isActive && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}
      <Toast active={isActive}>
        <Info onClose={handleClose}>
          <Stack>
            <H4>{t('publishConfirmTitle', { ns: 'strings' })}</H4>
            <Text4>
              {t('publishConfirmMessage', { ns: 'strings' })}
            </Text4>
            <Row>
              <Mutation mutation={publishStoryMutation} refetchQueries={refetchList}>
                {(publishStoryMutation: MutationFunction<any, OperationVariables>, { error }: MutationResult<any>) => (
                  <Fragment>
                    {error && <p>Error :( Please try again</p>}
                    <Primary
                      onClick={e => {
                        // e.preventDefault();
                        // if (doSave) {
                        //   publishStoryMutation({ variables: publishStoryVariable });
                        // }
                        handleCompletion(e, publishStoryMutation);
                      }}
                      data-test-id="button_publish-confirmation"
                    >
                      {t('publishBtnLabel', { ns: 'labels' })}
                    </Primary>
                  </Fragment>
                )}
              </Mutation>
              <Secondary onClick={handleClose}>{t('cancelBtnLabel', { ns: 'labels' })}</Secondary>
            </Row>
          </Stack>
        </Info>
      </Toast>
    </Fragment>
  );
}

export default ConfirmPublishModal;

(ConfirmPublishModal as any).propTypes = {
  isActive: bool.isRequired,
  handleClose: func.isRequired,
  handleCompletion: func.isRequired,
  unitType: number,
  user: userType,
};
