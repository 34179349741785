import React from 'react';
import { useClickBoundary } from '@churchofjesuschrist/eden-click-boundary';
import { node, func } from 'prop-types';

// styles
import { DropDownSize } from 'src/utils/appCSS/appStyles';

export const DropDownCard = ({ children, cardWidth, handleClose, ...props }: { children?, cardWidth?, handleClose? }) => {
  const clickRef = useClickBoundary({
    onClickInside: () => {
      return null;
    },
    onClickOutside: handleClose,
  });
  return (
    <DropDownSize cardWidth={cardWidth} ref={clickRef} {...props}>
      {children}
    </DropDownSize>
  );
};

export default DropDownCard;

DropDownCard.propTypes = {
  /** Content to render inside the Card */
  children: node.isRequired,
  handleClose: func.isRequired,
};
