import { createSlice } from '@reduxjs/toolkit';
import { Unit, UnitOrganization } from 'src/models/Unit';
import { mapUnitTypes } from 'src/utils/AppFunctions/appFunctions';

// Keep here in case we need to retrieve from api
// export const fetchAreas = createAsyncThunk(
//   'units/fetchAreas',
//   async (_, api) => {
//     return await fetchAreasApi();
//   }
// )

export const unitsSlice = createSlice({
  name: 'units',
  initialState: {
    units: [],
    // Keep here in case we need to retrieve from api
    areas: [{
      id: '0',
      name: 'US/Canada'
    }, {
      id: '1',
      name: 'Asia/Pacific'
    }, {
      id: '2',
      name: 'Europe/Africa'
    }, {
      id: '3',
      name: 'Latin America'
    }] as Unit[],
    types: [],
    orgs: [],
    loading: false,
    unitsError: false,
  },
  reducers: {
    setUnits: (state, action) => {
      state.units = action.payload;
      state.loading = false;
      state.unitsError = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.unitsError = action.payload;
    },
    setUnitOrgs: (state, action) => {
      state.orgs = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUnits, setLoading, setError, setUnitOrgs } = unitsSlice.actions;

export const selectUnits = (state) => (state.units.units ?? []) as Unit[];
export const selectUnitOrgs = (state) => (state.units.orgs ?? []) as UnitOrganization[];
export const selectUnitTypes = (state) => mapUnitTypes(state.units.types ?? []) as string[];
export const selectLoading = (state) => state.units.loading as boolean;
export const selectError = (state) => state.units.unitsError as boolean;
export const selectAreas = (state) => state.units.areas as Unit[];

export default unitsSlice.reducer;
