import React from 'react';
import DropZone from 'react-dropzone';
import styled from 'styled-components';
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import { Upload } from '@churchofjesuschrist/eden-icons';
import Row from '@churchofjesuschrist/eden-row';
import { useTranslation } from 'react-i18next';

const ZoneBase = styled.div({
  flex: 1,
  padding: '1rem',
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
  color: 'rgb(127 127 127)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  position: 'relative',
  maxWidth: '30rem',
  textAlign: 'center'
});

export interface IUploadWrapperProps {
  /** Callback for when a file is selected/dropped. */
  onDocumentSelect: (files: File[]) => void
  /** Sets wrapper as loading. */
  loading: boolean
  children: React.ReactNode
}
/** Wraps content in upload/drag-n-drop style element. */
const UploadWrapper = ({ onDocumentSelect, loading, children }: IUploadWrapperProps) => {
  const { t } = useTranslation();
  const onDrop = (files: File[]) => {
    onDocumentSelect(files);
  }

  return (
    <DropZone noClick onDrop={onDrop}>
      {({ getInputProps, getRootProps, isDragActive, open }) => (
        <ZoneBase {...getRootProps({ style: isDragActive ? { borderColor: '#2196f3', backgroundColor: '#eaf7e8' } : { borderColor: '#eeeeee', backgroundColor: 'transparent' } })}>
          <Row align="start">
            <input {...getInputProps()} />
            {!loading &&
              <Secondary disabled={loading} onClick={open}>
                <Upload />{t('uploadFileLabel')}
              </Secondary>
            }
          </Row>
          {children}
        </ZoneBase>
      )}
    </DropZone>
  )
}

export default UploadWrapper;
