import React, { useState } from 'react';
import { array, string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import { ArrowDropDown, Add } from '@churchofjesuschrist/eden-icons';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import { Label } from '@churchofjesuschrist/eden-form-parts';
import Row from '@churchofjesuschrist/eden-row';

// Components
import { OrganizationDropDown } from 'src/components/DropDownCards/OrganizationDropDown';
import DisplayTags from 'src/components/Tags/DisplayTags';

// styles
import { DisplaySelectedOrgList } from 'src/components/Organizations/OrganzationStyle';
import {
  DropDownContainer,
  DropDownTitle,
  DropDownRotated,
  DropDownBtn,
} from 'src/components/SortAndSearchBar/SortAndSearchStyles';

// constants
import { MOOD_BUTTON } from 'src/utils/appConstants/appConstants';
import { UnitOrganization } from 'src/models/Unit';

const SelectOrganizations = ({
  fullOrgList = [] as UnitOrganization[],
  selectedOrgsList = [] as string[],
  showTags,
  showLabel,
  filterFavorites,
  mood,
  handleOrgChange,
}: {
  fullOrgList?: UnitOrganization[]
  selectedOrgsList?: string[]
  showTags?
  showLabel?
  filterFavorites?
  mood?
  handleOrgChange
}) => {
  const { t } = useTranslation('strings');
  const [isOrganizationDropdownOpen, setOrgDropDownActive] = useState(false);

  const toggleAddOrgBtnClick = () => setOrgDropDownActive(!isOrganizationDropdownOpen);

  const handleOrgRemove = removeOrg => {
    handleOrgChange(selectedOrgs.filter(org => org !== removeOrg)); // boost state for selected organizations
  };

  const handleLocalOrgChange = (newSelected: string[], filterFavorites: boolean) => {
    handleOrgChange(newSelected, filterFavorites); // boost state for organization changes
  };

  const handleRemoveAll = () => {
    handleOrgChange([]); // boost state for cleared organizations
  };

  const selectedOrgs = fullOrgList.filter(o => selectedOrgsList.some(c => c === o.uuid));

  return (
    <DropDownContainer paddingTop="0px">
      {showLabel && (
        <Row gapSize="8" columnGapSize="4" style={{ paddingBottom: '6px' }}>
          <Label>{t('addOrganizationTitleLabel')}</Label>
        </Row>
      )}
      {mood === MOOD_BUTTON
        ? (
          <Secondary onClick={toggleAddOrgBtnClick} data-test-id="selectOrganizations">
            <span style={{ marginRight: '8px' }}>
              <Add size="24px" />
            </span>
            {t('addOrganizationBtnLabel')}
          </Secondary>
        )
        : (
          <DropDownBtn onClick={toggleAddOrgBtnClick}>
            <Row gapSize="16">
              <DropDownTitle>{t('dropDownFilterByLabel')}</DropDownTitle>
              <DropDownRotated shouldRotate={isOrganizationDropdownOpen}>
                <ArrowDropDown />
              </DropDownRotated>
            </Row>
          </DropDownBtn>
        )}

      {/* console.log("SelectOrganizations: ", makeSelectedOrgObject(fullOrgList, selectedOrgs)) */}

      {isOrganizationDropdownOpen && (
        <OrganizationDropDown
          fullOrgList={fullOrgList}
          selectedOrgIds={selectedOrgsList}
          handleOrgChange={handleLocalOrgChange}
          toggleAddOrgBtnClick={toggleAddOrgBtnClick}
          filterFavorites={filterFavorites}
        />
      )}

      {showTags && selectedOrgs.length > 0 && (
        <React.Fragment>
          <DisplaySelectedOrgList>
            <Text4>{t('formOrganizationTitle')}</Text4>
          </DisplaySelectedOrgList>

          <DisplayTags
            showCloseBtn={true}
            tagArray={selectedOrgs}
            removeTag={handleOrgRemove}
            removeAll={handleRemoveAll}
          />
        </React.Fragment>
      )}
    </DropDownContainer>
  );
};

export default SelectOrganizations;

SelectOrganizations.propTypes = {
  fullOrgList: array.isRequired,
  preSelected: array,
  handleOrgChange: func.isRequired,
  showLabel: bool,
  showTags: bool,
  mood: string,
  filterFavorites: bool
};
