import React, { createContext, useContext, useEffect } from 'react';
import { pageNames } from '../utils/appConstants/appConstants';

// functions
import { useLazyQuery } from '@apollo/client';
import { displayStoryQuery } from 'src/utils/graphQL_Queries/appQueries';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import { selectStory, selectStoryLoading, setStory, setStoryLoading } from 'src/reducers/storiesReducer';
import { Story } from 'src/models/Story';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import { StoryEditShimmer } from 'src/components/Shimmer/StoryEditShimmer';
import { usePageContext } from './PageContext';
import { StoryShimmer } from 'src/components/Shimmer/StoryShimmer';

const { viewStoryPageName, editPageName } = pageNames;

export interface IStoryContext {
  story: Story
  badOrMissingStory: boolean
  wardCouncil: any[]
  isLocked: boolean
  lockDuration: number
  isUnlockable: boolean
  inRework: boolean
}

export const StoryContext = createContext<IStoryContext>({} as any);

export function useStoryContext() {
  const context = useContext(StoryContext);
  if (context === undefined) {
    throw new Error('useStoryContext must be used within a StoryProvider.');
  }
  return context;
}

const StoryProvider = ({ children, id }: any) => {
  const { t } = useTranslation();
  const { pageName } = usePageContext();

  const [fetchStory, { error }] = useLazyQuery(displayStoryQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });

  const isEdit = pageName === editPageName;

  const story = useSelector(selectStory);
  const storyLoading = useSelector(selectStoryLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    const startQuery = async () => {
      dispatch(setStoryLoading(true));
      const response = await fetchStory();
      dispatch(setStory(response.data));
    };
    startQuery();
  }, [fetchStory, dispatch]);

  return (
    <>
      {storyLoading
        ? <>
          <LoadingSpinner />
          {isEdit
            ? <StoryEditShimmer />
            : <StoryShimmer />
          }
        </>
        : error
          ? <ErrorModal
            currentPageName={viewStoryPageName}
            title={t('retrieveStoryErrorMessage')}
            onRetry={() => window.location.reload()}
            errors={error}
            isActive
          />
          : story?.id
            ? (
              children
            )
            : (
              <ErrorModal
                currentPageName={viewStoryPageName}
                title={t('retrieveStoryErrorMessage')}
                onRetry={() => window.location.reload()}
                isActive
              />
            )}
    </>
  )
}

export function withStory(
  WrappedComponent: React.ComponentType<React.ComponentProps<any>>
) {
  return function StoryHOC(props: any) {
    const id = props.match.params.id;
    return (
      <StoryProvider id={id}>
        <WrappedComponent {...props} />
      </StoryProvider>
    );
  };
}
