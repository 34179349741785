import isBrowser from 'is-in-browser';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetchWrapper from './fetchWrapper';

const { REACT_APP_GRAPHQL_ENDPOINT } = process.env; // get();

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

if (isBrowser) {
  window.fetch = fetchWrapper(window.fetch) as any;
} else {
  (global as any).fetch = fetchWrapper((global as any).fetch);
}

function create(initialState) {
  return new ApolloClient({
    connectToDevTools: true,
    link: createUploadLink({ uri: `${REACT_APP_GRAPHQL_ENDPOINT}` }),
    cache: new InMemoryCache({
      addTypename: false,
      dataIdFromObject: (object, context) => {
        return object.id?.toString();
      },
    }).restore(initialState || {}),
    // defaultOptions,
  });
}

export default function initApollo(initialState?) {
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
}
