import styled from 'styled-components';

import { spacing8, spacing16, grey5 } from '@churchofjesuschrist/eden-style-constants';

export const DisplaySelectedOrgList = styled.div`
  margin: ${spacing16} 0 0 ${spacing8};
`;

export const OrganizationItem = styled.div`
  margin: ${spacing8};
  border-bottom: 1px solid ${grey5};
`;
