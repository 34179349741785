import React from 'react';
import { useQuery } from '@apollo/client';
import { object, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import { Text3 } from '@churchofjesuschrist/eden-text';

// components
import DeleteStoryButton from '../../Buttons/DeleteStoryButton';
import StoryContent from './StoryContent';
import { EditStoryButton } from '../../Buttons/EditStoryButton';
// import { SendReminderButton } from '../../Buttons/SendReminderButton';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import InContextHelp from '../../InContextHelp/InContextHelp';
import { usePageContext } from 'src/contexts/PageContext';
import {
  LockedByMessage,
  LockedUntilMesssage,
} from '../../Modals/StoryLocked/StoryLocked';

// functions
import { formattedDisplayName, formatDate } from 'src/utils/AppFunctions/appFunctions';

// constants
import {
  StoryContainer,
  LabelContainer,
  InfoContainer,
  AssigneeContainer,
  DateContainer,
  Buttons,
} from './DisplayUnpublishedStories.style';
import { userType, pendingStoryVarPropType } from '../../../utils/appConstants/propTypesConstants';
import { STORY_STATUSES } from '../../../utils/appConstants/appConstants';

// functions
import { getStoryManagerQuery } from '../../../utils/graphQL_Queries/appQueries';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { TabStoriesShimmer } from 'src/components/Shimmer/CardShimmer';
import { IStoryManagerResults, Story } from 'src/models/Story';

const { DRAFT_STATUS } = STORY_STATUSES;

const DraftStories = ({ currentPageName, manageStories, storyVariables }: { currentPageName, manageStories, storyVariables }) => {
  const { t } = useTranslation('strings');
  const { localeCode } = usePageContext();
  const { user } = useUserPermissions();

  const renderAuthorList = authorList => authorList.map(author => formattedDisplayName(author.name)).join(', ');

  const { data, error, loading, refetch } = useQuery<IStoryManagerResults>(getStoryManagerQuery, {
    fetchPolicy: 'network-only',
    variables: storyVariables,
    displayName: 'draftStories-Query'
  });

  if (loading) return <TabStoriesShimmer />;
  if (error ?? !data) {
    return (
      <ErrorModal
        currentPageName={currentPageName}
        title={t('retrieveStoryErrorMessage')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />
    );
  }

  if (data.storyManager.total === 0) {
    return (
      <StoryContainer data-test-id="draft_story_container0">
        {manageStories && <Text3>{t('noStoriesManageDraftsLabel')}</Text3>}
        {!manageStories && <Text3>{t('noStoriesMyStoriesDraftLabel')}</Text3>}
      </StoryContainer>
    );
  }

  return (
    <>
      {data.storyManager.content.map((story: Story, index) => {
        const {
          status,
          id,
          created,
          submitted,
          modified,
          published,
          title,
          lock,
          authors = [],
        } = story;

        const locked = (lock && lock.accountId !== user?.accountId) ?? false;

        return (
          <StoryContainer key={id} data-test-id={`draft_story_container${index}`}>
            <StoryContent
              currentPageName={currentPageName}
              index={index}
              status={status}
              story={story}
              title={status}
            />

            <InfoContainer data-test-id="drafts_info_container">
              {manageStories && (
                <AssigneeContainer>
                  <LabelContainer>{t('authorLabel')}:</LabelContainer>
                  <Text3>{renderAuthorList(authors)}</Text3>
                </AssigneeContainer>
              )}
              <DateContainer>
                <LabelContainer>{t('dateCreatedLabel')}: </LabelContainer>
                <Text3>{created ? formatDate(created, localeCode) : ''}</Text3>
              </DateContainer>
              <DateContainer>
                <LabelContainer>
                  {submitted ? t('dateSubmittedLabel') : t('dateModifiedLabel')}:{' '}
                </LabelContainer>
                <Text3>{(submitted || modified) ? formatDate(submitted ?? modified!, localeCode) : ''}</Text3>
              </DateContainer>
              {status === DRAFT_STATUS && (submitted || published) && (
                <DateContainer>
                  <LabelContainer>{t('returnedForReworkLabel')}</LabelContainer>
                  <Text3>{modified ? formatDate(modified, localeCode) : ''}</Text3>

                  <InContextHelp
                    // label={t('disabledButtonsLabel')}
                    index={0}
                  >
                    <div style={{ zIndex: 2 }}>{t('returnedForReworkTooltip')}</div>
                  </InContextHelp>
                </DateContainer>
              )}

              <Buttons data-test-id={`draft_buttons-${index}`} isMobile={false}>
                <EditStoryButton
                  id={id as string}
                  currentPageName={currentPageName}
                  index={index}
                />

                <DeleteStoryButton
                  storyId={id as string}
                  storyTitle={title}
                  currentPageName={currentPageName}
                  index={index}
                  disabled={locked} // disable while someone is editing the record
                  onAfterDelete={async () => await refetch()}
                />

                {!!lock && (
                  <InContextHelp
                    // label={t('disabledButtonsLabel')}
                    index={index}
                  >
                    <div style={{ zIndex: 101 }}>
                      <LockedByMessage lockObj={lock} />
                      <p>{t('storyLockedMessage')}</p>
                      <p>
                        <LockedUntilMesssage lockObj={lock} />
                      </p>
                    </div>
                  </InContextHelp>
                )}

                {/* <SendReminderButton story={story} /> */}
              </Buttons>
            </InfoContainer>
          </StoryContainer>
        )
      })}
    </>
  );
}

export default DraftStories;

(DraftStories as any).propTypes = {
  currentPageName: string.isRequired,
  manageStories: bool,
  storyVariables: pendingStoryVarPropType,
  user: userType,
  activeUnitObj: object,
};
