import React, { Fragment, useState } from 'react';
import { bool, func, string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import Row from '@churchofjesuschrist/eden-row';
import { Info } from '@churchofjesuschrist/eden-alert';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { Text4 } from '@churchofjesuschrist/eden-text';
import InContextHelp from 'src/components/InContextHelp/InContextHelp';

// components
import Modal from '@churchofjesuschrist/eden-tool-modal';
import { Label, TextArea, Checkbox } from '@churchofjesuschrist/eden-form-parts';

// styles
import { CheckboxLabel, Box } from 'src/utils/appCSS/appStyles';

// constants
import {
  MOOD_SUBMIT,
  MOOD_REWORK
} from 'src/utils/appConstants/appConstants';
import styled from 'styled-components';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const FlexBox = styled(Box)`
  display: flex;
`;

const OverflowModal = styled(Modal)`
  & div {
    overflow: visible
  }
`

export const ConfirmSubmissionModal = (props: { isActive?, handleClose?, unitType?: ldsxrm_unittypes, mood?, handleCompletion?, story?, storyId?}) => {
  const { t } = useTranslation('strings');
  const { isActive, handleClose, unitType, mood } = props;
  const moodSubmit = mood === MOOD_SUBMIT;
  const moodRework = mood === MOOD_REWORK;

  const [sensitive, setSensitive] = useState(false);
  const [sensitiveComments, setSensitiveComments] = useState('');

  const sensitiveChange = () => {
    setSensitive(!sensitive);
  }

  const sensitiveCommentsChange = (event) => {
    setSensitiveComments(event.target.value);
  }

  const heading = moodRework
    ? t('returnForReworkTitle')
    : moodSubmit
      ? t('submitConfirmTitle')
      : t('publishConfirmTitle');

  const alert = unitType === ldsxrm_unittypes.Ward
    ? (moodRework
      ? t('returnToReworkConfirmMessage')
      : moodSubmit
        ? t('submitConfirmMessage')
        : t('publishConfirmMessage'))
    : unitType === ldsxrm_unittypes.Stake
      ? (moodRework
        ? t('returnToReworkConfirmMessage')
        : moodSubmit
          ? t('submitConfirmMessageStake')
          : t('publishConfirmMessageStake'))
      : null;
  return (
    <Fragment>
      {/* {isActive && <AlertClickBlocker opacity={0.6} color={black} zIndex={3} />} */}
      <OverflowModal active={isActive} heading={heading} onCloseGesture={handleClose}>
        <Box p={32}>
          {alert &&
            <Box mb={15}>
              <Info>
                <Text4>
                  {alert}
                </Text4>
              </Info>
            </Box>
          }
          <FlexBox p={5}>
            <CheckboxLabel>
              {t('containsSensitiveInfo')}
              <Checkbox checked={sensitive} value="sensitive" onChange={sensitiveChange} />
            </CheckboxLabel>
            <InContextHelp index={0}>
              {t('approverSpcVerify')}
            </InContextHelp>
          </FlexBox>
          <Box p={5} mb={5}>
            <Label>
              {t('sensitiveInfoComments')}
              <TextArea value={sensitiveComments} disabled={!sensitive} onChange={sensitiveCommentsChange}></TextArea>
            </Label>
          </Box>
          <Row>
            <Primary
              onClick={(e) => props.handleCompletion(e, sensitive, sensitiveComments)} // if the user approves, then invoke the method
              data-test-id="button_submit-confirmation"
            >
              {moodRework
                ? t('returnForReworkBtnLabel', { ns: 'labels' })
                : moodSubmit
                  ? t('submitBtnLabel', { ns: 'labels' })
                  : t('publishBtnLabel', { ns: 'labels' })}
            </Primary>

            <Secondary onClick={handleClose}>{t('cancelBtnLabel', { ns: 'labels' })}</Secondary>
          </Row>
        </Box>
      </OverflowModal>
    </Fragment>
  );
}

export default ConfirmSubmissionModal;

(ConfirmSubmissionModal as any).propTypes = {
  isActive: bool.isRequired,
  handleClose: func.isRequired,
  handleCompletion: func.isRequired,
  unitType: number,
  mood: string.isRequired,
};
