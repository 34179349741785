import React from 'react';
import { array, string, func, bool } from 'prop-types';

import Tag from '@churchofjesuschrist/eden-tag';

import beautify from 'src/utils/componentFunctions/beautify-words';
import { UnitOrganization } from 'src/models/Unit';

export const TagNoMutation = ({ terms, organizations = [], removeAllFun, tag, removeTermsFunc, clearAllFlag }: { terms?, organizations?, removeAllFun?, tag?, removeTermsFunc?, clearAllFlag? }) => {
  const removeAll = () => {
    const updatedOrganizations = organizations;

    while (updatedOrganizations.length) {
      updatedOrganizations.splice(0, 1);
    }

    removeAllFun(terms.replace(terms, ''), updatedOrganizations); // Will trigger refetch
  };

  return (
    terms
      ? (
        <Tag
          showClose
          onClick={() =>
            clearAllFlag ? removeAll() : removeTermsFunc(terms.replace(tag, ''))
          } // removes the first occurence of tag from terms
        >
          {terms}
        </Tag>
      )
      : <></>
  );
}

export const TagArrayNoMutation = ({ activeUnits = [], organizations = [], id, removeTagFunc, refetch, tagArray = [] }: { activeUnits?: any[], organizations?: UnitOrganization[], id?, removeTagFunc?, refetch?, tagArray?: string[] }) => {
  const handleTagRemoval = (selectedTag: string) => {
    if (id === 'unit') {
      const updatedUnits = activeUnits;

      // remove from list
      const index = updatedUnits.indexOf(selectedTag);
      updatedUnits.splice(index, 1);

      removeTagFunc(updatedUnits);
    } else {
      const updatedOrganizations = [...tagArray];

      const index = updatedOrganizations.findIndex(o => o === selectedTag);
      updatedOrganizations.splice(index, 1);

      removeTagFunc(updatedOrganizations);
    }
    refetch?.();
  };

  return (
    <>
      {organizations.map(tag => (
        <Tag showClose={true} key={tag.uuid} onClick={() => handleTagRemoval(tag.uuid)}>
          {beautify(tag.name)}
        </Tag>
      ))}
    </>
  )
}

export default TagArrayNoMutation;

(TagArrayNoMutation as any).propTypes = {
  id: string,
  organizations: array.isRequired,
  activeUnits: array,
  removeTagFunc: func,
  refetch: func,
};

(TagNoMutation as any).propTypes = {
  terms: string,
  tag: string,
  organizations: array,
  removeAllFun: func,
  removeTermsFunc: func,
  refetch: func,
  clearAllFlag: bool,
};
