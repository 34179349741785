import React from 'react';
import styled from 'styled-components';
import Grid from '@churchofjesuschrist/eden-grid';
import Row from '@churchofjesuschrist/eden-row';
import { Card } from '@churchofjesuschrist/eden-tile-parts';
import { spacing16, grey15 } from '@churchofjesuschrist/eden-style-constants';
import { ShimmerImage, ShimmerTitle, ShimmerSubtitle, ShimmerMeta, ShimmerButton } from './Shimmer';
import { range } from 'lodash';
import { InfoContainer, StoryContainer } from '../Stories/DisplayUnpublishedStories/DisplayUnpublishedStories.style';
import { Box } from 'src/utils/appCSS/appStyles';
import { HomeAddStoryCard } from '../Stories/StoryCard/ExampleStoryCard';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const ShimmerCard = styled(Card)`
  min-width: 275px;
`;

const ShimmerContent = styled.div`
  margin-top: 1em;
  height: 7em;
`;

export const StoriesHeader = styled.div`
  height: 50px;
  padding-top: ${spacing16};
  margin-bottom: ${spacing16};
  border-bottom: 1px solid ${grey15};
`;

const CardShimmer = () =>
  <ShimmerCard>
    <ShimmerImage />
    <ShimmerContent>
      <ShimmerTitle />
      <ShimmerSubtitle />
    </ShimmerContent>
    <ShimmerMeta />
  </ShimmerCard>;

export const StoriesShimmer = ({ displayCreate }: { displayCreate?: boolean }) => {
  const { canCreate } = useUserPermissions();
  return (
    <>
      {displayCreate &&
        <StoriesHeader />}
      <Grid gapSize="16" rowGapSize="16" min="275px">
        {displayCreate && canCreate &&
          <HomeAddStoryCard />
        }
        {range(8).map(n => <CardShimmer key={n} />)}
      </Grid>
    </>
  )
}

export const TabStoriesShimmer = () => {
  return (
    <>
      {range(7).map(n =>
        <StoryContainer key={n}>
          <CardShimmer />
          <InfoContainer>
            <Row>
              <ShimmerSubtitle />
              <ShimmerSubtitle />
            </Row>
            <Row>
              <ShimmerSubtitle />
              <ShimmerSubtitle />
            </Row>
            <Box pt={32}>
              <ShimmerButton />
            </Box>
          </InfoContainer>
        </StoryContainer>
      )}
    </>
  )
}

export default CardShimmer;
