import React from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';

// Eden
import Row from '@churchofjesuschrist/eden-row';

import {
  DropDownContainer,
  DropDownTitle,
} from 'src/components/SortAndSearchBar/SortAndSearchStyles';

export const TotalStorySorted = ({ totalStories }) => {
  const { t } = useTranslation('strings');

  return (
    <DropDownContainer paddingTop="0px">
      <Row>
        <DropDownTitle data-test-id="story-results-total">{t('resultsLabel')} ({totalStories})</DropDownTitle>
      </Row>
    </DropDownContainer>
  )
};

export default TotalStorySorted;

TotalStorySorted.propTypes = {
  totalStories: number.isRequired,
};
