import { pageNames } from 'src/utils/appConstants/appConstants';
import { addQueryParam } from 'src/utils/AppFunctions/appFunctions';
const { exampleStoriesPageName } = pageNames;

export const NAV_CREATE = '/admin/create';
export const NAV_FAVES = '/admin/favorites';
export const NAV_STORIES = '/admin/viewstories';
export const NAV_LOGIN = '/login';
export const NAV_LOGOUT = '/logout';
export const NAV_INVITE = '/sendInvite';
export const NAV_REPORTS = '/reports';
export const NAV_EXAMPLE = '/help/example';
export const NAV_EXAMPLES = '/help/examples';
export const NAV_UPLOAD = '/upload';
export const NAV_QUICK_START = '/help/quick-start';
export const NAV_QUICK_START_GUIDE = '/help/quick-start-guide';
export const NAV_QUICK_START_CREATE = '/help/quick-start-create';
export const NAV_WHAT_SHOULD_I_LEAVE_OUT = '/help/quick-start-create#leaveout';
export const NAV_RENLUND_QUOTE = '/help/quick-start-create#renlund-quote';
export const NAV_WHATS_NEW = '/help/whats-new';
export const NAV_FAQ = '/help/faq';

export const setCreateStoryUrl = () => NAV_CREATE;
export const setEditStoryUrl = (storyId, prevPageName) => `/admin/edit/${storyId}?edit=true&prev_page=${prevPageName}`;
export const setQSTopicAnchor = (topic) => `${NAV_QUICK_START_GUIDE}#${topic}`;
export const setQSTopicPageUrl = (topic) => `${NAV_QUICK_START}-${topic}`;
export const setFaqUrl = (questionNumber, idToJumpTo) =>
  `${NAV_FAQ}?quest=${questionNumber}#${idToJumpTo}`;
export const setExamplesURL = () => NAV_EXAMPLES;
export const setFavoritesUrl = () => NAV_FAVES;
export const setHomeUrl = () => '/';
export const setInviteUrl = () => `/admin/manage${NAV_INVITE}`;
export const setManageStoryUrl = (tabName) => `/admin/manage/${tabName}`;
export const setMyStoryUrl = (tabName) => `/admin/my-stories/${tabName}`;
export const setViewStoryUrl = (storyId, prevPageName) =>
  prevPageName === exampleStoriesPageName
    ? `${NAV_EXAMPLE}/${storyId}`
    : `/admin/view/${storyId}?page=${prevPageName}`;
export const setReportUrl = (report) => `${NAV_REPORTS}/${report}`;
export const ChurchHeaderLink = 'https://header-int.churchofjesuschrist.org';
export const TermsOfUsePolicyLink =
  'https://www.churchofjesuschrist.org/legal/local-unit-history-submission-agreement?lang=eng&country=go';
export const getContentSubmissionPolicyURL = (language = 'eng') => addQueryParam({ url: TermsOfUsePolicyLink, host: undefined, key: 'lang', value: language }).toString();
