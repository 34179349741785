import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

// eden

// components
import Notification from '../NotificationManager/NotificationManager';

// notification context
import { useNotificationContext } from '../../contexts/NotificationContext';

// constants
import {
  pageNames,
  MESSAGE_MIN_WIDTH,
  MESSAGE_MAX_WIDTH,
} from '../../utils/appConstants/appConstants';
import { usePageContext } from 'src/contexts/PageContext';

const { createPageName, editPageName, invitePageName, helpPageName } = pageNames;
const DivWrapper = styled.div<{ isForm: boolean }>`
  width: 100%;
  @media(min-width:1600px) {
    padding: 0 22rem;
  }
  @media(max-width:1600px) {
    padding: 0 15rem;
  }
  @media(max-width:1400px) {
    padding: 0 12rem;
  }
  @media(max-width:1260px) {
    padding: 0 8rem;
  }
  @media(max-width:1130px) {
    padding: 0 6rem;
  }
  @media(max-width:1065px) {
    padding: 0 3rem;
  }
  @media(max-width:975px) {
    padding: 0 1rem;
  }
`;

const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 25px;
  min-width: ${MESSAGE_MIN_WIDTH};
  max-width: ${MESSAGE_MAX_WIDTH};
  z-index: 2;
`;

const PageWrapper = ({ children }: { children }) => {
  const { messages } = useNotificationContext();
  const { pageName } = usePageContext();
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to top if route changes
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const isForm = [createPageName, editPageName, invitePageName, helpPageName].includes(
    pageName
  );

  return (
    <DivWrapper data-test-id={`pageWrapper-${pageName}`} isForm={isForm}>
      {children}
      <NotificationWrapper id="notification-wrapper">
        {messages.map(notification => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </NotificationWrapper>
    </DivWrapper>
  );
}

export default PageWrapper;
