import styled from 'styled-components';
import {
  white,
  sans as zoram,
  fontSize32,
  lineHeight,
  info40,
} from '@churchofjesuschrist/eden-style-constants';
import { Clear } from '@churchofjesuschrist/eden-icons';

const HEADER_HEIGHT = '96px';
const FAQ_WIDTH = '800px';

export const FaqBody = styled.div`
  padding: 0 32px;
`;

export const FaqTitle = styled.div`
  font-size: ${fontSize32};
  line-height: ${lineHeight};
  padding: 32px;
  font-family: ${zoram};
`;

export const ClearBtn = styled(Clear)`
  cursor: 'pointer';
  right: 32px;
  top: 32px;
  position: fixed;
`;

export const FaqHeader = styled.div`
  background-color: ${white};
  height: ${HEADER_HEIGHT};
  width: ${FAQ_WIDTH};
  position: relative;
`;

export const SectionTitle = styled.div<{ id: any }>`
  padding: 32px 0 27px;
  font-family: ${zoram};
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #e0e2e2;
`;

export const QuestionTitle = styled.div`
  padding: 23px 24px;
  color: ${info40};
`;
