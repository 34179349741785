import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { TabContent } from '../../components/TallyTabTotal/TabContent';
import { UnitSelectorContainer } from '../../components/SelectUnit/SelectUnit';

// styles
import { PageTitle, TitleRow } from '../../utils/appCSS/appStyles';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_MY_STORIES } from 'src/utils/analytics';
import {
  pageNames,
  // CONTRIBUTOR
} from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const { myStoriesPageName, homePageName } = pageNames;

const MyStories = ({ match }: any) => {
  const tabName = useMemo(() => match.params.tab, [match]);
  const { activeUnitObj, user } = useUserPermissions();
  const { t, i18n } = useTranslation('strings');

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: myStoriesPageName, // always use English
        activePage: tabName, // used to set primary category
        breadCrumbs: [homePageName, myStoriesPageName, tabName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_MY_STORIES,
        userInfo: user,
      });
    }

    addAnalytics();
  }, [tabName]);

  return (
    <>
      <TitleRow>
        <PageTitle>{t('myStoriesLabel')}</PageTitle>
        <UnitSelectorContainer>
          <div>
            {`${activeUnitObj.unitName} (${activeUnitObj.unitNumber})`}
            {/* currentUnits.length > 1 && (
                        <UnitDropDownSelector
                          units={currentUnits}
                          activeUnitId={activeUnitObj.id}
                          changeHandler={handleUnitChange}
                          strings={strings}
                          showId
                          // showHelp
                        />
                    ) */}
          </div>
        </UnitSelectorContainer>
      </TitleRow>

      <TabContent
        pageName={myStoriesPageName}
        tabName={tabName}
        activeUnitObj={activeUnitObj}
      />
    </>
  );
};

export default withPage({ pageName: myStoriesPageName, pageTitle: 'myStoriesPageTitle' })(MyStories);
