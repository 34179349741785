import React, { useState } from 'react';

// components
import UnitBadge from '../components/UnitBadge';
import InviteForm from '../components/Invite/InviteForm';
import { ButtonBar } from '@churchofjesuschrist/eden-button-bar';
import InvitesSentStories from '../components/Stories/DisplayUnpublishedStories/InvitesSentStories';

// Queries
import { setStoryByInviterQueryParams } from '../utils/graphQL_Queries/queryVariableFormats';

// styles
import { Line, PageSubTitle } from '../utils/appCSS/appStyles';

// utilities, constants
import { addChangeViewAnalytics, CATEGORY_TYPE_INVITATIONS, } from 'src/utils/analytics';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import {
  pageNames,
  orderByDirections,
  orderByProperties,
  STORY_STATUSES
} from '../utils/appConstants/appConstants';

const { PENDING_INVITE } = STORY_STATUSES;
const { ORDER_BY_MODIFIED } = orderByProperties;
const { DESCENDING } = orderByDirections;
const { sendInvitePageName } = pageNames;
const ACTION_SEND = 'send invitations';
const ACTION_MANAGE = 'manage invitations';

const SendInvite = () => {
  const { t } = useTranslation('strings');
  const { user, activeUnitObj, ...permissions } = useUserPermissions();
  const { canApprove, canEdit, canView, isAdmin } = permissions;
  const [sendingInvite, setSendingInvite] = useState(true);

  const storyVariables = setStoryByInviterQueryParams(
    null,
    null,
    PENDING_INVITE,
    [ORDER_BY_MODIFIED],
    DESCENDING
  );

  React.useEffect(() => {
    // add analytics
    addChangeViewAnalytics({
      view: sendingInvite ? ACTION_SEND : ACTION_MANAGE,
      categoryType: CATEGORY_TYPE_INVITATIONS,
    });
  }, [sendingInvite]);

  const handleInviteModeChange = (value) => {
    setSendingInvite(value === ACTION_SEND)
  };

  return (
    <>
      <style>
        {`
          #inviteButtonBar {
            margin-top: 30px;
          }            
        `}
      </style>

      <ButtonBar id="inviteButtonBar" onChange={handleInviteModeChange}>
        <option value={ACTION_SEND} label={t('invitesButtonBarSend')} />
        <option value={ACTION_MANAGE} label={t('invitesButtonBarManage')} />
      </ButtonBar>

      {canApprove && (
        <>
          {sendingInvite && (
            <>
              <PageSubTitle>
                <div>
                  <UnitBadge unit={activeUnitObj}></UnitBadge>
                </div>
                {t('sendInviteLabel')}
              </PageSubTitle>

              <Line />

              {activeUnitObj && (
                <InviteForm
                  user={{ ...user, canApprove, canEdit, canView, isAdmin }}
                  activeUnitObj={activeUnitObj}
                />
              )}
            </>
          )}

          {!sendingInvite && (
            <>
              <InvitesSentStories
                manageStories={true}
                currentPageName={sendInvitePageName}
                storyVariables={storyVariables}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default SendInvite;
