import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import './custom.css'
import styled from 'styled-components';

// Eden
import Normalize from '@churchofjesuschrist/eden-normalize';
import Fonts from '@churchofjesuschrist/eden-fonts';

import { useNotificationContext, withNotificationContext } from './contexts/NotificationContext'; // Global notification context
import Home from './pages/Home';
import AuthorizeRoute from './components/Authentication/AuthorizeRoute';
import AdminRoutes from './pages/admin/AdminRoutes';
import HelpRoutes from './pages/help/HelpRoutes';
import ActivityPage from './pages/reports/Activity';
import SendInvite from './pages/SendInvite';
import Error from './pages/Error';
import NotAuthorized from './pages/NotAuthorized';
import { useCookies } from 'react-cookie';
import './i18n';
import NavigationBar from './components/Navigation/NavigationBar';
import FeedbackForm from './components/Feedback/FeedbackForm';
import { useTranslation } from 'react-i18next';
import { PageProvider } from './contexts/PageContext';
import { PanelProvider } from './contexts/PanelContext';
import Upload from './pages/upload';
import UploadComplete from './pages/upload/UploadComplete';
import Login from './pages/Login';
import {
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  LANGUAGE_COOKIE,
} from './utils/appConstants/appConstants';

import { useQuery } from '@apollo/client';
import { getOrganizationsQuery, UNITS_NO_CHILDREN_QUERY } from 'src/utils/graphQL_Queries/appQueries';
import { setUnitOrgs, setUnits } from 'src/reducers/unitsReducer';
import { useAppDispatch } from './utils/appStore';
import { setUnitsQueryParams } from './utils/graphQL_Queries/queryVariableFormats';

const FullHeight = styled.div`
  min-height: 100vh;
`;

interface IAuthorizeConditionalRouteProps {
  component?: React.ComponentType<any>
  path?: string
  exact?: boolean
  authorize?: boolean
  suspense?: boolean
  children?: JSX.Element
  adminRoute?: boolean
}
const AuthorizeConditionalRoute = ({ path, exact, authorize, suspense, component: Component, children, adminRoute }: IAuthorizeConditionalRouteProps) => {
  if (Component) {
    if (authorize) {
      return (
        <AuthorizeRoute
          path={path}
          exact={exact}
          suspense={suspense}
          adminRoute={adminRoute}>
          <Component />
        </AuthorizeRoute>
      )
    }
    return (
      <Route
        path={path}
        exact={exact}
        render={props => <Component {...props} />}
      />
    )
  }
  if (authorize) {
    return (
      <AuthorizeRoute
        path={path}
        exact={exact}
        suspense={suspense}
        adminRoute={adminRoute}>
        {children ?? <Error />}
      </AuthorizeRoute>
    )
  }
  return (
    <Route
      path={path}
      exact={exact}
    >
      {children ?? <Error />}
    </Route>
  )
}

/** Start queries that take longer to load the home page. */
const PreHome = () => {
  const dispatch = useAppDispatch();

  useQuery(UNITS_NO_CHILDREN_QUERY, {
    variables: setUnitsQueryParams(0, ''),
    onCompleted(data) {
      dispatch(setUnits(data?.units));
    }
  });

  useQuery(getOrganizationsQuery, {
    onCompleted(data) {
      dispatch(setUnitOrgs(data?.organizations));
    },
  });

  return <></>;
}

const App = () => {
  const { t, i18n } = useTranslation();
  const { addMessage } = useNotificationContext();
  const location = useLocation();
  const isUpload = location.pathname.includes('/upload');

  const [cookies] = useCookies([LANGUAGE_COOKIE]);

  useEffect(() => {
    const language = cookies[LANGUAGE_COOKIE];
    // console.log(`user language: ${language}`);
    i18n.changeLanguage(language);
  }, [cookies]);

  const handleFeedbackStatusUpdate = (success: boolean) => {
    // Add message to notification queue.
    if (success) {
      addMessage({
        title: t('feedbackSuccessLabel', { ns: 'strings' }),
        message: '',
        messageType: MESSAGE_SUCCESS,
      });
    } else {
      addMessage({
        title: t('errorTextLabel', { ns: 'strings' }),
        message: t('feedbackErrorLabel', { ns: 'strings' }),
        messageType: MESSAGE_ERROR,
      });
    }
  };

  return (
    <FullHeight>
      <Normalize />
      <Fonts />
      <NavigationBar />
      <PanelProvider>
        <PageProvider>
          <Switch>
            {/** Auth Routes **/}
            <Route path="/logout" exact>
              <p>logging you out...</p>
              <Redirect to="/" />
            </Route>
            <Route path="/login" exact>
              <Login />
            </Route>

            {/** Main Routes **/}
            <Route path="/" exact>
              <PreHome />
              <AuthorizeConditionalRoute authorize suspense exact path="/" component={Home} />
            </Route>
            <AuthorizeConditionalRoute path="/reports/activity" authorize exact component={ActivityPage} />
            <AuthorizeConditionalRoute path="/admin" component={AdminRoutes} />
            <AuthorizeConditionalRoute path="/sendInvite" authorize component={SendInvite} />
            <AuthorizeConditionalRoute path="/help" component={HelpRoutes} />

            {/** Upload Routes **/}
            <AuthorizeRoute exact path="/upload/complete">
              <UploadComplete />
            </AuthorizeRoute>
            <AuthorizeRoute exact path="/upload">
              <Upload />
            </AuthorizeRoute>

            {/** Default Routes **/}
            <Route path="/not-authorized" component={NotAuthorized} />
            <Route component={Error} />
          </Switch>
          {!isUpload &&
            <FeedbackForm
              handleFeedbackStatusUpdate={handleFeedbackStatusUpdate}
            />
          }
        </PageProvider>
      </PanelProvider>
    </FullHeight>
  );
}

export default withNotificationContext(App);
