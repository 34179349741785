import styled from 'styled-components';
import {
  grey10,
  fontSize14,
  sans as zoram,
  danger60,
  spacing8,
  lineHeight,
} from '@churchofjesuschrist/eden-style-constants';

export const BottomLine = styled.div`
  width: 100%;
  border-top: 1px solid ${grey10};
  padding-top: 16px;
`;

export const FormError = styled.div<{ validityState?: string }>`
  color: ${danger60};
  margin: ${spacing8} ${spacing8} ${spacing8} 0;
  font: normal normal normal ${fontSize14} / ${lineHeight} ${zoram};
`;

export const TitleSection = styled.section`
  input {
    margin-top: 4px;
    width: 416px;
    display: block;
  }
`;
