import { UnitType } from 'src/models/Unit';
import { defaultPermissions } from '../../components/User/permissions';

// units
export const UNIT_WARD = 'WARD';
export const UNIT_GROUP = 'GROUP';
export const UNIT_BRANCH = 'BRANCH';
export const UNIT_DISTRICT = 'DISTRICT';
export const UNIT_STAKE = 'STAKE';
export const UNIT_MISSION = 'MISSION';
export const UNIT_AREA = 'AREA';
export const GLOBAL = 'GLOBAL';

// roles
export const ADMIN = 'ADMIN';
export const VIEWER = 'VIEWER';
export const APPROVER = 'APPROVER';
export const CONTRIBUTOR = 'CONTRIBUTOR';
export const REPORTER = 'REPORTER';

export const APP_ROLES = [ADMIN, VIEWER, APPROVER, CONTRIBUTOR, REPORTER];

// branch organizations
export const BRANCH_PRESIDENCY = 'BRANCH_PRESIDENCY';

// district organizations
export const DISTRICT_PRESIDENCY = 'DISTRICT_PRESIDENCY';

// ward organizations
export const BISHOPRIC = 'BISHOPRIC';
export const ELDERS_QUORUM = 'ELDERS_QUORUM';
export const PRIMARY = 'PRIMARY';
export const SUNDAY_SCHOOL = 'SUNDAY_SCHOOL';
export const RELIEF_SOCIETY = 'RELIEF_SOCIETY';
export const YOUNG_MEN = 'YOUNG_MEN';
export const YOUNG_WOMEN = 'YOUNG_WOMEN';

// stake organizations
export const STAKE_PRESIDENCY = 'STAKE_PRESIDENCY';
export const HIGH_COUNCIL = 'HIGH_COUNCIL';
export const STAKE_HIGH_COUNCIL = 'HIGH_COUNCIL';
export const STAKE_PRIMARY = 'STAKE_PRIMARY';
export const STAKE_SUNDAY_SCHOOL = 'STAKE_SUNDAY_SCHOOL';
export const STAKE_RELIEF_SOCIETY = 'STAKE_RELIEF_SOCIETY';
export const STAKE_YOUNG_MEN = 'STAKE_YOUNG_MEN';
export const STAKE_YOUNG_WOMEN = 'STAKE_YOUNG_WOMEN';

// stake and ward positions
export const UNKNOWN = 'UNKNOWN';
export const STAKE_PRESIDENT = 'STAKE_PRESIDENT';
export const STAKE_PRESIDENCY_FIRST_COUNSELOR = 'STAKE_PRESIDENCY_FIRST_COUNSELOR';
export const STAKE_PRESIDENCY_SECOND_COUNSELOR = 'STAKE_PRESIDENCY_SECOND_COUNSELOR';
export const STAKE_EXECUTIVE_SECRETARY = 'STAKE_EXECUTIVE_SECRETARY';
export const STAKE_CLERK = 'STAKE_CLERK';
export const STAKE_ASSISTANT_CLERK = 'STAKE_ASSISTANT_CLERK';
export const STAKE_HIGH_COUNCILOR = 'STAKE_HIGH_COUNCILOR';
export const STAKE_PRIMARY_PRESIDENT = 'STAKE_PRIMARY_PRESIDENT';
export const STAKE_PRIMARY_FIRST_COUNSELOR = 'STAKE_PRIMARY_FIRST_COUNSELOR';
export const STAKE_PRIMARY_SECOND_COUNSELOR = 'STAKE_PRIMARY_SECOND_COUNSELOR';
export const STAKE_PRIMARY_SECRETARY = 'STAKE_PRIMARY_SECRETARY';
export const STAKE_SUNDAY_SCHOOL_PRESIDENT = 'STAKE_SUNDAY_SCHOOL_PRESIDENT';
export const STAKE_SUNDAY_SCHOOL_FIRST_COUNSELOR = 'STAKE_SUNDAY_SCHOOL_FIRST_COUNSELOR';
export const STAKE_SUNDAY_SCHOOL_SECOND_COUNSELOR = 'STAKE_SUNDAY_SCHOOL_SECOND_COUNSELOR';
export const STAKE_SUNDAY_SCHOOL_SECRETARY = 'STAKE_SUNDAY_SCHOOL_SECRETARY';
export const STAKE_RELIEF_SOCIETY_PRESIDENT = 'STAKE_RELIEF_SOCIETY_PRESIDENT';
export const STAKE_RELIEF_SOCIETY_PRESIDENCY = 'STAKE_RELIEF_SOCIETY_PRESIDENCY';
export const STAKE_RELIEF_SOCIETY_FIRST_COUNSELOR = 'STAKE_RELIEF_SOCIETY_FIRST_COUNSELOR';
export const STAKE_RELIEF_SOCIETY_SECOND_COUNSELOR = 'STAKE_RELIEF_SOCIETY_SECOND_COUNSELOR';
export const STAKE_RELIEF_SOCIETY_SECRETARY = 'STAKE_RELIEF_SOCIETY_SECRETARY';
export const STAKE_YOUNG_MEN_PRESIDENT = 'STAKE_YOUNG_MEN_PRESIDENT';
export const STAKE_YOUNG_MEN_FIRST_COUNSELOR = 'STAKE_YOUNG_MEN_FIRST_COUNSELOR';
export const STAKE_YOUNG_MEN_SECOND_COUNSELOR = 'STAKE_YOUNG_MEN_SECOND_COUNSELOR';
export const STAKE_YOUNG_MEN_SECRETARY = 'STAKE_YOUNG_MEN_SECRETARY';
export const STAKE_YOUNG_WOMEN_PRESIDENT = 'STAKE_YOUNG_WOMEN_PRESIDENT';
export const STAKE_YOUNG_WOMEN_FIRST_COUNSELOR = 'STAKE_YOUNG_WOMEN_FIRST_COUNSELOR';
export const STAKE_YOUNG_WOMEN_SECOND_COUNSELOR = 'STAKE_YOUNG_WOMEN_SECOND_COUNSELOR';
export const STAKE_YOUNG_WOMEN_SECRETARY = 'STAKE_YOUNG_WOMEN_SECRETARY';
export const STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT =
  'STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT';
export const WARD_TEMPLE_FAMILY_HISTORY_LEADER =
  'WARD_TEMPLE_FAMILY_HISTORY_LEADER';
export const WARD_MISSION_LEADER = 'WARD_MISSION_LEADER';
export const PRESIDENT = 'PRESIDENT';
export const FIRST_COUNSELOR = 'FIRST_COUNSELOR';
export const SECOND_COUNSELOR = 'SECOND_COUNSELOR';
export const SECRETARY = 'SECRETARY';
export const BISHOP = 'BISHOP';
export const BISHOPRIC_FIRST_COUNSELOR = 'BISHOPRIC_FIRST_COUNSELOR';
export const BISHOPRIC_SECOND_COUNSELOR = 'BISHOPRIC_SECOND_COUNSELOR';
export const EXECUTIVE_SECRETARY = 'EXECUTIVE_SECRETARY';
export const WARD_CLERK = 'WARD_CLERK';
export const WARD_ASSISTANT_CLERK = 'WARD_ASSISTANT_CLERK';
export const WARD_MISSIONARIES = 'WARD_MISSIONARIES';
export const ELDERS_QUORUM_PRESIDENT = 'ELDERS_QUORUM_PRESIDENT';
export const ELDERS_QUORUM_FIRST_COUNSELOR = 'ELDERS_QUORUM_FIRST_COUNSELOR';
export const ELDERS_QUORUM_SECOND_COUNSELOR = 'ELDERS_QUORUM_SECOND_COUNSELOR';
export const ELDERS_QUORUM_SECRETARY = 'ELDERS_QUORUM_SECRETARY';
export const PRIMARY_PRESIDENT = 'PRIMARY_PRESIDENT';
export const PRIMARY_FIRST_COUNSELOR = 'PRIMARY_FIRST_COUNSELOR';
export const PRIMARY_SECOND_COUNSELOR = 'PRIMARY_SECOND_COUNSELOR';
export const PRIMARY_SECRETARY = 'PRIMARY_SECRETARY';
export const SUNDAY_SCHOOL_PRESIDENT = 'STAKE_SUNDAY_SCHOOL_PRESIDENT';
export const SUNDAY_SCHOOL_FIRST_COUNSELOR = 'SUNDAY_SCHOOL_FIRST_COUNSELOR';
export const SUNDAY_SCHOOL_SECOND_COUNSELOR = 'SUNDAY_SCHOOL_SECOND_COUNSELOR';
export const SUNDAY_SCHOOL_SECRETARY = 'SUNDAY_SCHOOL_SECRETARY';
export const RELIEF_SOCIETY_PRESIDENT = 'RELIEF_SOCIETY_PRESIDENT';
export const RELIEF_SOCIETY_FIRST_COUNSELOR = 'RELIEF_SOCIETY_FIRST_COUNSELOR';
export const RELIEF_SOCIETY_SECOND_COUNSELOR = 'RELIEF_SOCIETY_SECOND_COUNSELOR';
export const RELIEF_SOCIETY_SECRETARY = 'RELIEF_SOCIETY_SECRETARY';
export const YOUNG_MEN_PRESIDENT = 'YOUNG_MEN_PRESIDENT';
export const YOUNG_MEN_FIRST_COUNSELOR = 'YOUNG_MEN_FIRST_COUNSELOR';
export const YOUNG_MEN_SECOND_COUNSELOR = 'YOUNG_MEN_SECOND_COUNSELOR';
export const YOUNG_MEN_SECRETARY = 'YOUNG_MEN_SECRETARY';
export const YOUNG_WOMEN_PRESIDENT = 'YOUNG_WOMEN_PRESIDENT';
export const YOUNG_WOMEN_FIRST_COUNSELOR = 'YOUNG_WOMEN_FIRST_COUNSELOR';
export const YOUNG_WOMEN_SECOND_COUNSELOR = 'YOUNG_WOMEN_SECOND_COUNSELOR';
export const YOUNG_WOMEN_SECRETARY = 'YOUNG_WOMEN_SECRETARY';
export const HISTORY_SPECIALIST = 'HISTORY_SPECIALIST';
export const PUBLIC_AFFAIRS_SPECIALIST = 'PUBLIC_AFFAIRS_SPECIALIST';

// misc
export const AUTOSAVE_DURATION = 30000;
export const MAX_LOCK_DURATION = 48; // in hours
export const MAX_IMAGE_DIMENSION = 200;
export const LANGUAGE_COOKIE = 'lds-preferred-lang';
export const LANGUAGE_COOKIE_V2 = 'lds-preferred-lang-v2';

// defaults
export const defaultUnitObject = {
  type: UNIT_WARD,
  id: 0,
  name: '',
};

export const defaultUserObject = {
  accountId: 0,
  name: '',
  favorites: [],
  unit: defaultUnitObject,
  parentUnit: defaultUnitObject,
  position: '',
  contributorUnits: [],
  viewerUnits: [],
  ...defaultPermissions,
  roles: {},
};

export const appConstants = {
  DEFAULT_NUM_STORIES: 1000,
  DEFAULT_NUM_UNITS: 100,
  UNITS: [UNIT_AREA, UNIT_BRANCH, UNIT_DISTRICT, UNIT_MISSION, UNIT_STAKE, UNIT_WARD],
  UNIT_TYPES: [UnitType.Stake, UnitType.District, UnitType.Ward, UnitType.Branch],
  OLDEST_DATE: new Date('Jan 1, 100 00:00:00'),
};

export const orderByDirections = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export const orderByProperties = {
  ORDER_BY_AUTHOR: 'author',
  ORDER_BY_CREATED: 'created',
  ORDER_BY_DATE_END: 'dateEnd',
  ORDER_BY_DATE_START: 'dateStart',
  ORDER_BY_MODIFIED: 'modified',
  ORDER_BY_ORGANIZATION: 'organization',
  ORDER_BY_PARENT_UNIT: 'parentUnit',
  ORDER_BY_PUBLISHED: 'published',
  ORDER_BY_STATUS: 'status',
  ORDER_BY_TITLE: 'title',
  ORDER_BY_TOPIC: 'topic',
  ORDER_BY_UNIT: 'unit',
  ORDER_BY_DISPLAY_NAME: 'displayName',
  ORDER_BY_DATE_ADDED: 'dateAdded',
  ORDER_BY_NAME: 'name',
};

export type STORY_STATUS = {
  [S in STORY_STATUS_TYPE]: number
};

export type STORY_STATUS_TYPE = 'DRAFT' | 'CREATED' | 'PENDING' | 'PUBLISHED' | 'REQUESTED' | 'RETURNED';

export const STORY_STATUSES = {
  DRAFT_STATUS: 'DRAFT',
  CREATED_STATUS: 'CREATED',
  PENDING_STATUS: 'PENDING',
  PUBLISHED_STATUS: 'PUBLISHED',
  REQUESTED_STATUS: 'REQUESTED',
  RETURNED_STATUS: 'RETURNED',
  PENDING_INVITE: 'PENDING_INVITE',
  INVITE_TAB: 'SENDINVITE',
  INVITES_SENT: 'INVITESSENT'
};

export const UNIT_STATUSES = {
  STATUS_ACTIVE: 'ACTIVE',
  STATUS_INACTIVE: 'INACTIVE',
  STATUS_ARCHIVED: 'ARCHIVED',
  STATUS_UNKNOWN: 'UNKNOWN',
};

export const FORM_INPUT_STATUSES = {
  UNKNOWN: 'unknown',
  SUCCESS: 'success',
  ERROR: 'error',
};
export const pageNames = {
  addUnitPageName: 'add_unit_page',
  createPageName: 'create_story_page',
  editPageName: 'edit_story_page',
  errorPageName: 'error_page',
  favoritesPageName: 'favorites_page',
  helpPageName: 'help_page',
  helpFAQsPageName: 'help_page_faqs',
  helpQuickStartGuidePageName: 'help_page_quick_start_guide',
  helpCreatingStoriesPageName: 'help_page_creating_stories',
  helpApprovingStoriesPageName: 'help_page_approving_stories',
  helpRolesAndCallingsPageName: 'help_page_roles_and_callings',
  helpDoctrinalResourcesPageName: 'help_page_doctrincal_resources',
  homePageName: 'home_page',
  invitePageName: 'invite_page',
  lostPageName: 'lost_page',
  manageAdminRolesPageName: 'manage_admin_roles',
  manageDraftPageName: 'manage_draft_page',
  manageInvitesSentPageName: 'manage_invitessent_page',
  managePageName: 'manage_page', // remove when tabs are separate pages
  managePendingPageName: 'manage_pending_page',
  managePublishPageName: 'manage_publish_page',
  myStoriesPageName: 'myStories_page', // remove when tabs are separate pages
  myStoriesDraftPageName: 'myStories_draft_page',
  myStoriesPendingPageName: 'myStories_pending_page',
  myStoriesPublishPageName: 'myStories_publish_page',
  myStoriesReturnedPageName: 'myStories_returned_page',
  myStoriesPendingInvitesPageName: 'myStories_pending_invites_page',
  notAuthorizedPageName: 'not_authorized',
  nonPilotPageName: 'non_pilot_page',
  previewPageName: 'preview_page',
  storyFromPageName: 'story_form_page',
  sendInvitePageName: 'send_invite_page',
  underConstructionPageName: 'under_construction_page',
  viewStoryPageName: 'view_story_page',
  activityReportPageName: 'activity_report',
  exampleStoryInPanel: 'panel_example_story',
  exampleStoryPageName: 'example_story',
  exampleStoriesPageName: 'example_stories',
  noPage: '',
  uploadPageName: 'upload_page',
  changeRolePageName: 'change_role_page',
  viewStoriesPageName: 'view_stories_page'
};

export const WARD_ORGANIZATIONS = [
  BISHOPRIC,
  ELDERS_QUORUM,
  PRIMARY,
  SUNDAY_SCHOOL,
  RELIEF_SOCIETY,
  YOUNG_MEN,
  YOUNG_WOMEN,
  UNIT_WARD,
];

export const STAKE_ORGANIZATIONS = [
  STAKE_PRESIDENCY,
  STAKE_HIGH_COUNCIL,
  STAKE_PRIMARY,
  STAKE_SUNDAY_SCHOOL,
  STAKE_RELIEF_SOCIETY,
  STAKE_YOUNG_MEN,
  STAKE_YOUNG_WOMEN,
  UNIT_STAKE,
];

export const formValidityState = {
  validityStateErrorLabel: 'error',
  validityStateSuccessLabel: 'success',
  validityStateUnknownLabel: 'unknown',
  validityStateWarningLabel: 'warning',
};

export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';

// notifications
export const MESSAGE_DURATION = 7000;
export const MESSAGE_INFO = 'INFO';
export const MESSAGE_SUCCESS = 'SUCCESS';
export const MESSAGE_WARN = 'WARN';
export const MESSAGE_ERROR = 'ERROR';
export const MESSAGE_MIN_WIDTH = '330px';
export const MESSAGE_MAX_WIDTH = '530px';

export const AUTO_TRANSITION = 'AUTO_TRANSITION';
export const MOOD_SUBMIT = 'SUBMIT';
export const MOOD_PUBLISH = 'PUBLISH';
export const MOOD_REWORK = 'REWORK';
export const MOOD_BUTTON = 'BUTTON';

export const SESSION_STORAGE_AHT = 'annual_history';
export const emptyAppParams = {
  id: 0,
};

// reports
export const REPORT_ACTIVITY = 'activity';
export const REPORT_UNITS = 'units';
export const REPORT_TOTALS = 'totals';
export const REPORT_TYPE_DEFAULT = 'BY_UNIT';
export const REPORT_TYPES = [
  {
    label: 'By Unit',
    value: 'BY_UNIT',
  },
  {
    label: 'By Organization',
    value: 'BY_ORG',
  },
  {
    label: 'By Modifying Organization',
    value: 'BY_MODIFIER_ORG',
  },
];
export const EMPTY_UNIT = {
  id: 0,
  name: 'Unit Name',
  type: 'WARD',
  organizations: [{ name: '', totals: {} }],
  totals: {},
};

// messaging
export const MESSAGING_INVITE = 'MESSAGING_INVITE';
export const MESSAGING_REWORK = 'MESSAGING_REWORK';
export const MESSAGING_REMINDER = 'MESSAGING_REMINDER';

// device size configuration (media queries)
export const PANEL_WIDTH_BREAK_WIDTH = '431px'; // MUST be the same as DEVICE_SMALL_QUERY (needed for eden-panel)
export const DEVICE_XSMALL = 'XSMALL'; // always true (no media query performed)
export const DEVICE_SMALL = 'SMALL';
export const DEVICE_SMALL_QUERY = '(min-width: 431px)';
export const DEVICE_MEDIUM = 'MEDIUM';
export const DEVICE_MEDIUM_QUERY = '(min-width: 840px)';
export const DEVICE_LARGE = 'LARGE';
export const DEVICE_LARGE_QUERY = '(min-width: 1280px)';
export const DEVICE_XLARGE = 'XLARGE';
export const DEVICE_XLARGE_QUERY = '(min-width: 1600px)';
export const DEVICE_SIZES = [
  DEVICE_XSMALL,
  DEVICE_SMALL,
  DEVICE_MEDIUM,
  DEVICE_LARGE,
  DEVICE_XLARGE,
];
export const DEVICE_QUERIES = [
  DEVICE_SMALL_QUERY,
  DEVICE_MEDIUM_QUERY,
  DEVICE_LARGE_QUERY,
  DEVICE_XLARGE_QUERY,
];

// PDFs
export const CREATING_A_STORY = 'create_story';
export const PURPOSE_AND_VALUE = 'purpose_value';
export const UH_TOOL = 'uh_tool';

// WebVTT fiels
export const CONTRIBUTING = 'contributing';
export const SHARE_STORIES_OF_FAITH = 'share_stories_faith';
