import React from 'react';
import { Hint } from '@churchofjesuschrist/eden-form-parts';
import { useTranslation } from 'react-i18next';

const FieldValidityHint = ({
  field,
  check,
}: {
  field: any
  check?: boolean
}) => {
  const { t } = useTranslation();

  if (!field) return <></>;
  return !field.valid
    ? (
      <Hint>
        {field.valueMissing
          ? t(check ? 'check_required' : 'value_required', { ns: 'strings' })
          : ''}
        {field.patternMismatch ? t('check_value', { ns: 'strings' }) : ''}

        {
          // This uses the browser provided (and hopefully translated) validation message specific to the rangeOverflow or rangeUnderflow error messages
          field.rangeOverflow || field.rangeUnderflow
            ? field.validationMessage
            : ''
        }
      </Hint>
    )
    : (
      <></>
    );
};

export default FieldValidityHint;
