import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { warning40, spacing16 } from '@churchofjesuschrist/eden-style-constants';

import { Info as InfoIcon } from '@churchofjesuschrist/eden-icons';
import Row from '@churchofjesuschrist/eden-row';

import {
  DrawerTitleBold,
  DrawerTitleNonBold,
  WarningText,
} from 'src/components/Create/Form/Form.style';

export const BoldDrawerTitle = ({ title }) => (
  <Row>
    <DrawerTitleBold>{title}</DrawerTitleBold>
    <DrawerTitleNonBold />
  </Row>
);

export const DrawerTitleWithWarning = ({ title, warning }) => {
  const { t } = useTranslation('strings');
  return (
    <Row gapSize="8">
      <DrawerTitleBold>{title}</DrawerTitleBold>{' '}
      <span style={{ fontWeight: 'normal' }}>{t('optionalLabel')}</span>
      <div style={{ paddingLeft: spacing16 }}>
        <InfoIcon size="24px" color={warning40} />
      </div>
      <WarningText>{warning}</WarningText>
    </Row>
  )
};

BoldDrawerTitle.propTypes = {
  title: string.isRequired,
};

DrawerTitleWithWarning.propTypes = {
  title: string.isRequired,
  warning: string,
};
