import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// eden
import { Label, Checkbox, FieldSet } from '@churchofjesuschrist/eden-form-parts';
import { Title2, Title3 } from '@churchofjesuschrist/eden-headings';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { Add, Delete } from '@churchofjesuschrist/eden-icons';
import Modal from '@churchofjesuschrist/eden-platform-modal';
import Card from '@churchofjesuschrist/eden-card';
import { Body, Drawer, Handle } from '@churchofjesuschrist/eden-drawer';
import { RemoveContentModal } from '../Modals/RemoveContent/RemoveContent';
import { spacing8 } from '@churchofjesuschrist/eden-style-constants';

// components
import ClickIcon from '../ClickIcon/ClickIcon';
import { AuthorStatus, IAuthor } from '../../models/Author';

// functions
import beautify from '../../utils/componentFunctions/beautify-words';
import { formattedDisplayName } from '../../utils/AppFunctions/appFunctions';
// styles
import { FormError } from '../Invite/Invite.style';
import { DropDownContent, ScrollContent } from '../../utils/appCSS/appStyles';
import { ButtonContainer } from '../Modals/AddAuthor/AddAuthor.style';
import { Space } from '../Feedback/FeedbackFormStyles';
import {
  DisplayPeople,
  Heading,
  Person,
  PersonContainer,
  PersonItem,
  PersonName,
  PersonRole,
  PersonRow,
} from '../AddToStory/AddPerson.style';

// constants
import { buildCheckPeople, usePositionConstants } from '../../utils/appConstants/positionsConstants';
import { pageNames, UNKNOWN, formValidityState } from '../../utils/appConstants/appConstants';
import { UserUnit } from 'src/models/Unit';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const { validityStateUnknownLabel } = formValidityState;

const { invitePageName } = pageNames;

const SelectPerson = ({
  selectedPeople = [], fullAuthorList, handleUpdatePeople, reset, errorMessage,
  isMissingPerson,
  pageName,
  displayCallingList,
  activeUnitObj
}: {
  selectedPeople
  fullAuthorList
  handleUpdatePeople
  reset
  errorMessage
  isMissingPerson
  pageName
  displayCallingList: {
    auxiliaryName: string
    positions: any[]
  }[]
  activeUnitObj: UserUnit
}) => {
  const { WARD_AUXILIARIES_OBJ, STAKE_AUXILIARIES_OBJ, DISTRICT_AUXILIARIES_OBJ, BRANCH_AUXILIARIES_OBJ } = usePositionConstants();
  const [checkedPeople, setCheckedPeople] = useState(buildCheckPeople(selectedPeople));
  const [isPersonDropdownOpen, setIsPersonDropdownOpen] = useState(false);
  const [isConfirmModelOpen, setIsConfirmModelOpen] = useState(false);
  const [personToRemove, setPersonToRemove] = useState<{ uuid: string, name: string }>({ uuid: '', name: '' });
  const [tempSelected, setTempSelected] = useState({});
  const [peopleInitialized, setPeopleInitialized] = useState(false);

  const { t } = useTranslation();

  const removeMsg = t('removeAuthorMessage').replace('${authorName}', personToRemove.name);

  const handleBtnClick = () => {
    setIsPersonDropdownOpen(!isPersonDropdownOpen);
    setTempSelected(checkedPeople);
  };

  const handleDropdownClose = event => {
    event.stopPropagation();
    setCheckedPeople(tempSelected);
    setIsPersonDropdownOpen(false);
  };

  const handleCancelBtn = event => {
    handleDropdownClose(event);
  };

  const handleSubmitPeople = () => {
    const peopleObj = checkedPeople;
    const addedAuthors: any[] = [];

    for (const [key, value] of Object.entries(peopleObj)) {
      if (value) {
        const selectedAuthor = fullAuthorList.find(author => author.uuid === key);
        addedAuthors.push(selectedAuthor);
      }
    }

    setTempSelected(checkedPeople);
    setIsPersonDropdownOpen(false);

    handleUpdatePeople(addedAuthors);
  };

  const handlePersonOnChange = (idToChange, posToChange) => {
    const updatedCheckedPeople = { ...checkedPeople };

    if (idToChange) {
      updatedCheckedPeople[idToChange] = !checkedPeople[idToChange];
    } else if (posToChange) {
      updatedCheckedPeople[posToChange] = !checkedPeople[posToChange];
    }

    setCheckedPeople(updatedCheckedPeople);
  };

  const handleRemovePerson = remove => {
    let updatedSelectedPeople = [];
    const { uuid } = remove ? personToRemove : { uuid: '' };

    updatedSelectedPeople = selectedPeople.filter(
      person => person.uuid !== uuid
    );

    const updatedCheckedPeople = {
      ...checkedPeople,
      [uuid]: false,
    };

    handleUpdatePeople(updatedSelectedPeople);

    setCheckedPeople(updatedCheckedPeople);
    setTempSelected(updatedCheckedPeople)
    setIsConfirmModelOpen(false);
  };

  const handleOpenDeleteConformation = person => {
    setIsConfirmModelOpen(true);
    setPersonToRemove({ ...person });
  };

  const getPersonPosition = (person: IAuthor) => {
    const authors = fullAuthorList;
    if (!authors) {
      return t(UNKNOWN);
    }
    const position = [...STAKE_AUXILIARIES_OBJ, ...WARD_AUXILIARIES_OBJ, ...DISTRICT_AUXILIARIES_OBJ, ...BRANCH_AUXILIARIES_OBJ].flatMap(c => c.positions).find(p => p.id === person.position)?.displayName ??
      person.position;
    if (!position) {
      return t(UNKNOWN);
    }
    return position as string;
  };

  useEffect(() => {
    if (!peopleInitialized) {
      setPeopleInitialized(true);
      setCheckedPeople(buildCheckPeople(selectedPeople));
    }
    if (reset) {
      setCheckedPeople(buildCheckPeople(selectedPeople));
    }
  }, [reset, peopleInitialized, selectedPeople]);

  return (
    <PersonContainer>
      {pageName === invitePageName && (
        <Fragment>
          <Label id="addPerson" validityState={validityStateUnknownLabel}>
            {t('addRecipientLabel') + ' ' + t('requiredLabel')}
          </Label>
          <br />
        </Fragment>
      )}

      <Secondary
        onClick={handleBtnClick}
        data-test-id="addPersonDropDownBtn"
        style={{ marginTop: '4px' }}
      >
        <Add />
        <Space start={spacing8}>
          {pageName === invitePageName ? t('recipientBtnLabel', { ns: 'labels' }) : t('addAuthorsBtnLabel', { ns: 'labels' })}
        </Space>
      </Secondary>

      {isMissingPerson && <FormError>{errorMessage}</FormError>}

      {isPersonDropdownOpen
        ? (
          <Modal active={isPersonDropdownOpen} onCloseGesture={handleDropdownClose}>
            <Card>
              <DropDownContent>
                <Title2>{t('addAuthorTitle')}</Title2>
                <hr />
                <Title3>
                  {activeUnitObj.unitType === ldsxrm_unittypes.Ward
                    ? t('addPersonSubtitleWard')
                    : activeUnitObj.unitType === ldsxrm_unittypes.Stake
                      ? t('addPersonSubtitleStake')
                      : activeUnitObj.unitType === ldsxrm_unittypes.District
                        ? t('addPersonSubtitleDistrict')
                        : activeUnitObj.unitType === ldsxrm_unittypes.Branch
                          ? t('addPersonSubtitleBranch')
                          : null}
                </Title3>
                <hr />

                <ScrollContent boxHeight="350px">
                  {displayCallingList.map((auxiliary, index) => {
                    return (
                      <Drawer key={auxiliary.auxiliaryName} open>
                        <Handle>
                          <div data-test-id={`auxiliaryDrawer${index}`}>
                            {beautify(auxiliary.auxiliaryName)}
                          </div>
                        </Handle>
                        <Body>
                          {auxiliary.positions.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map((position, indexPos) => {
                            const positionName = position.name;
                            const positionValue = position.value;
                            const uuid = position.uuid;
                            const prioritizedValue = uuid || positionValue;

                            return (
                              <FieldSet
                                key={`${auxiliary.auxiliaryName}_${positionName}`}
                                id={`positionName${indexPos}-${positionValue}`}
                              >
                                <Label>
                                  <Checkbox
                                    checked={checkedPeople[prioritizedValue] || false}
                                    disabled={position.isDisabled}
                                    name={prioritizedValue}
                                    onChange={() =>
                                      handlePersonOnChange(uuid, positionValue)
                                    }
                                    value={prioritizedValue}
                                  />
                                  {positionName}
                                </Label>
                              </FieldSet>
                            );
                          })}
                        </Body>
                      </Drawer>
                    );
                  })}
                </ScrollContent>

                <ButtonContainer>
                  <Primary id="submitAddPersonBtn" onClick={handleSubmitPeople}>
                    <div>{t('submitBtnLabel', { ns: 'labels' })}</div>
                  </Primary>
                  <Secondary id="cancelAddPersonBtn" onClick={e => handleCancelBtn(e)}>
                    {t('cancelBtnLabel', { ns: 'labels' })}
                  </Secondary>
                </ButtonContainer>
              </DropDownContent>
            </Card>
          </Modal>
        )
        : null}

      {selectedPeople.length > 0 && (
        <DisplayPeople>
          <Heading>{t('formSelectPersonHeader')}</Heading>

          {selectedPeople
            .filter(person => person && (person.position !== UNKNOWN || person.uuid))
            .map((person: IAuthor, index) => {
              const name = formattedDisplayName(person.name);
              const position = getPersonPosition(person);
              const isPersonActive = person.status !== AuthorStatus.Removed;

              return (
                <Fragment key={index}>
                  <PersonItem id={`personItem${index}`}>
                    <PersonRow>
                      <Person>
                        <PersonName isActive={isPersonActive}>{name}</PersonName>
                        <PersonRole
                          isActive={isPersonActive}
                          data-test-id={`person_${beautify(position)}`}
                        >
                          {beautify(position) ?? 'No Current Calling'}
                        </PersonRole>
                      </Person>

                      <ClickIcon
                        data-test-id={`delPerson-${index}`}
                        Icon={Delete}
                        text={t('formRemovePersonLabel')}
                        onClick={() => handleOpenDeleteConformation(person)}
                      />
                    </PersonRow>
                  </PersonItem>
                  <hr />
                </Fragment>
              );
            })}
        </DisplayPeople>
      )}

      {isConfirmModelOpen && (
        <RemoveContentModal
          isActive={isConfirmModelOpen}
          title={t('removeAuthorTitle')}
          message={removeMsg}
          handleFinish={handleRemovePerson}
        />
      )}
    </PersonContainer>
  );
}

export default SelectPerson;
