import styled from 'styled-components';

import {
  black,
  fontSize14,
  lineHeight,
  sans as zoram,
  spacing16,
  spacing24,
  info40,
  spacing8,
} from '@churchofjesuschrist/eden-style-constants';

export const InContextHelpContainer = styled.div`
  position: relative;
  z-index: 100;
  text-align: left;
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

export const IconLabel = styled.div`
  color: ${info40};
`;

export const CalloutContainer = styled.div<{ bottomDist?: number | null, isActive?: boolean, calloutLeft?: number }>`
  bottom: ${props => (props.bottomDist ? `${props.bottomDist}px` : spacing8)};
  color: ${black};
  display: ${props => (props.isActive ? 'block' : 'none')};
  font-size: ${fontSize14};
  font-weight: ${lineHeight};
  font: ${zoram};
  left: ${props => (props.calloutLeft ? `${props.calloutLeft}px` : '-154px')};
  position: absolute;
`;

export const CalloutContent = styled.div`
  padding: ${spacing16} ${spacing24};
`;
