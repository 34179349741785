import React, { createRef, Fragment, useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { subYears, addMonths, format, parseISO } from 'date-fns';
import { OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import isSameDay from 'date-fns/isSameDay';
import { useTranslation } from 'react-i18next';

// eden
import { grey15, spacing8, spacing16 } from '@churchofjesuschrist/eden-style-constants';
import Stack from '@churchofjesuschrist/eden-stack';
import { Input, Label, Checkbox, FieldSet, Hint } from '@churchofjesuschrist/eden-form-parts';
import { Drawer, Handle, Body } from '@churchofjesuschrist/eden-drawer';
import Row from '@churchofjesuschrist/eden-row';
import { Text3, Text4 } from '@churchofjesuschrist/eden-text';
import {
  RichTextEditor,
  convertContentToHtml,
  convertHtmlToContent,
} from '@churchofjesuschrist/eden-rich-text-editor';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Warn, Info } from '@churchofjesuschrist/eden-alert';
import { Secondary } from '@churchofjesuschrist/eden-buttons';

// components
import LoadingSpinner from '../../../utils/componentFunctions/loading-spinner';
import SelectPerson from '../../AddToStory/SelectPerson';
import { getPageRedirect } from '../../Stories/utils/RedirectPages';
import { StoryFormButtonBar } from '../../ButtonBars/StoryFormButtonBar';
import AddOrganizationsToStory from '../../Organizations/AddOrganizationsToStory';
import DateSelector from '../../Create/DateSelector';
import InContextHelp from '../../InContextHelp/InContextHelp';
import PageSteps from '../../InContextHelp/PageSteps';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import NotSavedModal from '../../Modals/NotSavedModal/NotSavedModal';
import { BoldDrawerTitle } from './DrawerTitle';
import ImageDrawer from './ImageDrawer';
import AttachmentDrawer from './AttachmentDrawer';

// styles
import {
  CharacterCounter,
  DrawerInstructions,
  EditorMetaData,
} from '../../Create/Form/Form.style';
import { FormError } from '../../Invite/Invite.style';
import { Line, FloatRight, ReadingWidth, Box } from '../../../utils/appCSS/appStyles';

// functions
import {
  formattedDisplayName, getBodyTextLenth,
} from '../../../utils/AppFunctions/appFunctions';
import {
  setSaveStoryVariables,
  moveEndDateToStartDate,
  validateForm,
  useFormFunctions,
} from '../../../utils/AppFunctions/formFunctions';

// queries
import { getAuthorsQuery, MEMBER_SEARCH_QUERY } from '../../../utils/graphQL_Queries/appQueries';

// constants
import {
  pageNames,
  formValidityState,
  AUTOSAVE_DURATION,
  STORY_STATUSES,
} from '../../../utils/appConstants/appConstants';

import { useHistory } from 'react-router-dom';
import { useStory } from 'src/components/Stories/utils/storyHooks';
import { User } from 'src/models/User';
import { usePanelContext } from 'src/contexts/PanelContext';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { useSelector } from 'react-redux';
import { selectUnitOrgs } from 'src/reducers/unitsReducer';
import { Story } from 'src/models/Story';
import DisplayTags from 'src/components/Tags/DisplayTags';
import SelectField from 'src/components/Form/SelectField';
import { EMPTY_GUID, generateGuid } from 'src/utils/formUtil';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const { validityStateUnknownLabel, validityStateErrorLabel } = formValidityState;
const { editPageName, storyFromPageName } = pageNames;
const textLimit = 5000;
// min and max dates for stories
const now = new Date();
const minDate = format(subYears(now, 10), 'yyyy-MM-dd'); // allow up to 10 years in the past
const maxDate = format(addMonths(now, 6), 'yyyy-MM-dd'); // allow up to 6 months in the future
const TEMP_ID = 'tempId_';

const setDefaultUser = (user: User, story: { authors?: any[] } = {}) => {
  const { authors = [] } = story;
  const authorsRef = [...authors];
  const isCurrentUserAnAuthor = authorsRef.find(author => author.uuid === user.uuid);
  const currentUser = {
    id: user.id,
    uuid: user.uuid,
    name: user.name,
    email: user.email,
    formattedName: formattedDisplayName(user.name),
  };

  if (isCurrentUserAnAuthor) {
    return authorsRef;
  } else {
    authorsRef.push(currentUser);
    return authorsRef;
  }
};

const startingStateFormInput = {
  authorValidityState: validityStateUnknownLabel,
  dateValidityState: validityStateUnknownLabel,
  summaryValidityState: validityStateUnknownLabel,
  titleValidityState: validityStateUnknownLabel,
};

const isSafari =
  !navigator.userAgent.includes('Chrome') &&
  navigator.userAgent.includes('Safari');

let autoSaveTimer: NodeJS.Timeout | null = null;
let unlisten: () => void = () => { };
const _titleField = createRef<any>();
const _personSearchField = createRef<any>();
let filterTimeout: NodeJS.Timeout;

const removeTempIds = (storyToUpdate: Story) => {
  const fixedPersonTags = storyToUpdate.personTags?.map((personTag) =>
    ({ ...personTag, id: personTag?.id?.startsWith(TEMP_ID) ? undefined : personTag.id })) ?? [];
  return { ...storyToUpdate, personTags: fixedPersonTags };
}

// --------- StoryForm ---------
export const StoryForm = (props: { isCreatePage, prevPageName, history?: any }) => {
  const { user, activeUnitObj } = useUserPermissions();
  const { prevPageName, isCreatePage, history } = props;
  const { story: storyObj } = useStory(isCreatePage);
  const { t } = useTranslation('strings');
  const { formatPersonObj } = useFormFunctions();
  const { open, close, isOpen } = usePanelContext();
  const fullOrgList = useSelector(selectUnitOrgs);
  const [personSearch, setPersonSearch] = useState<string>('');
  const [personSearchResults, setPersonSearchResults] = useState<any[]>([]);
  const [searchPersons, { loading: personSearching }] = useLazyQuery(MEMBER_SEARCH_QUERY, {
    onCompleted: data => {
      const results: { id?, uuid?, name }[] = (data.search ?? []).map((r, i) => ({
        id: TEMP_ID + r.uuid, // Note: the MEMBER_SEARCH_QUERY does not return an Azure ID
        uuid: r.uuid,
        name: (i + 1) + ' - ' + r.fullName + ' - ' + r.membershipUnit.unitName
      }));

      // Add manualLy entered text as a selectable person
      if (personSearch.length > 0) {
        if (results.length === 0) {
          results.push({
            name: `-- ${t('noResultsLabel')} --`
          });
        }
        results.unshift({
          id: TEMP_ID + generateGuid(), // Temporary identifier. Azure will create an ID when the person tag is saved.
          name: `${t('addLabel')}+ ${personSearch}`,
          uuid: EMPTY_GUID
        });
      }

      // TODO: Re-evaluate if this is needed still
      // Edge has an occasional bug where if there is only one item in the list it will not display the datalist, or show it in various places relative to the input field. This has been tested on the same exact browser version on multiple windows machines, with a different result in each (working in one, in one spot on another, hiding on another). The below will apply an ugly fix until it is solved.
      if (
        results.length === 1 &&
        navigator.userAgent.includes('Edg/')
      ) {
        // The userAgent check is specifically for the Edge browser as that is the only one with this issue currently
        // Duplicate the single entry. The fix is to make there be more than one result in the list. Adding a "Choose one" or any other value would allow the selection of an incorrect value that requires other handling. Duplicating the one value will look like a minor bug, but choosing either one will work and not need the extra logic.
        results.push(results[0]);
      }
      // TODO: Re-evaluate if this is still needed every so often. Safari has a different issue where it won't dynamically update the displayed datalist without blurring and focusing the field. so the hack below takes care of it https://bugs.webkit.org/show_bug.cgi?id=201121
      if (isSafari) {
        const theInput = _personSearchField.current;
        const theDatalist = theInput.nextElementSibling;
        const listName = `list${new Date().getTime()}`;
        theInput.setAttribute('list', listName);
        theDatalist.setAttribute('id', listName);
        setTimeout(() => {
          // setDatalistID(unitSearchString);
          theInput.blur();
          theInput.focus();
          theInput.click();
          console.log('safari patched', theInput);
        }, 500);
      }
      setPersonSearchResults(results);
    }
  });
  const [story, setStory] = useState<Story>({
    id: storyObj.id,
    title: storyObj.title ?? '',
    status: storyObj.status,
    organizations: storyObj.organizations,
    images: storyObj.images ?? [],
    attachments: storyObj.attachments ?? [],
    confidential: false,
    sPCReviewCompleted: false,
    created: '',
    modified: '',
    submitted: '',
    published: '',
    lock: undefined,
    authors: setDefaultUser(user, storyObj),
    unit: activeUnitObj,
    dateStart: storyObj.dateStart,
    dateEnd: storyObj.dateEnd,
    body: storyObj.body ? storyObj.body : '<p>*</p>',
    textLength: getBodyTextLenth(
      storyObj?.body ? storyObj.body : '<p>*</p>'
    ),
    flagForReview: storyObj.flagForReview,
    sensitive: storyObj.sensitive,
    sensitiveComments: storyObj.sensitiveComments,
    approver: storyObj.approver,
    approverComments: storyObj.approverComments,
    inviter: storyObj.inviter,
    notes: storyObj.notes,
    personTags: storyObj.personTags
  });
  const [formAttachmentFileContent, setFormAttachmentFileContent] = useState({
    attachmentFiles: [], // attachments to add
  });
  const [formImageFileContent, setFormImageFileContent] = useState({
    imageFiles: [], // image files to add
  });
  const [formInput, setFormInput] = useState(startingStateFormInput);
  const [changesToSave, setChangesToSave] = useState(false);
  const [isDrawAuthorOpen, setIsDrawAuthorOpen] = useState(true);
  const [isMissingRequiredFields, setIsMissingRequiredFields] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [canLeave, setCanLeave] = useState(false);
  const [next, setNext] = useState<string | null>(null);
  const [showReworkAlert, setShowReworkAlert] = useState(true);
  const [hideInvitationAlert, setHideInvitationAlert] = useState(false);
  const [hideReviewFlagAlert, setHideReviewFlagAlert] = useState(false);
  const [sPCReviewCompleted, setSPCReviewCompleted] = useState(false);

  useEffect(() => {
    if (changesToSave) {
      setAutoSave(true);
    }
  }, [changesToSave]);

  useEffect(() => {
  }, [autoSave]);

  const togglePanel = () => {
    if (isOpen) {
      close();
    } else {
      open('spc');
    }
  }

  const router = useHistory();

  const onPersonSearch = (event, personList: { name: string, uuid: string, id?: string }[]) => {
    const searchString = event.target.value;
    setPersonSearch(searchString);
    // first check to see if we have a direct match of the string in the unitList. If so, use that and don't call the API for units. Otherwise, call the API.
    const personListMatch = personList?.find(person => person.name === searchString);
    if (personListMatch) {
      const isAdd = personListMatch.name.includes(`${t('addLabel')}+`);
      onTagChange([
        ...(personTags ?? []),
        {
          id: personListMatch?.id ?? '',
          uuid: personListMatch.uuid,
          name: personListMatch.name.split(isAdd ? `${t('addLabel')}+ ` : ' - ')[1]
        }
      ]);

      // Clear out the list so it doesn't show up when the input is focused
      setPersonSearchResults([]);
      setPersonSearch('');
    } else if (
      searchString?.length > 2
    ) {
      // It wasn't a match, so this must be a new search
      // Debounce it so we aren't sending out a request for every keystroke
      clearTimeout(filterTimeout);

      const searchStringWildcard = searchString + '%';
      filterTimeout = setTimeout(() => {
        searchPersons({
          variables: {
            name: searchStringWildcard
          }
        })
      }, 500);
    } else {
      // no match and search string doesn't match criteria, so clear out some stuff
      setPersonSearchResults([]);
    }
  }

  const onUnload = e => {
    if (!changesToSave) return;
    e.preventDefault();
    e.returnValue = '';
  };

  const handleTitleChange = e => {
    const updatedStory = {
      ...story,
      title: e.target.value,
    };
    handleFormValidation(updatedStory);
  };

  const handleStartDateChange = e => {
    const updatedStory = {
      ...story,
      dateStart: e.target.value,
    };
    handleFormValidation(updatedStory);
  };

  const handleEndDateChange = e => {
    const dateEnd = e.target.value;
    const updatedStory = {
      ...story,
      dateStart: !story.dateStart ? dateEnd : story.dateStart,
      dateEnd,
    };
    handleFormValidation(updatedStory);
  };

  const handleOrganizationChange = (orgs: string[]) => {
    const updatedStory = {
      ...story,
      organizations: orgs.map(o => ({ uuid: o })),
    };
    handleFormValidation(updatedStory);
  };

  const truncateBlocks = blocks => {
    const truncatedBlocks: any[] = [];
    let textLength = 0;
    for (const block of blocks) {
      const blockLength = block.text.length;
      if (block.text.length + textLength < textLimit) {
        truncatedBlocks.push(block);
        textLength += blockLength;
      } else {
        const lengthRemaining = textLimit - textLength;
        block.text = block.text.substring(0, lengthRemaining);
        truncatedBlocks.push(block);
        break;
      }
    }
    return truncatedBlocks;
  };

  const onBodyChange = newBody => {
    const length = getBodyTextLenth(newBody);
    if (length > textLimit) {
      newBody.blocks = truncateBlocks(newBody.blocks);
    }
    const html = convertContentToHtml(newBody);
    if (html === story.body) {
      return;
    }
    const updatedStory = {
      ...story,
      body: html,
    };

    handleFormValidation(updatedStory);
  };

  const onTagChange = (tags?: any[]) => {
    const updatedStory = {
      ...story,
      personTags: tags,
    };

    handleFormValidation(updatedStory);
  };

  const toggleSPCReview = e => {
    const updatedStory = {
      ...story,
      sPCReviewCompleted: !sPCReviewCompleted,
    };
    setSPCReviewCompleted(!sPCReviewCompleted);
    handleFormValidation(updatedStory);
  };
  /** *************** Adding Authors ********************************/

  const handleAuthorChange = authors => {
    const updatedStory = {
      ...story,
      authors,
    };
    handleFormValidation(updatedStory);
  };

  /** *************** Form Functions ********************************/
  const handlePageChange = storyId => {
    const { prevPageName, isCreatePage } = props;
    const params = { storyId };

    if (next) {
      router.push(getPageRedirect('', params, next));
    } else if (isCreatePage && storyId) {
      router.push(getPageRedirect(editPageName, params));
    } else {
      router.push(getPageRedirect(prevPageName, params));
    }
  };

  const handleFormValidation = (story, saving?) => {
    const { dateEnd, dateStart } = moveEndDateToStartDate(story.dateStart, story.dateEnd);
    const updatedStory = {
      ...story,
      dateStart,
      dateEnd,
    };

    const validatedForm = validateForm(updatedStory, 'story', minDate, maxDate);

    const missingFields = !!Object.values(validatedForm).find(value => value !== 'success');

    // attachments, organizations, images
    if (!props.isCreatePage && !saving) {
      let changesToSave = false;
      const updatedStart =
        updatedStory.dateStart &&
        !isSameDay(parseISO(updatedStory.dateStart), parseISO(storyObj.dateStart!));
      const updatedEnd =
        updatedStory.dateEnd &&
        !isSameDay(parseISO(updatedStory.dateEnd), parseISO(storyObj.dateEnd!));
      const updatedTitle = updatedStory.title !== storyObj.title;
      const updatedBody = updatedStory.body !== storyObj.body;
      const updatedOrganizations =
        updatedStory.organizations.find(o => !storyObj.organizations.includes(o)) ||
        storyObj.organizations.find(o => !updatedStory.organizations.includes(o));
      const updatedAuthors =
        updatedStory.authors.find(
          ua => !storyObj.authors.find(oa => ua.uuid === oa.uuid)
        ) ||
        storyObj.authors.find(
          oa => !updatedStory.authors.find(ua => ua.uuid === oa.uuid)
        );
      const updatedPersons =
        updatedStory.personTags.find(
          ua => !storyObj.personTags?.find(oa => ua.uuid === oa.uuid)
        ) ||
        storyObj.personTags?.find(
          oa => !updatedStory.personTags.find(ua => ua.uuid === oa.uuid)
        );

      changesToSave = !!(
        updatedStart ||
        updatedEnd ||
        updatedTitle ||
        updatedBody ||
        updatedOrganizations ||
        updatedAuthors ||
        updatedPersons
      );
      setChangesToSave(changesToSave);
    }

    setFormInput(validatedForm);
    setIsMissingRequiredFields(missingFields);
    setStory(updatedStory);

    return !missingFields;
  };

  const handleSaveStory = async (event, saveStoryFunc: (vars) => any) => {
    // remove lock if there was a click event ('save and close' or 'continue' during initial story creation)
    const lock = event
      ? null
      : {
        uuid: user.uuid,
        name: user.name,
        date: new Date(),
      };

    const fixedStory = removeTempIds(story);
    const storyToSave = { ...fixedStory, lock };

    const saveStoryVars: any = setSaveStoryVariables(
      storyToSave
    );

    if (saveStoryVars === validityStateErrorLabel) {
      return null; // exit save story because there is a data problem
    }

    handleFormValidation(saveStoryVars.story, true); // Note: form validation is only for the story

    const { images, attachments, id } = saveStoryFunc
      ? (await saveStoryFunc({ variables: { ...saveStoryVars } })).data.saveStory
      : {} as any;

    const updatedStory = {
      ...story,
      images,
      attachments,
    };

    setCanLeave(!!event);
    setChangesToSave(false);
    setStory(updatedStory);
    setFormImageFileContent({
      imageFiles: [],
    });
    setFormAttachmentFileContent({
      attachmentFiles: [],
    });
    if (!event) return;
    handlePageChange(id);
  };

  const stopAutoSave = () => {
    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }
  };

  const onSave = () => {
    setChangesToSave(false);
    autoSaveTimer = setTimeout(() => setAutoSave(true), AUTOSAVE_DURATION);
  }

  const handleRouteChange = url => {
    if (next === undefined && changesToSave) {
      setNext(url);
      // throw new Error('Gather User Input');
    }
  };

  const handleCancelSurf = async () => {
    setNext(null);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    if (history) {
      unlisten = history.listen((location) => handleRouteChange(location));
    }

    if (!isCreatePage && story && story.id) {
      handleFormValidation({ ...story }, true);
    }
    if (isCreatePage && _titleField.current) {
      _titleField.current.focus();
    }
    return function cleanup() {
      window.removeEventListener('beforeunload', onUnload);
      unlisten();
      stopAutoSave();
    }
  }, []);

  const { unitType } = activeUnitObj;
  const { authors, organizations, title, dateStart, dateEnd, body, personTags } = story;
  const checkedOrganizations = organizations?.map(o => o.uuid);

  const {
    authorValidityState,
    dateValidityState,
    titleValidityState,
    summaryValidityState,
  } = formInput;

  const hideEditFieldStyles = isCreatePage ? { display: 'none' } : {};

  // Get list of organizations to display based on currently active unit
  const filteredOrgList = fullOrgList?.filter(o => o.unitType === unitType);

  const handleImageUpdate = async (childState) => {
    const { removed, added } = childState;
    // update image set with added / removed images
    const newImages = [...(story.images?.filter((storyImage) => !removed.find((ri) => ri.name === storyImage.name)) ?? []), ...(added ?? [])];

    setStory({
      ...story,
      images: newImages,
    });
    setAutoSave(true);
    setChangesToSave(false);
    setFormImageFileContent({ ...childState, imageFiles: added });
  };

  const handleAttachmentUpdate = async (childState) => {
    const { removed, added } = childState;

    setStory({
      ...story,
      attachments: [...(story.attachments?.filter(
        oi => !removed.find(ri => ri.name === oi.name)
      ) ?? []), ...(added ?? [])],
    });
    setAutoSave(true);
    setChangesToSave(false);
    setFormAttachmentFileContent({ ...childState, attachmentFiles: added })
  };

  const showFlagAlert = !hideReviewFlagAlert && (story.flagForReview === true || story.sensitive === true) && story.status === STORY_STATUSES.PENDING_STATUS;
  const showInvAlert = !hideInvitationAlert && story.inviter?.name;
  const showAlertContainer = showFlagAlert || showInvAlert;

  return (
    <>
      <style>
        {`
            #addStoryContent > div:nth-child(2) {
              border: 1px solid ${grey15};
            }
            #addStoryContent > div:nth-child(2) > div:first-child > div:first-child {
              padding: ${spacing8};
            }
            .DraftEditor-root {
              padding: 0 ${spacing8};
            }            
          `}
      </style>

      {!isCreatePage && formInput.dateValidityState === 'unknown' && <LoadingSpinner />}

      <Stack gapSize="16">
        {isCreatePage
          ? (
            <Fragment />
          )
          : (
            <>
              <section id="headerButtons">
                <FormError>{isMissingRequiredFields ? t('missingRequiredFieldsLabel') : ' '}</FormError>
                {story.status === STORY_STATUSES.RETURNED_STATUS && showReworkAlert &&
                  <Box mb={20}>
                    <Warn onClose={(event) => setShowReworkAlert(false)}>
                      <Stack>
                        <H4>{t('returnedForReworkHeader')}</H4>
                        <Text4>
                          {t('returnedBy')}{story.approver?.name}
                        </Text4>
                        <Text4>
                          {t('commentsSuggestions')}{story.approverComments}
                        </Text4>
                      </Stack>
                    </Warn>
                  </Box>
                }

                {showAlertContainer &&
                  <Box mb={50}>
                    {showInvAlert &&
                      <Box mb={20}>
                        <Info onClose={() => setHideInvitationAlert(true)}>
                          <Stack>
                            <H4>{t('storyInvitationHeader')}</H4>
                            <Text4>
                              {t('invitedBy')}{story.inviter?.name}
                            </Text4>
                            <Text4>
                              {t('commentsSuggestions')}{story.notes}
                            </Text4>
                          </Stack>
                        </Info>
                      </Box>
                    }

                    {showFlagAlert &&
                      <Box mb={20}>
                        <Info onClose={() => setHideReviewFlagAlert(true)}>
                          <Stack>
                            <H4>{t('flaggedForReviewHeader')}</H4>
                            {story.sensitiveComments &&
                              <Text4>
                                {t('sensitiveInfoComments')}: {story.sensitiveComments}
                              </Text4>
                            }
                            <Row>
                              <Secondary onClick={togglePanel}>{t('spcReviewButton')}</Secondary>
                            </Row>
                          </Stack>
                        </Info>
                      </Box>
                    }
                  </Box>
                }

                <Row gapSize="0" verticalAlign="end" align="space-between">
                  <StoryFormButtonBar
                    attachmentFiles={formAttachmentFileContent?.attachmentFiles}
                    currentPageName={prevPageName}
                    handleFormValidation={handleFormValidation}
                    handleSaveStory={handleSaveStory}
                    imageFiles={formImageFileContent?.imageFiles}
                    index={0}
                    story={removeTempIds(story)}
                    user={user}
                    activeUnitObj={activeUnitObj}
                    changesToSave={changesToSave}
                    isCreatePage={isCreatePage}
                    disabled={isMissingRequiredFields || (isCreatePage && canLeave)}
                    onSave={onSave}
                  />
                  <InContextHelp
                    index={1}
                    label={t('calloutStoryCreationStepsLabel')}
                  >
                    <PageSteps canApprove={user.isApprover} unitType={unitType} />
                  </InContextHelp>
                </Row>
              </section>

              <Line />
            </>
          )}
      </Stack>

      <Stack gapSize="32">
        <section id="storyFormMessage">
          <ReadingWidth>
            {isCreatePage
              ? (
                <Text3>{t('storyFormCreateMessage')}</Text3>
              )
              : (
                <Fragment>
                  <Text3 style={{ marginTop: spacing16 }}>
                    {t('storyFromMessage', { unitType: unitType === ldsxrm_unittypes.Stake ? t('stakeLabel').toLowerCase() : t('wardLabel').toLowerCase() })}
                    <span>{t('autoSaveNote1')}</span>
                    <strong>{t('autoSaveNote2')}</strong>
                    <span>{t('autoSaveNote3')}</span>
                  </Text3>
                </Fragment>
              )}
          </ReadingWidth>
        </section>

        {/* <section id="addConfidentialCheck">
          <ConfidentialCheck
            isConfidential={isConfidential}
            handleConfidentialChange={toggleConfidential}
          />
        </section> */}

        {/** *********************************** Add Title **********************************/}
        <section id="addTitle">
          <Label id="title" validityState={titleValidityState}>
            {t('storyTitleLabel') + ' ' + t('requiredLabel')}
            <Input
              style={{ marginTop: '4px' }}
              type="text"
              name="title"
              value={title}
              placeholder={t('storyTitlePlaceHolderLabel')}
              onChange={handleTitleChange}
              validityState={titleValidityState}
              ref={_titleField}
              maxLength="140"
            />
          </Label>
          {titleValidityState === validityStateErrorLabel
            ? (
              <FormError>{t('formErrorMessageTitle')}</FormError>
            )
            : null}
        </section>

        {/** *********************************** Add Dates **********************************/}
        <section
          id="addDates"
          style={{ display: title && titleValidityState ? 'unset' : 'none' }}
        >
          <>
            <DateSelector
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              dateStart={dateStart}
              dateEnd={dateEnd}
              minDate={minDate}
              maxDate={maxDate}
              dateValidityState={dateValidityState}
              required
            />
            {dateValidityState === validityStateErrorLabel
              ? (
                <FormError>{t('formFieldDateEndErrorMessage')}</FormError>
              )
              : null}
          </>
        </section>

        {/** *********************************** Add Organizations **********************************/}
        <section id="addOrganizations" style={hideEditFieldStyles}>
          <AddOrganizationsToStory
            fullOrgList={filteredOrgList}
            handleUpdateOrganizations={handleOrganizationChange}
            checkedOrganizations={checkedOrganizations}
            unitType={unitType}
          />
        </section>

        {/** *********************************** Add Story Content **********************************/}
        <section id="addStoryContent" style={hideEditFieldStyles}>
          <Label id="textEditor" validityState={validityStateUnknownLabel}>
            {t('storyRichTextEditorLabel') + ' ' + t('requiredLabel')}
          </Label>

          <RichTextEditor
            initialContent={convertHtmlToContent(body)}
            onChange={onBodyChange}
            placeholder={textLimit + t('limitTextMessage')}
            availableControls={['heading1', 'heading2', 'bold', 'italic', 'underline', 'link']}
            spellCheck={true}
          />

          <EditorMetaData>
            {summaryValidityState === validityStateErrorLabel
              ? (
                <div>{t('formErrorMessageSummary')}</div>
              )
              : (
                <div />
              )}
            <CharacterCounter
              textLength={getBodyTextLenth(body)}
              textLimit={textLimit}
              sizeError={getBodyTextLenth(body) >= textLimit}
            />
          </EditorMetaData>
        </section>

        {/** *********************************** Tag Persons **********************************/}
        <section id="tagPersons" style={hideEditFieldStyles}>
          <Label id="tagPersonsLabel" validityState={validityStateUnknownLabel} style={{ maxWidth: '500px' }}>
            <Row
              gapSize="4"
              columnGapSize="4"
              verticalAlign="center"
              style={{ margin: '-0.25rem 0 0.25rem 0' }} // to fix the -4px left margin and the 4px bottom margin
            >
              {t('storyTagPerson')}
              <InContextHelp
                index={4}
              >
                {t('calloutSearchPersons')}
              </InContextHelp>
            </Row>
            <SelectField
              value={personSearch}
              type="typeahead"
              loading={personSearching}
              options={personSearchResults?.map(r => ({
                label: r.id,
                value: r.name
              }))}
              onChange={ev => onPersonSearch(ev, personSearchResults)}
              name="personSearch"
              autoComplete="off"
              refs={{ personSearch: _personSearchField }}
            />
            {personSearch?.length > 0 && personSearch.length < 3 &&
              <Hint>
                {t('searchMinCharacters')}
              </Hint>
            }
          </Label>

          <DisplayTags
            showCloseBtn={true}
            tagArray={personTags}
            removeTag={(id) => onTagChange(personTags?.filter((personTag) => personTag.id !== id))}
            removeAll={() => onTagChange([])}
            idField="id" // Note: DisplayTags defaults to uuid, but person needs to use id (this allows it to be overridden)
          />
        </section>

        {/** *********************************** Add Photos **********************************/}
        <section id="addPhotos" style={hideEditFieldStyles}>
          <ImageDrawer story={story} handleFinish={handleImageUpdate} />
        </section>

        {/** *********************************** Add Documents **********************************/}
        <section id="addDocuments" style={hideEditFieldStyles}>
          <AttachmentDrawer
            story={story}
            handleFinish={handleAttachmentUpdate}
          />
        </section>

        {/** *********************************** Add Authors **********************************/}
        <section id="addAuthors" style={hideEditFieldStyles}>
          <Drawer open={isDrawAuthorOpen}>
            <Handle
              onClick={() => {
                setIsDrawAuthorOpen(!isDrawAuthorOpen);
              }}
            >
              <FloatRight>
                <InContextHelp index={8}>
                  {t('calloutAuthorText')}
                </InContextHelp>
              </FloatRight>
              <BoldDrawerTitle title={t('formDrawerLabelAuthors')} />
            </Handle>
            <Body>
              <DrawerInstructions>{t('formDrawerInstructionsAuthors')}</DrawerInstructions>
              <Query
                displayName="Story-getAuthors-Query"
                query={getAuthorsQuery}
                notifyOnNetworkStatusChange
                fetchPolicy="network-only"
                skip={!isDrawAuthorOpen}
              >
                {({ loading, error, data }: QueryResult<any, OperationVariables>) => {
                  if (loading || next) return <LoadingSpinner />;
                  if (error) {
                    return (
                      <ErrorModal
                        title={t('errorGettingAuthorsLabel')}
                        onRetry={() => window.location.reload()}
                        errors={error}
                        isActive
                      />
                    );
                  }

                  if (!loading && data) {
                    const displayCallingList = formatPersonObj(data.authors, unitType);

                    return (
                      <SelectPerson
                        errorMessage={t('formErrorMessageAuthor')}
                        fullAuthorList={data.authors}
                        handleUpdatePeople={handleAuthorChange}
                        isMissingPerson={
                          authorValidityState === validityStateErrorLabel
                        }
                        pageName={storyFromPageName}
                        displayCallingList={displayCallingList}
                        selectedPeople={authors}
                        reset={false}
                        activeUnitObj={activeUnitObj}
                      />
                    );
                  } else {
                    return <div />;
                  }
                }}
              </Query>
            </Body>
          </Drawer>
        </section>
      </Stack>

      <div
        style={
          isCreatePage
            ? { borderTop: `1px solid ${grey15}`, marginTop: '16px', paddingTop: '16px' }
            : { padding: '16px 0' }
        }
        id="footerButtons"
      >

        <FormError>{isMissingRequiredFields ? t('missingRequiredFieldsLabel') : ' '}</FormError>

        {/** *********************************** SPC Review Checkbox **********************************/}
        {(story.flagForReview === true && story.status === STORY_STATUSES.PENDING_STATUS && user.isApprover) &&
          <div style={{ paddingLeft: spacing8, paddingTop: spacing8, paddingBottom: spacing8 }}>
            <section id="spcReview">
              <FieldSet>
                <Label>
                  <Checkbox
                    checked={sPCReviewCompleted}
                    name="spcReviewCheckbox"
                    id="spcReviewCompleted"
                    onChange={toggleSPCReview}
                  />
                  {t('flaggedForReviewCheckbox')}
                </Label>
              </FieldSet>
            </section>
          </div>
        }

        <StoryFormButtonBar
          attachmentFiles={formAttachmentFileContent?.attachmentFiles}
          currentPageName={prevPageName}
          handleFormValidation={handleFormValidation}
          handleSaveStory={handleSaveStory}
          // handleAutoSave={handleAutoSave} // sets up the auto-save in SaveStoryButton
          imageFiles={formImageFileContent?.imageFiles}
          index={1}
          story={removeTempIds(story)}
          user={user}
          activeUnitObj={activeUnitObj}
          changesToSave={changesToSave}
          autoSave={autoSave}
          isCreatePage={isCreatePage}
          disabled={isMissingRequiredFields || (isCreatePage && canLeave)}
          onSave={onSave}
        />
        <div style={{ paddingLeft: spacing8, paddingTop: spacing8 }}>
          <Text3>{!isCreatePage && t('autoSaveNote1') + t('autoSaveNote2') + t('autoSaveNote3')}</Text3>
        </div>
      </div>
      {next && !canLeave && (
        <NotSavedModal
          handleContinueSurf={handleSaveStory}
          handleCancelSurf={handleCancelSurf}
        />
      )}
    </>
  );
}

export default StoryForm;

(StoryForm as any).propTypes = {
  prevPageName: string.isRequired,
  isCreatePage: bool,
};
