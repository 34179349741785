import React, { Ref } from 'react';
import {
  FormField,
  Label,
  FieldSet,
  Radio,
  Legend,
} from '@churchofjesuschrist/eden-form-parts';
import FieldValidityHint from './FieldValidityHint';
import { determineValidityState } from 'src/utils/formUtil';

interface IProps {
  /** The `label` of the input */
  label?: string
  /** The individual value of the input (as opposed to the full `formData` object passed in `formData`) */
  value?: string
  /** The name of the input */
  name: string
  /** The individual validity object for the specific field */
  field?: object
  /** Callback to call when the field changes */
  onChange: (ev) => void
  /** An array of label/value pairs that drive the radio buttons */
  values: { value: string | number, label: string }[]
  /** Is it required */
  required?: boolean
  /** Is it disabled */
  disabled?: boolean
  /** The individual `ref` of the input */
  ref?: Ref<Radio>
  /** The validity object from the form that the component will dynamically pull out the validity state for this field based on the `name` */
  validity?: object
  /** The full set of refs from the form so that this component can dynamically pull out the ref for the input based on the name */
  refs?: object
  /** The full set of data from the form so that this component can dynamically pull out its value */
  formData?: object
}

/**
 * Component for displaying a radio button group with associated wrappers and error display
 * @returns the input wrapped in a `<FormField>`
 */
export const RadioField = ({
  label,
  field,
  name,
  value,
  values,
  onChange,
  required = false,
  disabled,
  ref,
  validity,
  refs,
  formData,
}: IProps) => {
  // if the radio is the only element in the form and the submit is disabled until this has a value, we don't need a `field` to check validity on
  if (!field) {
    field = {};
  }

  // check for the full `formData` object being handed in with the named node as a child, or use the value
  const theValue = formData?.[name] || value;

  // check for the full `validity` object being handed in with the named node as a child, or use the field object presuming it is the individual node of the validity object already
  const validityField = validity?.[name] || field;

  return (
    <FormField validityState={determineValidityState(validityField)}>
      <FieldSet>
        {label && (
          <Legend>
            <Label id="title" validityState={determineValidityState(validityField)}>
              {label}
            </Label>
          </Legend>
        )}
        {values?.map((v, i) => {
          if (typeof v.value !== 'string') {
            v.value = v.value?.toString();
          }
          return (
            <Label key={i}>
              <Radio
                name={name}
                value={v.value}
                checked={v.value === theValue}
                onChange={onChange}
                required={required}
                disabled={disabled}
                ref={(i === 0 && refs && refs[name]) || ref}
              />
              {v.label}
            </Label>
          );
        })}
      </FieldSet>
      <FieldValidityHint check field={validityField} />
    </FormField>
  );
};
