import React from 'react';
import { useTranslation } from 'react-i18next';

// eden
import Row from '@churchofjesuschrist/eden-row';

// components
import SortStoryBar from 'src/components/SortAndSearchBar/SortStoryBar';
import SearchBox from 'src/components/SortAndSearchBar/SearchBox';
import { TagNoMutation, TagArrayNoMutation } from 'src/components/Tags/TagsNoMutation';
import {
  SortSearchContainer,
  SortContainer,
  SearchContainer,
  TagContainer,
} from 'src/components/SortAndSearchBar/SortAndSearchStyles';
import { addStoriesVariables, selectStories, selectStoriesVariables } from 'src/reducers/storiesReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnitOrgs } from 'src/reducers/unitsReducer';
import { usePageContext } from 'src/contexts/PageContext';

const SortStoryContainer = () => {
  const dispatch = useDispatch();
  const { isMobile } = usePageContext();
  const stories = useSelector(selectStories);
  const variables = useSelector(selectStoriesVariables);
  const orgs = useSelector(selectUnitOrgs);
  const { t } = useTranslation('strings');
  const { organizations, searchTerms, filterFavorites } = variables;

  const handleOrgChange = (newOrgList: any[]) => {
    dispatch(addStoriesVariables({ organizations: newOrgList }));
  }

  const handleSearchTermsChange = (newSearchTerms: string) => {
    dispatch(addStoriesVariables({ searchTerms: newSearchTerms }));
  }

  const handleFilterFavorites = () => {
    dispatch(addStoriesVariables({ filterFavorites: false }));
  }

  const handleClearAll = (newSearchTerms: any[], newOrgList: any[]) => {
    dispatch(addStoriesVariables({
      searchTerms: newSearchTerms,
      organizations: newOrgList,
      filterFavorites: false
    }));
  }

  const selectedOrgs = orgs.filter(o => organizations.some(c => c === o.uuid));

  return (
    <SortSearchContainer>
      <SearchContainer isMobile={isMobile}>
        <SearchBox handleSearchTextChange={handleSearchTermsChange} stories={stories} />
      </SearchContainer>
      <SortContainer>
        <SortStoryBar />
      </SortContainer>

      {(organizations.length > 0 || searchTerms !== '' || filterFavorites) && (
        <TagContainer>
          <Row gapSize="16">
            {organizations.length > 0 && (
              <TagArrayNoMutation
                key="organizations"
                id="organizations"
                organizations={selectedOrgs}
                removeTagFunc={handleOrgChange} // TagArrayNoMutation makes the new array
                tagArray={organizations}
              />
            )}
            {searchTerms !== '' && (
              <TagNoMutation
                key="searchTerms"
                removeTermsFunc={handleSearchTermsChange}
                tag={searchTerms} // change to an individual tag when ready to remove tag from terms
                terms={searchTerms}
              />
            )}
            {filterFavorites && (
              <TagNoMutation
                key="filterFavorites"
                removeTermsFunc={handleFilterFavorites}
                tag="filterFavorites"
                terms={t('filterFavoritesTagLabel')}
              />
            )}
            {(organizations.length > 0 || searchTerms !== '' || filterFavorites) && (
              <TagNoMutation
                key="clearAllTag"
                removeAllFun={handleClearAll}
                clearAllFlag
                terms={t('clearAllTagLabel')}
              />
            )}

          </Row>
        </TagContainer>
      )}
    </SortSearchContainer>
  );
}

export default SortStoryContainer;
