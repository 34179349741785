import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['strings', 'labels'],
    defaultNS: 'strings',
    supportedLngs: ['alb', 'ceb', 'ces', 'dan', 'deu', 'eng', 'fin', 'fra', 'hat', 'hun', 'ind', 'ita', 'jpn', 'khm', 'kor', 'mon', 'msa', 'nld', 'nor', 'por', 'smo', 'spa', 'swe', 'tgl', 'tha', 'ton', 'ukr', 'zho', 'rus'],
    fallbackLng: 'eng',
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
