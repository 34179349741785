import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { func, bool, array } from 'prop-types';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Eden
import Row from '@churchofjesuschrist/eden-row';
import { Label, Select } from '@churchofjesuschrist/eden-form-parts';
import { LoadingSpinner } from '@churchofjesuschrist/eden-icons';

// components
import ActivityReport from '../../components/Reports/ActivityReport';
import ErrorModal from '../../components/Modals/ErrorModal/ErrorModal';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// queries
import { ACTIVITY_REPORT_QUERY, UNITS_WITH_CHILDREN_QUERY } from '../../utils/graphQL_Queries/appQueries';
import { setUnitsQueryParams } from '../../utils/graphQL_Queries/queryVariableFormats';

// styles
import { PageTitle, Line } from '../../utils/appCSS/appStyles';

// utilities, constants
import {
  pageNames,
  UNIT_WARD,
  UNIT_STAKE,
  GLOBAL,
} from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { addPageAnalytics, CATEGORY_TYPE_REPORTS } from 'src/utils/analytics';

const { activityReportPageName } = pageNames;
const MAX_DROPDOWN_WIDTH = '350px';
const DEFAULT_REPORT_LENGTH = 365;

const AdminSetup = ({
  // availableStakes,
  handleAdminUnitChange,
  handleReportLevelChange,
  resetFlag,
  handleReset,
}) => {
  const [adminReportLevel, setAdminReportLevel] = useState('');
  const [adminStakeId, setAdminStakeId] = useState<string>('');
  const [adminWardId, setAdminWardId] = useState<string>('');
  const [availableStakes, setAvailableStakes] = useState<Array<{ name?: string, id?: string, childUnits?: any[] }>>([]);

  const handleLevelChange = e => setAdminReportLevel(e.target.value);
  const handleAdminStakeChange = e => {
    const stakeId = parseInt(e.target.value, 10);
    setAdminStakeId(stakeId.toString());
    handleAdminUnitChange(stakeId, true, 0, availableStakes); // set (boost) stake id, child flag and parent in ActivityPage
  };
  const handleAdminWardChange = e => {
    const wardId = parseInt(e.target.value, 10);
    setAdminWardId(wardId.toString());
    handleAdminUnitChange(wardId, false, adminStakeId, availableStakes); // set (boost) ward id, child flag and parent in ActivityPage
  };

  const { t } = useTranslation('strings');

  useEffect(() => {
    if (resetFlag) {
      setAdminReportLevel('');
      setAdminStakeId('');
      handleReset(false); // set (boost) reset flag in ActivityPage
    }
  }, [resetFlag, handleReset]);

  useEffect(() => {
    // Note: clear ward and stake ID when choosing GLOBAL (in case user previously selected Ward or Stake level)
    if (adminReportLevel === GLOBAL) {
      !!adminStakeId && setAdminStakeId('');
      !!adminWardId && setAdminWardId('');
      handleAdminUnitChange(0, false, 0, availableStakes); // clear ward id and child flag in ActivityPage
    }

    // Note: clear ward ID when choosing Stake (in case user previously selected Ward)
    if (adminReportLevel === UNIT_STAKE) {
      !!adminWardId && setAdminWardId('');
      handleAdminUnitChange(
        adminStakeId,
        true,
        0,
        availableStakes
        // !!adminStakeId ? availableStakes.find(stake => stake.id === adminStakeId) : []
        // availableStakes.find(stake => stake.id === adminStakeId)
      ); // clear ward id and child flag in ActivityPage
    }

    handleReportLevelChange(adminReportLevel); // set (boost) reportLevel in ActivityPage
  }, [
    adminReportLevel,
    adminStakeId,
    adminWardId,
    availableStakes,
    handleAdminUnitChange,
    handleReportLevelChange,
  ]);

  const adminLevels = [
    GLOBAL,
    // UNIT_AREA,
    UNIT_STAKE,
    UNIT_WARD,
  ];

  // unit lookup (stakes)
  const { loading, error, refetch } = useQuery(UNITS_WITH_CHILDREN_QUERY, {
    variables: setUnitsQueryParams(0, UNIT_STAKE), // startHere, unitType, unitNumber, unitStatus
    displayName: 'UNITS_WITH_CHILDREN_QUERY',
    onCompleted: (d) => setAvailableStakes(d.units),
  });

  if (loading) return <LoadingSpinner />;
  if (error) {
    return (
      <ErrorModal
        title={t('errorGettingActivityReportLabel')}
        onRetry={async () => await refetch()}
        errors={error}
        isActive
      />
    );
  }

  // const childUnits = adminStakeId ? PILOT_CHILD_UNITS[adminStakeId].childUnits : [];
  const childUnits = adminStakeId
    ? availableStakes.find(stake => stake.id === adminStakeId)?.childUnits ?? []
    : [];

  return (
    <Row gapSize="16">
      <section>
        <Label>
          {t('levelLabel')}
          <Select
            required
            data-test-id="activityPageAdminLevelSelector"
            value={adminReportLevel}
            onChange={handleLevelChange}
          >
            <option value="" disabled>
              {t('selectOneLabel')}
            </option>
            {adminLevels.map((level, index) => (
              <option key={index} value={level}>
                {t([level])}
              </option>
            ))}
          </Select>
        </Label>
      </section>

      <section>
        {[UNIT_STAKE, UNIT_WARD].includes(adminReportLevel)
          ? <Label>
            {t('selectStakeLabel')}
            <Select
              required
              data-test-id="activityPageAdminStakeSelector"
              value={adminStakeId.toString()}
              onChange={handleAdminStakeChange}
            >
              <option value="" disabled>
                {t('selectOneLabel')}
              </option>
              {availableStakes.map(unitObj => (
                <option key={unitObj.id} value={unitObj.id}>
                  {`${unitObj.name} (${unitObj.id})`}
                </option>
              ))}
            </Select>
          </Label>
          : null}
      </section>

      <section>
        {adminReportLevel === UNIT_WARD && adminStakeId
          ? <Label>
            {t('selectWardLabel')}
            <Select
              required
              data-test-id="activityPageAdminWardSelector"
              value={adminWardId.toString()}
              onChange={handleAdminWardChange}
            >
              <option value="" disabled>
                {t('selectOneLabel')}
              </option>
              {childUnits.map(unitObj => (
                <option key={unitObj.id} value={unitObj.id}>
                  {`${unitObj.name} (${unitObj.id})`}
                </option>
              ))}
            </Select>
          </Label>
          : null}
      </section>
    </Row>
  );
};

// reducer actions
// const SET_UNIT_ID = 'SET_UNIT_ID';
// const SET_INCLUDE_CHILDREN = 'SET_INCLUDE_CHILDREN';
// const SET_REPORT_LENGTH = 'SET_REPORT_LENGTH';
// const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
// const SET_START_DATE = 'SET_START_DATE';
// const SET_REPORT_ACTIVE = 'SET_REPORT_ACTIVE';
// const SET_REPORT_LEVEL = 'SET_REPORT_LEVEL';

const ActivityPage = () => {
  const [reportLength, setReportLength] = useState(DEFAULT_REPORT_LENGTH);
  const [startDate, setStartDate] = useState(subDays(new Date(), reportLength).toISOString());
  const [endDate] = useState(new Date().toISOString());
  const { loading, data, error, refetch } = useQuery(ACTIVITY_REPORT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      criteria: {
        reportLength,
      },
    },
    displayName: 'ACTIVITY_REPORT_QUERY',
  });

  const reportUnit = data?.activityReport?.units?.find(u => u.type === 'STAKE') ?? data?.activityReport?.units?.find(u => u.type === 'WARD');
  const reportUnitName = reportUnit ? `${reportUnit.name} (${reportUnit.unitNumber})` : '';
  const { t, i18n } = useTranslation('strings');
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: activityReportPageName, // always use English
        activePage: activityReportPageName, // used to set primary category
        breadCrumbs: [activityReportPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_REPORTS,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  const handleReportLengthChange = e => {
    const newReportLength = parseInt(e.target.value, 10);
    setReportLength(newReportLength);
    setStartDate(subDays(new Date(endDate), newReportLength).toISOString());
  };
  if (error) {
    return (
      <ErrorModal
        title={t('errorGettingActivityReportLabel')}
        onRetry={async () => await refetch()}
        errors={error}
        isActive
      />
    );
  }

  return (
    <>
      <PageTitle>{t('activityReportLabel')}</PageTitle>
      <Line />
      {!loading && <section style={{ maxWidth: MAX_DROPDOWN_WIDTH }}>
        <Label>
          {t('reportLengthLabel')}
          <Select
            required
            data-test-id="activityPageReportLengthSelector"
            value={reportLength.toString()}
            onChange={handleReportLengthChange}
          >
            <option value="" disabled>
              {t('selectOneLabel')}
            </option>
            <option value="365">{t('past365DaysLabel')}</option>
            <option value="90">{t('past90DaysLabel')}</option>
            <option value="60">{t('past60DaysLabel')}</option>
            <option value="30">{t('past30DaysLabel')}</option>
            <option value="7">{t('past7DaysLabel')}</option>
          </Select>
        </Label>
      </section>}

      <ActivityReport
        unitLabel={reportUnitName}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        data={data}
      />
    </>
  );
};

export default withPage({ pageName: activityReportPageName, pageTitle: 'activityReportPageTitle' })(ActivityPage);

AdminSetup.propTypes = {
  availableStakes: array,
  handleAdminUnitChange: func,
  handleReportLevelChange: func,
  resetFlag: bool,
  handleReset: func,
};
