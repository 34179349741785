import { useSelector } from 'react-redux';
import { UserUnit } from 'src/models/Unit';
import { User } from 'src/models/User';
import { selectUser } from 'src/reducers/authReducer';

interface IPermissionsObj {
  user: User
  isAdmin: boolean
  canView: boolean
  canApprove: boolean
  canEdit: boolean
  canCreate: boolean
  hasAny: boolean
  activeUnitObj: UserUnit
  activeUnitId: string
  primaryUnitId: string
}

export const useUserPermissions = (): IPermissionsObj => {
  const user = useSelector(selectUser);
  if (!user) {
    return {
      user: {} as User,
      isAdmin: false,
      canView: false,
      canApprove: false,
      canEdit: false,
      canCreate: false,
      hasAny: false,
      activeUnitObj: {} as UserUnit,
      activeUnitId: '',
      primaryUnitId: '',
    }
  }
  const { isAdmin, isViewer, isApprover, isContributor, unit, currentUnit } = user;

  const hasAny = isAdmin || isApprover || isContributor || isViewer;
  const canCreate = isAdmin || isApprover || isContributor;
  return {
    // hasPermissions,
    user,
    isAdmin,
    canView: isViewer,
    canApprove: isApprover,
    canEdit: isContributor,
    canCreate,
    activeUnitObj: currentUnit?.id ? currentUnit : unit,
    activeUnitId: currentUnit?.id ?? unit?.id,
    primaryUnitId: unit?.id,
    hasAny
  }
}
