import React from 'react';
import { bool, string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import Stack from '@churchofjesuschrist/eden-stack';

// styles
import { Bold } from '../../utils/appCSS/appStyles';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const { managePageName, myStoriesPageName } = pageNames;

export const TabInfoHelp = ({ canApprove, pageName, unitType }: { canApprove: boolean, pageName: string, unitType: ldsxrm_unittypes }) => {
  const { t } = useTranslation('');

  return (
    <Stack>
      {pageName === managePageName && (
        <>
          <section>
            <Bold>{t('tabTitleManageDrafts', { ns: 'strings' })}</Bold>
            {t('calloutTabInfoAssignDraftsHelp', { ns: 'strings' })}
          </section>

          <section>
            <Bold>{t('tabTitleManagePending', { ns: 'strings' })}</Bold>
            {t('calloutTabInfoPendingPubHelp', { ns: 'strings' })}
          </section>

          <section>
            <Bold>{t('tabTitlePublished', { ns: 'strings' })}</Bold>
            {unitType === ldsxrm_unittypes.Ward && t('calloutTabInfoPublishedApproverManageHelp', { ns: 'strings' })}
            {unitType === ldsxrm_unittypes.Stake && t('calloutTabInfoPublishedApproverManageHelpStake', { ns: 'strings' })}
          </section>
        </>
      )}

      {pageName === myStoriesPageName && (
        <>
          <section>
            <Bold>{t('tabTitleMyStoriesDrafts', { ns: 'strings' })}</Bold>
            {unitType === ldsxrm_unittypes.Ward && t('calloutTabInfoMyDraftsHelp', { ns: 'strings' })}
            {unitType === ldsxrm_unittypes.Stake && t('calloutTabInfoMyDraftsHelpStake', { ns: 'strings' })}
          </section>

          {!canApprove && (
            <section>
              <Bold>{t('tabTitleMyStoriesPending', { ns: 'strings' })}</Bold>
              {unitType === ldsxrm_unittypes.Ward && t('calloutTabInfoSubmittedHelp', { ns: 'strings' })}
              {unitType === ldsxrm_unittypes.Stake && t('calloutTabInfoSubmittedHelpStake', { ns: 'strings' })}
            </section>
          )}

          <section>
            <Bold>{t('tabTitlePublished', { ns: 'strings' })}</Bold>
            {canApprove &&
                  unitType === ldsxrm_unittypes.Ward &&
                  t('calloutTabInfoPublishedApproverMyStoriesHelp', { ns: 'strings' })}
            {canApprove &&
                  unitType === ldsxrm_unittypes.Stake &&
                  t('calloutTabInfoPublishedApproverMyStoriesHelpStake', { ns: 'strings' })}
            {!canApprove && unitType === ldsxrm_unittypes.Ward && t('calloutTabInfoPublishedMyStoriesHelp', { ns: 'strings' })}
            {!canApprove &&
                  unitType === ldsxrm_unittypes.Stake &&
                  t('calloutTabInfoPublishedMyStoriesHelpStake', { ns: 'strings' })}
          </section>
        </>
      )}
    </Stack>
  );
};

export default TabInfoHelp;

TabInfoHelp.propTypes = {
  pageName: string.isRequired,
  canApprove: bool,
  unitType: number,
};
