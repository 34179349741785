/****** uh_story ******
 * Auto-generated - do not modify.
 * To regenerate, run 'npm run enums'
 */

export const enum uh_story_statecode {
  Active = 0,
  Inactive = 1,
}
export const enum uh_story_statuscode {
  Draft = 1,
  Requested = 881930000,
  Pending_Unit = 881930001,
  Pending_Stake = 881930002,
  Published = 881930003,
  Pending = 881930004,
  Returned_for_Rework = 881930005,
  Inactive = 2,
  Pending_Invite = 881930006,
}
