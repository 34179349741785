import React, { createContext, useContext, useState } from 'react';
import shortid from 'shortid';

export interface IMessage {
  id?: string
  title: string
  message: string
  messageType: 'SUCCESS' | 'ERROR' | 'WARN' | 'INFO'
}

const defaultContext = {
  messages: [] as IMessage[],
  addMessage: (message: IMessage) => { },
  delMessage: (id: string) => { }
};

const NotificationContext = createContext(defaultContext);
NotificationContext.displayName = 'NotificationContext';

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext should be used within its provider.');
  }
  return context;
}

export function withNotificationContext(WrappedComponent: React.ComponentType<React.ComponentProps<any>>) {
  return function NotificationHOC(otherProps) {
    const [messages, setMessages] = useState<IMessage[]>([]);

    const addMessage = (message: IMessage) => {
      setMessages(prevState => [...prevState, { ...message, id: shortid.generate() }]);
      // console.log('Notification, added: message.title: ', message.title);
    };

    const delMessage = (id: string) => {
      const filtered = messages.filter(msg => msg.id !== id);
      setMessages([...filtered]);
    }

    return (
      <NotificationContext.Provider
        value={{
          messages,
          addMessage,
          delMessage,
        }}
      >
        <WrappedComponent {...otherProps} />
      </NotificationContext.Provider>
    );
  };
}

export default NotificationContext;
