import styled from 'styled-components';

import {
  grey15,
  grey40,
  grey5,
  grey60,
  lineHeightTight,
  sans as zoram,
  spacing16,
  spacing24,
  spacing8,
  white,
} from '@churchofjesuschrist/eden-style-constants';
import { flexRow } from 'src/components/ComponentStyles/div';
import { P } from 'src/components/ComponentStyles/elements';

export const SortSearchContainer = styled.div`
  padding-top: ${spacing16};
  border-bottom: 1px solid ${grey15};
`;

export const SortStoryBarContainer = styled.div`
  padding-top: ${spacing8};
`;

export const SortContainer = styled.div``;

export const SearchContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: flex-end;
  input {
    width: ${props => props.isMobile ? '300px' : '400px'};
  }
`;

export const DropDownContainer = styled.div<{ paddingTop?: string }>`
  padding-top: ${props => (props.paddingTop ? props.paddingTop : spacing24)};
  padding-bottom: ${spacing24};
`;

export const DropDownBtn = styled.div`
  cursor: pointer;
`;

export const DropDownTitle = styled.div<{ fontWeight?: string }>`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '700')};
  font-family: ${zoram};
`;

export const DropDownHeader = styled(flexRow)`
  cursor: pointer;
`;

export const DropDownRotated = styled.div<{ shouldRotate?: boolean }>`
  position: relative;
  top: ${props => (props.shouldRotate ? -6 : 0)}px;
  transform: rotateZ(${props => (props.shouldRotate ? -180 : 0)}deg);
`;

export const CheckboxContainer = styled(flexRow)`
  border-bottom: 1px solid ${grey5};
  padding: ${spacing16};
`;

export const Text = styled(P)`
  color: ${grey60};
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: ${spacing16};
  font-family: ${zoram};
  line-height: ${lineHeightTight};
  color: ${grey40};
  border-bottom: 1px solid ${grey5};
`;

export const OrgContainer = styled.div`
  padding: ${spacing8};
  margin: ${spacing8} 0;
`;

export const RadioContainer = styled(flexRow)`
  margin: ${spacing8} 0;
  align-items: center;
`;

export const SearchBar = styled.div`
  position: relative;
  width: 210px;
`;

export const SearchDropDown = styled.ol`
  position: absolute;
  z-index: 1;
  background: ${white};
  border: 1px solid ${grey60};
  padding: 4px;
  width: 210px;
  max-height: 116px;
  overflow: scroll;
  list-style-position: inside;
  list-style-type: circle;
`;

export const TagContainer = styled.div`
  padding: ${spacing16};
  width: auto;
`;
