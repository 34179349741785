import React from 'react';
import { useQuery } from '@apollo/client';
import { object, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import { Text3 } from '@churchofjesuschrist/eden-text';

// components
import DeleteStoryButton from '../../Buttons/DeleteStoryButton';
import StoryContent from './StoryContent';
import { EditStoryButton } from '../../Buttons/EditStoryButton';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import InContextHelp from '../../InContextHelp/InContextHelp';
import { usePageContext } from 'src/contexts/PageContext';
import {
  LockedByMessage,
  LockedUntilMesssage,
} from '../../Modals/StoryLocked/StoryLocked';

// constants
import {
  StoryContainer,
  LabelContainer,
  InfoContainer,
  DateContainer,
  Buttons,
} from './DisplayUnpublishedStories.style';
import { pendingStoryVarPropType } from '../../../utils/appConstants/propTypesConstants';

// functions
import { getStoryManagerQuery } from '../../../utils/graphQL_Queries/appQueries';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { TabStoriesShimmer } from 'src/components/Shimmer/CardShimmer';
import { formatDate } from 'src/utils/AppFunctions/appFunctions';
import { IStoryManagerResults } from 'src/models/Story';

const InvitesSentStories = ({ currentPageName, manageStories, storyVariables }: { currentPageName, manageStories, storyVariables }) => {
  const { t } = useTranslation('strings');
  const { localeCode } = usePageContext();
  const { user } = useUserPermissions();

  storyVariables.criteria.inviter = user?.id;

  const { data, error, loading, refetch } = useQuery<IStoryManagerResults>(getStoryManagerQuery, {
    fetchPolicy: 'network-only',
    variables: storyVariables,
    displayName: 'draftStories-Query'
  });

  if (loading) return <TabStoriesShimmer />;
  if (error ?? !data) {
    return (
      <ErrorModal
        currentPageName={currentPageName}
        title={t('retrieveStoryErrorMessage')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />
    );
  }

  if (data.storyManager.total === 0) {
    return (
      <StoryContainer data-test-id="invitessent_story_container0">
        {manageStories && <Text3>{t('noStoriesManageInvitesSentLabel')}</Text3>}
      </StoryContainer>
    );
  }

  return (
    <>
      {data.storyManager.content.map((story, index) => {
        const {
          status,
          id,
          created,
          submitted,
          modified,
          title,
          lock
        } = story;

        const locked = lock && lock.accountId !== user?.accountId;

        return (
          <StoryContainer key={id} data-test-id={`invitessent_container${index}`}>
            <StoryContent
              currentPageName={currentPageName}
              index={index}
              status={status}
              story={story}
              title={t('invitationLabel')}
            />

            <InfoContainer data-test-id="invitessent_info_container">
              <DateContainer>
                <LabelContainer>{t('dateCreatedLabel')}: </LabelContainer>
                <Text3>{formatDate(created, localeCode)}</Text3>
              </DateContainer>
              <DateContainer>
                <LabelContainer>
                  {submitted ? t('dateSubmittedLabel') : t('dateModifiedLabel')}:{' '}
                </LabelContainer>
                <Text3>{formatDate(submitted ?? modified, localeCode)}</Text3>
              </DateContainer>

              {id &&
                <Buttons data-test-id={`draft_buttons-${index}`} isMobile={false}>
                  <EditStoryButton
                    id={id}
                    currentPageName={currentPageName}
                    index={index}
                  />

                  <DeleteStoryButton
                    storyId={id}
                    storyTitle={title}
                    currentPageName={currentPageName}
                    index={index}
                    disabled={locked ?? false} // disable while someone is editing the record
                    onAfterDelete={async () => await refetch()}
                  />

                  {!!lock && (
                    <InContextHelp
                      // label={t('disabledButtonsLabel')}
                      index={index}
                    >
                      <div style={{ zIndex: 101 }}>
                        <LockedByMessage lockObj={lock} />
                        <p>{t('storyLockedMessage')}</p>
                        <p>
                          <LockedUntilMesssage lockObj={lock} />
                        </p>
                      </div>
                    </InContextHelp>
                  )}

                  {/* <SendReminderButton story={story} /> */}
                </Buttons>
              }
            </InfoContainer>
          </StoryContainer>
        )
      })}
    </>
  );
}

export default InvitesSentStories;

(InvitesSentStories as any).propTypes = {
  currentPageName: string.isRequired,
  manageStories: bool,
  storyVariables: pendingStoryVarPropType,
  activeUnitObj: object,
};
