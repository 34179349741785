import React from 'react';
import { number } from 'prop-types';

// components
import FAQ from '../../components/FAQ';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// styles
import { PageTitle } from '../../utils/appCSS/appStyles';

// utilities, constants
import {
  pageNames,
} from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { useTranslation } from 'react-i18next';
import { addPageAnalytics, CATEGORY_TYPE_HELP } from 'src/utils/analytics';

const { helpPageName, helpFAQsPageName } = pageNames;

export const FAQPage = ({ questionToOpen = 9 }) => {
  const { t, i18n } = useTranslation('strings');
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: helpPageName, // always use English
        activePage: helpFAQsPageName, // used to set primary category
        breadCrumbs: [helpPageName, helpFAQsPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_HELP,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <>
      <PageTitle>{t('faqLabel')}</PageTitle>
      <FAQ questionToOpen={questionToOpen} />
    </>
  );
}

export default withPage({ pageName: helpPageName, headTitle: 'faqPageTitle' })(FAQPage);

FAQPage.propTypes = {
  questionToOpen: number,
};
