import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Eden
import Stack from '@churchofjesuschrist/eden-stack';
import Split from '@churchofjesuschrist/eden-split';
import { H2, H3 } from '@churchofjesuschrist/eden-headings';
import { Unordered, Item } from '@churchofjesuschrist/eden-list';
import HtmlFragment from '@churchofjesuschrist/eden-html-fragment';
import {
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// components
import QuickStartTOC from './QuickStartTOC';
import { QuickStartWrapper } from '../../utils/appCSS/appStyles';

// constants
import { NAV_WHAT_SHOULD_I_LEAVE_OUT } from 'src/utils/AppFunctions/setUrls';
import { pageNames } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';
import { A } from 'src/components/Create/Form/Form.style';

const { helpApprovingStoriesPageName } = pageNames;

export const ApprovingStories = ({ match }: any) => {
  const { t } = useTranslation();
  const router = useHistory();
  const handleClick = (url) => router.push(url);

  return (
    <Split ratio="1/4">
      <aside>
        <QuickStartTOC activePage={helpApprovingStoriesPageName} />
      </aside>

      <main>
        <QuickStartWrapper>
          <H2 id="approving-stories">{t('approvingStories')}</H2>
          <Space top={spacing32} />
          <Stack>
            <H3>{t('roleOfApprovers')}</H3>
            <div>{t('roleOfApproversP1')}</div>
            <div>{t('roleOfApproversP2')}</div>
            <HtmlFragment content={t('roleOfApproversP3')} />
            <HtmlFragment content={t('roleOfApproversP4')} />
            <A href={NAV_WHAT_SHOULD_I_LEAVE_OUT} onClick={() => handleClick(NAV_WHAT_SHOULD_I_LEAVE_OUT)}>{t('roleOfApproversP4a')}</A>
            <div>{t('roleOfApproversP5')}</div>
            <div>{t('roleOfApproversP6')}</div>
            <HtmlFragment content={t('roleOfApproversP7')} />

            <H3>{t('clerkAndHistorySpecialists')}</H3>
            <div>{t('clerkAndHistorySpecialistsP1')}</div>
            <div>{t('clerkAndHistorySpecialistsP2')}</div>
            <Unordered>
              <Item key="1">{t('clerkAndHistorySpecialistsList1')}</Item>
              <Item key="2">{t('clerkAndHistorySpecialistsList2')}</Item>
              <Item key="3">{t('clerkAndHistorySpecialistsList3')}</Item>
              <Item key="4">{t('clerkAndHistorySpecialistsList4')}</Item>
              <Item key="5">{t('clerkAndHistorySpecialistsList5')}</Item>
            </Unordered>
            <div>{t('clerkAndHistorySpecialistsP3')}</div>
          </Stack>
        </QuickStartWrapper>
      </main>
    </Split>
  );
}

export default withPage({ pageName: helpApprovingStoriesPageName, headTitle: 'quickStartApprovingStories' })(ApprovingStories);
