import React, { Ref } from 'react';
import {
  Input,
  FormField,
  Select,
  TypeAhead,
  InputGroup,
} from '@churchofjesuschrist/eden-form-parts';
import { LoadingSpinner } from '@churchofjesuschrist/eden-icons';
import FieldValidityHint from './FieldValidityHint';
import { determineValidityState } from 'src/utils/formUtil';

interface ISelectFieldProps {
  /** The name of the input */
  name: string
  /** The individual value of the input (as opposed to the full `formData` object passed in `formData`) */
  value?: string
  /** Callback to call when the field changes */
  onChange: (ev) => void
  /** Is it required */
  required?: boolean
  /** Is it disabled */
  disabled?: boolean
  /** The validity object from the form that the component will dynamically pull out the validity state for this field based on the `name` */
  validity?: object
  /** The individual `ref` of the input */
  ref?: Ref<Input>
  /** The full set of refs from the form so that this component can dynamically pull out the ref for the input based on the name */
  refs?: object
  /** The full set of data from the form so that this component can dynamically pull out its value */
  formData?: object
  /** An array of label/value pairs that drive the `option`s of the select */
  options: { label: string, value: any }[]
  /** The type of input field */
  type?: 'select' | 'typeahead'
  /** autoComplete attribute */
  autoComplete?: string
  /** pattern attribute */
  pattern?: string | false
  /** Show spinner? */
  loading?: boolean
}

/**
 * Component for displaying a `select` with associated wrappers and error display
 * @returns the input wrapped in a `<FormField>`
 */
const SelectField = ({
  name,
  value = '',
  onChange,
  required,
  disabled,
  validity,
  ref,
  refs,
  formData,
  options,
  type,
  autoComplete,
  pattern,
  loading,
}: ISelectFieldProps) => {
  const fieldTypeGrabber = (fieldType) => {
    switch (fieldType) {
      case 'typeahead':
        return TypeAhead;
      default:
        return Select;
    }
  };
  const DynamicField = fieldTypeGrabber(type);

  // check for the full `formData` object being handed in with the named node as a child, or use the value
  const theValue = formData?.[name] || value;

  const TheInput = (
    <DynamicField
      name={name}
      value={theValue}
      onChange={onChange}
      required={required}
      disabled={disabled}
      ref={(refs?.[name]) || ref}
      autoComplete={autoComplete}
      pattern={pattern}
      id={
        theValue
      } /* ID needs to be unique for a safari bug with ajax loading the data */
    >
      {type !== 'typeahead' && <option value=""></option>}
      {options?.map((o, i) => (
        <option value={o.value} key={i} id={o.label} onClick={e => alert(e)}>
        </option>
      ))}
    </DynamicField>
  );

  return (
    <FormField
      validityState={validity ? determineValidityState(validity[name]) : null}
    >
      {type === 'typeahead'
        ? (
          <InputGroup>
            {TheInput}
            {loading && <LoadingSpinner size="2rem" />}
          </InputGroup>
        )
        : (
          TheInput
        )}
      <FieldValidityHint field={validity ? validity[name] : null} />
    </FormField>
  );
};

export default SelectField;
