/**
 * Formats bytes into respective string value.
 * @param bytes
 * @param decimals
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  if (!bytes) return '';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Converts byte array to base64 string.
 * @param buffer
 */
export const arrayBufferToBase64 = (buffer: []) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const readBlockToBlob = (_offset: number, length: number, _file: File, totalSize: number) => {
  const newOffset = _offset + length;
  return { result: _file.slice(_offset, length + _offset), offset: newOffset, success: newOffset >= totalSize };
}

export const readBlock = async (_offset: number, length: number, _file: File, totalSize: number) =>
  await new Promise<{ offset: number, result: [], success: boolean }>((resolve, reject) => {
    const reader = new FileReader();
    const blob = _file.slice(_offset, length + _offset);
    // Blob read cancel
    reader.onabort = () => {
      console.log('File reading was aborted.');
      reject(new Error('File reading was aborted.'));
    }
    // Blob read error
    reader.onerror = () => {
      reject(new Error('File reading errored.'));
    }
    // Blob read callback
    reader.onload = (evt: any) => {
      if (evt.target.error == null) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const newOffset = _offset + evt.target.result.byteLength; // Add length that was read to existing offset
        resolve({ offset: newOffset, result: evt.target.result, success: newOffset >= totalSize });
      } else {
        reject(evt.target.error);
      }
    };
    reader.readAsArrayBuffer(blob); // Start reading the chunk
  })
