import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// eden
import Modal from '@churchofjesuschrist/eden-platform-modal';
import { spacing16 } from '@churchofjesuschrist/eden-style-constants';

// components
import DisplayStory from 'src/components/View/DisplayStory';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

// queries
import { displayStoryQuery } from 'src/utils/graphQL_Queries/appQueries';

// constants
import { storyFormPropType } from 'src/utils/appConstants/propTypesConstants';

const ModalContainer = styled.div`
  width: 90vw;
  max-height: 90vh;
  padding: ${spacing16};
  overflow-y: auto;
`;

const PreviewModal = ({ isActive, handleClose, story }) => {
  const { t } = useTranslation('strings');

  const { loading, data, error, refetch } = useQuery(displayStoryQuery, {
    variables: {
      id: story.id,
    },
    displayName: 'DisplayStoryQuery',
    fetchPolicy: 'network-only'
  });

  if (loading) return <LoadingSpinner />;

  if (error) {
    return (
      <ErrorModal
        title={t('retrieveStoryErrorMessage')}
        onRetry={refetch}
        errors={error}
        isActive
      />
    );
  }

  return (
    <Modal active={isActive} onCloseGesture={handleClose}>
      <ModalContainer>
        <DisplayStory story={data.story} />
      </ModalContainer>
    </Modal>
  );
};

export default PreviewModal;

PreviewModal.propTypes = {
  isActive: bool,
  handleClose: func,
  story: storyFormPropType.isRequired,
};
