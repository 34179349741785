import styled from 'styled-components';

import {
  black,
  fontSize14,
  fontSize16,
  grey30,
  grey40,
  sans as zoram,
  spacing16,
  grey10,
  spacing8,
  spacing24,
} from '@churchofjesuschrist/eden-style-constants';

export const PersonContainer = styled.div`
  padding-top: ${spacing16};
`;

export const Heading = styled.div`
  font-size: ${fontSize14};
  font-weight: bold;
  padding: ${spacing24} ${spacing16} ${spacing8};
  border-bottom: 4px solid ${grey10};
`;

export const Person = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonName = styled.div<{ isActive: boolean }>`
  width: auto;
  font-size: ${fontSize16};
  color: ${props => (props.isActive ? black : grey30)};
`;

export const PersonRole = styled.div<{ isActive: boolean }>`
  width: auto;
  font-size: ${fontSize14};
  color: ${props => (props.isActive ? grey40 : grey30)};
`;

export const PersonItem = styled.div`
  padding: ${spacing8} ${fontSize16};
`;

export const PersonRow = styled.div`
  width: 100%;
  display: flex;
  font-family: ${zoram};
  justify-content: space-between;
  align-items: flex-start;
`;

export const DisplayPeople = styled.div``;
