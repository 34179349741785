import React, { useEffect, useState } from 'react';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { array, string, number, func, object } from 'prop-types';

import { Star, StarFilled } from '@churchofjesuschrist/eden-icons';
import { yellow10, info40 } from '@churchofjesuschrist/eden-style-constants';

import InContextHelp from 'src/components/InContextHelp/InContextHelp';
import { Favorite } from './buttonStyles';
import { updateUserPreferencesMutation } from 'src/utils/graphQL_Queries/appMutations';

import { userType } from 'src/utils/appConstants/propTypesConstants';
import { useAppDispatch } from 'src/utils/appStore';
import { selectUser, setUser } from 'src/reducers/authReducer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const FavoriteButton = ({
  storyId,
  hasImage,
  favorites,
  index,
}: {
  storyId?
  hasImage?
  favorites?
  index?
}) => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [recent, setRecent] = useState(false);
  const [changed, setChanged] = useState(false);
  const updateFavorites = async (id, updateFavoritesFunc: MutationFunction<any, OperationVariables>) => {
    const updatedFavorites = [...favorites];
    if (updatedFavorites.includes(id)) {
      // remove the favorite from the list
      updatedFavorites.splice(
        updatedFavorites.findIndex(favorite => id === favorite),
        1
      );
    } else {
      // add the favorite to the list
      updatedFavorites.push(id);
    }
    setChanged(true);
    dispatch(setUser({ user: { ...user, favorites: updatedFavorites } }));
    await updateFavoritesFunc({
      variables: { ids: updatedFavorites },
    });
  };

  const favorited = favorites.includes(storyId);

  useEffect(() => {
    if (changed) {
      setRecent(true);

      setTimeout(() => setRecent(false), 2000);
    }
  }, [favorited, changed]);

  return (
    <Mutation mutation={updateUserPreferencesMutation}>
      {(updateFavoritesFunc: MutationFunction<any, OperationVariables>, { error }: MutationResult<any>) => (
        <Favorite
          onClick={e => {
            e.preventDefault();
            updateFavorites(storyId, updateFavoritesFunc);
          }}
          hasImage={hasImage}
          data-test-id={`favStatus-${storyId}`}
          className="favorite"
        >
          {error && <div>{t('errorLoadingFavesLabel')}</div>}
          <InContextHelp
            Icon={favorited ? StarFilled : Star}
            iconColor={favorited ? yellow10 : info40}
            index={index}
          >
            <div style={{ zIndex: 2 }}>
              {favorited ? recent ? t('favoriteAdded') : t('removeFavoriteMessage') : recent ? t('favoriteRemoved') : t('addToFavoritesMessage')}
            </div>
          </InContextHelp>
        </Favorite>
      )}
    </Mutation>
  );
}

export default FavoriteButton;

(FavoriteButton as any).propTypes = {
  favorites: array,
  hasImage: string,
  index: number,
  storyId: string.isRequired,
  user: userType,
  finish: func,
  strings: object,
};
