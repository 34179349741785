/* eslint-disable @typescript-eslint/indent */
import React, { Fragment, useState } from 'react';
import { func, array, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import { Add } from '@churchofjesuschrist/eden-icons';
import { Checkbox, Label, FieldSet } from '@churchofjesuschrist/eden-form-parts';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { Text4 } from '@churchofjesuschrist/eden-text';
import Card from '@churchofjesuschrist/eden-card';
import Row from '@churchofjesuschrist/eden-row';
import { spacing8, spacing16 } from '@churchofjesuschrist/eden-style-constants';

// components
import DisplayTags from 'src/components/Tags/DisplayTags';
import DropDownCard from 'src/components/DropDownCards/DropDownCard';
import InContextHelp from 'src/components/InContextHelp/InContextHelp';

// styles
import {
  DisplaySelectedOrgList,
  OrganizationItem,
} from 'src/components/Organizations/OrganzationStyle';
import { DropDownContent, ScrollContent } from 'src/utils/appCSS/appStyles';
import { Space } from '../Feedback/FeedbackFormStyles';

// constants
import { pageNames, formValidityState } from 'src/utils/appConstants/appConstants';
import { UnitOrganization } from 'src/models/Unit';
import { usePageContext } from 'src/contexts/PageContext';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const { storyFromPageName } = pageNames;
const { validityStateUnknownLabel } = formValidityState;

const AddOrganizationsToStory = ({ checkedOrganizations = [], handleUpdateOrganizations, unitType, fullOrgList }:
  {
    checkedOrganizations: string[]
    handleUpdateOrganizations: (orgs: string[]) => void
    unitType: ldsxrm_unittypes
    fullOrgList: UnitOrganization[]
  }) => {
  const { pageName } = usePageContext();
  const [isOrganizationDropdownOpen, setDropdownOpen] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(checkedOrganizations);
  const { t } = useTranslation();

  const selectedOrganizations = fullOrgList.filter(o => checkedOrganizations.some(c => c === o.uuid));

  const toggleAddOrgBtnClick = () => {
    setDropdownOpen(open => !open);
  }

  const handleOrgDropdownClose = event => {
    event.stopPropagation();
    setCurrentChecked(checkedOrganizations);
    setDropdownOpen(false);
  }

  const handleOrgChange = (event) => {
    const organizationToChange = event.target.value;
    const existing = currentChecked.find(o => o === organizationToChange);
    let updateCheckOrganizations: string[];
    if (existing) {
      updateCheckOrganizations = currentChecked.filter(o => o !== organizationToChange);
    } else {
      updateCheckOrganizations = [
        ...currentChecked,
        organizationToChange
      ];
    }

    setCurrentChecked(updateCheckOrganizations);
    // handleUpdateOrganizations(updateCheckOrganizations);
  }

  const handleOrgRemove = (org) => {
    const updatedOrgList = checkedOrganizations.filter(
      organization => organization !== org
    );

    handleUpdateOrganizations(updatedOrgList);
    setCurrentChecked(updatedOrgList);
  }

  const handleRemoveAll = () => {
    handleUpdateOrganizations([]);
    setCurrentChecked([]);
  }

  const submitChecked = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    handleUpdateOrganizations(currentChecked.map(c => fullOrgList.find(o => o.uuid === c)?.uuid!));
    setDropdownOpen(false);
  }

  const helpContent = unitType === ldsxrm_unittypes.Stake ? t('calloutOrganizationTextStake') : unitType === ldsxrm_unittypes.Ward ? t('calloutOrganizationText') : t('UNKNOWN');

  return (
    <>
      <Label id="selectOrganizationLabel" validityState={validityStateUnknownLabel}>
        <Row
          gapSize="4"
          columnGapSize="4"
          verticalAlign="center"
          style={{ margin: '-0.25rem 0 0.25rem 0' }} // to fix the -4px left margin and the 4px bottom margin
        >
          {t('addOrganizationTitleLabel')} {pageName === storyFromPageName && t('optionalLabel')}
          <InContextHelp index={3}>
            {helpContent}
          </InContextHelp>
        </Row>
      </Label>

      <Secondary data-test-id="organizationFormDropDownBtn" onClick={toggleAddOrgBtnClick}>
        <Add />
        <Space start={spacing8}>{t('addOrganizationBtnLabel', { ns: 'labels' })}</Space>
      </Secondary>

      {isOrganizationDropdownOpen
        ? (
          <DropDownCard handleClose={handleOrgDropdownClose}>
            <Card>
              <DropDownContent>
                <ScrollContent boxHeight="300px">
                  {fullOrgList.map((o, orgIndex) => {
                    const checked = currentChecked.some(c => c === o.uuid);
                    return (
                      <OrganizationItem
                        id={`organizationName${orgIndex}`}
                        key={`organizationName${orgIndex}`}
                      >
                        <FieldSet>
                          <Label>
                            <Checkbox
                              checked={checked}
                              name={o.name}
                              onChange={handleOrgChange}
                              value={o.uuid}
                            />
                            {o.name}
                          </Label>
                        </FieldSet>
                      </OrganizationItem>
                    );
                  })}
                </ScrollContent>

                <div style={{ margin: `24px ${spacing16} 0 0` }}>
                  <Row gapSize="16">
                    <Primary id="submitAddPersonBtn" onClick={submitChecked}>
                      <div>{t('submitBtnLabel', { ns: 'labels' })}</div>
                    </Primary>
                    <Secondary
                      id="cancelOrgDropdownBtn"
                      onClick={handleOrgDropdownClose}
                    >
                      {t('cancelBtnLabel', { ns: 'labels' })}
                    </Secondary>
                  </Row>
                </div>
              </DropDownContent>
            </Card>
          </DropDownCard>
        )
        : (
          <div />
        )}

      {checkedOrganizations.length > 0 && (
        <DisplaySelectedOrgList>
          <Text4>{t('formOrganizationTitle')}</Text4>
        </DisplaySelectedOrgList>
      )}

      <DisplayTags
        showCloseBtn={true}
        tagArray={selectedOrganizations}
        removeTag={handleOrgRemove}
        removeAll={handleRemoveAll}
      />
    </>
  );
}

export default AddOrganizationsToStory;

(AddOrganizationsToStory as any).propTypes = {
  fullOrgList: array.isRequired,
  handleUpdateOrganizations: func.isRequired,
  checkedOrganizations: array,
  unitType: number,
};
