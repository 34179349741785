import gql from 'graphql-tag';

export const saveInviteMutation = gql`
  mutation saveInviteMutation($story: StoryInput!) {
    saveInvite(story: $story) {
      inviter
      notes
      unit
      story {
        id
        dateStart
        dateEnd
        title
        authors {
          accountId
          name
          email
          position
        }
        body
        personTags {
          name
          id
        }
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        unit {
          id
          name
          type
        }
        confidential
        status
        created
        modified
        submitted
        published
      }
    }
  }
`;

export const saveStoryMutation = gql`
  mutation saveStoryMutation($story: StoryInput) {
    saveStory(story: $story) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      personTags {
        name
        id
        uuid
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      lock {
        accountId
        name
        date
      }
      confidential
      sensitive
      sensitiveComments
      status
      created
      modified
      submitted
      published
    }
  }
`;

export const saveAndSubmitStoryMutation = gql`
  mutation saveAndSubmitStoryMutation($story: StoryInput, $images: [Upload], $documents: [Upload]) {
    saveAndSubmitStory(story: $story, images: $images, documents: $documents) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      personTags {
        name
        id
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      lock {
        accountId
        name
        date
      }
      confidential
      status
      created
      modified
      submitted
      published
    }
  }
`;

// add Attachment (PDF)
export const addAttachmentsMutation = gql`
  mutation addAttachmentsMutation($storyId: Guid!, $attachments: [Upload]) {
    addAttachments(storyId: $storyId, attachments: $attachments) {
      id
      name
      source
      type
    }
  }
`;

// add Image
export const addImagesMutation = gql`
  mutation addImagesMutation($storyId: Guid!, $images: [Upload]) {
    addImages(storyId: $storyId, images: $images) {
      id
      name
      sources
      type
    }
  }
`;

// Invite User Mutation
export const createStoryFromInviteMutation = gql`
  mutation createStoryFromInviteMutation($invite: StoryInviteInput, $sender: PersonInput!) {
    assignStory(invite: $invite, sender: $sender) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      confidential
      status
      created
      modified
      submitted
      published
    }
  }
`;

// remove Attachment (PDF)
export const deleteAttachmentsMutation = gql`
  mutation deleteAttachmentsMutation($contentIds: [String]!, $storyId: Guid!) {
    deleteAttachments(contentIds: $contentIds, storyId: $storyId)
  }
`;

// remove Image
export const deleteImagesMutation = gql`
  mutation deleteImagesMutation($contentIds: [String]!, $storyId: Guid!) {
    deleteImages(contentIds: $contentIds, storyId: $storyId)
  }
`;

// remove Story
export const deleteStoryMutation = gql`
  mutation deleteStoryMutation($id: Guid!) {
    deleteStory(id: $id)
  }
`;

// Publish story
export const publishStoryMutation = gql`
  mutation publishStoryMutation($id: Guid!) {
    publishStory(id: $id) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      confidential
      status
      created
      modified
      submitted
      published
    }
  }
`;

// save Translations
export const saveTranslationsMutation = gql`
  mutation saveTranslationsMutation($key: Guid!, $value: String!, $locale: String!) {
    saveTranslations(key: $key, value: $value, locale: $locale) {
      scalar
    }
  }
`;

// Submit story for review
export const submitStoryMutation = gql`
  mutation submitStoryMutation($id: Guid!) {
    submitStory(id: $id) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      confidential
      status
      created
      modified
      submitted
      published
    }
  }
`;

// Update Favorites
export const updateUserPreferencesMutation = gql`
  mutation updateUserPreferencesMutation($ids: [Guid!]) {
    userPreferences(ids: $ids) {
      favorites
    }
  }
`;

// move story back to DRAFT status
export const unpublishStoryMutation = gql`
  mutation unpublishStoryMutation($id: Guid!, $comments: String) {
    unpublishStory(id: $id, comments: $comments) {
      id
      dateStart
      dateEnd
      title
      authors {
        accountId
        name
        email
        position
      }
      modifiedBy
      modifiedById
      modifiedByOrganization
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      lock {
        accountId
        name
        date
      }
      confidential
      status
      created
      modified
      submitted
      published
    }
  }
`;

// add admin
export const ADD_ADMIN_MUTATION = gql`
  mutation saveAccountMutation($account: AccountInput) {
    saveAccount(account: $account) {
      ldsAccountId
      username
      displayName
      roles
      modified
      modifiedBy
      modifiedById
      dateAdded
    }
  }
`;

// remove admin
export const DEL_ADMIN_MUTATION = gql`
  mutation deleteAccountMutation($id: Long!) {
    deleteAccount(ldsAccountId: $id)
  }
`;

// add / update unit
export const ADD_UNIT_MUTATION = gql`
  mutation saveUnitMutation($unitInput: UnitInput!) {
    saveUnit(unit: $unitInput) {
      id
      name
      type
      # dateAdded
      # status
      parentId
    }
  }
`;

// remove unit
export const DEL_UNIT_MUTATION = gql`
  mutation deleteUnitMutation($unitInput: UnitInput!) {
    deleteUnit(unit: $unitInput)
  }
`;

export const UPDATE_ACTIVE_UNIT = gql`
  mutation updateActiveUnit($id: Guid!) {
    updateActiveUnit(id: $id) {
      id
      unit {
        id
      }
    }
  }
`

export const VALIDATE_PROXY = gql`
  mutation validateProxy($uuid: String!) {
    validateProxy(uuid: $uuid) {
      id
      accountId
      ldsAccountId
      name
      email
      position
      authenticated
      favorites
      unit {
        id
        name
        type
        unitNumber
      }
      parentUnit {
        id
        name
        type
        unitNumber
      }
      isAdmin
      isApprover
      isContributor
      isViewer
      isReporter
    }
  }
`;
