import { StoreEnhancer } from '@reduxjs/toolkit';

const round = (number) => Math.round(number * 100) / 100;

const monitorReducerEnhancer: StoreEnhancer =
  (createStore) => (reducer, initialState) => {
    const monitoredReducer = (state, action) => {
      if (typeof performance !== 'undefined') {
        const start = performance.now();
        const newState = reducer(state, action);
        const end = performance.now();
        const diff = round(end - start);

        process.env.NODE_ENV !== 'production' && console.log('reducer process time:', diff);

        return newState;
      }
      return reducer(state, action);
    };

    return createStore(monitoredReducer, initialState);
  };

export default monitorReducerEnhancer;
