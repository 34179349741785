import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, array, string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import Row from '@churchofjesuschrist/eden-row';
import Card from '@churchofjesuschrist/eden-card';
import Stack from '@churchofjesuschrist/eden-stack';
import Modal from '@churchofjesuschrist/eden-platform-modal';
import { Select } from '@churchofjesuschrist/eden-form-parts';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';

// components
import DropDownCard from 'src/components/DropDownCards/DropDownCard';
import InContextHelp from 'src/components/InContextHelp/InContextHelp';

// constants
import { UNIT_STAKE } from 'src/utils/appConstants/appConstants';

// styles
import { ButtonContainer } from 'src/components/Modals/AddAuthor/AddAuthor.style';
import { DropDownTitle } from 'src/components/SortAndSearchBar/SortAndSearchStyles';
import { DropDownContent } from 'src/utils/appCSS/appStyles';

export const UnitSelectorContainer = styled.div<{ paddingTop?: string }>`
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '32px')};
  width: ${props => (props.paddingTop ? props.paddingTop : '600px')};
  text-align: right;
`;

// just the unit selector piece
export const UnitDropDownSelector = ({
  units,
  activeUnitId,
  changeHandler,
  showId,
  showHelp,
  showCalling,
}) => {
  const { t } = useTranslation('strings');
  const id = activeUnitId ? activeUnitId.toString() : '';
  return (
    <Row gapSize="4" verticalAlign="center">
      <section>
        <Select data-test-id="select-unit" onChange={changeHandler} value={id}>
          <option value="" disabled>
            {t('selectOneLabel')}
          </option>
          {units?.map(unitObj => (
            <option key={unitObj.id} value={unitObj.id.toString()}>
              {showCalling
                ? `${unitObj.positions?.map(calling => t([calling])).join(', ')} / `
                : ''}
              {unitObj.name} {showId ? ` (${unitObj.unitNumber})` : ''}
            </option>
          ))}
        </Select>
      </section>

      <section>
        {showHelp
          ? (
            <InContextHelp index={0}>
              {t('chooseUnitToolTip')}
            </InContextHelp>
          )
          : null}
      </section>
    </Row>
  );
};

// unit selector with submit and cancel
export const SelectUnit = ({
  isActive,
  activeUnitId,
  units,
  handleUnitSelect,
  handleCancel,
  showId,
  showHelp,
  showCalling,
}) => {
  const { t } = useTranslation('strings');
  const [active, setActive] = useState(isActive);
  const [selectedUnitId, setUnitId] = useState(
    activeUnitId || (units.length > 1
      ? units.find(unitObj => unitObj.type === UNIT_STAKE).id
      : units[0].id)
  );

  const closeModal = () => setActive(false);

  const handleUnitChange = e => {
    const newUnitId = parseInt(e.target.value, 10);
    setUnitId(newUnitId);
  };

  const handleSubmit = event => {
    event.stopPropagation();
    closeModal();
    handleUnitSelect(selectedUnitId);
  };

  const handleDropdownClose = event => {
    event.stopPropagation();
    closeModal();
    handleCancel?.();
  };

  return (
    <Modal active={active} onCloseGesture={handleDropdownClose}>
      <DropDownCard handleClose={handleDropdownClose}>
        <Card>
          <DropDownContent>
            <Stack>
              <section>
                <DropDownTitle>{t('selectUnitLabel')}</DropDownTitle>
              </section>
              <section>
                <UnitDropDownSelector
                  units={units}
                  activeUnitId={selectedUnitId}
                  changeHandler={handleUnitChange}
                  showId={showId}
                  showHelp={showHelp}
                  showCalling={showCalling}
                />
              </section>
              <section>
                <ButtonContainer>
                  <Primary onClick={handleSubmit}>
                    <div>{t('submitBtnLabel', { ns: 'labels' })}</div>
                  </Primary>
                  <Secondary onClick={handleDropdownClose}>{t('cancelBtnLabel', { ns: 'labels' })}</Secondary>
                </ButtonContainer>
              </section>
            </Stack>
          </DropDownContent>
        </Card>
      </DropDownCard>
    </Modal>
  );
};

export default SelectUnit;

UnitDropDownSelector.propTypes = {
  units: array,
  activeUnitId: string,
  changeHandler: func,
  showId: bool,
  showHelp: bool,
  showCalling: bool,
};

SelectUnit.propTypes = {
  isActive: bool,
  activeUnitId: string,
  units: array,
  handleUnitSelect: func,
  handleCancel: func,
  showId: bool,
  showHelp: bool,
  showCalling: bool,
};
