import { useTranslation } from 'react-i18next';
import { ldsxrm_positions } from 'src/common/enums/ldsxrm_churchposition';
import {
  BISHOPRIC,
  ELDERS_QUORUM,
  PRIMARY,
  SUNDAY_SCHOOL,
  RELIEF_SOCIETY,
  YOUNG_MEN,
  YOUNG_WOMEN,
  STAKE_PRESIDENCY,
  STAKE_PRESIDENT,
  STAKE_PRESIDENCY_FIRST_COUNSELOR,
  STAKE_PRESIDENCY_SECOND_COUNSELOR,
  STAKE_EXECUTIVE_SECRETARY,
  STAKE_CLERK,
  STAKE_ASSISTANT_CLERK,
  STAKE_HIGH_COUNCILOR,
  STAKE_HIGH_COUNCIL,
  STAKE_PRIMARY,
  STAKE_SUNDAY_SCHOOL,
  STAKE_RELIEF_SOCIETY,
  STAKE_YOUNG_MEN,
  STAKE_YOUNG_WOMEN,
  STAKE_PRIMARY_PRESIDENT,
  STAKE_PRIMARY_FIRST_COUNSELOR,
  STAKE_PRIMARY_SECOND_COUNSELOR,
  STAKE_PRIMARY_SECRETARY,
  STAKE_SUNDAY_SCHOOL_PRESIDENT,
  STAKE_SUNDAY_SCHOOL_FIRST_COUNSELOR,
  STAKE_SUNDAY_SCHOOL_SECOND_COUNSELOR,
  STAKE_SUNDAY_SCHOOL_SECRETARY,
  STAKE_RELIEF_SOCIETY_PRESIDENT,
  STAKE_RELIEF_SOCIETY_FIRST_COUNSELOR,
  STAKE_RELIEF_SOCIETY_SECOND_COUNSELOR,
  STAKE_RELIEF_SOCIETY_SECRETARY,
  STAKE_YOUNG_WOMEN_PRESIDENT,
  STAKE_YOUNG_WOMEN_FIRST_COUNSELOR,
  STAKE_YOUNG_WOMEN_SECOND_COUNSELOR,
  STAKE_YOUNG_WOMEN_SECRETARY,
  STAKE_YOUNG_MEN_PRESIDENT,
  STAKE_YOUNG_MEN_FIRST_COUNSELOR,
  STAKE_YOUNG_MEN_SECOND_COUNSELOR,
  STAKE_YOUNG_MEN_SECRETARY,
  STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT,
  PRESIDENT,
  FIRST_COUNSELOR,
  SECOND_COUNSELOR,
  SECRETARY,
  BISHOP,
  BISHOPRIC_FIRST_COUNSELOR,
  BISHOPRIC_SECOND_COUNSELOR,
  EXECUTIVE_SECRETARY,
  RELIEF_SOCIETY_PRESIDENT,
  RELIEF_SOCIETY_FIRST_COUNSELOR,
  RELIEF_SOCIETY_SECOND_COUNSELOR,
  RELIEF_SOCIETY_SECRETARY,
  WARD_CLERK,
  WARD_ASSISTANT_CLERK,
  WARD_MISSION_LEADER,
  ELDERS_QUORUM_PRESIDENT,
  ELDERS_QUORUM_FIRST_COUNSELOR,
  ELDERS_QUORUM_SECOND_COUNSELOR,
  ELDERS_QUORUM_SECRETARY,
  PRIMARY_PRESIDENT,
  PRIMARY_FIRST_COUNSELOR,
  PRIMARY_SECOND_COUNSELOR,
  PRIMARY_SECRETARY,
  SUNDAY_SCHOOL_PRESIDENT,
  SUNDAY_SCHOOL_FIRST_COUNSELOR,
  SUNDAY_SCHOOL_SECOND_COUNSELOR,
  SUNDAY_SCHOOL_SECRETARY,
  YOUNG_WOMEN_PRESIDENT,
  YOUNG_WOMEN_FIRST_COUNSELOR,
  YOUNG_WOMEN_SECOND_COUNSELOR,
  YOUNG_WOMEN_SECRETARY,
  YOUNG_MEN_PRESIDENT,
  YOUNG_MEN_FIRST_COUNSELOR,
  YOUNG_MEN_SECOND_COUNSELOR,
  YOUNG_MEN_SECRETARY,
  HISTORY_SPECIALIST,
  PUBLIC_AFFAIRS_SPECIALIST,
  UNIT_STAKE,
  UNIT_WARD,
  UNIT_BRANCH,
  BRANCH_PRESIDENCY,
  DISTRICT_PRESIDENCY,
  WARD_TEMPLE_FAMILY_HISTORY_LEADER,
} from './appConstants';

export const BRANCH_AUXILIARIES = [
  UNIT_BRANCH,
  BRANCH_PRESIDENCY,
  // ELDERS_QUORUM,
  // PRIMARY,
  // SUNDAY_SCHOOL,
  // RELIEF_SOCIETY,
  // YOUNG_MEN,
  // YOUNG_WOMEN,
];

export const WARD_AUXILIARIES = [
  UNIT_WARD,
  BISHOPRIC,
  ELDERS_QUORUM,
  PRIMARY,
  SUNDAY_SCHOOL,
  RELIEF_SOCIETY,
  YOUNG_MEN,
  YOUNG_WOMEN,
];

export const STAKE_AUXILIARIES = [
  UNIT_STAKE,
  STAKE_PRESIDENCY,
  STAKE_HIGH_COUNCIL,
  STAKE_PRIMARY,
  STAKE_SUNDAY_SCHOOL,
  STAKE_RELIEF_SOCIETY,
  STAKE_YOUNG_MEN,
  STAKE_YOUNG_WOMEN,
];

export const POSITIONS = [PRESIDENT, FIRST_COUNSELOR, SECOND_COUNSELOR, SECRETARY];

export const BISHOPRIC_POSITIONS = [
  BISHOP,
  BISHOPRIC_FIRST_COUNSELOR,
  BISHOPRIC_SECOND_COUNSELOR,
  EXECUTIVE_SECRETARY,
  WARD_CLERK,
];

export const STAKE_POSITIONS = [
  STAKE_PRESIDENT,
  STAKE_PRESIDENCY_FIRST_COUNSELOR,
  STAKE_PRESIDENCY_SECOND_COUNSELOR,
  STAKE_EXECUTIVE_SECRETARY,
  STAKE_CLERK,
  STAKE_ASSISTANT_CLERK,
  STAKE_HIGH_COUNCILOR,
  STAKE_PRIMARY_PRESIDENT,
  STAKE_PRIMARY_FIRST_COUNSELOR,
  STAKE_PRIMARY_SECOND_COUNSELOR,
  STAKE_PRIMARY_SECRETARY,
  STAKE_SUNDAY_SCHOOL_PRESIDENT,
  STAKE_SUNDAY_SCHOOL_FIRST_COUNSELOR,
  STAKE_SUNDAY_SCHOOL_SECOND_COUNSELOR,
  STAKE_SUNDAY_SCHOOL_SECRETARY,
  STAKE_RELIEF_SOCIETY_PRESIDENT,
  STAKE_RELIEF_SOCIETY_FIRST_COUNSELOR,
  STAKE_RELIEF_SOCIETY_SECOND_COUNSELOR,
  STAKE_RELIEF_SOCIETY_SECRETARY,
  STAKE_YOUNG_MEN_PRESIDENT,
  STAKE_YOUNG_MEN_FIRST_COUNSELOR,
  STAKE_YOUNG_MEN_SECOND_COUNSELOR,
  STAKE_YOUNG_MEN_SECRETARY,
  STAKE_YOUNG_WOMEN_PRESIDENT,
  STAKE_YOUNG_WOMEN_FIRST_COUNSELOR,
  STAKE_YOUNG_WOMEN_SECOND_COUNSELOR,
  STAKE_YOUNG_WOMEN_SECRETARY,
  STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT,
  HISTORY_SPECIALIST,
];

export const WARD_POSITIONS = [
  BISHOP,
  BISHOPRIC_FIRST_COUNSELOR,
  BISHOPRIC_SECOND_COUNSELOR,
  EXECUTIVE_SECRETARY,
  WARD_CLERK,
  WARD_ASSISTANT_CLERK,
  ELDERS_QUORUM_PRESIDENT,
  ELDERS_QUORUM_FIRST_COUNSELOR,
  ELDERS_QUORUM_SECOND_COUNSELOR,
  ELDERS_QUORUM_SECRETARY,
  PRIMARY_PRESIDENT,
  PRIMARY_FIRST_COUNSELOR,
  PRIMARY_SECOND_COUNSELOR,
  PRIMARY_SECRETARY,
  SUNDAY_SCHOOL_PRESIDENT,
  SUNDAY_SCHOOL_FIRST_COUNSELOR,
  SUNDAY_SCHOOL_SECOND_COUNSELOR,
  SUNDAY_SCHOOL_SECRETARY,
  RELIEF_SOCIETY_PRESIDENT,
  RELIEF_SOCIETY_FIRST_COUNSELOR,
  RELIEF_SOCIETY_SECOND_COUNSELOR,
  RELIEF_SOCIETY_SECRETARY,
  YOUNG_MEN_PRESIDENT,
  YOUNG_MEN_FIRST_COUNSELOR,
  YOUNG_MEN_SECOND_COUNSELOR,
  YOUNG_MEN_SECRETARY,
  YOUNG_WOMEN_PRESIDENT,
  YOUNG_WOMEN_FIRST_COUNSELOR,
  YOUNG_WOMEN_SECOND_COUNSELOR,
  YOUNG_WOMEN_SECRETARY,
  HISTORY_SPECIALIST,
  PUBLIC_AFFAIRS_SPECIALIST,
  WARD_MISSION_LEADER,
];

export const ALL_POSITIONS = WARD_POSITIONS.concat(STAKE_POSITIONS);
export const ALL_ORGANIZATIONS = WARD_AUXILIARIES.concat(STAKE_AUXILIARIES).concat(
  BRANCH_AUXILIARIES
);

export const usePositionConstants = () => {
  const { t } = useTranslation();
  const presidentPosName = t('PRESIDENT');
  const counselorFirstName = t('FIRST_COUNSELOR');
  const counselorSecondName = t('SECOND_COUNSELOR');
  const secretaryName = t('SECRETARY');
  const priestsQuorumAdviserName = t('PRIESTS_QUORUM_ADVISER');
  const teachersQuorumAdviserName = t('TEACHERS_QUORUM_ADVISER');
  const deaconsQuorumAdviserName = t('DEACONS_QUORUM_ADVISER');
  const youngWomenClassAdviser = t('YOUNG_WOMEN_CLASS_ADVISER');

  const STAKE_AUXILIARIES_OBJ = [
    {
      auxiliaryName: t('STAKE_PRESIDENCY'),
      positions: [
        {
          displayName: t('STAKE_PRESIDENT'),
          positionName: STAKE_PRESIDENT,
          id: ldsxrm_positions.Stake_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_PRESIDENCY_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Presidency_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_PRESIDENCY_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Presidency_Second_Counselor
        },
        {
          displayName: t('EXECUTIVE_SECRETARY'),
          positionName: STAKE_EXECUTIVE_SECRETARY,
          id: ldsxrm_positions.Stake_Executive_Secretary
        },
        {
          displayName: t('STAKE_CLERK'),
          positionName: STAKE_CLERK,
          id: ldsxrm_positions.Stake_Clerk
        },
        {
          displayName: t('STAKE_ASSISTANT_CLERK'),
          positionName: STAKE_ASSISTANT_CLERK,
          id: ldsxrm_positions.Stake_Assistant_Clerk
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_HIGH_COUNCIL'),
      positions: [
        {
          displayName: t('STAKE_HIGH_COUNCILOR'),
          positionName: STAKE_HIGH_COUNCILOR,
          id: ldsxrm_positions.Stake_High_Councilor
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_PRIMARY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: STAKE_PRIMARY_PRESIDENT,
          id: ldsxrm_positions.Stake_Primary_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_PRIMARY_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Primary_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_PRIMARY_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Primary_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: STAKE_PRIMARY_SECRETARY,
          id: ldsxrm_positions.Stake_Primary_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_RELIEF_SOCIETY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: STAKE_RELIEF_SOCIETY_PRESIDENT,
          id: ldsxrm_positions.Stake_Relief_Society_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_RELIEF_SOCIETY_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Relief_Society_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_RELIEF_SOCIETY_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Relief_Society_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: STAKE_RELIEF_SOCIETY_SECRETARY,
          id: ldsxrm_positions.Stake_Relief_Society_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_SUNDAY_SCHOOL'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: STAKE_SUNDAY_SCHOOL_PRESIDENT,
          id: ldsxrm_positions.Stake_Sunday_School_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_SUNDAY_SCHOOL_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Sunday_School_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_SUNDAY_SCHOOL_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Sunday_School_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: STAKE_SUNDAY_SCHOOL_SECRETARY,
          id: ldsxrm_positions.Stake_Sunday_School_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_YOUNG_MEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: STAKE_YOUNG_MEN_PRESIDENT,
          id: ldsxrm_positions.Stake_Young_Men_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_YOUNG_MEN_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Young_Men_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_YOUNG_MEN_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Young_Men_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: STAKE_YOUNG_MEN_SECRETARY,
          id: ldsxrm_positions.Stake_Young_Men_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('STAKE_YOUNG_WOMEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: STAKE_YOUNG_WOMEN_PRESIDENT,
          id: ldsxrm_positions.Stake_Young_Women_President
        },
        {
          displayName: counselorFirstName,
          positionName: STAKE_YOUNG_WOMEN_FIRST_COUNSELOR,
          id: ldsxrm_positions.Stake_Young_Women_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: STAKE_YOUNG_WOMEN_SECOND_COUNSELOR,
          id: ldsxrm_positions.Stake_Young_Women_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: STAKE_YOUNG_WOMEN_SECRETARY,
          id: ldsxrm_positions.Stake_Young_Women_Secretary
        },
      ],
    },
    {
      auxiliaryName: '',
      positions: [
        {
          displayName: t('STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT'),
          positionName: STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT,
          id: ldsxrm_positions.Stake_Temple_and_Family_History_Consultant
        },
        {
          displayName: t('HISTORY_SPECIALIST'),
          positionName: HISTORY_SPECIALIST,
          id: ldsxrm_positions.History_Specialist
        },
        {
          displayName: t('COMMUNICATION_DIRECTOR'),
          positionName: 'COMMUNICATION_DIRECTOR',
          id: ldsxrm_positions.Communication_Director
        },
        {
          displayName: t('ASSISTANT_COMMUNICATION_DIRECTOR'),
          positionName: 'CHURCH_COMMUNICATION_ASSISTANT_DIRECTOR',
          id: ldsxrm_positions.Church_Communication_Assistant_Director
        },
        {
          displayName: t('COMMUNICATION_SPECIALIST'),
          positionName: 'COMMUNICATION_SPECIALIST',
          id: ldsxrm_positions.Communication_Specialist
        },
      ],
    },
  ];

  const DISTRICT_AUXILIARIES_OBJ = [
    {
      auxiliaryName: DISTRICT_PRESIDENCY,
      positions: [
        {
          displayName: t('DISTRICT_PRESIDENT'),
          positionName: 'DISTRICT_PRESIDENT',
          id: ldsxrm_positions.District_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_PRESIDENCY_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Presidency_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_PRESIDENCY_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Presidency_Second_Counselor
        },
        {
          displayName: t('EXECUTIVE_SECRETARY'),
          positionName: 'DISTRICT_EXECUTIVE_SECRETARY',
          id: ldsxrm_positions.District_Executive_Secretary
        },
        {
          displayName: t('DISTRICT_CLERK'),
          positionName: 'DISTRICT_CLERK',
          id: ldsxrm_positions.District_Clerk
        },
        {
          displayName: t('DISTRICT_ASSISTANT_CLERK'),
          positionName: 'DISTRICT_ASSISTANT_CLERK',
          id: ldsxrm_positions.District_Assistant_Clerk
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_COUNCIL'),
      positions: [
        {
          displayName: t('DISTRICT_COUNCILOR'),
          positionName: 'DISTRICT_COUNCILOR',
          id: ldsxrm_positions.District_Councilor
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_PRIMARY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'DISTRICT_PRIMARY_PRESIDENT',
          id: ldsxrm_positions.District_Primary_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_PRIMARY_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Primary_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_PRIMARY_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Primary_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'DISTRICT_PRIMARY_SECRETARY',
          id: ldsxrm_positions.District_Primary_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_RELIEF_SOCIETY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'DISTRICT_RELIEF_SOCIETY_PRESIDENT',
          id: ldsxrm_positions.District_Relief_Society_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_RELIEF_SOCIETY_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Relief_Society_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_RELIEF_SOCIETY_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Relief_Society_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'DISTRICT_RELIEF_SOCIETY_SECRETARY',
          id: ldsxrm_positions.District_Relief_Society_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_SUNDAY_SCHOOL'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'DISTRICT_SUNDAY_SCHOOL_PRESIDENT',
          id: ldsxrm_positions.District_Sunday_School_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_SUNDAY_SCHOOL_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Sunday_School_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_SUNDAY_SCHOOL_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Sunday_School_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'DISTRICT_SUNDAY_SCHOOL_SECRETARY',
          id: ldsxrm_positions.District_Sunday_School_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_YOUNG_MEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'DISTRICT_YOUNG_MEN_PRESIDENT',
          id: ldsxrm_positions.District_Young_Men_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_YOUNG_MEN_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Young_Men_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_YOUNG_MEN_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Young_Men_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'DISTRICT_YOUNG_MEN_SECRETARY',
          id: ldsxrm_positions.District_Young_Men_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('DISTRICT_YOUNG_WOMEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'DISTRICT_YOUNG_WOMEN_PRESIDENT',
          id: ldsxrm_positions.District_Young_Women_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'DISTRICT_YOUNG_WOMEN_FIRST_COUNSELOR',
          id: ldsxrm_positions.District_Young_Women_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'DISTRICT_YOUNG_WOMEN_SECOND_COUNSELOR',
          id: ldsxrm_positions.District_Young_Women_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'DISTRICT_YOUNG_WOMEN_SECRETARY',
          id: ldsxrm_positions.District_Young_Women_Secretary
        },
      ],
    },
    {
      auxiliaryName: '',
      positions: [
        {
          displayName: t('DISTRICT_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT'),
          positionName: 'DISTRICT_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT',
          id: ldsxrm_positions.District_Temple_and_Family_History_Consultant
        },
        {
          displayName: t('HISTORY_SPECIALIST'),
          positionName: HISTORY_SPECIALIST,
          id: ldsxrm_positions.History_Specialist
        },
        {
          displayName: t('COMMUNICATION_DIRECTOR'),
          positionName: 'COMMUNICATION_DIRECTOR',
          id: ldsxrm_positions.Communication_Director
        },
        {
          displayName: t('ASSISTANT_COMMUNICATION_DIRECTOR'),
          positionName: 'CHURCH_COMMUNICATION_ASSISTANT_DIRECTOR',
          id: ldsxrm_positions.Church_Communication_Assistant_Director
        },
        {
          displayName: t('COMMUNICATION_SPECIALIST'),
          positionName: 'COMMUNICATION_SPECIALIST',
          id: ldsxrm_positions.Communication_Specialist
        },
      ],
    },
  ];

  const WARD_AUXILIARIES_OBJ = [
    {
      auxiliaryName: t('BISHOPRIC'),
      positions: [
        {
          displayName: t('BISHOP'),
          positionName: 'BISHOP',
          id: ldsxrm_positions.Bishop
        },
        {
          displayName: counselorFirstName,
          positionName: 'BISHOPRIC_FIRST_COUNSELOR',
          id: ldsxrm_positions.Bishopric_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'BISHOPRIC_SECOND_COUNSELOR',
          id: ldsxrm_positions.Bishopric_Second_Counselor
        },
        {
          displayName: t('EXECUTIVE_SECRETARY'),
          positionName: 'EXECUTIVE_SECRETARY',
          id: ldsxrm_positions.Ward_Executive_Secretary
        },
        {
          displayName: t('WARD_CLERK'),
          positionName: 'WARD_CLERK',
          id: ldsxrm_positions.Ward_Clerk
        },
        {
          displayName: t('WARD_ASSISTANT_CLERK'),
          positionName: WARD_ASSISTANT_CLERK,
          id: ldsxrm_positions.Ward_Assistant_Clerk
        },
      ],
    },
    {
      auxiliaryName: t('ELDERS_QUORUM'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'ELDERS_QUORUM_PRESIDENT',
          id: ldsxrm_positions.Elders_Quorum_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'ELDERS_QUORUM_FIRST_COUNSELOR',
          id: ldsxrm_positions.Elders_Quorum_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'ELDERS_QUORUM_SECOND_COUNSELOR',
          id: ldsxrm_positions.Elders_Quorum_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'ELDERS_QUORUM_SECRETARY',
          id: ldsxrm_positions.Elders_Quorum_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('PRIMARY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'PRIMARY_PRESIDENT',
          id: ldsxrm_positions.Primary_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'PRIMARY_FIRST_COUNSELOR',
          id: ldsxrm_positions.Primary_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'PRIMARY_SECOND_COUNSELOR',
          id: ldsxrm_positions.Primary_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'PRIMARY_SECRETARY',
          id: ldsxrm_positions.Primary_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('RELIEF_SOCIETY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'RELIEF_SOCIETY_PRESIDENT',
          id: ldsxrm_positions.Relief_Society_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'RELIEF_SOCIETY_FIRST_COUNSELOR',
          id: ldsxrm_positions.Relief_Society_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'RELIEF_SOCIETY_SECOND_COUNSELOR',
          id: ldsxrm_positions.Relief_Society_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'RELIEF_SOCIETY_SECRETARY',
          id: ldsxrm_positions.Relief_Society_Secretary
        },
      ],
    },
    // TODO: ward sunday school presidency needed
    {
      auxiliaryName: t('SUNDAY_SCHOOL'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'SUNDAY_SCHOOL_PRESIDENT',
          id: ldsxrm_positions.Sunday_School_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'SUNDAY_SCHOOL_FIRST_COUNSELOR',
          id: ldsxrm_positions.Sunday_School_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'SUNDAY_SCHOOL_SECOND_COUNSELOR',
          id: ldsxrm_positions.Sunday_School_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'SUNDAY_SCHOOL_SECRETARY',
          id: ldsxrm_positions.Sunday_School_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('YOUNG_MEN'),
      positions: [
        {
          displayName: priestsQuorumAdviserName,
          positionName: 'PRIESTS_QUORUM_ADVISER',
          id: ldsxrm_positions.Priests_Quorum_Adviser
        },
        {
          displayName: teachersQuorumAdviserName,
          positionName: 'TEACHERS_QUORUM_ADVISER',
          id: ldsxrm_positions.Teachers_Quorum_Adviser
        },
        {
          displayName: deaconsQuorumAdviserName,
          positionName: 'DEACONS_QUORUM_ADVISER',
          id: ldsxrm_positions.Deacons_Quorum_Adviser
        },
        // ==========> Young Men positions removed due to organization consolidation
      ],
    },
    {
      auxiliaryName: t('YOUNG_WOMEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'YOUNG_WOMEN_PRESIDENT',
          id: ldsxrm_positions.Young_Women_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'YOUNG_WOMEN_FIRST_COUNSELOR',
          id: ldsxrm_positions.Young_Women_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'YOUNG_WOMEN_SECOND_COUNSELOR',
          id: ldsxrm_positions.Young_Women_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'YOUNG_WOMEN_SECRETARY',
          id: ldsxrm_positions.Young_Women_Secretary
        },
        {
          displayName: youngWomenClassAdviser,
          positionName: 'YOUNG_WOMEN_CLASS_ADVISER',
          id: ldsxrm_positions.Young_Women_Class_Advisor
        },
      ],
    },
    {
      auxiliaryName: '',
      positions: [
        {
          displayName: t('HISTORY_SPECIALIST'),
          positionName: HISTORY_SPECIALIST,
          id: ldsxrm_positions.History_Specialist
        },
        {
          displayName: t('WARD_MISSION_LEADER'),
          positionName: WARD_MISSION_LEADER,
          id: ldsxrm_positions.Ward_Mission_Leader
        },
        {
          displayName: t('WARD_TEMPLE_FAMILY_HISTORY_LEADER'),
          positionName: WARD_TEMPLE_FAMILY_HISTORY_LEADER,
          id: ldsxrm_positions.Ward_Temple_and_Family_History_Leader
        },
      ],
    },
  ];

  const BRANCH_AUXILIARIES_OBJ = [
    {
      auxiliaryName: t('BRANCH_PRESIDENCY'),
      positions: [
        {
          displayName: t('BISHOP'),
          positionName: 'BRANCH_PRESIDENT',
          id: ldsxrm_positions.Branch_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'BRANCH_PRESIDENCY_FIRST_COUNSELOR',
          id: ldsxrm_positions.Branch_Presidency_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'BRANCH_PRESIDENCY_SECOND_COUNSELOR',
          id: ldsxrm_positions.Branch_Presidency_Second_Counselor
        },
        {
          displayName: t('EXECUTIVE_SECRETARY'),
          positionName: 'EXECUTIVE_SECRETARY',
          id: ldsxrm_positions.Branch_Executive_Secretary
        },
        {
          displayName: t('BRANCH_CLERK'),
          positionName: 'BRANCH_CLERK',
          id: ldsxrm_positions.Branch_Clerk
        },
        {
          displayName: t('BRANCH_ASSISTANT_CLERK'),
          positionName: 'BRANCH_ASSISTANT_CLERK',
          id: ldsxrm_positions.Branch_Assistant_Clerk
        },
      ],
    },
    {
      auxiliaryName: t('ELDERS_QUORUM'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'ELDERS_QUORUM_PRESIDENT',
          id: ldsxrm_positions.Elders_Quorum_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'ELDERS_QUORUM_FIRST_COUNSELOR',
          id: ldsxrm_positions.Elders_Quorum_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'ELDERS_QUORUM_SECOND_COUNSELOR',
          id: ldsxrm_positions.Elders_Quorum_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'ELDERS_QUORUM_SECRETARY',
          id: ldsxrm_positions.Elders_Quorum_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('PRIMARY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'PRIMARY_PRESIDENT',
          id: ldsxrm_positions.Primary_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'PRIMARY_FIRST_COUNSELOR',
          id: ldsxrm_positions.Primary_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'PRIMARY_SECOND_COUNSELOR',
          id: ldsxrm_positions.Primary_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'PRIMARY_SECRETARY',
          id: ldsxrm_positions.Primary_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('RELIEF_SOCIETY'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'RELIEF_SOCIETY_PRESIDENT',
          id: ldsxrm_positions.Relief_Society_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'RELIEF_SOCIETY_FIRST_COUNSELOR',
          id: ldsxrm_positions.Relief_Society_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'RELIEF_SOCIETY_SECOND_COUNSELOR',
          id: ldsxrm_positions.Relief_Society_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'RELIEF_SOCIETY_SECRETARY',
          id: ldsxrm_positions.Relief_Society_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('SUNDAY_SCHOOL'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'SUNDAY_SCHOOL_PRESIDENT',
          id: ldsxrm_positions.Sunday_School_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'SUNDAY_SCHOOL_FIRST_COUNSELOR',
          id: ldsxrm_positions.Sunday_School_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'SUNDAY_SCHOOL_SECOND_COUNSELOR',
          id: ldsxrm_positions.Sunday_School_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'SUNDAY_SCHOOL_SECRETARY',
          id: ldsxrm_positions.Sunday_School_Secretary
        },
      ],
    },
    {
      auxiliaryName: t('YOUNG_MEN'),
      positions: [
        {
          displayName: priestsQuorumAdviserName,
          positionName: 'PRIESTS_QUORUM_ADVISER',
          id: ldsxrm_positions.Priests_Quorum_Adviser
        },
        {
          displayName: teachersQuorumAdviserName,
          positionName: 'TEACHERS_QUORUM_ADVISER',
          id: ldsxrm_positions.Teachers_Quorum_Adviser
        },
        {
          displayName: deaconsQuorumAdviserName,
          positionName: 'DEACONS_QUORUM_ADVISER',
          id: ldsxrm_positions.Deacons_Quorum_Adviser
        },
        // ==========> Young Men positions removed due to organization consolidation
      ],
    },
    {
      auxiliaryName: t('YOUNG_WOMEN'),
      positions: [
        {
          displayName: presidentPosName,
          positionName: 'YOUNG_WOMEN_PRESIDENT',
          id: ldsxrm_positions.Young_Women_President
        },
        {
          displayName: counselorFirstName,
          positionName: 'YOUNG_WOMEN_FIRST_COUNSELOR',
          id: ldsxrm_positions.Young_Women_First_Counselor
        },
        {
          displayName: counselorSecondName,
          positionName: 'YOUNG_WOMEN_SECOND_COUNSELOR',
          id: ldsxrm_positions.Young_Women_Second_Counselor
        },
        {
          displayName: secretaryName,
          positionName: 'YOUNG_WOMEN_SECRETARY',
          id: ldsxrm_positions.Young_Women_Secretary
        },
        {
          displayName: youngWomenClassAdviser,
          positionName: 'YOUNG_WOMEN_CLASS_ADVISER',
          id: ldsxrm_positions.Young_Women_Class_Advisor
        },
      ],
    },
    {
      auxiliaryName: '',
      positions: [
        {
          displayName: t('HISTORY_SPECIALIST'),
          positionName: HISTORY_SPECIALIST,
          id: ldsxrm_positions.History_Specialist
        },
        {
          displayName: t('WARD_MISSION_LEADER'),
          positionName: 'BRANCH_MISSION_LEADER',
          id: ldsxrm_positions.Branch_Mission_Leader
        },
        {
          displayName: t('BRANCH_TEMPLE_FAMILY_HISTORY_LEADER'),
          positionName: 'BRANCH_TEMPLE_FAMILY_HISTORY_LEADER',
          id: ldsxrm_positions.Branch_Temple_and_Family_History_Leader
        },
      ],
    },
  ];

  return {
    STAKE_AUXILIARIES_OBJ,
    WARD_AUXILIARIES_OBJ,
    BRANCH_AUXILIARIES_OBJ,
    DISTRICT_AUXILIARIES_OBJ
  };
}

export const buildCheckPeople = selectedPeople => {
  let newObject = {};

  selectedPeople?.forEach(person => {
    const newPerson = {
      [person.uuid]: true,
    };
    newObject = { ...newObject, ...newPerson };
  });

  return newObject;
};
