import React from 'react';
import { string, func, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import { Text4 } from '@churchofjesuschrist/eden-text';
import Row from '@churchofjesuschrist/eden-row';
import { Label, DateInput as DateInputBase } from '@churchofjesuschrist/eden-form-parts';
import { spacing4 } from '@churchofjesuschrist/eden-style-constants';

import InContextHelp from '../InContextHelp/InContextHelp';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const DateInput = styled(DateInputBase)`
  margin-top: ${spacing4};
`;

const DateSelector = ({
  onEndDateChange,
  onStartDateChange,
  dateStart,
  dateEnd,
  minDate,
  maxDate,
  dateValidityState,
  required
}: {
  onEndDateChange
  onStartDateChange
  dateStart
  dateEnd
  minDate?
  maxDate?
  dateValidityState
  required?
}) => {
  const { t } = useTranslation('');
  return (
    <Row gapSize="16">
      <Label id="startDate" validityState={dateValidityState} style={{ width: '200px' }}>
        <Row
          gapSize="4"
          columnGapSize="4"
          verticalAlign="center"
          style={{ margin: '-0.25rem 0 0 0' }} // to resolve the -4px left margin
        >
          {t('formFieldDateStart', { ns: 'strings' })} {required && t('requiredLabel', { ns: 'strings' })}
          <InContextHelp index={2}>
            <Text4>
              {required && <Text4>{t('calloutDateText1', { ns: 'strings' })}</Text4>}
              {t('calloutDateText2', { ns: 'strings' })}
            </Text4>
          </InContextHelp>
        </Row>
        <DateInput
          id="startDate"
          min={minDate}
          max={maxDate}
          name={t('formFieldDateStart', { ns: 'strings' })}
          onChange={onStartDateChange}
          value={!dateStart ? '' : format(parseISO(dateStart), 'yyyy-MM-dd')}
          validityState={dateValidityState}
          required
        />
      </Label>

      <Label id="endDate" validityState={dateValidityState} style={{ width: '200px' }}>
        {t('formFieldDateEnd', { ns: 'strings' })} {t('ifApplicableLabel', { ns: 'strings' })}
        <DateInput
          id="endDate"
          min={minDate}
          max={maxDate}
          name={t('formFieldDateEnd', { ns: 'strings' })}
          onChange={onEndDateChange}
          validityState={dateValidityState}
          value={!dateEnd ? '' : format(parseISO(dateEnd), 'yyyy-MM-dd')}
        />
      </Label>
    </Row>
  )
};

export default DateSelector;

(DateSelector as any).propTypes = {
  onEndDateChange: func.isRequired,
  onStartDateChange: func.isRequired,
  dateStart: string,
  dateEnd: string,
  dateValidityState: string,
  minDate: string,
  maxDate: string,
  required: bool,
};
