import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Eden
import { Text3 } from '@churchofjesuschrist/eden-text';

// Components
import StoryContent from './StoryContent';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import InContextHelp from '../../InContextHelp/InContextHelp';
import { LockedByMessage } from '../../Modals/StoryLocked/StoryLocked';
import { EditStoryButton } from '../../Buttons/EditStoryButton';
import { usePageContext } from 'src/contexts/PageContext';

// functions
import { formattedDisplayName, formatDate } from 'src/utils/AppFunctions/appFunctions';

// queries
import { getStoryManagerQuery } from '../../../utils/graphQL_Queries/appQueries';

// styles
import {
  StoryContainer,
  LabelContainer,
  InfoContainer,
  AssigneeContainer,
  DateContainer,
  Buttons,
} from './DisplayUnpublishedStories.style';

// constants
import { pendingStoryVarPropType } from '../../../utils/appConstants/propTypesConstants';
import { TabStoriesShimmer } from 'src/components/Shimmer/CardShimmer';
import { IStoryManagerResults } from 'src/models/Story';

const PendingInviteStories = ({ storyVariables, currentPageName }: { storyVariables, currentPageName }) => {
  const { t } = useTranslation('strings');
  const { localeCode } = usePageContext();

  const renderAuthorList = authorList => {
    return authorList?.map(author => formattedDisplayName(author.name)).join(', ');
  };

  const { data, error, loading } = useQuery<IStoryManagerResults>(getStoryManagerQuery, {
    fetchPolicy: 'network-only',
    variables: storyVariables,
    displayName: 'pendingInviteStories-Query'
  });

  if (loading) return <TabStoriesShimmer />;
  if (error ?? !data) {
    return (
      <ErrorModal
        currentPageName={currentPageName}
        title={t('retrieveStoryErrorMessage')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />
    );
  }

  if (!loading && data.storyManager.total <= 0) {
    return (
      <StoryContainer>
        <Text3>{t('noStoriesMyStoriesPendingInviteLabel')}</Text3>
      </StoryContainer>
    );
  }

  const stories = data.storyManager.content;

  return (
    <>
      {stories.map((story, index) => {
        const { status, id, created, published, modified, authors = [] } = story;
        return (
          <StoryContainer key={id}>
            <StoryContent
              currentPageName={currentPageName}
              index={index}
              status={status}
              story={story}
              title={status}
            />

            <InfoContainer>
              <AssigneeContainer>
                <LabelContainer>{t('authorLabel')}:</LabelContainer>
                <Text3>{renderAuthorList(authors)}</Text3>
              </AssigneeContainer>

              <DateContainer>
                <LabelContainer>{t('dateCreatedLabel')}: </LabelContainer>
                <Text3>{formatDate(created, localeCode)}</Text3>
              </DateContainer>

              {published && (
                <DateContainer>
                  <LabelContainer>{t('datePublishedLabel')}: </LabelContainer>
                  <Text3>{formatDate(published, localeCode)}</Text3>
                </DateContainer>
              )}

              <DateContainer>
                <LabelContainer>{t('dateModifiedLabel')}: </LabelContainer>
                <Text3>{formatDate(modified, localeCode)}</Text3>
              </DateContainer>

              {id &&
                <Buttons data-test-id={`returned_buttons-${index}`} isMobile={false}>
                  <EditStoryButton
                    id={id}
                    currentPageName={currentPageName}
                    index={index}
                  />

                  {!!story.lock && (
                    <InContextHelp
                      // label={t('disabledButtonsLabel')}
                      index={index}
                    >
                      <p style={{ zIndex: 101 }}>
                        <LockedByMessage lockObj={story.lock} />
                        {t('storyLockedMessage')}
                      </p>
                    </InContextHelp>
                  )}
                </Buttons>
              }
            </InfoContainer>
          </StoryContainer>
        );
      })}
    </>
  )
}

export default PendingInviteStories;

(PendingInviteStories as any).propTypes = {
  storyVariables: pendingStoryVarPropType
};
