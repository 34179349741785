/* eslint-disable @typescript-eslint/indent */
import isBrowser from 'is-in-browser';

import { User } from 'src/models/User';
import { pageNames, STORY_STATUSES } from './appConstants/appConstants';

const {
  createPageName,
  editPageName,
  activityReportPageName,
  favoritesPageName,
  helpPageName,
  helpFAQsPageName,
  helpQuickStartGuidePageName,
  managePageName,
  myStoriesPageName,
  viewStoryPageName,
  homePageName,
  sendInvitePageName,
  notAuthorizedPageName,
  errorPageName,
} = pageNames;

const { DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, RETURNED_STATUS, PENDING_INVITE, INVITE_TAB, INVITES_SENT } = STORY_STATUSES;

export const DIGITAL_DATA_EVENTS = {
  pageView: 'Page View',
  productView: 'Product View',
  addToCart: 'Add to Cart',
  removeFromCart: 'Remove from Cart',
  checkout: 'Checkout',
  purchase: 'Purchase',
  search: 'Search',
  component: {
    click: 'Component Click',
    share: 'Share',
    formStart: 'Form Start',
    formError: 'Form Error',
    formSubmit: 'Form Submit',
    download: 'Download',
    checklistUpdated: 'Checklist Updated',
  },
};

// category types
export const CATEGORY_TYPE_HOME = 'home';
export const CATEGORY_TYPE_VIEW = 'view';
export const CATEGORY_TYPE_HELP = 'help';
export const CATEGORY_TYPE_EDIT = 'edit';
export const CATEGORY_TYPE_CREATE = 'create';
export const CATEGORY_TYPE_MY_STORIES = 'mystories';
export const CATEGORY_TYPE_MANAGE_STORIES = 'manage_stories';
export const CATEGORY_TYPE_FAVORITES = 'favorites';
export const CATEGORY_TYPE_INVITATIONS = 'invitations';
export const CATEGORY_TYPE_REPORTS = 'reports';
export const CATEGORY_TYPE_NOT_FOUND = '404';
export const CATEGORY_TYPE_NOT_AUTHORIZED = 'not_authorized';
// export const CATEGORY_TYPE_SEARCH = 'search';

// other primary categories
export const CATEGORY_PRIMARY_KEYWORD = 'keyword';
export const CATEGORY_PRIMARY_ADVANCED = 'advanced';
export const CATEGORY_PRIMARY_REFINE = 'refine';
export const CATEGORY_PRIMARY_FEATURED_CONTENT = 'featured content';
export const CATEGORY_PRIMARY_SEARCH_RESULT = 'search result';
export const CATEGORY_PRIMARY_SOCIAL_SHARE = 'social media';
export const CATEGORY_PRIMARY_DOWNLOAD = 'download';
export const CATEGORY_PRIMARY_FORM = 'form';
export const CATEGORY_PRIMARY_COLLECTION_VIEW = 'collection view';
export const CATEGORY_PRIMARY_CHANGE_VIEW = 'change view';

// social / share channels
export const SOCIAL_CHANNEL_COPY_CITATION = 'Copy';
export const SOCIAL_CHANNEL_FACEBOOK = 'Facebook';
export const SOCIAL_CHANNEL_TWITTER = 'Twitter';
export const SOCIAL_CHANNEL_WHATSAPP = 'WhatsApp';
export const SOCIAL_CHANNEL_EMAIL = 'Email';
export const SOCIAL_CHANNEL_PRINT = 'Print';
export const SOCIAL_CHANNEL_FAMILY_SEARCH = 'Family Search';

// event types
export const FORM_EVENT_START = 'start';
export const FORM_EVENT_ERROR = 'error';
export const FORM_EVENT_SUBMIT = 'submit';
export const FORM_TYPE_PATH = 'path';
export const FORM_TYPE_JOURNEY = 'journey';

// user roles
export const USER_STAFF = 'staff';
export const USER_PUBLIC = 'public';
export const USER_PROXY_SELF = 'self';
export const USER_PROXY_STAFF = 'admin';

const getPrimaryCategory = (page) => {
  let returnValue;

  switch (page.toLowerCase()) {
    case homePageName.toLowerCase():
      returnValue = 'home page';
      break;

    case editPageName.toLowerCase():
      returnValue = 'edit story';
      break;

    case createPageName.toLowerCase():
      returnValue = 'create story';
      break;

    case viewStoryPageName.toLowerCase():
      returnValue = 'view story';
      break;

    case myStoriesPageName.toLowerCase():
      returnValue = 'my stories';
      break;

    case managePageName.toLowerCase():
      returnValue = 'manage stories';
      break;

    case helpPageName.toLowerCase():
      returnValue = 'help';
      break;

    case helpFAQsPageName.toLowerCase():
      returnValue = 'faqs';
      break;

    case helpQuickStartGuidePageName.toLowerCase():
      returnValue = 'quick start guide';
      break;

    case sendInvitePageName.toLowerCase():
      returnValue = 'send invites';
      break;

    case favoritesPageName.toLowerCase():
      returnValue = 'favorites';
      break;

    case activityReportPageName.toLowerCase():
      returnValue = 'reports';
      break;

    case errorPageName.toLowerCase():
      returnValue = 'error';
      break;

    case notAuthorizedPageName.toLowerCase():
      returnValue = 'not authorized';
      break;

    case DRAFT_STATUS.toLowerCase():
      returnValue = 'draft stories';
      break;

    case PENDING_STATUS.toLowerCase():
      returnValue = 'pending stories';
      break;

    case PUBLISHED_STATUS.toLowerCase():
      returnValue = 'published stories';
      break;

    case RETURNED_STATUS.toLowerCase():
      returnValue = 'returned stories';
      break;

    case PENDING_INVITE.toLowerCase():
      returnValue = 'invites pending';
      break;

    case INVITE_TAB.toLowerCase():
      returnValue = 'invitations';
      break;

    case INVITES_SENT.toLowerCase():
      returnValue = 'invites sent';
      break;

    // case SEARCH_RESULTS_PAGE:
    //   returnValue = seoSearchResultsPageLabel;
    //   break;

    default:
      returnValue = 'unknown';
      break;
  }
  return returnValue;
};

const getEvent = (eventType) => {
  let returnValue = '';
  switch (eventType) {
    case FORM_EVENT_START:
      returnValue = DIGITAL_DATA_EVENTS.component.formStart;
      break;

    case FORM_EVENT_ERROR:
      returnValue = DIGITAL_DATA_EVENTS.component.formError;
      break;

    case FORM_EVENT_SUBMIT:
    default:
      returnValue = DIGITAL_DATA_EVENTS.component.formSubmit;
      break;
  }
  return returnValue;
};

export const makeInfoPageName = ({ page, callNo = '', title = '' }) =>
  `${page} | ${callNo} | ${title}`;

interface IAddPageAnalyticsProps {
  infoName: string
  activePage: string
  breadCrumbs?: string[]
  categoryType: string
  subCat1?: string
  subCat2?: string
  userInfo: User
  language?: string
}

interface IAddChangeViewProps {
  view: string
  subView?: string
  categoryType: string
}

export const addPageAnalytics = ({
  infoName,
  activePage,
  breadCrumbs,
  categoryType,
  subCat1,
  subCat2,
  userInfo,
  language = '',
}: IAddPageAnalyticsProps) => {
  try {
    isBrowser &&
      window?.digitalData?.push({
        event: DIGITAL_DATA_EVENTS.pageView,
        page: {
          info: {
            name: infoName, // String - friendly name for the current page, usually based on the URL (always use English)
            ...(breadCrumbs && { breadCrumbs: breadCrumbs || [] }), // Array, Site breadcrumbs for the current page
            language, // String - Site language, consistent with churchofjesuschrist.org URL params and should be in short form as displayed here ie 3 letter abbreviation (FRA, DEU, ESP)
            // geoRegion: userInfo?.currentUnit?.language?.cultureCode ?? '', // String - country selected by the user in site settings
            siteName: 'Unit History Tool', // String - friendly unique name that identifies the site (always use English)
          },
          category: {
            primary: getPrimaryCategory(activePage), // String, site section report
            type: categoryType, // Type of page, could include content, search, navigation, etc (there should be relatively few types)
            ...(subCat1 && { subCategory1: subCat1 }), // String, view (browse vs summary)
            ...(subCat2 && { subCategory2: subCat2 }), // String, subView (arrangement vs gallery)
          },
        },
        user: {
          profile: {
            info: {
              language: userInfo?.currentUnit?.language?.translationLocale ?? '', // ISO 639-3 code (can use cultureCode if locale code is desired instaed)
              loginStatus: userInfo?.authenticated ? 'signed in' : 'signed out', // Current authentication status
            },
            address: {
              country: userInfo?.currentUnit?.language?.cultureCode ?? '', // Country selected in site settings
            },
          },
          segment: {
            // User role (calling)
            role: ([] as string[]).concat(userInfo?.position),
          },
        },
      });
  } catch (error) {
    console.error(`analytics error: ${error}`);
  }
};

export const addSearchAnalytics = ({
  searchTerms,
  totalResults,
  searchType,
  searchFields,
  sortType,
  searchFilter,
  currentPage,
}) => {
  isBrowser &&
    window?.digitalData?.push({
      event: DIGITAL_DATA_EVENTS.search,
      search: {
        info: {
          term: searchTerms, // String - typed term, applies to typed keyword searches
          results: totalResults, // Number of results found
          filter: searchFilter.map((elt) => elt.replace(/[,]/g, ' ')), // Facet category / value pairs (replace commas with space)
          page: currentPage, // Number - current page number
        },
        category: {
          primary: 'fix this',
          // searchType === SEARCH_ADVANCED
          //   ? CATEGORY_PRIMARY_ADVANCED
          //   : searchFilter.length > 0
          //     ? CATEGORY_PRIMARY_REFINE // String - indicates that a user has refined an existing search using facets
          //     : CATEGORY_PRIMARY_KEYWORD, // String - indicates that a user has entered a typed keyword term
          type: searchFields, // String - could also be any one of (Call Number, Title, Author, Subject)
        },
        attributes: {
          // Note:  always use English for analytics
          // sort: getSortLabel(sortType, getStrings('eng')), // String - indicates the type of result sorting method currently selected (optional) "relevance/z-a/a-z"
        },
      },
    });
};

export const addFeaturedContentAnalytics = ({ title, callNo, url }) => {
  isBrowser &&
    window.digitalData && // prevents StoryBook problem
    window.digitalData.push({
      event: DIGITAL_DATA_EVENTS.component.click,
      search: {
        info: {
          name: title, // String - title of content clicked
          callNo, // String - call number of content clicked
          location: url, // String - location of clicked item
        },
        category: {
          primary: CATEGORY_PRIMARY_FEATURED_CONTENT, // String - indicates that a user has clicked on a featured item
        },
      },
    });
};

export const addSearchResultsClickAnalytics = ({
  title,
  callNo,
  currentPage,
  rank,
}) => {
  isBrowser &&
    window.digitalData && // prevents StoryBook problem
    window.digitalData.push({
      event: DIGITAL_DATA_EVENTS.component.click,
      search: {
        info: {
          name: title, // String - title of content clicked
          callNo, // String - call number of content clicked
          page: currentPage, // Number - current page number
          rank, // String - location of clicked item. e.g. "page: x, rank: y"
        },
        category: {
          primary: CATEGORY_PRIMARY_SEARCH_RESULT, // String - indicates that a user has clicked on a featured item
        },
      },
    });
};

export const addChangeViewAnalytics = ({ view, subView, categoryType }: IAddChangeViewProps) => {
  isBrowser &&
    window?.digitalData?.push({
      event: DIGITAL_DATA_EVENTS.component.click,
      component: {
        category: {
          primary: CATEGORY_PRIMARY_CHANGE_VIEW, // String - indicates that a user has clicked on a tab to change the view
          type: categoryType,
          ...(view && { subCategory1: view }), // String, view
          ...(subView && { subCategory2: subView }), // String, subView (browse vs summary)
        },
        info: {
          name: view, // String - name of tab clicked (view)
        },
      },
    });
};

export const addShareAnalytics = ({ target, url }) => {
  isBrowser &&
    window.digitalData && // prevents StoryBook problem
    window.digitalData.push({
      event: DIGITAL_DATA_EVENTS.component.share,
      component: {
        info: {
          channel: target, // String - channel "Print/Copy/Download/FamilySearch/Feedback/AskUs". Coming soon: Facebook/Twitter/Pinterest/Email (need suport from Eden)
          link: url, // String - location of content shared
        },
        category: {
          primary: CATEGORY_PRIMARY_SOCIAL_SHARE, // String - indicates that a user has shared content
        },
      },
    });
};

export const addDownloadAnalytics = ({
  name,
  url,
  type,
  subType,
  breadCrumbs,
}) => {
  // console.log("analytics, download: ", name, url, type);
  isBrowser &&
    window.digitalData && // prevents StoryBook problem
    window.digitalData.push({
      event: DIGITAL_DATA_EVENTS.component.download,
      component: {
        info: {
          name, // String - friendly name or title of the file being downloaded
          link: url, // String - set to a dynamic value of URL of the item being downloaded, not the URL of the page they are on when downloading.
        },
        category: {
          primary: type, // String - primary file type of the file being downloaded - video, image, calendar, or whatever is most applicable
          type: subType, // Strings - ORIGINAL, QUICKVIEW, MOBILE, THUMBNAIL (only applicable to images and PDF sets, everthing else is ORIGINAL)
        },
        attributes: {
          breadCrumbs, //  breadcrumbs for the item being downloaded
        },
      },
    });
};

export const addFormAnalytics = ({
  eventType,
  name,
  formType,
  callNo,
  url,
  item,
  errors,
}) => {
  // console.log("analytics, form: ", eventType, name);
  isBrowser &&
    window?.digitalData?.push({
      event: getEvent(eventType),
      component: {
        info: {
          name, // String, friendly form name (Feedback, Ask Us)
          formType, // String, set to value of "path" or "journey"
          callNo: callNo || '',
          ...(errors && { error: errors || [] }), // String, list of the errors encountered while filling the form
          ...(item && { item }), // specific item associated with the form. e.g. item the user requested
        },
        attributes: {
          link: url || '', // String, url in question
        },
        category: {
          primary: CATEGORY_PRIMARY_FORM,
        },
      },
    });
};

export const addCollectionViewAnalytics = ({
  title,
  callNo,
  language = 'eng',
  breadCrumbs,
  categoryType,
  subCat1,
  subCat2,
}) => {
  // console.log("analytics, collection view: ", title, callNo, subCat1, subCat2);
  isBrowser &&
    window.digitalData && // prevents StoryBook problem
    window.digitalData.push({
      event: DIGITAL_DATA_EVENTS.productView,
      product: [
        {
          info: {
            // id: "123456",
            name: title,
            callNo,
            language, // String - Site language
            // color: "White",
            // size: "40",
          },
          category: {
            primary: CATEGORY_PRIMARY_COLLECTION_VIEW, // String, site section report
            type: categoryType, // Type of page, could include content, search, navigation, etc (there should be relatively few types)
            ...(subCat1 && { subCategory1: subCat1 }), // String, view (browse vs summary)
            ...(subCat2 && { subCategory2: subCat2 }), // String, subView (arrangement vs gallery)
          },
          attributes: {
            breadCrumbs,
          },
        },
      ],
    });
};

// export default addPageAnalytics;
