import React from 'react';
import View from './View';
import Create from './Create';
import Edit from './Edit';
import Favorites from './Favorites';
import Manage from './Manage';
import MyStories from './MyStories';
import AuthorizeRoute from 'src/components/Authentication/AuthorizeRoute';
import { Switch } from 'react-router-dom';
import Error from 'src/pages/Error';
import ChangeRole from './ChangeRole';
import ViewStories from './ViewStories';

const AdminRoutes = () => {
  return (
    <Switch>
      <AuthorizeRoute exact path='/admin/view/:id' component={View} />
      <AuthorizeRoute exact path='/admin/viewstories' component={ViewStories} />
      <AuthorizeRoute exact path='/admin/create' component={Create} />
      <AuthorizeRoute exact path='/admin/edit/:id' component={Edit} />
      <AuthorizeRoute exact path='/admin/favorites' component={Favorites} />
      <AuthorizeRoute path='/admin/manage/:tab' component={Manage} />
      <AuthorizeRoute exact path='/admin/my-stories/:tab' component={MyStories} />
      <AuthorizeRoute exact path='/admin/change-role' component={ChangeRole} adminRoute />
      <AuthorizeRoute component={Error} />
    </Switch>);
}

export default AdminRoutes;
