import { User } from 'src/models/User';

export const defaultPermissions = {
  isAdmin: false,
  canView: false,
  canApprove: false,
  canEdit: false,
};

export const getPermissions = (user: User) => {
  if (!user) {
    return defaultPermissions;
  }

  const permissions = {
    isAdmin: user.isAdmin,
    canView: user.isViewer,
    canApprove: user.isApprover,
    canEdit: user.isContributor
  };
  // console.log(`permissions for ${user.name}`);
  return permissions;
};
