import React from 'react';
import { useTranslation } from 'react-i18next';

// Eden
import Stack from '@churchofjesuschrist/eden-stack';
import { H2, H3 } from '@churchofjesuschrist/eden-headings';
import { Text3 } from '@churchofjesuschrist/eden-text';
import HtmlFragment from '@churchofjesuschrist/eden-html-fragment';
import { spacing16, spacing32 } from '@churchofjesuschrist/eden-style-constants';

// utilities, constants
import { getContentSubmissionPolicyURL } from 'src/utils/AppFunctions/setUrls';

const SPCReviewPanel = () => {
  const { t } = useTranslation('strings');
  // when the content submission policy is available in additional languages
  // const { t, i18n } = useTranslation('strings');
  // const language = i18n.language;
  // const termsUrl = getContentSubmissionPolicyURL(language);
  const termsUrl = getContentSubmissionPolicyURL(); // defaults to English

  return (
    <Stack
      style={{
        background: 'white',
        padding: `0 ${spacing32} ${spacing16} ${spacing16}`,
      }}
    >
      <H2>{t('spcReviewCardTextHeader')}</H2>
      <H3>{t('spcReviewCardTextTitle')}</H3>
      <Text3>{t('spcReviewCardText1')}</Text3>

      <ul>
        <li><Text3>{t('spcReviewCardText1.1')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.2')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.3')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.4')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.5')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.6')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.7')}</Text3></li>
        <li><Text3>{t('spcReviewCardText1.8')}</Text3></li>
      </ul>

      <Text3>{t('spcReviewCardText2')}</Text3>
      <Text3>
        <HtmlFragment content={t('spcReviewCardText3', { TermsOfUsePolicyURL: termsUrl })} />
      </Text3>
      <Text3>{t('spcReviewCardText4')}</Text3>
    </Stack>
  );
};

export default SPCReviewPanel;
