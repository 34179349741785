import React from 'react';
import { useHistory } from 'react-router-dom';
import { object, string, number } from 'prop-types';

// eden

// components
import { UnitBadge } from 'src/components/UnitBadge';
import { usePageContext } from 'src/contexts/PageContext';

// styles
import { VerticalTileWrapper } from 'src/components/Stories/StoryCard/index.style';

// functions
import { getMainImage } from 'src/utils/AppFunctions/formFunctions';
import { formattedDisplayName, formatDate } from 'src/utils/AppFunctions/appFunctions';

// constants
import { setViewStoryUrl } from 'src/utils/AppFunctions/setUrls';

// utils
import { Story } from 'src/models/Story';
import { StyledCompactCard } from 'src/utils/appCSS/appStyles';

export const TabStoryCard = ({ story, currentPageName }: { story: Story, currentPageName: string }) => {
  const history = useHistory();
  const { localeCode } = usePageContext();

  if (!story) {
    return <></>;
  }

  const { id } = story;
  const mainImageSource = getMainImage(story);

  return (
    <VerticalTileWrapper>
      <StyledCompactCard
        onClick={() => history.push(setViewStoryUrl(id, currentPageName))}
        pretitle={formatDate(story.dateStart, localeCode, 'd MMMM yyyy',)}
        title={story.title}
        description={story.authors?.map(author => formattedDisplayName(author.name)).join(', ')}
        meta={' '} // per Steve Rogers
        image={{
          src: mainImageSource,
        }}
        lightRays={!mainImageSource}
        mediaAspectRatio="8:5"
      />
      <UnitBadge unit={story.unit}></UnitBadge>
    </VerticalTileWrapper>
  );
}

export default TabStoryCard;

(TabStoryCard as any).propTypes = {
  currentPageName: string,
  index: number,
  story: object,
  activeUnitObj: object,
};
