/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { format } from 'date-fns';
import * as locales from 'date-fns/locale';
import isBrowser from 'is-in-browser';
import { convertHtmlToContent } from '@churchofjesuschrist/eden-rich-text-editor';

// Utilities, constants
import {
  STAKE_ORGANIZATIONS,
  WARD_ORGANIZATIONS,
  UNIT_WARD,
  UNIT_STAKE,
  UNIT_BRANCH,
  UNIT_DISTRICT,
  // UNIT_MISSION,
  // UNIT_AREA,
  SESSION_STORAGE_AHT,
  CREATING_A_STORY,
  PURPOSE_AND_VALUE,
  UH_TOOL,
  CONTRIBUTING,
  SHARE_STORIES_OF_FAITH,
} from 'src/utils/appConstants/appConstants';

// compare two objects
// source: https://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects/16788517#16788517
export const isObjectEqual = (x, y) => {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false;
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y;
  }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false;
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  // recursive object equality check
  const p = Object.keys(x);
  return (
    Object.keys(y).every(function (i) {
      return p.includes(i);
    }) &&
    p.every(function (i) {
      return isObjectEqual(x[i], y[i]);
    })
  );
};

export const convertDate = dateToConvert => {
  let updatedDate = '';
  if (dateToConvert) {
    updatedDate = dateToConvert.split('T')[0];
  }

  return updatedDate;
};

export const getUnitTypeFromUser = user => {
  if (!user || !user.unit || !user.unit.type) {
    return;
  }
  return user.unit.type;
};

export const removeHTMLTags = textToClean => {
  const cleanedText = textToClean.replace(/<\/?[^>]+(>|$)/g, '');

  return cleanedText;
};

export const convertObjToArray = object => {
  if (!object) return [];
  const res = Object.keys(object).map(key => object[key]);
  return res;
};

export const convertObjToArrayByKey = (object, keyName) => {
  if (!object) return [];
  const res = Object.keys(object)
    .filter(key => {
      if (key.includes(keyName)) {
        return object[key];
      }
      return false;
    })
    .map(key => object[key]);
  return res;
};

export const convertArrayToObject = (array1, value) => {
  let newObject = {};

  array1.forEach(pos => {
    const newItem = { [pos]: value };
    newObject = { ...newObject, ...newItem };
  });

  return newObject;
};

export const formattedDisplayName = (name: string) => {
  let formattedName = name ?? '';

  if (name?.includes('(')) {
    const regex = /[^()]+/g;
    const match = regex.exec(name);
    formattedName = match ? match[0] : name;
  }

  if (formattedName.includes('+')) {
    const splitName = formattedName.split('+');
    formattedName = splitName[0];
  }

  if (formattedName.includes(',')) {
    const splitName = formattedName.split(',');
    formattedName = splitName[1] + ' ' + splitName[0];
  }

  return formattedName;
};

export const buildCheckOrganizationObj = (fullList = [] as any[], selectedOrganizationList = [] as any[]) => {
  let newObject = {};

  fullList.forEach(organization => {
    const orgName = parseOrg(organization)[0];
    const foundOrganization = selectedOrganizationList.find(el => el === orgName);
    const newItem = { [orgName]: !!foundOrganization };
    newObject = { ...newObject, ...newItem };
  });

  return newObject;
};

export const buildCheckObj = (fullList = [], selectedItemList) => {
  let newObject = {};

  fullList.forEach(item => {
    const foundItem = selectedItemList.find(el => el === item);
    const newItem = { [item]: !!foundItem };
    newObject = { ...newObject, ...newItem };
  });

  return newObject;
};

export const parseOrg = org => {
  return org.split('|');
};

export const makeSelectedOrgObject = (orgList = [] as any[], selectedList = [] as any[]) => {
  const fullOrgList = orgList.map(org => parseOrg(org)[0]); // remove unit designator e.g. remove '|WARD' or '|STAKE'
  const selectedOrgs = selectedList.reduce((accum, org) => {
    return {
      ...accum,
      [org]: fullOrgList.includes(org),
    };
  }, {});
  // console.log('makeSelectedOrgObject, selectedOrgs: ', selectedOrgs);
  return selectedOrgs;
};
/** currentUnits is an array of current units. e.g. [258598]
 *
  * allAvailableUnits is an array of currently available units. e.g.
  *
  * [{
  *   'id': 258598,
  *   'name': 'Fagamalo  2nd Ward',
  *   'type': 'WARD',
  * },
  * {
  *   'id': 524735,
  *   'name': 'Savaii Samoa Fagamalo Stake',
  *   'type': 'STAKE',
  * }]
  *
  * get list of currently active units types. e.g. WARD, STAKE, BRANCH, DISTRICT, etc.
*/
export const getUnitTypes = (currentUnits, allAvailableUnits) => {
  // console.log('getUnitTypes, currentUnits: ', currentUnits);
  // console.log('getUnitTypes, allAvailableUnits: ', allAvailableUnits);
  //
  const activeUnitTypes = currentUnits.map(unitId => {
    const foundIt = allAvailableUnits.findIndex(unitObj => unitObj.id === unitId);
    return foundIt > -1 ? allAvailableUnits[foundIt].type : '';
  });
  // console.log('getUnitTypes, activeUnitTypes: ', activeUnitTypes);

  // get list of organizations based on currently active unit types
  const reducedOrgList = activeUnitTypes.reduce((accum, unitType) => {
    if ([UNIT_WARD, UNIT_BRANCH].includes(unitType)) {
      return accum.concat(WARD_ORGANIZATIONS.map(org => org + '|' + UNIT_WARD));
    } else if ([UNIT_STAKE, UNIT_DISTRICT].includes(unitType)) {
      return accum.concat(STAKE_ORGANIZATIONS.map(org => org + '|' + UNIT_STAKE));
      // Note: uncomment these lines when mission and area are available
      // } else if ([UNIT_MISSION].includes(unitType)) {
      //   return accum.concat(MISSION_ORGANIZATIONS);
      // } else if ([UNIT_AREA].includes(unitType)) {
      //   return accum.concat(AREA_ORGANIZATIONS);
    } else {
      return accum;
    }
  }, []);

  const response = reducedOrgList.filter((elem, pos, arr) => arr.indexOf(elem) === pos); // remove duplicates

  return response;
};

export const mapUnitTypes = (units) => {
  const activeUnitTypes = units.map(unit => {
    return unit.type;
  });
  // console.log('getUnitTypes, activeUnitTypes: ', activeUnitTypes);

  // get list of organizations based on currently active unit types
  const reducedOrgList = activeUnitTypes.reduce((accum, unitType) => {
    if ([UNIT_WARD, UNIT_BRANCH].includes(unitType)) {
      return accum.concat(WARD_ORGANIZATIONS.map(org => org + '|' + UNIT_WARD));
    } else if ([UNIT_STAKE, UNIT_DISTRICT].includes(unitType)) {
      return accum.concat(STAKE_ORGANIZATIONS.map(org => org + '|' + UNIT_STAKE));
      // Note: uncomment these lines when mission and area are available
      // } else if ([UNIT_MISSION].includes(unitType)) {
      //   return accum.concat(MISSION_ORGANIZATIONS);
      // } else if ([UNIT_AREA].includes(unitType)) {
      //   return accum.concat(AREA_ORGANIZATIONS);
    } else {
      return accum;
    }
  }, []);

  const response = reducedOrgList.filter((elem, pos, arr) => arr.indexOf(elem) === pos); // remove duplicates

  return response;
};

export const setSessionInfo = ahtAppParamsObj => {
  if (isBrowser) {
    // const currentUrl = JSON.stringify(window.location.pathname + window.location.search);
    sessionStorage.setItem(SESSION_STORAGE_AHT, JSON.stringify(ahtAppParamsObj));
  }
};

// used to create paragraphs of text with links to external
// e.g.
// data = [
//   { type: 'text', text: tellMeMoreApprovalRequired },
//   {
//     type: 'url',
//     text: 'Access Policy',
//     url: tellMeMoreAccessPolicyURL
//   }
// ];
export const textAndExternalLinks = data =>
  data.map((elt, i) =>
    elt.type === 'url'
      ? (
        <a key={i} href={elt.url} target="_blank" rel="noopener noreferrer">
          {elt.text}
        </a>
      )
      : elt.text
  );

export const addQueryParam = ({ url, host, key, value }) => {
  const newUrl = new URL(url, host); // Note: if url is relative, host is required

  if (newUrl.searchParams.has(key)) {
    newUrl.searchParams.set(key, value);
  } else {
    newUrl.searchParams.append(key, value);
  }

  // console.log("addQueryParam, new url:", newUrl.toString());
  return newUrl;
};

export const getPDFandVTT = (language: string = 'eng') => {
  let returnValue = {
    [UH_TOOL]: '', // PDF
    [CREATING_A_STORY]: '', // PDF
    [PURPOSE_AND_VALUE]: '', // PDF
    [CONTRIBUTING]: '', // WebVTT
    [SHARE_STORIES_OF_FAITH]: '', // WebVTT
  };

  switch (language) {
    case 'eng':
    case 'en':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/f7/cf/f7cfb138f03511eda253eeeeac1ea707aedd1d37/pd80008240_000_web_unit_history_tool.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/66/8a/668aa007f03411edb83beeeeac1e93bdb9bf66a5/pd80008240_000_web_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/e5/c3/e5c37138f03411edbbd8eeeeac1ea29dbb7141e9/pd80008240_000_web_purpose_and_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_ENG.txt',
        [SHARE_STORIES_OF_FAITH]: 'https://media.ldscdn.org/webvtt/2021-08-0010-share-stories-of-faith-in-your-annual-history-eng.vtt',
      };
      break;

    case 'alb':
    case 'sqi':
    case 'sq':
      // Albanian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/7f/70/7f7084ac4e9811ee9127eeeeac1e5ccfa2055b76/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/7f/1f/7f1f576c4e9811eeb00ceeeeac1ebd3b411d2a83/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/7f/46/7f46676d4e9811ee8663eeeeac1ebdec754f18e6/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_ALB.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_ALB.txt',
      };
      break;

    case 'spa':
    case 'es':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/9b/3f/9b3f956ef34211edbeb1eeeeac1e97af791c61b6/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/9b/1e/9b1ec6fcf34211eda5a6eeeeac1ed48d72775996/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/9b/39/9b3905b8f34211edb234eeeeac1e5a19839e0f50/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_SPA.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_SPA.txt',
      };
      break;

    case 'por':
    case 'pt':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/b3/bc/b3bc1360f33f11ed9f00eeeeac1e18d080975775/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/b3/4b/b34bec6af33f11edb9d8eeeeac1e594db5787ec3/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/b3/ab/b3abbfacf33f11ed892feeeeac1e903134b0df81/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_POR.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_POR.txt',
      };
      break;

    case 'deu':
    case 'de':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/94/2b/942b6f34f36e11eda70feeeeac1eb5cd44b89be3/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/93/f4/93f43297f36e11ed8d2beeeeac1eac20de94240f/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/94/07/94074565f36e11ed9f00eeeeac1e18d0c29d7d14/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_DEU.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_DEU.txt',
      };
      break;

    case 'fra':
    case 'fr':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/de/5c/de5c429df05811edb2e7eeeeac1ea735dec03478/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/dd/eb/ddebf498f05811ed8699eeeeac1eaba93d276a38/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/de/55/de5564cbf05811eda626eeeeac1e2af0da49bc74/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_FRA.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_FRA.txt',
      };
      break;

    case 'dan':
    case 'da':
      // Danish
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/a8/5a/a85ad2c34dc311ee89bfeeeeac1e5cdf5bad9382/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/a8/04/a80475644dc311eeac0deeeeac1e2d0c6cf85df9/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/a8/32/a8328a404dc311ee885aeeeeac1e23ae9a7f87dc/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_DAN.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_DAN.txt',
      };
      break;

    case 'nld':
    case 'dut':
    case 'nl':
      // Dutch
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/00/94/009430b4475811ee94dceeeeac1e486522e02bbf/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/29/53/295395c4475511eea1e5eeeeac1e2cbbb6886dc4/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/66/fb/66fb2a44475611ee96a1eeeeac1eaddc9a72ece9/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_NLD.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_NLD.txt',
      };
      break;

    case 'jpn':
    case 'ja':
      // Japanese
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/2c/48/2c4895c0f34911edaccdeeeeac1ec743abcebb15/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/2b/ec/2bec6bfbf34911ed8214eeeeac1ebe38e6eb8d26/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/2c/20/2c20c26af34911eda70feeeeac1eb5cd9c493de3/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_JPN.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_JPN.txt',
      };
      break;

    case 'kor':
    case 'ko':
      // Korean
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/8c/01/8c016885f41011ed970ceeeeac1e8496ee0c57d9/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/8b/91/8b9168a4f41011ed882deeeeac1e16bdf387fd37/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/8b/f6/8bf6e132f41011edafa8eeeeac1ea439ffa9bcab/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_KOR.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_KOR.txt',
      };
      break;

    case 'mon':
    case 'mn':
      // Mongolian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/53/26/5326491ef37311edbeb1eeeeac1e97afc806ea6c/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/52/af/52afb988f37311ed9d9aeeeeac1e3045daa66585/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/53/01/53015bf3f37311ed8209eeeeac1e8de5850e81fe/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_MON.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_MON.txt',
      };
      break;

    case 'hat':
    case 'ht':
      // Haitian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/2f/20/2f204be450c111eea8c8eeeeac1e98eaa1ba9f43/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/73/9c/739c256650c011eeb1a6eeeeac1e31997e8fcc01/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/d6/40/d640d4e150c011ee84aeeeeeac1e42e315e78b3e/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_HAT.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_HAT.txt',
      };
      break;

    case 'ita':
    case 'it':
      // Italian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/2b/72/2b7252d5f37111eda7f1eeeeac1ea40211338ca4/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/2b/2e/2b2eba2df37111edbeb1eeeeac1e97af770dc8a2/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/2b/48/2b485caef37111eda4c8eeeeac1eef4337e09591/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_ITA.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_ITA.txt',
      };
      break;

    case 'khm':
    case 'km':
      // Cambodian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/cf/48/cf487efc477311ee9c72eeeeac1e1477f2a8ca2e/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/cf/1b/cf1ba298477311ee9933eeeeac1e6de393cd668d/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/cf/2e/cf2e4045477311ee856beeeeac1ed8cd55f5f666/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_KHM.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_KHM.txt',
      };
      break;

    case 'hun':
    case 'hu':
      // Hungarian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/b9/af/b9afa48c476c11eea528eeeeac1e3295107e9253/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/d2/46/d2466bc7476a11ee9915eeeeac1ea905e6ff8d91/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/9b/4f/9b4f43c5476b11ee9933eeeeac1e6de3f81660b4/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_HUN.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_HUN.txt',
      };
      break;

    case 'ind':
    case 'id':
      // Indonesian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/a3/23/a32326b04e8911ee99b3eeeeac1e2d1b88450120/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/a2/da/a2dad3054e8911ee90dceeeeac1e2d1c5eb25c0a/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/a3/00/a3000e474e8911ee8f32eeeeac1e0a85c602110b/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_IND.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_IND.txt',
      };
      break;

    case 'ceb':
      // Cebuano
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/0a/fb/0afbd5584d9b11eeafe3eeeeac1ebd0dc60616c3/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/bc/d3/bcd322c84d9a11eeb67feeeeac1e900772abda9d/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/e4/5d/e45d0db74d9a11eeafe3eeeeac1ebd0d3431ff35/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_CEB.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_CEB.txt',
      };
      break;

    case 'cze':
    case 'ces':
    case 'cs':
      // Czech
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/b2/5a/b25a562c4cf411eeaf4aeeeeac1e710719705640/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/3c/3f/3c3f32d54cf411ee8c60eeeeac1e13096f6d9f75/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/76/43/764361d24cf511ee9beaeeeeac1e5a9e6b5f5699/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_CZE.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_CZE.txt',
      };
      break;

    case 'fin':
    case 'fi':
      // Finnish
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/1c/a8/1ca83f44527a11ee88fdeeeeac1e2e7c09e73908/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/1c/82/1c828ed4527a11eeabd3eeeeac1e44025675a7bc/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/1c/95/1c95efc7527a11ee9206eeeeac1e137dfcb980f8/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_FIN.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_FIN.txt',
      };
      break;

    case 'msa':
    case 'may':
    case 'ms':
      // Malay
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/c4/ee/c4ee5b144e6611ee9127eeeeac1e5ccf037beb14/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/b8/73/b8736544518311ee8517eeeeac1ef2a3477b91cd/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/b8/bb/b8bb91d5518311ee8f7eeeeeac1ec0677ab07eee/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_MSA.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_MSA.txt',
      };
      break;

    case 'nor':
    case 'no':
      // Norwegian
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/c4/ee/c4ee5b144e6611ee9127eeeeac1e5ccf037beb14/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/c4/99/c4995d434e6611ee89a3eeeeac1ef6587fb3ecf4/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/c4/c3/c4c3efb64e6611ee931eeeeeac1e39d00c24701f/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_NOR.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_NOR.txt',
      };
      break;

    case 'smo':
    case 'sm':
      // Samoan
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/84/99/8499822e4dbb11ee9291eeeeac1e8dd1d79eb3eb/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/34/76/3476381d4dbb11ee9397eeeeac1eb93749ffc8c3/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/63/15/6315a4df4dbb11eeb00ceeeeac1e13561985efc4/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_SMO.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_SMO.txt',
      };
      break;

    case 'swe':
    case 'sv':
      // Swedish
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/3b/03/3b03a4934cff11ee932aeeeeac1ecf9b88fbda96/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/e3/91/e391ee624cfe11ee932aeeeeac1ecf9b0a317d12/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/0a/98/0a9878894cff11eea08ceeeeac1e23a2b29902d9/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_SWE.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_SWE.txt',
      };
      break;

    case 'tag':
    case 'tgl':
    case 'tl':
      // Tagalog
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/08/0d/080d68324dc011eebbb6eeeeac1e5e453d23062a/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/b2/22/b22203e04dbf11ee8e9feeeeac1e39c6ed779624/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/db/6b/db6b2b054dbf11eea1bbeeeeac1e9a93abb6a312/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_TGL.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_TGL.txt',
      };
      break;

    case 'tha':
    case 'th':
      // Thai
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/2f/61/2f61beb44e7111ee8e20eeeeac1ec6f36ebc7461/unit_history_a_new_way_forward.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/2f/31/2f3138d44e7111ee8708eeeeac1ee5aa37fef953/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/2f/3a/2f3a87a44e7111eeaf8ceeeeac1ef5e7a9569332/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_THA.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_THA.txt',
      };
      break;

    case 'ton':
    case 'to':
      // Tongan
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/de/66/de66968d53e711eea046eeeeac1e133c41e08279/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/88/64/88644ed953e711eea87ceeeeac1e25925a247ecc/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/af/a5/afa5980753e711eeb6f9eeeeac1ef9ad169494cb/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_TON.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_TON.txt',
      };
      break;

    case 'ukr':
    case 'uk':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/0e/3b/0e3b7be14ddb11ee9127eeeeac1e5ccfc2cff360/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/c1/e5/c1e58d834dda11ee9567eeeeac1e6606ff80c11e/unit_history_tool_infographics.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/e7/99/e7997ff14dda11ee8e20eeeeac1ec6f3832eeaaf/unit_history_tool_infographics.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_UKR.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_UKR.txt',
      };
      break;

    case 'rus':
    case 'ru':
      returnValue = {
        ...returnValue,
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/82/9d/829dc358a5cd11ee8636eeeeac1ed3ecdc7e510d/unit_history_tool_infographics.pdf',
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/80/a7/80a74a91a5cd11ee8f63eeeeac1e768d34175e76/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/82/8b/828b9af5a5cd11ee91b0eeeeac1ed746970599cf/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_RUS.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_RUS.txt',
      };
      break;

    case 'zho':
    case 'zh':
      returnValue = {
        ...returnValue,
        [CREATING_A_STORY]: 'https://assets.churchofjesuschrist.org/06/9e/069ea4e2f05511ed99caeeeeac1ea4211cfb4016/unit_history_creating_a_story.pdf',
        [PURPOSE_AND_VALUE]: 'https://assets.churchofjesuschrist.org/06/a7/06a7095af05511ed94d6eeeeac1ee8735af45bb6/unit_history_purpose_value_of_ward_and_stake_histories.pdf',
        [UH_TOOL]: 'https://assets.churchofjesuschrist.org/06/ce/06ceb593f05511ed99caeeeeac1ea42173eec257/unit_history_a_new_way_forward.pdf',
        [CONTRIBUTING]: '/static/webvtt/contributing_to_ward_and_stake_histories_ZHO.txt',
        [SHARE_STORIES_OF_FAITH]: '/static/webvtt/ward_and_stake_histories_stories_of_faith_ZHO.txt',
      };
      break;

    default:
      console.error('ERROR, getPDFandVTT, language not found: ', language);
      break;
  }
  return returnValue;
};

export const formatDate = (dateField = '', localeObj, formatString = 'PP',) => {
  const localeParts = localeObj.code?.split('-') ?? []; // split region
  const fnsCode = localeParts.join(''); // fns locale keys do not contain spacers
  const isoCode = localeParts[0]; // try 2-3 character code
  const locale = locales[fnsCode] ?? locales[isoCode];
  return format(new Date(dateField), formatString, { locale });
}

export const getBodyTextLenth = (body: string) => {
  if (!body) return 0;
  const bodyContent = typeof body === 'string' ? convertHtmlToContent(body) : body;
  return bodyContent.blocks.map(b => b.text.length).reduce((total, next) => total + next);
};
