import React from 'react';

import { usePageContext } from 'src/contexts/PageContext';
import PageWrapper from './PageWrapper';
import { useCookies } from 'react-cookie';
import ProxyBar from '../Authentication/ProxyBar';

const Layout = ({ children }: { children }) => {
  const { headTitle } = usePageContext();
  const cookies = useCookies(['.uh.pu.r']);
  const hasCookie = cookies['0']['.uh.pu.r'];
  return (
    <>
      <title>{headTitle}</title>
      {hasCookie &&
        <ProxyBar />
      }
      <PageWrapper>
        {children}
      </PageWrapper>
    </>
  );
};

export default Layout;
