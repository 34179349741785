import React from 'react';
import { TabContent } from '../../components/TallyTabTotal/TabContent';
import { UnitSelectorContainer } from '../../components/SelectUnit/SelectUnit';

// styles
import { PageTitle, FloatRight, TitleRow } from '../../utils/appCSS/appStyles';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_MANAGE_STORIES, } from 'src/utils/analytics';
import { pageNames, STORY_STATUSES } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const { managePageName, homePageName } = pageNames;
const { INVITE_TAB } = STORY_STATUSES;

const Manage = ({ match }: any) => {
  const tabName = React.useMemo(() => match.params.tab.toUpperCase(), [match]); // Note sendInvites comes in camelCase
  const { activeUnitObj, canApprove, user } = useUserPermissions();
  const { t, i18n } = useTranslation('strings');

  const tabCrumb = tabName === INVITE_TAB ? 'invitations' : tabName; // handle invite page in SendInvite.tsx

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: managePageName, // always use English
        activePage: tabName.toLowerCase(), // used to set primary category
        breadCrumbs: [homePageName, managePageName, tabCrumb],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_MANAGE_STORIES,
        userInfo: user,
      });
    }

    addAnalytics();
  }, [tabName]);

  return (
    <>
      <TitleRow>
        <div>
          <PageTitle>{t('manageStoriesLabel')}</PageTitle>
        </div>
        <UnitSelectorContainer>
          <FloatRight cursor='auto'>
            {`${activeUnitObj.unitName} (${activeUnitObj.unitNumber})`}
          </FloatRight>
        </UnitSelectorContainer>
      </TitleRow>

      {canApprove
        ? (
          <TabContent
            pageName={managePageName}
            tabName={tabName}
            activeUnitObj={activeUnitObj}
          />
        )
        : (
          <div>{t('noManageRights')}</div>
        )}
    </>
  );
};

export default withPage({ pageName: managePageName, pageTitle: 'manageStoriesPageTitle' })(Manage);
