import React from 'react';
import styled from 'styled-components';

// Eden
import { spacing16 } from '@churchofjesuschrist/eden-style-constants';

// Components
import DisplayStory from 'src/components/View/DisplayStory';
import { getExampleStoryObject } from 'src/pages/help/example';

// constants
const storyId = 'leadershipGood1';

const Container = styled.div`
  padding-left: ${spacing16};
  padding-right: ${spacing16};
`;

const ExampleLeadershipStory = () => {
  const {
    // subTitleLabel,
    exampleStory,
    // exampleLabel,
    goodOrBad,
    // Icon,
    toolTipMessages,
  } = getExampleStoryObject(storyId);

  return (
    <Container>
      <DisplayStory
        example
        good={goodOrBad}
        toolTipMessages={toolTipMessages}
        story={exampleStory}
      />
    </Container>
  );
};

export default ExampleLeadershipStory;
