import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

// Components
import DisplayStories from 'src/components/DisplayStories/DisplayStories';
import Notification from 'src/components/NotificationManager/NotificationManager';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import { UnitDropDownSelector, UnitSelectorContainer } from 'src/components/SelectUnit/SelectUnit';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// reducers
import { selectUnits, setUnitOrgs, setUnits } from 'src/reducers/unitsReducer';
import { selectStoriesLoadingCount, selectStoriesVariables, setLoading, setStories, setStoriesError } from 'src/reducers/storiesReducer';

// styles
import { PageTitle, FloatRight } from 'src/utils/appCSS/appStyles';

// queries
import { setUnitsQueryParams } from 'src/utils/graphQL_Queries/queryVariableFormats';
import { getOrganizationsQuery, homePageQuery, UNITS_NO_CHILDREN_QUERY } from 'src/utils/graphQL_Queries/appQueries';
import { UPDATE_ACTIVE_UNIT } from 'src/utils/graphQL_Queries/appMutations';

// utilities, constants
import { withPage } from 'src/contexts/PageContext';
import { addPageAnalytics, CATEGORY_TYPE_HOME } from 'src/utils/analytics';
import { pageNames, MESSAGE_INFO, MESSAGE_MIN_WIDTH, MESSAGE_MAX_WIDTH } from 'src/utils/appConstants/appConstants';

const { viewStoriesPageName } = pageNames;

const MultiUnitNotificationWrapper = styled.div`
  position: fixed;
  margin-top: 96px;
  min-width: ${MESSAGE_MIN_WIDTH};
  max-width: ${MESSAGE_MAX_WIDTH};
  z-index: 2;
  text-align: left;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ViewStories = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('strings');
  const { activeUnitId, user } = useUserPermissions();
  const units = useSelector(selectUnits);
  const variables = useSelector(selectStoriesVariables);
  const loadingCount = useSelector(selectStoriesLoadingCount);

  const [updateActiveUnit, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ACTIVE_UNIT);

  const handleUnitChange = async (newUnit: string) => {
    await updateActiveUnit({
      variables: {
        id: newUnit
      }
    });

    window.location.reload(); // reload with correct active unit
  };

  useQuery(UNITS_NO_CHILDREN_QUERY, {
    variables: setUnitsQueryParams(0, ''),
    onCompleted(data) {
      dispatch(setUnits(data?.units));
    }
  });

  useQuery(getOrganizationsQuery, {
    onCompleted(data) {
      dispatch(setUnitOrgs(data?.organizations));
    },
  });

  const [fetchStories, { error, loading }] = useLazyQuery(homePageQuery, {
    variables,
    fetchPolicy: 'no-cache',
    displayName: 'display-stories',
    onCompleted: (data) => {
      dispatch(setStories(data.stories));
    }
  });

  useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: viewStoriesPageName, // always use English
        activePage: viewStoriesPageName, // used to set primary category
        breadCrumbs: [viewStoriesPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_HOME,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  useEffect(() => {
    if (variables) {
      dispatch(setLoading(true));
      fetchStories({
        variables
      });
    }
  }, [variables, dispatch, fetchStories]);

  useEffect(() => {
    if (error) {
      dispatch(setStoriesError(error));
    }
  }, [error, dispatch]);

  return (
    <>
      {(loading || updateLoading) && <LoadingSpinner noBackground={loadingCount > 0} />}

      {updateError &&
        <ErrorModal
          title={t('updateUnitErrorMessage')}
          onRetry={() => { }}
          errors={updateError}
          isActive
        />
      }
      <TitleRow>
        <PageTitle>{t('viewStories')}</PageTitle>

        <UnitSelectorContainer>
          <div>
            {units?.length > 1 &&
              <React.Fragment>
                <FloatRight>
                  <UnitDropDownSelector
                    units={units}
                    activeUnitId={activeUnitId}
                    changeHandler={async (ev) => await handleUnitChange(ev.target.value)}
                    showId
                    showHelp
                  />
                </FloatRight>
                {/* multi-unit notification */}
                <MultiUnitNotificationWrapper id="multi-unit-notification-wrapper">
                  <Notification
                    notification={{
                      title: t('chooseUnitLabel'),
                      message: t('chooseUnitMessage'),
                      messageType: MESSAGE_INFO,
                    }}
                  />
                </MultiUnitNotificationWrapper>
              </React.Fragment>
            }
          </div>
        </UnitSelectorContainer>
      </TitleRow>

      <DisplayStories
        pageName={viewStoriesPageName}
      />
    </>
  )
};

export default withPage({ pageName: viewStoriesPageName })(ViewStories);
