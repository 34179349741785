import React from 'react';
import { node, number, string, func } from 'prop-types';
import debounce from '@tuxsudo/debounce';

import Row from '@churchofjesuschrist/eden-row';
import { Help } from '@churchofjesuschrist/eden-icons';
import { info40 } from '@churchofjesuschrist/eden-style-constants';
import Callout from '@churchofjesuschrist/eden-callout';
import {
  // CalloutContainer,
  CalloutContent,
  IconContainer,
  IconLabel,
  // InContextHelpContainer,
} from './InContextHelpStyles';

export const InContextHelp = ({
  children,
  index,
  label = '',
  Icon = Help,
  iconColor = info40,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(null);

  const debounceIt = debounce(
    newValue =>
      setCurrentIndex(newValue),
    333
  ); // add third second delay

  const handleMouseEnter = index => {
    debounceIt(parseInt(index, 10));
  };

  const handleMouseLeave = () => {
    debounceIt.cancel();
    setCurrentIndex(null);
  };

  const helpRef = React.useRef(null);

  return (
    <div
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <IconContainer ref={helpRef}>
        <Row gapSize="8" verticalAlign="center">
          <IconLabel>{label}</IconLabel>
          <Icon color={iconColor} size="24px" />
        </Row>
        <Callout
          forRef={helpRef}
          open={currentIndex === index}
          onClickOutside={handleMouseLeave}
        >
          <CalloutContent>{children}</CalloutContent>
        </Callout>
      </IconContainer>
    </div>
  );
}

export default InContextHelp;

InContextHelp.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  label: string,
  Icon: func,
  iconColor: string,
};
