import i18next from 'i18next';

// utilities, constants
import { TermsOfUsePolicyLink, NAV_QUICK_START_GUIDE, NAV_QUICK_START_CREATE } from 'src/utils/AppFunctions/setUrls';

// Note: t must be passed in
export const getFaqs = (t = i18next.t, termsOfUsePolicyURL = TermsOfUsePolicyLink) => (
  [
    {
      title: t('faqUnitHistoryPurpose'),
      content: [
        {
          id: 0,
          question: t('faqUnitHistoryPurposeQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqUnitHistoryPurposeA0'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 1,
          question: t('faqUnitHistoryPurposeQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqUnitHistoryPurposeA1'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 2,
          question: t('faqUnitHistoryPurposeQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqUnitHistoryPurposeA2'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqTheUnitHistoryTool'),
      content: [
        {
          id: 3,
          question: t('faqTheUnitHistoryToolQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqTheUnitHistoryToolA0'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 4,
          question: t('faqTheUnitHistoryToolQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqTheUnitHistoryToolA1'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 5,
          question: t('faqTheUnitHistoryToolQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text:
              t('faqTheUnitHistoryToolA2'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 6,
          question: t('faqTheUnitHistoryToolQ3'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqTheUnitHistoryToolA3'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 7,
          question: t('faqTheUnitHistoryToolQ4'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqTheUnitHistoryToolA4'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 8,
          question: t('faqTheUnitHistoryToolQ5'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqTheUnitHistoryToolA5'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 39,
          question: t('faqTheUnitHistoryToolQ6'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqTheUnitHistoryToolA6'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqCreatingStories'),
      content: [
        {
          id: 9,
          question: t('faqCreatingStoriesQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqCreatingStoriesA01'),
              bulletPoints: [
                t('faqCreatingStoriesB0'),
                t('faqCreatingStoriesB1'),
                t('faqCreatingStoriesB2'),
                t('faqCreatingStoriesB3'),
                t('faqCreatingStoriesB4'),
                t('faqCreatingStoriesB5'),
              ],
            },
            {
              paragraphNumber: 1,
              text: t('faqCreatingStoriesA02'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 10,
          question: t('faqCreatingStoriesQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA1', { helpQuickStartGuide: NAV_QUICK_START_GUIDE }),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 12,
          question: t('faqCreatingStoriesQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA2'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 13,
          question: t('faqCreatingStoriesQ3'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA3'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 14,
          question: t('faqCreatingStoriesQ4'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA4'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 15,
          question: t('faqCreatingStoriesQ5'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA5'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 16,
          question:
              t('faqCreatingStoriesQ6'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqCreatingStoriesA6'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 17,
          question:
              t('faqCreatingStoriesQ7'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA7'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 18,
          question: t('faqCreatingStoriesQ8'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqCreatingStoriesA8'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqIncludingMedia'),
      content: [
        {
          id: 19,
          question: t('faqIncludingMediaQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text:
                  t('faqIncludingMediaA0'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 20,
          question: t('faqIncludingMediaQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqIncludingMediaA1', { TermsOfUsePolicyURL: termsOfUsePolicyURL }),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 21,
          question: t('faqIncludingMediaQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqIncludingMediaA2'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 22,
          question: t('faqIncludingMediaQ3'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqIncludingMediaA3'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqIncludingSensitive'),
      content: [
        {
          id: 23,
          question: t('faqIncludingSensitiveQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqIncludingSensitiveA01'),
              bulletPoints: [
                t('faqIncludingSensitiveB0'),
                t('faqIncludingSensitiveB1'),
                t('faqIncludingSensitiveB2'),
                t('faqIncludingSensitiveB3'),
                t('faqIncludingSensitiveB4'),
                t('faqIncludingSensitiveB5'),
                t('faqIncludingSensitiveB6'),
              ],
            },
            {
              paragraphNumber: 1,
              text: t('faqIncludingSensitiveA02'),
              bulletPoints: [],
            },
            {
              paragraphNumber: 2,
              text: t('faqIncludingSensitiveA03', { TermsOfUsePolicyURL: termsOfUsePolicyURL }),
              bulletPoints: [],
            },
          ],
        }
      ]
    },
    {
      title: t('faqStakeVsWard'),
      content: [
        {
          id: 25,
          question: t('faqStakeVsWardQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqStakeVsWardA0'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 26,
          question: t('faqStakeVsWardQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqStakeVsWardA1'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqPermissions'),
      content: [
        {
          id: 27,
          question: t('faqPermissionsQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqPermissionsA0'),
              bulletPoints: [
                t('faqPermissionsB00'),
                t('faqPermissionsB01'),
                t('faqPermissionsB02'),
                t('faqPermissionsB03'),
                t('faqPermissionsB04'),
                t('faqPermissionsB05'),
                t('faqPermissionsB06'),
                t('faqPermissionsB07'),
                t('faqPermissionsB08'),
                t('faqPermissionsB09'),
                t('faqPermissionsB10'),
              ],
            },
          ],
        },
        {
          id: 28,
          question: t('faqPermissionsQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqPermissionsA1'),
              bulletPoints: [
                t('faqPermissionsC00'),
                t('faqPermissionsC01'),
                t('faqPermissionsC02'),
                t('faqPermissionsC03'),
                t('faqPermissionsC04'),
                t('faqPermissionsC05'),
                t('faqPermissionsC06'),
                t('faqPermissionsC07'),
                t('faqPermissionsC08'),
              ],
            },
          ],
        },
        {
          id: 29,
          question: t('faqPermissionsQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqPermissionsA2'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 30,
          question: t('faqPermissionsQ3'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqPermissionsA3'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqInvitesToWrite'),
      content: [
        {
          id: 31,
          question: t('faqInvitesToWriteQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqInvitesToWriteA0'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 32,
          question: t('faqInvitesToWriteQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqInvitesToWriteA1'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 33,
          question: t('faqInvitesToWriteQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqInvitesToWriteA2'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
    {
      title: t('faqSubmitAndApprove'),
      content: [
        {
          id: 34,
          question: t('faqSubmitAndApproveQ0'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqSubmitAndApproveA0', { helpQuickStartCreate: NAV_QUICK_START_CREATE }),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 35,
          question: t('faqSubmitAndApproveQ1'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqSubmitAndApproveA1', { helpQuickStartCreate: NAV_QUICK_START_CREATE }),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 36,
          question: t('faqSubmitAndApproveQ2'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqSubmitAndApproveA2'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 37,
          question: t('faqSubmitAndApproveQ3'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqSubmitAndApproveA3'),
              bulletPoints: [],
            },
          ],
        },
        {
          id: 38,
          question: t('faqSubmitAndApproveQ4'),
          answer: [
            {
              paragraphNumber: 0,
              text: t('faqSubmitAndApproveA4'),
              bulletPoints: [],
            },
          ],
        },
      ],
    },
  ]
);
