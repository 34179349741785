import React from 'react'
import styled from 'styled-components';

// Eden
import {
  // fontSize42,
  fontSize16,
  // spacing32,
  spacing16,
//   info40,
//   blue20,
} from '@churchofjesuschrist/eden-style-constants';

// Components
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// styles
import { PageTitle } from '../utils/appCSS/appStyles';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_NOT_FOUND } from '../utils/analytics';
import { pageNames } from '../utils/appConstants/appConstants';
import { useTranslation } from 'react-i18next';

const { errorPageName } = pageNames;

const Text = styled.div`
  font-size: ${fontSize16};
  margin: ${spacing16} 0;
`;

export const AppError = () => {
  const { t, i18n } = useTranslation('strings');
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: errorPageName, // always use English
        activePage: errorPageName, // used to set primary category
        breadCrumbs: [errorPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_NOT_FOUND,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <>
      <PageTitle>{t('appUnavailableLabel')}</PageTitle>
      <Text>{t('appUnavailableMsg')}</Text>
    </>
  );
}
