import React, { useState } from 'react';
import { string, number, bool } from 'prop-types';

// Eden
import { Delete } from '@churchofjesuschrist/eden-icons';
import { spacing4 } from '@churchofjesuschrist/eden-style-constants';

// components
import { DeleteButton } from 'src/components/Stories/DisplayUnpublishedStories/DisplayUnpublishedStories.style';
import { RemoveStoryModal } from 'src/components/Modals/RemoveContent/RemoveContent';

// notification context
import { useNotificationContext } from 'src/contexts/NotificationContext';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

// constants
import { storyIdType, userType } from 'src/utils/appConstants/propTypesConstants';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from 'src/utils/appConstants/appConstants';

import { getPageRedirect } from 'src/components/Stories/utils/RedirectPages';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface IDeleteStoryButtonProps {
  storyId: string
  storyTitle: string
  index: number
  disabled: boolean
  currentPageName: string
  onAfterDelete: () => any
}

const DeleteStoryButton = ({ storyId, storyTitle, index, disabled, currentPageName, onAfterDelete }: IDeleteStoryButtonProps) => {
  const { t } = useTranslation();
  const [confirmStoryDelete, setConfirmStoryDelete] = useState(false);
  const { addMessage } = useNotificationContext();
  const history = useHistory();

  const onDelete = () => {
    setConfirmStoryDelete(true);
  };

  const closeRemovalConfirmation = () => {
    setConfirmStoryDelete(false);
  };

  const handleCancel = () => {
    closeRemovalConfirmation();
  };

  const handlePostRemoval = (error?: boolean) => {
    setConfirmStoryDelete(false);
    if (error) {
      addMessage({
        title: t('removeStoryErrorTitle'),
        message: '',
        messageType: MESSAGE_ERROR,
      });
    } else {
      addMessage({
        title: t('removeStorySuccessTitle'),
        message: '',
        messageType: MESSAGE_SUCCESS,
      });

      if (onAfterDelete) onAfterDelete();
      else history.push(getPageRedirect(currentPageName));
    }
  };

  const dataTestId = `button_delete${index}`;

  return (
    <>
      <DeleteButton onClick={onDelete} data-test-id={dataTestId} disabled={disabled}>
        <Space end={spacing4}>
          <Delete size="24px" />
        </Space>
        {t('deleteLabel')}
      </DeleteButton>

      {confirmStoryDelete && (
        <RemoveStoryModal
          isActive={confirmStoryDelete}
          storyTitle={storyTitle}
          handlePostRemoval={handlePostRemoval}
          handleCancel={handleCancel}
          delVariables={{ id: storyId }}
        />
      )}
    </>
  );
}

export default DeleteStoryButton;

(DeleteStoryButton as any).propTypes = {
  currentPageName: string.isRequired,
  storyId: storyIdType.isRequired,
  storyTitle: string,
  user: userType,
  index: number,
  disabled: bool,
};
