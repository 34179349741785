import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { history } from './components/Stories/utils/RedirectPages';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import appStore from './utils/appStore';
import initApollo from './utils/initApollo';

// components
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

const HistRouter = Router as any;
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');

const store = appStore;
const apollo = initApollo() as any;

ReactDOM.render(
  <HistRouter history={history} basename={baseUrl}>
    <Suspense fallback={<LoadingSpinner />}>
      <CookiesProvider>
        <Provider store={store}>
          <ApolloProvider client={apollo}>
            <App />
          </ApolloProvider>
        </Provider>
      </CookiesProvider>
    </Suspense>
  </HistRouter>,
  rootElement);

registerServiceWorker();
