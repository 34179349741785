import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import {
  Table,
  THead,
  TBody,
  TR,
  TH,
  TD,
  TFoot,
  // TotalsRow,
  // ColGroup,
  // useColumnSorting,
  // useRowSelection,
} from '@churchofjesuschrist/eden-table';
import Stack from '@churchofjesuschrist/eden-stack';
import { H4, Title1 } from '@churchofjesuschrist/eden-headings';
import { spacing64 } from '@churchofjesuschrist/eden-style-constants';

// components
import { usePageContext } from 'src/contexts/PageContext';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

// functions
import { formatDate } from 'src/utils/AppFunctions/appFunctions';

// constants
import { REPORT_UNITS } from 'src/utils/appConstants/appConstants';
import { uh_story_statuscode } from 'src/common/enums/uh_story';

const StyleTH = styled(TH)`
  width: 20%;
`;
const StyleTD = styled(TD)`
  padding-left: 40px;
`;

interface IUnitResult {
  id: string
  name: string
  totals: IUnitResultTotal[]
  unitNumber: number
  type: string
}

interface IUnitResultTotal {
  status: number
  total: number
}

const AdminReportHeader = ({ startDate, endDate }: any) => {
  const { t } = useTranslation();
  const { localeCode } = usePageContext();
  return (
    <React.Fragment>
      <section>
        <H4>
          {t('reportForLabel')} {t('allUnitsLabel')} {t('fromLabel')}{' '}
          {formatDate(startDate, localeCode)} {t('toLabel')} {formatDate(endDate, localeCode)}
        </H4>
      </section>
    </React.Fragment>
  )
};

const StatusElements = ({ statusData, bold }: { statusData: IUnitResultTotal[], bold?: boolean }) => {
  const Status = bold ? TH : TD; // subTotal uses TH (to make it bold)
  const statusDictionary: { [index: number]: number } = statusData.reduce((p, c) => ({ ...p, [c.status]: c.total }), {});
  return (
    <React.Fragment>
      <Status type="number">{statusDictionary[uh_story_statuscode.Pending_Invite] || 0}</Status>
      <Status type="number">{statusDictionary[uh_story_statuscode.Draft] || 0}</Status>
      <Status type="number">{statusDictionary[uh_story_statuscode.Pending] || 0}</Status>
      <Status type="number">{statusDictionary[uh_story_statuscode.Published] || 0}</Status>
    </React.Fragment>
  );
};

// Note: exporting for unit testing / development in StoryBook
export const ActivityTable = ({ data }: { data: { units: IUnitResult[] } }) => {
  const { t } = useTranslation();
  const reportUnits = data[REPORT_UNITS] ?? [];
  const reportRows = [...reportUnits];
  reportRows.forEach((item, i) => {
    if (item.type === 'STAKE') {
      reportRows.splice(i, 1);
      reportRows.unshift(item);
    }
  });

  return (
    <Table verticalRuled striped stickyHeader>
      <THead>
        <TR>
          <StyleTH></StyleTH>
          <StyleTH type="number">{t('reportRequestedLabel')}</StyleTH>
          <StyleTH type="number">{t('reportDraftsLabel')}</StyleTH>
          <StyleTH type="number">{t('reportPendingLabel')}</StyleTH>
          <StyleTH type="number">{t('reportPublishedLabel')}</StyleTH>
        </TR>
      </THead>
      <TBody>
        {reportRows.map(({ id, name, totals, unitNumber, type }) => (
          <TR key={id}>
            {type === 'WARD' ? <StyleTD>{`${name} (${unitNumber})`}</StyleTD> : <TD>{`${name} (${unitNumber})`}</TD>}
            {/* <TD>{strings[orgObj.name]}{`(${id})`}</TD> */}
            <StatusElements statusData={totals} />
            {/* suppress unit totals for single unit */}
            {/* {unitData.length > 1 && (
                <TotalElement
                  data={totals}
                  unitLabel={`${name} (${id})`}
                  orgLabel={t('unitTotalLabel')}
                  subTotal
                />
              )} */}
          </TR>
        ))}
      </TBody>
      <TFoot>
        {/* <TotalElement
          data={data[REPORT_TOTALS]}
          unitLabel={
            data[REPORT_UNITS].length === 1
              ? `${data[REPORT_UNITS][0].name} (${data[REPORT_UNITS][0].id})`
              : t('allUnitsCombinedLabel')
          }
          orgLabel={
            data[REPORT_UNITS].length === 1 ? t('unitTotalLabel') : t('allOrganizationsLabel')
          }
        /> */}
      </TFoot>
    </Table>
  );
};

const ActivityReport = ({
  unitLabel,
  loading,
  startDate,
  endDate,
  data,
}: any) => {
  const { t } = useTranslation();

  if (loading) return <LoadingSpinner />;

  return data?.activityReport
    ? (
      <Stack gapSize="16">
        <React.Fragment>
          {/* admin reporting */}
          <AdminReportHeader
            startDate={startDate}
            endDate={endDate}
          />

          <section>
            <Space top={spacing64}>
              <Title1>{unitLabel}</Title1>
            </Space>
          </section>
        </React.Fragment>

        <section>
          <ActivityTable data={data.activityReport} />
        </section>
      </Stack>
    )
    : (
      <div>{t('noDataAvailableLabel')}</div>
    );
};

export default ActivityReport;

// ActivityTable.propTypes = {
//   data: object.isRequired,
// };

// // TotalElement.propTypes = {
// //   data: object.isRequired,
// //   unitLabel: string,
// //   orgLabel: string,
// //   subTotal: bool,
// // };

// StatusElements.propTypes = {
//   statusData: object.isRequired,
//   bold: bool,
// };

// AdminReportHeader.propTypes = {
//   startDate: string,
//   endDate: string,
// };
