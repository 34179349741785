import React from 'react';
import { spacing32 } from '@churchofjesuschrist/eden-style-constants';
import { Box, Line } from 'src/utils/appCSS/appStyles';
import styled from 'styled-components';
import { ShimmerImage, ShimmerTitle, ShimmerSubtitle, ShimmerMeta } from './Shimmer';
import { flexColumn } from '../ComponentStyles/div';

const StoryHeader = styled(flexColumn)`
  width: 100%;
  min-height: 250px;
  padding: 32px;
  padding-top: 64px;
  padding-left: 0;
`;

export const StoryShimmer = () =>
  <div>
    <StoryHeader>
      <ShimmerTitle />
      <ShimmerSubtitle />
      <ShimmerMeta />
    </StoryHeader>
    <Line paddingTop={'0'} marginTop={spacing32} />
    <Box mt={32}>
      <ShimmerImage style={{ maxWidth: 350 }} />
    </Box>
  </div>;
