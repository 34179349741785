import React from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from '@churchofjesuschrist/eden-icons';

export const Loading = () => (
  <div style={{ position: 'fixed', top: '45vh', left: '45vw' }}>
    <LoadingSpinner size="2rem" />
  </div>
);

const LoadingWrapper = styled.div`
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5;
`;

const LoadingWrapperBackground = styled.div<{ windowLevel?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5; /* Sit on top */
  width: ${props => props.windowLevel ? '100vw' /* Full width */ : '100%'};
  height: ${props => props.windowLevel ? '100vh' /* Full height */ : '100%'};
  background-color: rgb(247, 248, 248); /* Fallback color */
  background-color: rgba(247, 248, 248, 0.4); /* grey2 w/ opacity */
  cursor: progress;
`;

interface ILoadingModalProps {
  /** Controls whether or not to show darkened background. (default: false) */
  noBackground?: boolean
  /** Controls whether to display loader at window or block level. (default: true) */
  windowLevel?: boolean
}

/** Displays a loading spinner. */
export const LoadingModal = ({ noBackground = false, windowLevel = true }: ILoadingModalProps) => (
  noBackground
    ? <LoadingWrapper>
      <LoadingSpinner size="48px" />
    </LoadingWrapper>
    : <LoadingWrapperBackground windowLevel={windowLevel}>
      <LoadingSpinner size="48px" />
    </LoadingWrapperBackground>
);

export default LoadingModal;
