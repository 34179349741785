import React from 'react';
import { useTranslation } from 'react-i18next';

// Eden
import Stack from '@churchofjesuschrist/eden-stack';
import { Text3 } from '@churchofjesuschrist/eden-text';

// components
import { ReadingWidth } from 'src/utils/appCSS/appStyles';

export const InviteHeader = () => {
  const { t } = useTranslation('');
  return (
    <Stack>
      <ReadingWidth>
        <Text3>{t('inviteFormMessage1', { ns: 'strings' })}</Text3>
        <br />
      </ReadingWidth>
    </Stack>
  );
}

export default InviteHeader;
