import React, { Fragment } from 'react';
import { number, object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eden
import Row from '@churchofjesuschrist/eden-row';
import { grey15, spacing16 } from '@churchofjesuschrist/eden-style-constants';

// components
import DeleteStoryButton from '../Buttons/DeleteStoryButton';
import { EditStoryButton } from '../Buttons/EditStoryButton';
import { WorkflowTransitionButton } from '../Buttons/WorkflowTransitionButton';
import InContextHelp from '../InContextHelp/InContextHelp';
import ReturnForReworkButton from '../Buttons/ReturnForReworkButton';
import { LockedByMessage } from '../Modals/StoryLocked/StoryLocked';

// constants
import {
  pageNameType,
  userType,
  viewStoryPropType,
} from '../../utils/appConstants/propTypesConstants';
import { pageNames, MOOD_SUBMIT, MOOD_PUBLISH } from '../../utils/appConstants/appConstants';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/authReducer';
import { useHistory } from 'react-router-dom';
import { getPageRedirect } from '../Stories/utils/RedirectPages';

const {
  manageDraftPageName,
  managePendingPageName,
  myStoriesDraftPageName,
  managePublishPageName,
  createPageName,
} = pageNames;

const ButtonContainer = styled.div<{ isTop?: boolean }>`
  justify-content: space-between;
  padding-bottom: ${spacing16};
  padding-top: ${spacing16};
  cursor: pointer;
  ${props =>
    props.isTop ? `border-bottom: 1px solid ${grey15}` : `border-top: 1px solid ${grey15}`};
`;

const PreviewButtonBar = ({ currentPageName, index, story, activeUnitObj }: { currentPageName, index, story, activeUnitObj }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();

  const { lock, id, title } = story;
  const locked = lock && lock.accountId !== user.accountId;
  const isTop = index === 0;

  return (
    <Fragment>
      {(currentPageName === manageDraftPageName ||
        currentPageName === myStoriesDraftPageName ||
        currentPageName === createPageName) &&
        (
          <ButtonContainer isTop={isTop} data-test-id={`DraftBtnContainer${index}`}>
            <Row verticalAlign="center" gapSize="16">
              {user.isApprover
                ? (
                  <WorkflowTransitionButton
                    index={index}
                    story={story}
                    user={user}
                    activeUnitObj={activeUnitObj}
                    disabled={locked} // disable while someone is editing the record
                    mood={MOOD_PUBLISH}
                  />
                )
                : (
                  <WorkflowTransitionButton
                    index={index}
                    story={story}
                    user={user}
                    activeUnitObj={activeUnitObj}
                    disabled={locked} // disable while someone is editing the record
                    mood={MOOD_SUBMIT}
                  />
                )}

              <EditStoryButton
                id={id}
                currentPageName={currentPageName}
                index={index}
              />

              <DeleteStoryButton
                currentPageName={currentPageName}
                index={index}
                storyId={id}
                storyTitle={title}
                disabled={locked} // disable while someone is editing the record
                onAfterDelete={() => history.push(getPageRedirect(managePendingPageName))}
              />

              {locked && (
                <InContextHelp label={t('disabledButtonsLabel')} index={index}>
                  <p style={{ zIndex: 101 }}>
                    <LockedByMessage lockObj={lock} />
                    {t('storyLockedMessage')}
                  </p>
                </InContextHelp>
              )}
            </Row>
          </ButtonContainer>
        )}

      {user.isApprover &&
        (currentPageName === managePendingPageName ||
          currentPageName === managePublishPageName) &&
        (
          <ButtonContainer isTop={isTop} data-test-id={`PendingBtnContainer${index}`}>
            <Row gapSize="16">
              {currentPageName === managePendingPageName && (
                <WorkflowTransitionButton
                  index={index}
                  story={story}
                  user={user}
                  activeUnitObj={activeUnitObj}
                  disabled={locked} // disable while someone is editing the record
                  mood={MOOD_PUBLISH}
                />
              )}

              <EditStoryButton
                id={id}
                currentPageName={currentPageName}
                index={index}
              />

              <ReturnForReworkButton
                story={story}
                activeUnitObj={activeUnitObj}
                disabled={locked} // disable while someone is editing the record
              />

              <DeleteStoryButton
                currentPageName={currentPageName}
                index={index}
                storyId={id}
                storyTitle={title}
                disabled={locked} // disable while someone is editing the record
                onAfterDelete={() => history.push(getPageRedirect(managePendingPageName))}
              />

              {locked && (
                <div style={{ paddingTop: '8px' }}>
                  <InContextHelp
                    label={t('disabledButtonsLabel')}
                    index={index}
                  >
                    <p style={{ zIndex: 101 }}>
                      <LockedByMessage lockObj={lock} />
                      {t('storyLockedMessage')}
                    </p>
                  </InContextHelp>
                </div>
              )}
            </Row>
          </ButtonContainer>
        )}
    </Fragment>
  );
}

export default PreviewButtonBar;

(PreviewButtonBar as any).propTypes = {
  currentPageName: pageNameType,
  index: number.isRequired,
  story: viewStoryPropType,
  user: userType,
  activeUnitObj: object,
};
