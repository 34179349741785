import React from 'react';
import { func, string, bool, object } from 'prop-types';

// Eden
import { Primary } from '@churchofjesuschrist/eden-buttons';

// notification context
import NotificationContext from 'src/contexts/NotificationContext';
import { storyFormPropType } from 'src/utils/appConstants/propTypesConstants';

export const SendMessageButton = ({ message, handleCompletion, subject, story, user, btnLabel, disabled }: { message?, handleCompletion?, subject?, story?, user?, btnLabel?, disabled?}) => {
  const handleSendMessage = async () => {
    handleCompletion(); // from calling Component
  };

  return (
    <Primary
      disabled={disabled}
      data-test-id="button-send-rework-email"
      onClick={handleSendMessage}
    >
      {btnLabel}
    </Primary>
  );
}

export default SendMessageButton;

(SendMessageButton as any).propTypes = {
  story: storyFormPropType.isRequired,
  message: string.isRequired,
  handleCompletion: func.isRequired,
  btnLabel: string.isRequired,
  subject: string,
  strings: object.isRequired,
  disabled: bool.isRequired,
};

// Provides this.context (see above)
SendMessageButton.contextType = NotificationContext;
