import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
// import NextRouteify from "@churchofjesuschrist/eden-next-routeify";
import SubNavigation from '@churchofjesuschrist/eden-sub-navigation';

import { BrowserRouter as Router, useHistory, useRouteMatch } from 'react-router-dom';

// functions
import {
  setManageStoryUrl,
  setMyStoryUrl,
  setReportUrl,
  setCreateStoryUrl,
  NAV_QUICK_START_GUIDE,
  // NAV_WHATS_NEW,
} from 'src/utils/AppFunctions/setUrls';

// style
import {
  HeaderWrapper,
  NavigationContainer,
  StickyContainer,
} from 'src/utils/appCSS/appStyles';

// constants
import { STORY_STATUSES, REPORT_ACTIVITY } from 'src/utils/appConstants/appConstants';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const { DRAFT_STATUS, PENDING_STATUS } = STORY_STATUSES;

interface IMenuItem {
  text: string
  href?: string
  onClick?: any
  items?: IMenuItem[]
  current?: boolean
}

export const NavigationBar = () => {
  const { t } = useTranslation('strings');
  const history = useHistory();
  const { isAdmin, canApprove, canEdit, hasAny } = useUserPermissions();
  const manageStoriesMatch = useRouteMatch('/admin/manage/:tab');
  const activityMatch = useRouteMatch('/reports/activity');
  const manageAdminMatch = useRouteMatch('/admin/role');
  const addUnitMatch = useRouteMatch('/admin/addUnit');
  const createStoryMatch = useRouteMatch('/admin/create');
  const myStoriesMatch = useRouteMatch('/admin/my-stories/:tab');
  const changeRoleMatch = useRouteMatch('/admin/change-role');
  const viewStoriesMatch = useRouteMatch('/admin/viewstories');

  const isProd = window.location.hostname === 'unithistory.churchofjesuschrist.org';
  const isUpload = window.location.pathname === '/upload';

  const MenuItems: IMenuItem[] = [];

  if (!isUpload) {
    MenuItems.push({
      text: t('homeLabel'),
      onClick: () => history.push('/'),
      current: history.location.pathname === '/'
    });

    MenuItems.push({
      text: t('viewStories'),
      onClick: () => history.push('/admin/viewstories'),
      current: viewStoriesMatch?.isExact
    });

    if (canEdit || canApprove || isAdmin) {
      MenuItems.push({
        onClick: () => history.push(setCreateStoryUrl()),
        text: t('createStoryLabel'),
        current: createStoryMatch?.isExact
      });
      MenuItems.push({
        text: t('navMyStories'),
        onClick: () => history.push(setMyStoryUrl(DRAFT_STATUS)),
        current: myStoriesMatch?.isExact
      });
    }

    const manageItems = [
      {
        text: t('navManageStories'),
        onClick: () => history.push(setManageStoryUrl(PENDING_STATUS)),
        current: manageStoriesMatch?.isExact
      },
      {
        text: t('navActivityReport'),
        onClick: () => history.push(setReportUrl(REPORT_ACTIVITY)),
        current: activityMatch?.isExact
      },
    ];

    if (canApprove) {
      MenuItems.push({
        text: t('navManage'),
        items: manageItems,
        current: (manageStoriesMatch?.isExact ?? activityMatch?.isExact) ?? manageAdminMatch?.isExact ?? addUnitMatch?.isExact
      });
    }

    if (hasAny) {
      const helpItemsItems = [
        {
          text: t('navFAQ'),
          onClick: () => history.push('/help/faq'),
        },
        {
          text: t('navQuickStart'),
          onClick: () => history.push(NAV_QUICK_START_GUIDE),
        }
        // {
        //   text: t('whatsNew'),
        //   onClick: () => history.push(NAV_WHATS_NEW),
        // },
      ];
      MenuItems.push({
        text: t('navHelp'),
        items: helpItemsItems,
      });
    }

    if (isAdmin) {
      MenuItems.push({
        text: t('changeRole', { ns: 'labels' }),
        onClick: () => history.push('/admin/change-role'),
        current: changeRoleMatch?.isExact
      });
    }
  }

  if (!isProd) {
    // temporary logoff
    MenuItems.push({
      text: 'Logout',
      href: '/security/logout'
    });
  }

  return (
    <StickyContainer>
      <NavigationContainer id="NavigationContainer">
        <HeaderWrapper>
          <Router>
            <SubNavigation
              title={{
                text: '',
              }}
              items={MenuItems}
            />
          </Router>
        </HeaderWrapper>
      </NavigationContainer>
    </StickyContainer>
  );
};

export default NavigationBar;

NavigationBar.propTypes = {
  canApprove: bool,
  canEdit: bool,
  isAdmin: bool
};
