import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

// Eden
import { FileInput } from '@churchofjesuschrist/eden-form-parts';
import { Drawer, Handle, Body } from '@churchofjesuschrist/eden-drawer';

// Components
import LoadingSpinner from '../../../utils/componentFunctions/loading-spinner';
import ImagePermissionModal from '../../Modals/Permissions/ImagePermissionModal';
import { RemoveContentModal } from '../../Modals/RemoveContent/RemoveContent';
import PhotoHelp from '../../InContextHelp/PhotoHelp';
import InContextHelp from '../../InContextHelp/InContextHelp';
import { DrawerTitleWithWarning } from './DrawerTitle';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';

// queries
import { addImagesMutation, deleteImagesMutation } from '../../../utils/graphQL_Queries/appMutations';

// styles
import { Line, FloatRight } from '../../../utils/appCSS/appStyles';

// functions
import { setAddImageVariables } from '../../../utils/AppFunctions/formFunctions';
import { useNotificationContext } from 'src/contexts/NotificationContext';

const ImageDrawer = ({ story, handleFinish }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [added, setAdded] = useState<any[]>([]);
  const [removed, setRemoved] = useState<any[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isDrawPhotoOpen, setIsDrawPhotoOpen] = useState<boolean>(true);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [addVariables, setAddVariables] = useState<{ images?: File[], storyId?: any }>({});
  const { t } = useTranslation('strings');
  const { addMessage } = useNotificationContext();

  const [addImages, { loading: addLoading, error: addError }] = useMutation(addImagesMutation);
  const [deleteImages, { loading: deleteLoading, error: deleteError }] = useMutation(deleteImagesMutation);

  const loading = addLoading || deleteLoading;
  const error = addError ?? deleteError;

  const handleInputChange = (event) => {
    const input = event.target;
    const inputFiles = input.value; // Note: the Eden control does NOT preserve the Azure blob id

    const added = inputFiles.filter((ui) => !story.images?.find((storyImage) => ui.name === storyImage.name));
    const removed = story.images?.find((storyImage) => !inputFiles.find((ui) => ui.name === storyImage.name));

    const toRemove: any[] = [];
    // get matched images
    if (removed) {
      toRemove.push(removed);
    }

    setIsAdding(!!added.length);
    setIsRemoving(!!toRemove.length);
    setFiles(inputFiles);
    setAdded(added); // Note: doesn't contain new Azure blob id
    setRemoved(toRemove);
  };

  const initialize = () => {
    const fileList = story.images?.filter((image) => !image?.preview)
      .map((image) => ({
        id: image.id,
        name: image.name,
        base64String: 'use the file object instead',
        file: new File([image.sources], image.name) // Note: following pattern in convertToFileInputs
      }));

    setIsAdding(false);
    setIsRemoving(false);
    setFiles(fileList);
    setIsInitialized(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!isAdding) return;
    const load = async () => {
      const results = await setAddImageVariables(story.id, added);
      setAddVariables(results);
    }

    load();
  }, [added, isAdding]);

  const confirmAdd = async (add) => {
    if (!add) {
      initialize();
    } else {
      setIsAdding(false);
      setAdded([]);
    }
  };

  const confirmRemove = async (remove) => {
    if (!remove) {
      initialize();
    } else {
      await deleteImages({
        variables: { contentIds: removed.map(r => r?.id ?? `${story.id}/${r.name}`), storyId: story.id }
      });

      // update parent form
      handleFinish({
        files,
        isAdding,
        isDrawPhotoOpen,
        isInitialized,
        isRemoving,
        added,
        removed
      });
      setIsRemoving(false);
      setRemoved([]);
      addMessage({
        title: t('removeFileSuccess'),
        message: t('removeFileSuccessMsg'),
        messageType: 'SUCCESS'
      })
    }
  };

  const handleAdd = async (add: boolean) => {
    if (!add) initialize();
    else if (addVariables.images?.length) {
      // Uploading multiple at the same time results in one or more files being null.
      // Upload one-by-one to make sure each file gets uploaded.
      // This approach seems to work fine for now, loop back if time allows.
      await Promise.all(addVariables.images.map(async (image) =>
        await addImages({
          variables: {
            storyId: addVariables.storyId,
            images: [image]
          },
          onCompleted: (data) => {
            const newFiles = files.slice(); // shallow copy of state
            const addedItems = data?.addImages ?? [];

            addedItems.forEach((itemObj) => {
              // const filename = itemObj.name; // filename (doesn't work if the filename has a space in it)
              const newId = itemObj.id; // contains Azure blob ID. e.g. 'storyId/filename'
              const idParts = newId.split('/'); // split into Story ID / filename
              const filename = idParts[1]; // filename
              const findIndex = newFiles.findIndex(fileObj => fileObj.name === filename); // find it in state

              if (findIndex > -1) {
                const updatedFile = { ...newFiles[findIndex], id: newId }; // file to be updated with new Azure blob id
                newFiles.splice(findIndex, 1, updatedFile); // update copy of state
                setFiles(newFiles);
              }
            });

            // debugging
            // console.info('handleAdd, newFiles: ', newFiles);

            // update parent form
            handleFinish({
              files: newFiles,
              isAdding,
              isDrawPhotoOpen,
              isInitialized,
              isRemoving,
              added,
              removed
            });
          }
        })
      ));

      addMessage({
        title: t('uploadSuccessLabel'),
        message: t('uploadSuccessMsg'),
        messageType: 'SUCCESS'
      });
    }
  }

  useEffect(() => {
    if (!loading && isAdding) {
      confirmAdd(true);
    }
  }, [loading]);

  return (
    <Drawer open={isDrawPhotoOpen}>
      <Handle
        onClick={() => {
          setIsDrawPhotoOpen(!isDrawPhotoOpen);
        }}
      >
        <FloatRight>
          <InContextHelp index={6}>
            <PhotoHelp />
          </InContextHelp>
        </FloatRight>
        <DrawerTitleWithWarning title={t('formDrawerLabelPhotos')} warning={t('photoContentWarningLabel')} />
      </Handle>
      <Body>
        <Line />
        {((!isInitialized || isAdding || isRemoving || loading) && !error) && <LoadingSpinner />}
        <FileInput
          name="files"
          data-test-id='image-file-upload'
          accept='image/png, image/jpeg'
          onChange={handleInputChange}
          isDroppable
          multiple
          value={files}
          disabled={(!isInitialized || isAdding || isRemoving || loading)}
        >
          {t('addImageBtnLabel', { ns: 'labels' })}
        </FileInput>

        <ImagePermissionModal isActive={isAdding} handleFinish={handleAdd} />
        <RemoveContentModal
          isActive={isRemoving}
          title={t('removeImageTitleLabel')}
          message={t('removeImageMessage')}
          handleFinish={confirmRemove}
        />
        {error &&
          <ErrorModal
            isActive={!!error}
            title={''}
            errors={error}
            onRetry={() => { }}
          />
        }
      </Body>
    </Drawer>
  );
};

export default ImageDrawer;

ImageDrawer.propTypes = {
  story: object,
  handleFinish: func,
};
