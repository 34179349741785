import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Eden
import { Drawer, Body, Handle } from '@churchofjesuschrist/eden-drawer';

// utilities, constants
import { getFaqs } from './faqConstants';
import { FaqBody, SectionTitle, QuestionTitle } from './FAQ.style';
import { getContentSubmissionPolicyURL } from 'src/utils/AppFunctions/setUrls';

const EMPTY_FAQS = getFaqs(); // get the structure w/o the translation strings
const TOTAL_QUESTIONS = EMPTY_FAQS.reduce((p, q) => p + q.content.length, 0) + 2;
const initialState: any = Array(TOTAL_QUESTIONS).fill({ open: false });

export const FAQ = ({ questionToOpen }: { questionToOpen?: number }) => {
  const [drawers, setDrawers] = useState(initialState);

  const { t } = useTranslation('strings');
  // when the content submission policy is available in additional languages
  // const { t, i18n } = useTranslation('strings');
  // const language = i18n.language;
  // const termsUrl = getContentSubmissionPolicyURL(language);
  const termsUrl = getContentSubmissionPolicyURL(); // defaults to English
  const FAQ_QUESTIONS = getFaqs(t, termsUrl); // get structure with strings

  useEffect(() => {
    if (questionToOpen) {
      toggleDrawer(questionToOpen)
    }
  }, [questionToOpen]);

  const toggleDrawer = (id: number) => {
    setDrawers(drawers.map((item, index) => {
      if (index === id) {
        return { open: !item.open };
      } else {
        return { open: false };
      }
    }));
  };

  if (!drawers.length) {
    return <FaqBody></FaqBody>;
  }

  return (
    <FaqBody>
      {FAQ_QUESTIONS.map((sectionItem, sectionKey) => (
        <Fragment key={'section' + sectionKey}>
          <SectionTitle id={sectionKey as any}>{sectionItem.title}</SectionTitle>
          {sectionItem.content.map((questionItem, questionKey) => (
            <Fragment key={'question' + questionKey}>
              <Drawer open={drawers[questionItem.id].open}>
                <Handle onClick={() => toggleDrawer(questionItem.id)}>
                  <QuestionTitle>{questionItem.question}</QuestionTitle>
                </Handle>
                <Body>
                  {questionItem.answer.map((paragraph, paragraphKey) => (
                    <Fragment key={'paragraph' + paragraphKey}>
                      <p dangerouslySetInnerHTML={{ __html: paragraph.text }}></p>
                      {paragraph.bulletPoints.length > 0
                        ? (
                          <ul>
                            {paragraph.bulletPoints.map((listItem, listItemKey) => (
                              <li
                                key={'listItem' + listItemKey}
                                dangerouslySetInnerHTML={{ __html: listItem }}
                              ></li>
                            ))}
                          </ul>
                        )
                        : null}
                    </Fragment>
                  ))}
                </Body>
              </Drawer>
            </Fragment>
          ))}
        </Fragment>
      ))}
    </FaqBody>
  );
}

export default FAQ;
