import React from 'react';
import { bool, number } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import { Label } from '@churchofjesuschrist/eden-form-parts';
import { Text4 } from '@churchofjesuschrist/eden-text';
import {
  blue20,
  danger60,
  fontSize14,
  fontSize20,
  grey10,
  lineHeight,
  spacing16,
  spacing32,
  spacing4,
  spacing8,
  text120,
  warning60,
  white,
  fontSize16,
} from '@churchofjesuschrist/eden-style-constants';

// styles
import { flexRow } from '../../ComponentStyles/div';
import { P } from '../../ComponentStyles/elements';

export const WithAutoSave = styled(flexRow)`
  justify-content: space-between;
  margin-bottom: ${spacing32};
`;

export const SpaceBetweenContainer = styled(flexRow)`
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const Counter = styled.div<{ error?: boolean }>`
  color: ${props => (props.error ? danger60 : text120)};
`;

export const CharacterCounter = ({ textLength, textLimit, sizeError }) => {
  const { t } = useTranslation('strings');
  return (
    <Text4>
      <Counter error={sizeError}>
        <span style={{ marginRight: spacing16 }}>{sizeError && t('maxCharactersErrorMessage')}</span>
        <span>
          {textLength} / {textLimit}
        </span>
      </Counter>
    </Text4>
  )
};

CharacterCounter.propTypes = {
  textLength: number,
  textLimit: number,
  sizeError: bool,
};

export const EditorMetaData = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 8px;
`;

export const MinorText = styled(P)`
  font-size: 11px;
`;

export const DrawerTitleNonBold = styled.div`
  font-size: ${fontSize14};
  padding-top: ${spacing4};
`;

export const DrawerTitleBold = styled.div`
  font-size: ${fontSize20};
`;

export const DrawerInstructions = styled.div`
  font-size: ${fontSize16};
  padding-bottom: ${spacing16};
  border-top: 1px solid ${grey10};
`;

export const LabelWithMargin = styled(Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing32};
  width: 33%;
`;

export const ButtonBar = styled.div<{ isMobile?: boolean, align: string }>`
  position: ${props => (props.isMobile ? 'fixed' : null)};
  bottom: ${props => (props.isMobile ? '4px' : null)};
  align-items: center;
  display: flex;
  justify-content: ${({ align }) =>
    align === 'center' ? 'center' : align === 'end' ? 'flex-end' : 'flex-start'};
  margin-top: ${spacing8};
  background: ${white};
  width: 370px;
  padding: 4px;
  z-index: 2;

  & > :not(:first-child) {
    margin-left: ${spacing8};
  }
`;

export const FileInfoContainer = styled.div`
  display: inline-block;
`;

export const A = styled.a`
  text-decoration: underline;
  color: ${text120};
  :hover {
    color: ${blue20};
  }
`;
export const InputLabel = styled.span`
  color: ${text120};
  font-size: ${fontSize14};
  line-height: ${lineHeight};
  padding-left: ${spacing8};
`;

export const WarningText = styled.div`
  color: ${warning60};
`;
