import React, { useState } from 'react';
import { array, func, bool } from 'prop-types';

// eden
import Card from '@churchofjesuschrist/eden-card';
import Row from '@churchofjesuschrist/eden-row';
import { Label, Checkbox, FieldSet } from '@churchofjesuschrist/eden-form-parts';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { Text3 } from '@churchofjesuschrist/eden-text';

// components
import DropDownCard from 'src/components/DropDownCards/DropDownCard';
import { useTranslation } from 'react-i18next';

// functions
import { isObjectEqual } from 'src/utils/AppFunctions/appFunctions';

// styles
import { OrganizationItem } from 'src/components/Organizations/OrganzationStyle';
import {
  DropDownContent,
  HorizontalDropdowns,
  HorizontalDropdown,
} from 'src/utils/appCSS/appStyles';

// constants
import { UnitOrganization } from 'src/models/Unit';
import { appConstants } from 'src/utils/appConstants/appConstants';

interface IDropdownProps { fullOrgList: UnitOrganization[], selectedOrgIds: string[], filterFavorites: boolean, toggleAddOrgBtnClick?, handleOrgChange?}
export const OrganizationDropDown = ({ selectedOrgIds, filterFavorites, fullOrgList, toggleAddOrgBtnClick, handleOrgChange }: IDropdownProps) => {
  const [checkedOrganizations, setCheckedOrganizations] = useState<string[]>(selectedOrgIds);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [favoritesFiltered, setFilterFavorites] = useState(false);
  const [unitTypesChecked, setUnitTypesChecked] = useState<string[]>([]);

  const handleOrgDropdownClose = event => {
    event.stopPropagation();
    setCheckedOrganizations(selectedOrgIds); // revert organizations
    setFilterFavorites(filterFavorites);
    toggleAddOrgBtnClick();
  };

  const handleFilterFavorites = () => {
    setFilterFavorites(filtered => !filtered);
    setSubmitDisabled(isObjectEqual(selectedOrgIds, checkedOrganizations) && isObjectEqual(filterFavorites, !favoritesFiltered));
  };

  const handleLocalOrgChange = (e, unitTypeName) => {
    const organizationToChange = e.target.value as string;

    // Reset select all when manually picking orgs
    setUnitTypesChecked(unitTypesChecked.filter(u => u !== unitTypeName));

    const existingValue = checkedOrganizations.find(o => o === organizationToChange);
    let updateCheckOrganizations: string[] = [];
    if (existingValue) {
      updateCheckOrganizations = checkedOrganizations.filter(o => o !== existingValue);
    } else {
      const newValue = fullOrgList.find(o => o.uuid === organizationToChange)?.uuid;
      updateCheckOrganizations = [
        ...checkedOrganizations,
        newValue!,
      ];
    }

    setFilterFavorites(favoritesFiltered);
    setCheckedOrganizations(updateCheckOrganizations);
    setSubmitDisabled(isObjectEqual(selectedOrgIds, updateCheckOrganizations) && isObjectEqual(filterFavorites, favoritesFiltered));
  }

  const handleSelectAllChange = (unitType) => {
    const checked = unitTypesChecked.some(u => u === unitType);
    let updatedOrgs: string[] = [];
    if (checked) {
      updatedOrgs = checkedOrganizations.filter(o => fullOrgList.some(f => f.uuid === o && f.unitTypeName !== unitType)).map(o => o);

      setUnitTypesChecked(unitTypesChecked.filter(u => u !== unitType));
      setCheckedOrganizations(updatedOrgs);
      setSubmitDisabled(!updatedOrgs.length);
    } else {
      updatedOrgs = fullOrgList.filter(o => o.unitTypeName === unitType).map(o => o.uuid);

      setUnitTypesChecked([...unitTypesChecked, unitType]);
      setCheckedOrganizations([...checkedOrganizations, ...updatedOrgs]);
      setSubmitDisabled(!updatedOrgs.length);
    }
  };

  const handleOrganizationSortingSubmission = () => {
    const addedOrganizations = fullOrgList?.filter(o => checkedOrganizations.some(c => c === o.uuid)).map(o => o.uuid);

    setSubmitDisabled(true);
    toggleAddOrgBtnClick();
    handleOrgChange(addedOrganizations, favoritesFiltered); // boost state up to DisplayStories
  };

  const { t } = useTranslation('strings');

  return (
    <DropDownCard handleClose={handleOrgDropdownClose}>
      <Card>
        <DropDownContent>
          <OrganizationItem>
            <FieldSet>
              <Label>
                <Checkbox
                  name="filterFavorites"
                  checked={favoritesFiltered}
                  onChange={handleFilterFavorites}
                />
                {t('dropDownFavoritesLabel')}
              </Label>
            </FieldSet>
          </OrganizationItem>
          <HorizontalDropdowns>
            {appConstants.UNIT_TYPES.map((type) => {
              const orgList = fullOrgList.filter(o => o.unitType === type);
              return !orgList?.length
                ? null
                : (
                  <HorizontalDropdown key={type}>
                    <Text3>{orgList[0].unitTypeName}</Text3>
                    <hr />
                    <OrganizationItem key='Key'>
                      <FieldSet>
                        <Label>
                          <Checkbox
                            id={`selectAll${orgList[0].unitTypeName}`}
                            name="organizationSelectAll"
                            checked={unitTypesChecked.some(u => u === orgList[0].unitTypeName)}
                            value={`selectAll${orgList[0].unitTypeName}`}
                            onChange={e => handleSelectAllChange(orgList[0].unitTypeName)}
                          />
                          {t('selectAll')}
                        </Label>
                      </FieldSet>
                    </OrganizationItem>
                    {orgList.map(o => {
                      return (
                        <OrganizationItem key={`organizationItem${o.uuid}`}>
                          <FieldSet>
                            <Label>
                              <Checkbox
                                checked={checkedOrganizations.some(c => c === o.uuid)}
                                name="organization"
                                onChange={e => handleLocalOrgChange(e, orgList[0].unitTypeName)}
                                value={o.uuid || ''}
                                data-test-id={`organizationNameCheckBox${o.uuid}`}
                                data-org-type={`organizationCheckBox${orgList[0].unitTypeName}`}
                              />
                              {o.name}
                            </Label>
                          </FieldSet>
                        </OrganizationItem>
                      );
                    })}
                  </HorizontalDropdown>
                )
            })}
          </HorizontalDropdowns>

          <br />

          <Row gapSize="16">
            <Primary
              disabled={isSubmitDisabled}
              onClick={handleOrganizationSortingSubmission}
              data-test-id="applyOrgDropDownBtn"
            >
              {t('applyBtnLabel', { ns: 'labels' })}
            </Primary>

            <Secondary data-test-id="cancelOrgDropdownBtn" onClick={handleOrgDropdownClose}>
              {t('cancelBtnLabel', { ns: 'labels' })}
            </Secondary>
          </Row>
        </DropDownContent>
      </Card>
    </DropDownCard>
  );
}

export default OrganizationDropDown;

(OrganizationDropDown as any).propTypes = {
  fullOrgList: array.isRequired,
  selectedOrgIds: array.isRequired,
  toggleAddOrgBtnClick: func.isRequired,
  filterFavorites: bool,
  handleOrgChange: func.isRequired
};
