import {
  white,
  text120,
  black,
  fontSize11,
  spacing8,
  spacing16,
} from '@churchofjesuschrist/eden-style-constants';
import Badge from '@churchofjesuschrist/eden-badge';
import styled from 'styled-components';

import { flexColumn } from 'src/components/ComponentStyles/div';

export const StoryHeader = styled(flexColumn)`
  width: 100%;
  min-height: 250px;
  padding: 32px;
  padding-left: 0;
  color: ${black};
  font-size: 42px;
`;

export const AuthorLine = styled.div`
  padding: 0;
  font-size: ${spacing16};
  margin-bottom: ${spacing16};
`;

export const DateLine = styled.div`
  padding: 0;
  font-size: ${spacing16};
  font-weight: bold;
`;

export const VisibleUntilLine = styled.div`
  font-size: ${fontSize11};
  padding: ${spacing8} 0;
  color: #ff0000
`;

export const OrgBadge = styled(Badge)`
  margin-right: 5px;
  border-radius: 0;
  background-color: ${white};
  color: ${black};
  padding: 5px;
  border: 1px solid ${text120};
  font-size: 13px;
`;

export const PublishedDate = styled.div`
  font-size: ${fontSize11};
  padding: ${spacing8} 0;
`;

export const DocLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Document = styled.div`
  box-shadow: 1px 1px 5px 1px rgba(74, 74, 74, 0.25);
  padding: ${spacing16};
`;
