import React, { useMemo, useState } from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import { ArrowDropDown } from '@churchofjesuschrist/eden-icons';
import { Text3 } from '@churchofjesuschrist/eden-text';
import { Label, Radio, FieldSet } from '@churchofjesuschrist/eden-form-parts';
import Row from '@churchofjesuschrist/eden-row';
import Card from '@churchofjesuschrist/eden-card';
import Stack from '@churchofjesuschrist/eden-stack';

// components
import DropDownCard from 'src/components/DropDownCards/DropDownCard';

// styles
import { DropDownContent } from 'src/utils/appCSS/appStyles';
import {
  DropDownContainer,
  DropDownBtn,
  DropDownTitle,
  DropDownRotated,
} from 'src/components/SortAndSearchBar/SortAndSearchStyles';

// constants
import { orderByDirections, orderByProperties } from 'src/utils/appConstants/appConstants';

const { ASCENDING, DESCENDING } = orderByDirections;

// add more properties to this array to cause new orderBy options to show up.
const { ORDER_BY_DATE_START, ORDER_BY_TITLE, ORDER_BY_PUBLISHED } = orderByProperties;

export const SortingStoryDropDown = ({ handleSortChange, variables }: { handleSortChange?: any, variables?: any }) => {
  const { properties: sortBy, direction: orderBy } = variables; // note: renaming variables (see below)
  const [isSortingDropDownOpen, setIsSortingDropDownOpen] = useState(false);
  const { t } = useTranslation('strings');

  const sortByDropDownList = [
    {
      name: t('sortNameSortLabel', { ns: 'labels' }),
      label: t('dropDownSortByPublishedDateLabel'),
      value: ORDER_BY_PUBLISHED,
    },
    {
      name: t('sortNameSortLabel', { ns: 'labels' }),
      label: t('dropDownSortByEventDateLabel'),
      value: ORDER_BY_DATE_START,
    },
    {
      name: t('sortNameSortLabel', { ns: 'labels' }),
      label: t('dropDownSortByTitleLabel'),
      value: ORDER_BY_TITLE,
    },
  ];

  const handleSortByChange = e => {
    const { direction } = variables;
    handleSortChange([e.target.value], direction); // props: sortBy, orderBy
    toggleSortDropdown();
  };

  const handleOrderByChange = e => {
    const { properties } = variables;
    handleSortChange(properties, e.target.value); // props: sortBy, orderBy
    toggleSortDropdown();
  };

  const toggleSortDropdown = () => {
    setIsSortingDropDownOpen(!isSortingDropDownOpen);
  };

  const handleSortDropdownClose = event => {
    event.stopPropagation();
    setIsSortingDropDownOpen(false);
  };

  // orderBy list used by this component (is dependent upon props)
  const orderByDropDownList = useMemo(() => {
    let dropdownItems = [{
      name: t('sortNameOrderLabel', { ns: 'labels' }),
      label: sortBy.includes(ORDER_BY_TITLE)
        ? t('dropDownSortDirection_Z_A_Label')
        : t('dropDownSortDirectionNewestLabel'),
      value: DESCENDING
    },
    {
      name: t('sortNameOrderLabel', { ns: 'labels' }),
      label: sortBy.includes(ORDER_BY_TITLE)
        ? t('dropDownSortDirection_A_Z_Label')
        : t('dropDownSortDirectionOldestLabel'),
      value: ASCENDING
    }];

    if (sortBy.includes(ORDER_BY_TITLE)) {
      dropdownItems = dropdownItems.reverse();
    }
    return dropdownItems;
  }, [sortBy]);

  return (
    <DropDownContainer paddingTop="0px">
      <DropDownBtn onClick={toggleSortDropdown}>
        <Row gapSize="16">
          <DropDownTitle>{t('dropDownSortLabel')}</DropDownTitle>
          <DropDownRotated shouldRotate={isSortingDropDownOpen}>
            <ArrowDropDown />
          </DropDownRotated>
        </Row>
      </DropDownBtn>

      {isSortingDropDownOpen && (
        <DropDownCard handleClose={handleSortDropdownClose}>
          <Card>
            <DropDownContent>
              <Stack gapSize="4">
                <section>
                  <Text3>{t('dropDownSortPropertiesLabel')}</Text3>
                  <hr />
                </section>

                {/* Sorty by (properties)  */}
                {sortByDropDownList.map((item, index) => (
                  <FieldSet key={index}>
                    <Label>
                      <Radio
                        id={item.value}
                        name={item.name}
                        value={item.value}
                        checked={sortBy.includes(item.value)}
                        onChange={handleSortByChange}
                      />
                      {item.label}
                    </Label>
                  </FieldSet>
                ))}

                {/**************************************************************************/}

                <section>
                  <Text3>{t('dropDownSortDirectionLabel')}</Text3>
                  <hr />
                </section>

                {/* Order by (direction) */}
                {orderByDropDownList.map((item, index) => (
                  <FieldSet key={index}>
                    <Label>
                      <Radio
                        id={item.value}
                        name={item.name}
                        value={item.value}
                        checked={orderBy.includes(item.value)}
                        onChange={handleOrderByChange}
                      />
                      {item.label}
                    </Label>
                  </FieldSet>
                ))}
              </Stack>
            </DropDownContent>
          </Card>
        </DropDownCard>
      )}
    </DropDownContainer>
  );
}

export default SortingStoryDropDown;

(SortingStoryDropDown as any).propTypes = {
  handleSortChange: func.isRequired,
  variables: object.isRequired,
};
