import React from 'react';
import styled from 'styled-components';
import { string, func, bool } from 'prop-types';

// Eden
import Row from '@churchofjesuschrist/eden-row';
import { fontSize14, fontSize16, info40 } from '@churchofjesuschrist/eden-style-constants';

// Padded component baseline definition
const Padding = styled.div<{ paddingTop?: string, paddingRight?: string, paddingBottom?: string, paddingLeft?: string, fontSize?: string, backgroundColor?: string }>`
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop}` : null)};
  ${props => (props.paddingRight ? `padding-right: ${props.paddingRight}` : null)};
  ${props => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : null)};
  ${props => (props.paddingLeft ? `padding-left: ${props.paddingLeft}` : null)};
  ${props => (props.color ? `color: ${props.color}` : null)};
  ${props => (props.fontSize ? `font-size: ${props.fontSize}` : null)};
  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : null)};
`;

// Clickable link base component
export const Click = styled.a<{ paddingTop?: string, paddingRight?: string, paddingBottom?: string, paddingLeft?: string, fontSize?: string, marginRight?: string, marginLeft?: string, fontStyle?: string, fontWeight?: string }>`
  display: inline-block;
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop}` : null)};
  ${props => (props.paddingRight ? `padding-right: ${props.paddingRight}` : null)};
  ${props => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : null)};
  ${props => (props.paddingLeft ? `padding-left: ${props.paddingLeft}` : null)};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0px')};
  color: ${props => (props.color ? props.color : info40)};
  border-radius: 3px;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  font-size: ${props => (props.fontSize ? props.fontSize : fontSize16)};
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  text-decoration: none !important;
  cursor: pointer;
`;

const ClickIcon = ({ text = 'unlabeled', color, onClick = x => x, Icon, minimal, ...props }: { text?: string, color?: string, onClick: (x: any) => void, Icon: any, minimal?: boolean, [index: string]: any }) => (
  <Click color={color} onClick={onClick}>
    <Row
      data-test-id={'ClickIcon_' + text.replace(/ /g, '_') + '_Link'}
      gapSize="4"
      {...props} // pick up data-tip and data-for if available (for tool tips)
    >
      <Icon size={minimal ? '20px' : '24px'} />
      {minimal
        ? null
        : (
          <Padding paddingTop="3px" fontSize={fontSize14}>
            {text}
          </Padding>
        )}
    </Row>
  </Click>
);

ClickIcon.propTypes = {
  text: string,
  color: string,
  onClick: func,
  Icon: func,
  minimal: bool,
};

export default ClickIcon;
