import React, { useState } from 'react';
import { array } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Modal from '@churchofjesuschrist/eden-platform-modal';
import Row from '@churchofjesuschrist/eden-row';
import {
  fontSize14,
  fontSize20,
  lineHeight,
  lineHeightTight,
  text120,
  grey10,
} from '@churchofjesuschrist/eden-style-constants';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';

import FadedDrawer from '../../FadedDrawer/FadedDrawer';
import { flexRow } from '../../ComponentStyles/div';
import { pageNames } from '../../../utils/appConstants/appConstants';

const {
  viewStoryPageName,
  editPageName,
} = pageNames;

const Group = styled(flexRow)`
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const Title = styled.div`
  font-size: ${fontSize20};
  font-weight: bold;
  line-height: ${lineHeightTight};
  color: ${text120};
`;

const ModalContainer = styled.div`
  width: 600px;
  padding: 16px;
  max-height: 750px;
  overflow-y: auto;
`;

const ChildBody = styled.div`
  font-size: ${fontSize14};
  font-weight: normal;
  line-height: ${lineHeight};
  color: #333333;
`;

const HR = styled.hr`
  border-top: 2px solid ${grey10};
  margin-bottom: 16px;
`;

const GraphQLErrors = ({ errors }: { errors: any[] }) => {
  const { t } = useTranslation('strings');
  // Documentation note: graphQLErrors is an array, and each element: const { message, locations, path } = graphQLError[0];

  return errors.length === 0
    ? null
    : (
      <React.Fragment>
        <b>{t('errorDatabaseLabel')}</b>

        {errors.map((graphQLerror, i) => {
          const { message, locations, path, extensions } = graphQLerror;

          return (
            <React.Fragment key={i}>
              {message && (
                <div>
                  <b>message: </b>
                  {message}
                </div>
              )}
              {locations && locations.length > 0 && (
                <div>
                  <b>locations:</b>
                  {locations.map((elt, i) => (
                    <div key={i}>{Object.keys(elt) ? JSON.stringify(elt) : elt}</div>
                  ))}
                </div>
              )}
              {path && <div>path: {path}</div>}
              {extensions && (
                <div>
                  <b>errorCode:</b> {extensions.errorCode || extensions.code}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
};

const ErrorModal = ({ isActive, currentPageName, title, onRetry, errors = {}, children }: { isActive: boolean, currentPageName?: string, title: string, onRetry?, errors?: any, children?: React.ReactNode }) => {
  const [active, setActive] = useState(isActive || false);
  const { t } = useTranslation();

  const closeModal = () => setActive(false);

  const statusCode = (errors.networkError?.statusCode) || null;
  const sessionTimedOut = statusCode === 400 || statusCode === 401;
  const errorKeys = Object.keys(errors);

  let additionalErrorMessage = '';
  if (currentPageName === viewStoryPageName) {
    if (statusCode === 403) {
      additionalErrorMessage = t('noViewerRights');
    } else {
      additionalErrorMessage = t('retrieveStoryErrorMessage');
    }
  }

  if (currentPageName === editPageName) {
    if (statusCode === 403) {
      additionalErrorMessage = t('noEditRights');
    } else {
      additionalErrorMessage = t('retrieveStoryErrorMessage');
    }
  }

  // modify the error title based on the problem
  const errorTitle = errors.graphQLErrors?.length ? t('errorDatabaseTitle') : title;

  return (
    <Modal active={active} onCloseGesture={closeModal}>
      <ModalContainer>
        <Group>
          {/* <Alert size="24px" color={"red"} /> */}
          <Title>{errorTitle}</Title>
        </Group>

        {errorKeys.length > 0
          ? (
            // Documentation note: const { graphQLErrors, networkError, message, extraInfo } = errors;
            <FadedDrawer minHeight={80}>
              <HR />
              {/* i > 0 && <div>---</div> */}
              {statusCode && (
                <div>
                  <b>{t('statusLabel')}: </b>
                  {statusCode}
                  {sessionTimedOut ? ` -- ${t('userNotLoggedIn')}` : ''}
                </div>
              )}
              {additionalErrorMessage && (
                <div>
                  <b>{t('errorAdditionalMessageLabel')}</b>
                  {additionalErrorMessage}
                </div>
              )}
              <div>
                <b>{t('errorMessageLabel')}</b>
                {errors.message}
              </div>

              <GraphQLErrors errors={errors.graphQLErrors} />

              {process.env.NODE_ENV !== 'production' && errors.networkError && (
                <React.Fragment>
                  <div>
                    <b>url: </b>
                    {(errors.networkError.response?.url) || ''}
                  </div>
                  <div>
                    <b>{t('responseLabel')}</b>
                    {(errors.networkError.response?.statusText) ||
                      ''}
                  </div>
                </React.Fragment>
              )}
            </FadedDrawer>
          )
          : children
            ? (
              <ChildBody>{children}</ChildBody>
            )
            : null}

        <HR />
        <Row gapSize="16">
          {onRetry && <Primary onClick={onRetry}>{t('retryMsg')} </Primary>}
          {errorKeys.length === 0 && <Secondary onClick={() => window.location.assign('/')}>{t('homeLabel', { ns: 'strings' })} </Secondary>}
        </Row>
      </ModalContainer>
    </Modal>
  );
}

export default ErrorModal;

GraphQLErrors.propTypes = {
  errors: array,
};
