import React, { createRef, RefObject, useState } from 'react';
import { Validator } from '@churchofjesuschrist/eden-form-parts';
import useFormRouteChange from '../../hooks/useFormRouteChange';
import { changeHandlerGlobal } from '../../utils/formUtil';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';
import { ProgressButton } from '@churchofjesuschrist/eden-progress';
import {
  Primary,
  Ghost,
  Danger,
} from '@churchofjesuschrist/eden-buttons';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from 'src/contexts/NotificationContext';

interface IFormProps {
  /** The current data of the form values */
  formData: object
  /** State management callback to set formData */
  setFormData: (object) => void
  /** Array of field names */
  fieldList: string[]
  /** Callback for when we submit the form (after validation) */
  onSubmit?: (object) => Promise<any>
  /** Callback for when we cancel any changes */
  onCancel?: () => void
  /** Should all form fields start off as `required` (can be overridden at the field level) */
  required?: boolean
  /** Should all form fields start off as `disabled` (can be overridden at the field level) */
  disabled?: boolean
  /** Child components */
  children: (props: IChildrenProps) => JSX.Element
  /** Is an action loading. */
  loading?: boolean
  /** An error from api. */
  error?: any
  /** Alert message */
  alertText?: string
  /** Text to display on successful submission */
  submitSuccessText?: string
  /** Callback to fire after submit. Succeed or fail */
  afterSubmit?: () => void
  /** Callback to fire after successful submit */
  afterSuccess?: () => void
  /** Text for primary button. Defaults to "Complete" */
  primaryText?: string
  /** If primary button should be disabled */
  primaryDisabled?: boolean
  /** Text to display if the primary button submission succeeded */
  primarySuccessText?: string
  /** Text to display if the primary button submission failed */
  primaryErrorText?: string
  /** What type of button to display for the primary button */
  primaryButtonType?: 'danger'
  /** Should we hide the success notification. Usually for when part of a multi-step form process and it shouldn't show until the final submission. */
  hideSuccessNotification?: boolean
}

interface IChildrenProps {
  validity: any
  commonFormProps: ICommonFormProps
  cancelForm: () => void
  invalidForm: boolean
  submitting: boolean
}

export interface ICommonFormProps {
  onChange: (ev) => void
  formData: any
  refs: { [index: string]: RefObject<any> }
  validity: any
  required: boolean
  disabled: boolean
}

const Form = ({
  children,
  formData,
  fieldList,
  onSubmit,
  onCancel,
  setFormData,
  error,
  required = true,
  disabled = false,
  alertText,
  afterSubmit,
  afterSuccess,
  primaryText,
  primaryDisabled,
  primarySuccessText,
  primaryErrorText,
  primaryButtonType,
  hideSuccessNotification
}: IFormProps) => {
  const { addMessage } = useNotificationContext();
  const [invalidForm, setInvalidForm] = useState(false);
  const [primarySubmitted, setPrimarySubmitted] = useState(false);
  const { t: ts } = useTranslation('strings');

  const defaultFormData = {
    ...formData,
  };

  const { unload, loadChanges } = useFormRouteChange();

  const submitForm = async () => {
    setPrimarySubmitted(true);
    unload();
    try {
      await onSubmit?.(formData);
      !hideSuccessNotification &&
        addMessage({
          title: ts('success'),
          message: primarySuccessText ?? ts('form_submitted_successfully'),
          messageType: 'SUCCESS',
        })
      afterSuccess?.();
    } catch (e) {
      addMessage({
        title: ts('error'),
        message: alertText ?? primaryErrorText ?? ts('form_submission_error'),
        messageType: 'ERROR',
      })
      console.error('Form submission error:', e);
      throw e;
    } finally {
      afterSubmit?.();
      setPrimarySubmitted(false);
    }
  }

  const cancelForm = () => {
    onCancel?.();
  }

  const changeHandler = (event) => {
    loadChanges();
    changeHandlerGlobal(event, formData, setFormData);
  }

  // make refs for being able to focus on errors
  const refs: { [index: string]: RefObject<any> } = {};
  fieldList.forEach((field) => (refs[field] = createRef()));

  const PrimaryButton = primaryButtonType === 'danger' ? Danger : Primary;
  return (
    <Validator>
      {({ checkValidity, resetValidity, validity }) => {
        const commonFormProps = {
          onChange: changeHandler,
          formData,
          refs,
          validity,
          required, // most are required, can override at the field level to not be required as needed by setting `required={false}` after the `commonFormProps` call
          disabled,
        };

        return (
          <form
            noValidate
            action=""
            method="get"
            onSubmit={(event) => {
              event.preventDefault();

              if (checkValidity(event) === true) {
                setInvalidForm(false);
                submitForm();
              } else {
                setInvalidForm(true);
                if (!checkValidity(event)) {
                  const target = event.target as HTMLElement;
                  (
                    target.querySelector(
                      ':is(input,select,textarea):invalid'
                    ) as HTMLElement
                  ).focus();
                }
              }
            }}
            onReset={(event) => {
              event.preventDefault();
              setFormData(defaultFormData);
              resetValidity();
            }}
          >
            <Stack gapSize="32">
              {children({
                validity,
                commonFormProps,
                cancelForm,
                invalidForm,
                submitting: primarySubmitted,
              })}
              <Row>
                {onSubmit && (
                  <ProgressButton progressing={primarySubmitted}>
                    <PrimaryButton
                      disabled={
                        primaryDisabled ??
                        primarySubmitted
                      }
                      type="submit"
                    >
                      {primaryText ?? ts('submitLabel')}
                    </PrimaryButton>
                  </ProgressButton>
                )}
                {onCancel && (
                  <Ghost
                    onClick={cancelForm}
                    target={'_blank'}
                  >
                    {ts('cancelBtnLabel')}
                  </Ghost>
                )}
              </Row>
            </Stack>
          </form>
        )
      }}
    </Validator>
  )
}

export default Form;
