import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import Stack from '@churchofjesuschrist/eden-stack';
import Split from '@churchofjesuschrist/eden-split';
import Image from '@churchofjesuschrist/eden-image';
import { Help } from '@churchofjesuschrist/eden-icons';
import { H2, H3 } from '@churchofjesuschrist/eden-headings';
import { Unordered, Item } from '@churchofjesuschrist/eden-list';
import HtmlFragment from '@churchofjesuschrist/eden-html-fragment';
import {
  grey10,
  info40,
  spacing24,
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// components
import { ExampleStoriesOnly } from './ExamplesPage';
import QuickStartTOC from './QuickStartTOC';
import { QuickStartWrapper } from '../../utils/appCSS/appStyles';

// constants, utilities
import { NAV_RENLUND_QUOTE, getContentSubmissionPolicyURL } from 'src/utils/AppFunctions/setUrls';
import { pageNames, CREATING_A_STORY, CONTRIBUTING } from 'src/utils/appConstants/appConstants';
import { getPDFandVTT } from 'src/utils/AppFunctions/appFunctions';
import { usePanelContext } from 'src/contexts/PanelContext';
import { goodColor } from 'src/components/ExamplePages/exampleConstants';
import { withPage } from 'src/contexts/PageContext';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

const HR = styled.hr`
  border-top: 2px solid ${grey10};
  width: 100%;
`;

const ShadowwImage = styled(Image)`
  box-shadow: 2px 2px 5px 2px rgba(74, 74, 74, 0.25);
  border-radius: 2px;
`;

const { helpCreatingStoriesPageName } = pageNames;

export const CreatingStories = ({ match }: any) => {
  const { open, close, isOpen } = usePanelContext();

  const togglePanel = (story) => {
    if (isOpen) {
      close();
    } else {
      open(story);
    }
  }

  const { t, i18n } = useTranslation('strings');
  const language = i18n.language;
  const pdfAndVtt = getPDFandVTT(language);
  // when the content submission policy is available in additional languages
  // const termsUrl = getContentSubmissionPolicyURL(language);
  const termsUrl = getContentSubmissionPolicyURL(); // defaults to English

  return (
    <Split ratio="1/4">
      <aside>
        <QuickStartTOC activePage={helpCreatingStoriesPageName} />
      </aside>

      <main>
        <QuickStartWrapper>
          <H2 id="creating-stories">{t('creatingStories')}</H2>
          <Space top={spacing32} />
          <Stack>
            <div>
              <em>{t('ourHistoryIsInspiringRenlund')}</em>
              &nbsp;<sup><a href={NAV_RENLUND_QUOTE}>1</a></sup>
            </div>
            <div />

            <H3>{t('processCreateApproveShare')}</H3>
            <div>{t('processCreateApproveShareP1')}</div>

            <div style={{ width: '144px' }}>
              <a
                href={pdfAndVtt[CREATING_A_STORY]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ShadowwImage
                  disableLazy
                  src='/static/images/creating-ward-stake-histories-thumb.png'
                  alt={t('creatingWardStakeHistories')}
                  width="144"
                />
                <br />
                <br />
                {t('creatingWardStakeHistories')}
              </a>
            </div>

            <section />
            <HtmlFragment content={t('processCreateApproveShareP2')} />
            <H3>{t('whatShouldIWrite')}</H3>
            <div>{t('whatShouldIWriteP1')}</div>
            <div>{t('whatShouldIWriteP2')}</div>
            <Unordered>
              <Item key="1">{t('whatShouldIWriteList1')}</Item>
              <Item key="2">{t('whatShouldIWriteList2')}</Item>
              <Item key="3">{t('whatShouldIWriteList3')}</Item>
              <Item key="4">{t('whatShouldIWriteList4')}</Item>
              <Item key="5">{t('whatShouldIWriteList5')}</Item>
              <Item key="6">{t('whatShouldIWriteList6')}</Item>
            </Unordered>
            <div>{t('whatShouldIWriteP3')}</div>
            <HtmlFragment content={t('whatShouldIWriteP4')} />

            <H3>{t('whatShouldILeaveOut')}</H3>
            <div id='leaveout'>{t('whatShouldILeaveOutP1')}</div>
            <div>{t('whatShouldILeaveOutP2')}</div>
            <Unordered>
              <Item key="1">{t('whatShouldILeaveOutList1')}</Item>
              <Item key="2">{t('whatShouldILeaveOutList2')}</Item>
              <Item key="3">{t('whatShouldILeaveOutList3')}</Item>
              <Item key="4">{t('whatShouldILeaveOutList4')}</Item>
              <Item key="5">{t('whatShouldILeaveOutList5')}</Item>
              <Item key="6">{t('whatShouldILeaveOutList6')}</Item>
              <Item key="7">{t('whatShouldILeaveOutList7')}</Item>
              <Item key="8">{t('whatShouldILeaveOutList8')}</Item>
            </Unordered>
            <div>{t('whatShouldILeaveOutP3')}</div>
            <HtmlFragment content={t('whatShouldILeaveOutP4', { TermsOfUsePolicyURL: termsUrl })} />
            <HtmlFragment content={t('whatShouldILeaveOutP5')} />

            <H3>{t('exampleStoriesLabel')}</H3>
            <div>
              {t('exampleStoriesP1')}{' '}
              <Help size={spacing24} color={goodColor} />
            </div>

            <ExampleStoriesOnly onClick={togglePanel} />
            <div />

            <H3>{t('findingJoyInContributing')}</H3>
            <video
              controls
              src='https://media2.ldscdn.org/assets/information-technology/annual-history-interface/2021-08-0020-annual-history-tool-1080p-eng.mp4'
              width={'95%'}
              poster={'/static/images/finding-joy-contributing.png'}
            >
              <track default kind='subtitles' srcLang={language} src={pdfAndVtt[CONTRIBUTING]} />
            </video>
            <div />

            <H3>{t('gettingHelpSharingFeedback')}</H3>
            <div>
              {t('gettingHelpSharingFeedbackP1')}{' '}
              <Help size={spacing24} color={info40} />
            </div>
            <div>{t('gettingHelpSharingFeedbackP2')}</div>
            <div />

            <HR />
            <cite id="renlund-quote">
              {t('renlundQuoteBoldlyNobly')}
            </cite>
          </Stack>
        </QuickStartWrapper>
      </main>
    </Split>
  );
}

export default withPage({ pageName: helpCreatingStoriesPageName, headTitle: 'quickStartCreatingStories' })(CreatingStories);
