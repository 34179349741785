import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server'
import { format } from 'date-fns';
import htmlToJsx from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Eden
import { Format } from '@churchofjesuschrist/eden-icons';
import { H1, H3, Title5 } from '@churchofjesuschrist/eden-headings';
import { Text1, Text4, Text5 } from '@churchofjesuschrist/eden-text';
import {
  spacing8,
  spacing16,
  spacing32,
  spacing64,
} from '@churchofjesuschrist/eden-style-constants';
import Row from '@churchofjesuschrist/eden-row';
import Image from '@churchofjesuschrist/eden-image';
import { MediaModal, ModalImage } from '@churchofjesuschrist/eden-media-modal';
import Modal from '@churchofjesuschrist/eden-tool-modal';
import Actionable from '@churchofjesuschrist/eden-tag';
import { Label } from '@churchofjesuschrist/eden-form-parts';
import { Warn } from '@churchofjesuschrist/eden-banner';
import { Secondary } from '@churchofjesuschrist/eden-buttons';

// components
// import { all_caps } from 'src/utils/componentFunctions/beautify-words';
import { UnitBadge } from 'src/components/UnitBadge';
import { Thumbnail, FlexRowStart, FlexChildFix } from 'src/components/ComponentStyles/div';
import InContextHelp from 'src/components/InContextHelp/InContextHelp';
import { usePageContext } from 'src/contexts/PageContext';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

// functions
import { formattedDisplayName, formatDate } from '../../utils/AppFunctions/appFunctions';

// constants
import { StoryHeader, AuthorLine, DateLine, PublishedDate, Document, VisibleUntilLine } from './DisplayStory.style';
import { ReadingWidth, Line, Box } from 'src/utils/appCSS/appStyles';
import { goodColor, badColor } from 'src/components/ExamplePages/exampleConstants';

import { STORY_STATUSES } from 'src/utils/appConstants/appConstants';
import { allCaps } from 'src/utils/componentFunctions/beautify-words';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { Story } from 'src/models/Story';
import { useSelector } from 'react-redux';
import { selectUnitOrgs } from 'src/reducers/unitsReducer';

const { PENDING_STATUS, PUBLISHED_STATUS } = STORY_STATUSES;
const DisplayStory = ({ story, example, good, toolTipMessages }: { story: Story, example?, good?, toolTipMessages?}) => {
  const { t } = useTranslation('strings');
  const { localeCode } = usePageContext();
  const permissions = useUserPermissions();
  const [modalView, setModalView] = useState(false);
  const [sensitiveModalOpen, setSensitiveModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const fullOrgList = useSelector(selectUnitOrgs);

  const {
    title,
    dateStart,
    body,
    images = [],
    attachments = [],
    published,
    status,
    unit,
    sensitive,
    sensitiveComments
  } = story;

  const organizations = story.organizations?.map(o => fullOrgList.find(f => f.uuid === o.uuid)) ?? [];

  const authors = useMemo(() =>
    story.authors?.reduce(
      (acc, curr, i) =>
        story.authors.length === 1 || i === 0
          ? `${acc} ${formattedDisplayName(curr.name)}`
          : story.authors.length === 2 || i === story.authors.length - 1
            ? `${acc} ${t('andLabel')} ${formattedDisplayName(curr.name)}`
            : `${acc}, ${formattedDisplayName(curr.name)}`,
      t('byLabel')
    ) || '', [story]);

  const viewableUntil = useMemo(() => {
    const today = new Date();
    const datePublished = new Date(published);

    const dateExpires = new Date(datePublished)
    dateExpires.setFullYear(dateExpires.getFullYear() + 2);

    const expirationInDays = (dateExpires.getTime() - today.getTime()) / (1000 * 3600 * 24);
    const expirationInMonths = dateExpires.getMonth() - today.getMonth() + 12 * (dateExpires.getFullYear() - today.getFullYear())

    if (expirationInDays <= 0) {
      return '';
    }
    if (expirationInDays <= 31) {
      return t('storyVisibleUntilDaysLabel', { days: Math.ceil(expirationInDays).toString() });
    } else if (expirationInMonths <= 3) {
      return t('storyVisibleUntilMonthsLabel', { months: Math.ceil(expirationInMonths).toString() });
    }
    return '';
  }, [published, t]);

  let previewImages = useMemo(() => images?.filter(i => i.preview) || [], [images]);
  const modalImages = useMemo(() => images?.filter(i => !i.preview) || [], [images]);

  if (!previewImages?.length) {
    previewImages = modalImages
  }

  const previewIndex = useMemo(() => {
    let index = 0;
    if (previewImages?.length) {
      const previewImage = previewImages[imageIndex];
      index =
        previewImage?.preview // is preview
          ? modalImages.findIndex(i => previewImage.preview) // get original
          : modalImages.findIndex(i => i.alt === previewImage.alt); // get self
    }
    return index;
  }, [previewImages, imageIndex, modalImages]);

  const bodyJsx = htmlToJsx(body ?? '');

  if (example && Array.isArray(bodyJsx)) {
    const foundIndex = bodyJsx.findIndex((elt) => ReactDOMServer.renderToString(elt).includes('${toolTip3}'));
    if (foundIndex > -1) {
      const bodyToolTip3 = (
        <InContextHelp key={foundIndex} index={3} iconColor={good ? goodColor : badColor}>
          <Text4>
            {toolTipMessages[3]}
          </Text4>
        </InContextHelp>
      );
      bodyJsx[foundIndex] = bodyToolTip3;
    };
  }

  return (
    <>
      {modalView && modalImages?.length > 0 && (
        <MediaModal
          initialItemIndex={imageIndex ?? previewIndex ?? 0}
          onClose={() => setModalView(false)}
        >
          {modalImages?.map((image, index) => (
            <ModalImage key={index} src={image.sources?.[0]} />
          ))}
        </MediaModal>
      )}
      <StoryHeader id="StoryHeader">
        {sensitive && permissions.canApprove &&
          <Row>
            <Warn
              title={t('spcReviewRequested')}
              action={<Secondary small onClick={() => setSensitiveModalOpen(true)}>{t('viewBtnLabel', { ns: 'labels' })}</Secondary>}
            />
          </Row>
        }
        <div>
          <UnitBadge unit={unit}></UnitBadge>
        </div>
        <Row>
          <H1 data-test-id="displayStoryTitle">{title}</H1>
          {example && toolTipMessages?.length && (
            <div style={{ alignSelf: 'center', marginLeft: '0px' }}>
              <InContextHelp index={0} iconColor={good ? goodColor : badColor}>
                <Text4>
                  {toolTipMessages[0]}
                </Text4>
              </InContextHelp>
            </div>
          )}
        </Row>
        <AuthorLine>{authors || ''}</AuthorLine>
        <DateLine>{dateStart ? formatDate(dateStart, localeCode) : ''}</DateLine>
        <PublishedDate>
          {status === PUBLISHED_STATUS
            ? `${t('publishedOnLabel')} ${formatDate(published, localeCode)} ${t('atLabel')} ${format(
              new Date(published),
              'hh:mm a'
            )}`
            : status === PENDING_STATUS
              ? published
                ? `${t('awaitingApprovalInReworkLabel')} ${formatDate(published, localeCode)
                } at ${format(new Date(published), 'hh:mm a')}`
                : `${t('awaitingApprovalLabel')} ${t('notPublishedLabel')}`
              : t('notPublishedLabel')}
        </PublishedDate>
        {status === PUBLISHED_STATUS && viewableUntil !== '' &&
          <VisibleUntilLine>{viewableUntil}</VisibleUntilLine>
        }

        <Row gapSize="16">
          {organizations?.map((org, orgIndex) => (
            <Actionable key={orgIndex}>{allCaps(org?.name ?? ' ')}</Actionable>
          ))}

          {example && toolTipMessages?.length > 1 && (
            // <div style={{ alignSelf: 'center', marginLeft: '0px' }}>
            <InContextHelp index={1} iconColor={good ? goodColor : badColor}>
              <Text4>
                {toolTipMessages[1]}
              </Text4>
            </InContextHelp>
            // </div>
          )}
        </Row>

        <Line paddingTop="0" marginTop={spacing32} />
      </StoryHeader>

      <Box pb={24}>
        {images?.length > 0 && (
          <>
            {example && toolTipMessages?.length > 2 && (
              <InContextHelp index={2} iconColor={good ? goodColor : badColor}>
                <Text4>
                  {toolTipMessages[2]}
                </Text4>
              </InContextHelp>
            )}

            <FlexRowStart>
              <FlexChildFix>
                {previewImages?.map((image, index) => (
                  <Thumbnail
                    key={index}
                    onClick={() => {
                      setModalView(true);
                      setImageIndex(index);
                    }}
                  >
                    <Image
                      key={index}
                      data-test-id={`image${index}`}
                      alt={image.alt}
                      src={image.sources?.[0]}
                    />
                  </Thumbnail>
                ))}
              </FlexChildFix>
            </FlexRowStart>
          </>
        )}

        {/* example && toolTipMessages?.length > 3
          ? (
            <>
              <Space top={spacing16} />
              <InContextHelp index={3} iconColor={good ? goodColor : badColor}>
                <Text4>
                  {toolTipMessages[3]}
                </Text4>
              </InContextHelp>
            </>
          )
          : (
            <Space top={spacing32} />
          ) */}
        <ReadingWidth>
          <Text1>{bodyJsx}</Text1>
        </ReadingWidth>

        {attachments?.length > 0 && (
          <>
            <Space top={spacing64} />
            <Row>
              <H3 style={{ marginBottom: spacing8 }}>{t('documentsLabel')}</H3>
              {example && toolTipMessages?.length > 4 && (
                <div style={{ marginLeft: '0px' }}>
                  <InContextHelp index={3} iconColor={good ? goodColor : badColor}>
                    <Text4>
                      {toolTipMessages[4]}
                    </Text4>
                  </InContextHelp>
                </div>
              )}
            </Row>

            <FlexRowStart>
              {attachments?.map((doc, index) => (
                <Space key={index} end={spacing16}>
                  <a
                    href={doc.source}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test-id={`doc${index}`}
                  >
                    <Document key={index}>
                      <Title5>
                        <Format /> {doc.name ?? t('untitledDocumentLabel')}
                      </Title5>
                    </Document>
                  </a>
                </Space>
              ))}
            </FlexRowStart>
          </>
        )}
      </Box>
      <Modal active={sensitiveModalOpen} heading={t('spcReviewRequested')} onCloseGesture={() => setSensitiveModalOpen(false)}>
        <Box p={24}>
          <Label>
            {t('sensitiveInfoComments')}
          </Label>
          <Box p={16}>
            <Text5>{sensitiveComments ?? ''}</Text5>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DisplayStory;
