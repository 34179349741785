import React from 'react';
import { spacing32 } from '@churchofjesuschrist/eden-style-constants';
import Row from '@churchofjesuschrist/eden-row';
import { Line } from 'src/utils/appCSS/appStyles';
import styled from 'styled-components';
import _ from 'lodash';
import { ShimmerTitle, ShimmerSubtitle, ShimmerMeta, ShimmerRow, ShimmerButton } from './Shimmer';
import { flexColumn } from '../ComponentStyles/div';

const StoryHeader = styled(flexColumn)`
  width: 100%;
  min-height: 250px;
  padding: 32px;
  padding-top: 64px;
  padding-left: 0;
`;
const ShimmerContent = styled.div`
  margin-top: 1em;
  height: 4em;
  width: 75%;
`;

export const StoryEditShimmer = () =>
  <div>
    <StoryHeader>
      <ShimmerTitle />
      <ShimmerSubtitle />
      <ShimmerMeta />
    </StoryHeader>
    <Row>
      <ShimmerButton />
      <ShimmerButton />
      <ShimmerButton />
    </Row>
    <Line paddingTop={'0'} marginTop={spacing32} />
    {_.range(8).map(i => (
      <div key={i}>
        <Row>
          <ShimmerContent>
            <ShimmerRow />
          </ShimmerContent>
        </Row>
        <Row>
          <ShimmerMeta />
        </Row>
      </div>
    ))}
  </div>;
