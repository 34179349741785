import styled from 'styled-components';

import Row from '@churchofjesuschrist/eden-row';
import { grey15, spacing16 } from '@churchofjesuschrist/eden-style-constants';

import { flexRow } from 'src/components/ComponentStyles/div';
import { H2 as Heading2 } from 'src/components/ComponentStyles/elements';

export const Container = styled.div`
  min-width: 450px;
  padding: 10px;
  overflow-y: auto;
  @media (max-width: 450px) {
    min-width: 300px;
  }
`;

export const TitleRow = styled(flexRow)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid ${grey15};
  justify-content: space-between;
  min-height: 48px;
`;

export const Title = styled(Heading2)`
  font-size: 20px;
  font-weight: bold;
`;

export const CloseModal = styled.div`
  cursor: pointer;
`;

export const Auxiliary = styled.div`
  border-bottom: 1px solid ${grey15};
`;

export const ScrollContent = styled.div`
  position: relative;
  overflow: scroll;
  height: 386px;
  border-bottom: 2px solid ${grey15};
`;

export const WardCouncilMembers = styled.div`
  padding: 10px 0;
  font-size: 18px;
  border-bottom: 2px solid ${grey15};
  font-weight: bold;
`;

export const ButtonContainer = styled(Row)`
  padding-top: ${spacing16};
`;
