import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import DisplayStory from '../../components/View/DisplayStory';
import PreviewButtonBar from '../../components/ButtonBars/PreviewButtonBar';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_VIEW } from 'src/utils/analytics';
import { pageNames, STORY_STATUSES } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { withStory } from 'src/contexts/StoryContext';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectStory } from 'src/reducers/storiesReducer';
import { useQuery } from '@apollo/client';
import { setUnitOrgs } from 'src/reducers/unitsReducer';
import { getOrganizationsQuery } from 'src/utils/graphQL_Queries/appQueries';

const { PENDING_STATUS, PUBLISHED_STATUS, CREATED_STATUS } = STORY_STATUSES;
const { viewStoryPageName, homePageName } = pageNames;

const View = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation('strings');
  const story = useSelector(selectStory);
  const params = new URLSearchParams(window.location.search);
  const prevPageName = params.get('page');

  const { activeUnitObj, canApprove, user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: viewStoryPageName, // always use English
        activePage: viewStoryPageName, // used to set primary category
        breadCrumbs: [homePageName, viewStoryPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_VIEW,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  useQuery(getOrganizationsQuery, {
    onCompleted(data) {
      dispatch(setUnitOrgs(data?.organizations));
    },
  });

  const handleShowBtnCheck = (prevPageName, status) => {
    return (
      prevPageName !== homePageName &&
      !(!canApprove && [PENDING_STATUS, PUBLISHED_STATUS, CREATED_STATUS].includes(status))
    );
  };

  const showButtonBar =
    handleShowBtnCheck(prevPageName, story.status);

  return (
    <>
      {showButtonBar &&
        <PreviewButtonBar
          index={0}
          activeUnitObj={activeUnitObj}
          currentPageName={prevPageName}
          story={story}
        />}

      <DisplayStory story={story} />

      {showButtonBar && (
        <PreviewButtonBar
          index={1}
          activeUnitObj={activeUnitObj}
          currentPageName={prevPageName}
          story={story}
        />
      )}
    </>
  );
}

export default compose(withStory, withPage({ pageName: viewStoryPageName, pageTitle: 'viewStoryPageTitle' }))(View) as (otherProps: any) => JSX.Element;
