import React from 'react';

// Eden
import Row from '@churchofjesuschrist/eden-row';

// components
import TotalStorySorted from 'src/components/SortAndSearchBar/TotalStorySorted';
// import OrganizationDropDownSort from "src/components/DropDownCards/OrganizationDropDownSort";
import SelectOrganizations from 'src/components/Organizations/SelectOrganizations';
import SortingStoryDropDown from 'src/components/DropDownCards/SortingStoryDropDown';

// styles
import { SortStoryBarContainer } from 'src/components/SortAndSearchBar/SortAndSearchStyles';

// functions
import { addStoriesVariables, selectStoriesVariables, selectTotal } from 'src/reducers/storiesReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnitOrgs } from 'src/reducers/unitsReducer';

const SortStoryBar = () => {
  const dispatch = useDispatch();
  const totalStories = useSelector(selectTotal);
  const variables = useSelector(selectStoriesVariables);
  const unitOrgs = useSelector(selectUnitOrgs);
  const { organizations, filterFavorites } = variables; // currently active organizations and units

  const handleOrgChange = (newOrgList: any[], newFilterFavorites: boolean) => {
    dispatch(addStoriesVariables({ organizations: newOrgList, filterFavorites: newFilterFavorites }));
  }
  const handleSortChange = (properties = [] as any[], orderBy = '') => {
    dispatch(addStoriesVariables({ direction: orderBy, properties }));
  }

  // get list of organizations to display based on currently active units
  const fullOrgList = unitOrgs;

  return (
    <SortStoryBarContainer>
      <Row gapSize="16">
        <TotalStorySorted totalStories={totalStories} />

        <SelectOrganizations
          filterFavorites={filterFavorites} // bool to determine if favorites are currently selected
          fullOrgList={fullOrgList} // full list of organizations for the currently selected unit
          preSelected={organizations} // currently selected organizations
          handleOrgChange={handleOrgChange} // boost state up to DisplayStories
        />

        <SortingStoryDropDown
          handleSortChange={handleSortChange}
          variables={variables}
        />
      </Row>
    </SortStoryBarContainer>
  );
}

export default SortStoryBar;
