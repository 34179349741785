import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import MainStories from '../DisplayStories/MainStories';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

// constants
import { pageNameType } from '../../utils/appConstants/propTypesConstants';
import { useSelector } from 'react-redux';
import { selectStoriesLoading, selectStoriesError, selectStoriesLoadingCount } from 'src/reducers/storiesReducer';
import { StoriesShimmer } from '../Shimmer/CardShimmer';
import { selectUserLoading } from 'src/reducers/authReducer';

const DisplayStories = ({ pageName }) => {
  const userLoading = useSelector(selectUserLoading);
  const loading = useSelector(selectStoriesLoading);
  const loadingCount = useSelector(selectStoriesLoadingCount);
  const error = useSelector(selectStoriesError);

  const { t } = useTranslation();

  if ((loading || userLoading) && loadingCount === 0) {
    return <StoriesShimmer displayCreate={window.location.pathname === '/'} />; // only show if first time loading
  }
  if (error) {
    return (
      <ErrorModal
        title={t('retrieveStoryErrorMessage')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />
    );
  }

  return (
    <MainStories
      pageName={pageName}
    />
  )
};

export default DisplayStories;

DisplayStories.propTypes = {
  pageName: pageNameType
};
