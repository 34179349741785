import styled from 'styled-components';
import {
  sans as zoram,
  fontSize16,
  info40,
  grey15,
  white,
  spacing8,
  spacing16,
  lineHeightTight,
} from '@churchofjesuschrist/eden-style-constants';

export const TabsContainer = styled.div`
  border-bottom: 1px solid ${grey15};
  font-family: ${zoram};
  font-size: ${fontSize16};
  line-height: ${lineHeightTight};
  z-index: 1;
  cursor: pointer;
`;

export const TabItem = styled.div<{ isActive: boolean }>`
  background: white;
  border-color: ${props =>
    props.isActive
      ? `${info40} ${grey15} ${white} ${grey15}`
      : `${white} ${white} ${grey15} ${white}`};
  border-style: solid;
  border-width: ${props => (props.isActive ? '3px 2px 0px 2px' : '0px 0px 1px 0px')};
  padding: ${spacing8} ${spacing16};
  position: relative;
  top: 1px;
`;
