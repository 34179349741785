import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { IUpload, IUploadComplete } from 'src/models/Upload';
import * as UploadService from '../services/upload';

/** Async thunk reducer for uploading file chunk. */
export const uploadChunk = createAsyncThunk<string, IUpload>('upload/chunk',
  async ({ file, name, offset, complete, uploadId, ...otherProps }, { rejectWithValue }) => {
    try {
      const result = await UploadService.uploadChunkForm(file, name, offset, complete, uploadId, otherProps);
      console.info(result);
      return result;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err?.message ?? err);
    }
  }
);
/** Async thunk reducer for sending upload complete message. */
export const uploadComplete = createAsyncThunk<string, IUploadComplete>('upload/complete',
  async ({ fileNames, email }, { rejectWithValue }) => {
    try {
      const result = await UploadService.uploadComplete(fileNames, email);
      console.info(result);
      return result;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err?.message ?? err);
    }
  }
);

const { actions, reducer } = createSlice({
  name: 'upload',
  initialState: {
    uploading: false,
    uploadComplete: false,
    uploadedChunk: false,
    uploadError: '' as string | undefined, // (undefined as any) as SerializedError | undefined,
    uploadId: '',
    uploadFile: '',
    uploadPercent: 0,
    batchUploading: false,
    sendingCompleteEmail: false,
    completeEmailSent: false,
    sendingCompleteEmailError: ''
  },
  reducers: {
    startBatch(state, payload) {
      state.batchUploading = true;
    },
    endBatch(state, payload) {
      state.batchUploading = false;
    },
    setUploadPercent(state, { payload }) {
      state.uploadPercent = payload.percent;
      state.uploadFile = payload.name;
    },
    setUploadComplete(state, { payload }) {
      state.uploadComplete = payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(uploadChunk.pending, (state, { meta, payload }) => {
        state.uploading = true;
        state.uploadComplete = false;
        state.uploadedChunk = false;
        state.uploadError = undefined;
        state.uploadId = meta.arg.uploadId;
      })
      .addCase(uploadChunk.fulfilled, (state, { meta, payload }) => {
        if (meta.arg.complete) {
          state.uploading = false;
          state.uploadComplete = true;
          state.uploadedChunk = true;
          state.uploadId = '';
        } else {
          state.uploading = true;
          state.uploadId = payload;
          state.uploadedChunk = true;
        }
      })
      .addCase(uploadChunk.rejected, (state, _) => {
        state.uploading = false;
        state.uploadComplete = false;
        state.uploadedChunk = false;
        state.uploadId = '';
        state.uploadError = 'An error occurred uploading the file.';
      })
      .addCase(uploadComplete.pending, (state, _) => {
        state.sendingCompleteEmail = true;
        state.completeEmailSent = false;
        state.sendingCompleteEmailError = '';
      })
      .addCase(uploadComplete.fulfilled, (state, _) => {
        state.sendingCompleteEmail = false;
        state.completeEmailSent = true;
      })
      .addCase(uploadComplete.rejected, (state, { payload }) => {
        state.sendingCompleteEmail = false;
        state.completeEmailSent = false;
        state.sendingCompleteEmailError = payload as string;
      })
});

export const { startBatch, endBatch, setUploadPercent, setUploadComplete } = actions;
export const selectUploadId = state => state.upload.uploadId as string;
export const selectUploading = state => state.upload.uploading as boolean;
export const selectUploadComplete = state => state.upload.uploadComplete as boolean;
export const selectUploadedChunk = state => state.upload.uploadedChunk as boolean;
export const selectUploadError = state => state.upload.uploadError as string; // SerializedError;
export const selectUploadPercent = state => state.upload.uploadPercent as number;
export const selectUploadFile = state => state.upload.uploadFile as string;
export const selectUploadCompleteEmail = state => ({
  sent: state.upload.completeEmailSent as boolean,
  error: state.upload.sendingCompleteEmailError as SerializedError,
  sending: state.upload.sendingCompleteEmail as boolean,
});

export default reducer
