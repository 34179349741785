import React from 'react';
import { array, number, object, func } from 'prop-types';

// eden

// components
import { UnitBadge } from '../../UnitBadge';
import { usePageContext } from 'src/contexts/PageContext';
import { FavoriteButton } from '../../Buttons/FavoriteButtons';
import { pageNames } from 'src/utils/appConstants/appConstants';

// styles
import { VerticalTileWrapper } from './index.style';

// functions
import { getMainImage } from 'src/utils/AppFunctions/formFunctions';
import { formattedDisplayName, formatDate } from 'src/utils/AppFunctions/appFunctions';

// constants
import {
  pageNameType,
} from 'src/utils/appConstants/propTypesConstants';
import { setViewStoryUrl } from 'src/utils/AppFunctions/setUrls';

// utils
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/authReducer';
import { StyledCompactCard } from 'src/utils/appCSS/appStyles';

const { favoritesPageName, exampleStoryInPanel } = pageNames;

const StoryCard = ({ currentPageName, index, story, togglePanel }: { story, currentPageName?, index?, activeUnitObj?, updateStoryFavorites?, togglePanel?}) => {
  const history = useHistory();
  const { localeCode } = usePageContext();
  const { favorites } = useSelector(selectUser);
  const isFavorite = favorites.includes(story.id)

  if (!story) {
    return null;
  }

  const { id } = story;
  const mainImageSource = getMainImage(story);

  if (currentPageName === favoritesPageName && !isFavorite) {
    return null;
  }

  const clickHandler = (currentPageName === exampleStoryInPanel && togglePanel) ? () => togglePanel(story.id) : () => history.push(setViewStoryUrl(id, currentPageName)); // Note: examples page doesn't have activeUnitObj

  return (
    <VerticalTileWrapper className={favorites ? 'hasFavorites' : ''}>
      <StyledCompactCard
        onClick={clickHandler}
        pretitle={formatDate(story.dateStart, localeCode, 'd MMMM yyyy')}
        title={story.title}
        description={story.authors?.map(author => formattedDisplayName(author.name)).join(', ')}
        meta={' '} // per Steve Rogers
        image={{
          src: mainImageSource,
        }}
        lightRays={!mainImageSource}
        mediaAspectRatio="8:5"
      />
      <FavoriteButton
        storyId={id}
        hasImage={mainImageSource}
        favorites={favorites ?? []}
        index={index}
      />
      <UnitBadge unit={story.unit}></UnitBadge>
    </VerticalTileWrapper>
  );
};

export default StoryCard;

StoryCard.propTypes = {
  currentPageName: pageNameType,
  favorites: array,
  index: number,
  story: object,
  togglePanel: func
};
