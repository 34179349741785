import React from 'react';
import { bool, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Eden
import { Text3 } from '@churchofjesuschrist/eden-text';

// Components
import { ViewStoryBtn } from '../../Buttons/ViewStoryBtn';
import ErrorModal from '../../Modals/ErrorModal/ErrorModal';
import InContextHelp from '../../InContextHelp/InContextHelp';
import StoryContent from './StoryContent';
import { getStoryManagerQuery } from '../../../utils/graphQL_Queries/appQueries';
import { pendingStoryVarPropType } from '../../../utils/appConstants/propTypesConstants';
import { LockedByMessage } from '../../Modals/StoryLocked/StoryLocked';
import { usePageContext } from 'src/contexts/PageContext';

// styles
import {
  StoryContainer,
  LabelContainer,
  InfoContainer,
  DateContainer,
  Buttons,
} from './DisplayUnpublishedStories.style';

// functions
import { formatDate } from 'src/utils/AppFunctions/appFunctions';

// constants
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { TabStoriesShimmer } from 'src/components/Shimmer/CardShimmer';
import { IStoryManagerResults } from 'src/models/Story';

const PendingStories = ({ canViewPreview, currentPageName, storyVariables }: { canViewPreview, currentPageName, storyVariables }) => {
  const { t } = useTranslation('strings');
  const { localeCode } = usePageContext();
  const { activeUnitObj, user, ...permissions } = useUserPermissions();
  const { data, error, loading } = useQuery<IStoryManagerResults>(getStoryManagerQuery, {
    fetchPolicy: 'network-only',
    variables: storyVariables,
    displayName: 'pendingStories-Query'
  });

  if (loading) return <TabStoriesShimmer />;
  if (error ?? !data) {
    return (
      <ErrorModal
        currentPageName={currentPageName}
        title={t('retrieveStoryErrorMessage')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />
    );
  }

  if (!loading && data.storyManager.total === 0) {
    return (
      <StoryContainer data-test-id="unpublished_story_container0">
        {!canViewPreview && <Text3>{t('noStoriesMyStoriesSubmittedLabel')}</Text3>}
        {canViewPreview && <Text3>{t('noStoriesManagePendingLabel')}</Text3>}
      </StoryContainer>
    );
  }

  return (
    <>
      {data.storyManager.content.map((story, index) => {
        const { status, id, created, submitted, modified = [], flagForReview, sensitive } = story;

        return (
          <StoryContainer key={id} data-test-id={`unpublished_story_container${index}`}>
            <StoryContent
              currentPageName={currentPageName}
              index={index}
              status={status}
              story={story}
              title={canViewPreview ? status : t('submittedTabLabel')}
            />

            <InfoContainer data-test-id="pending_info_container">
              <DateContainer>
                <LabelContainer>{t('dateCreatedLabel')}: </LabelContainer>
                <Text3>{formatDate(created, localeCode)}</Text3>
              </DateContainer>
              <DateContainer>
                <LabelContainer>
                  {submitted ? t('dateSubmittedLabel') : t('dateModifiedLabel')}:{' '}
                </LabelContainer>
                <Text3>{formatDate(submitted ?? modified, localeCode)}</Text3>
              </DateContainer>
              {permissions.canApprove &&
                <DateContainer>
                  <LabelContainer>
                    {t('spcReviewRequested')}:
                  </LabelContainer>
                  <Text3>&nbsp;{flagForReview ?? sensitive ? t('yesAffirm') : t('noAffirm')}</Text3>
                </DateContainer>
              }
              <Buttons isMobile={false}>
                <ViewStoryBtn
                  currentPageName={currentPageName}
                  index={index}
                  storyId={story.id}
                  activeUnitObj={activeUnitObj}
                />

                {permissions.canApprove && (
                  <>
                    {!!story.lock && (
                      <InContextHelp
                        // label={t('disabledButtonsLabel')}
                        index={index}
                      >
                        <p style={{ zIndex: 101 }}>
                          <LockedByMessage lockObj={story.lock} />
                          {t('storyLockedMessage')}
                        </p>
                      </InContextHelp>
                    )}
                  </>
                )}
              </Buttons>
            </InfoContainer>
          </StoryContainer>
        );
      })}
    </>
  );
}

export default PendingStories;

(PendingStories as any).propTypes = {
  canViewPreview: bool.isRequired,
  currentPageName: string.isRequired,
  storyVariables: pendingStoryVarPropType
};
