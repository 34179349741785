import { configureStore } from '@reduxjs/toolkit';

import monitorReducersEnhancer from '../reducers/monitorReducer';
// import loggerMiddleware from '../middleware/loggerMiddleware';
import { reducers } from '../reducers';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: reducers,
  preloadedState: {},
  // middleware: (defaultware) => [loggerMiddleware, ...defaultware()],
  enhancers: [monitorReducersEnhancer],
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('../reducers', () => store.replaceReducer(reducers));
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
