import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnregisterCallback } from 'history';
import { useHistory } from 'react-router-dom';

/**
 * Hook that provides a route change and window close bound message.
 * @param initialize begin form with bound message.
 * @returns unload (removes bound message) and loadChanges (loads bound message due to changes).
 */
const useFormRouteChange = (initialize?: boolean) => {
  const { t } = useTranslation();
  const [changesLoaded, setChangesLoaded] = useState(false);
  const [unlisten, setUnlisten] = useState<UnregisterCallback>(() => { });
  const { listen } = useHistory();

  const beforeUnload = useCallback(
    (e) => {
      // Cancel routeChange event by erroring
      // See https://github.com/zeit/next.js/issues/2476
      const result = window.confirm(t('leaving_msg', { ns: 'strings' }));
      if (result) return;
      throw new Error('routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.');
    },
    [t]
  );

  useEffect(() => {
    let unlisten: UnregisterCallback = () => { }; // local to avoid dep loop
    if (initialize) {
      // Load form with bound message
      window.onbeforeunload = () => {
        return 'ok';
      };
      unlisten = listen(beforeUnload);
      setUnlisten(unlisten);
    }
    return () => {
      window.onbeforeunload = null;
      unlisten?.();
    };
  }, [beforeUnload, initialize, listen]);

  /** Removes bound window and route change message. */
  const unload = () => {
    window.onbeforeunload = null;
    unlisten?.();
  };

  /** Binds window and route change message. */
  const loadChanges = () => {
    if (changesLoaded) return;

    window.onbeforeunload = () => {
      return 'ok';
    };
    setUnlisten(listen(beforeUnload));
    setChangesLoaded(true);
  };

  return {
    /** Binds window and route change message. */
    loadChanges,
    /** Removes bound window and route change message. */
    unload,
  };
};

export default useFormRouteChange;
