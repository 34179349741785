import styled from 'styled-components';

export const ShimmerBox = styled.div`
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1000px 100%;
  animation: shimmer 2.2s linear infinite forwards;
`;
export const ShimmerRow = styled(ShimmerBox)`
  width: 100%;
  height: 24px;
  padding-bottom: 12px;
`;
export const ShimmerImage = styled(ShimmerBox)`
  height: 250px;
  padding-bottom: 12px;
`;
export const ShimmerTitle = styled(ShimmerBox)`
  height: 32px;
  width: 100px;
  margin-bottom: 12px;
`;
export const ShimmerSubtitle = styled(ShimmerBox)`
  height: 28px;
  width: 150px;
  margin-bottom: 12px;
`;
export const ShimmerMeta = styled(ShimmerBox)`
  height: 24px;
  width: 125px;
`;
export const ShimmerButton = styled(ShimmerBox)`
  height: 40px;
  width: 75px;
`;
