import isBrowser from 'is-in-browser';

const fetchWrapper = (originalFetch: (url: string, options) => Promise<any>) => async (url: string, options = {}) => {
  if (!url || url.includes('_next') || url.includes('node_modules') || !url.includes('api') || url.includes('_configuration')) {
    return await originalFetch(url, options);
  }
  let response: Response = {} as any;

  try {
    response = await originalFetch(
      url,
      {
        ...options,
        redirect: 'error'
      });

    // const { status } = response;

    if (response.ok) {
      return response;
    } else if ((response.status === 400 || response.status === 401) && isBrowser) {
      // location.reload();
      // eslint-disable-next-line no-console
      console.log('Error in fetchWrapper, response: ', response);
      return response;
    } else {
      throw new Error(`Network fetch response was not OK, with status: ${response.status}`);
    }
  } catch (err) {
    if ((err as any).message === 'Failed to fetch') {
      // if (!url.includes('api/user')) {
      //   const link = new URL(window.location.href);
      //   const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
      //   window.location.assign(`${link.protocol}//${link.host}/security/logon?returnUrl=${encodeURIComponent(returnUrl)}`);
      // }
      throw new Error('Failed to fetch');
    }
    // 403 Forbidden = not authorized
    // Other errors that can not be handled by attempting relogin.
    // TODO in your project, handle the error - forward to an error page?
    console.log('Error in fetchWrapper -->', err); // eslint-disable-line no-console
    // Documentation:
    // Object.keys(err).map((elt, i) => console.log(`[fetch errors]: ${i} ${elt[i]}`)); // for debugging
  }

  return response;
};

export default fetchWrapper;
