import React, { useEffect } from 'react';
import { object, func } from 'prop-types';

// eden components
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Text4 } from '@churchofjesuschrist/eden-text';
import Snackbar from '@churchofjesuschrist/eden-snackbar';

// context
import { useNotificationContext } from 'src/contexts/NotificationContext';

// constants
import {
  MESSAGE_DURATION,
  MESSAGE_SUCCESS,
  MESSAGE_WARN,
  MESSAGE_ERROR,
  MESSAGE_INFO,
} from 'src/utils/appConstants/appConstants';

const Message = ({ notification, onCompletion }) => {
  const { id, title, message, messageType } = notification;

  useEffect(() => {
    const notificationTimerId = setTimeout(() => {
      handleCompletion();
    }, MESSAGE_DURATION);

    return () => {
      // remove timer if the user clicked the close button before the timer is done
      notificationTimerId && clearTimeout(notificationTimerId);
    };
  }, []);

  const handleCompletion = () => {
    onCompletion(id); // remove message from queue
  };

  const messageTypes = {
    [MESSAGE_SUCCESS]: 'success',
    [MESSAGE_WARN]: 'warn',
    [MESSAGE_ERROR]: 'error',
    [MESSAGE_INFO]: 'info',
  };

  const alertType = messageTypes[messageType] || messageTypes[MESSAGE_INFO];

  return (
    <Snackbar alertType={alertType}>
      <H4>{title}</H4>
      <Text4>{message}</Text4>
    </Snackbar>
  );
};

export const NotificationManager = ({ notification }) => {
  const { delMessage } = useNotificationContext();
  return (
    <Message notification={notification} onCompletion={delMessage} />
  )
};

export default NotificationManager;

Message.propTypes = {
  notification: object.isRequired,
  onCompletion: func,
};

NotificationManager.propTypes = {
  notification: object,
};
