import React from 'react';
import { useTranslation } from 'react-i18next';

// Eden
import Split from '@churchofjesuschrist/eden-split';
import { H2, H3, H4, H5 } from '@churchofjesuschrist/eden-headings';
import { Unordered, Item } from '@churchofjesuschrist/eden-list';
import { Table, THead, TBody, TR, TH, TD } from '@churchofjesuschrist/eden-table';
import { Done } from '@churchofjesuschrist/eden-icons';
import {
  spacing24,
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// components
import QuickStartTOC from './QuickStartTOC';
import { QuickStartWrapper } from '../../utils/appCSS/appStyles';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

// Utilities
import { getStakeCallingsWithAuthorPermissions, getWardCallingsWithAuthorPermissions } from 'src/utils/AppFunctions/doctrineResources';

const { helpRolesAndCallingsPageName } = pageNames;

export const RolesAndCallings = ({ match }: any) => {
  const { t } = useTranslation();
  const stakeCallings = getStakeCallingsWithAuthorPermissions(t);
  const wardCallings = getWardCallingsWithAuthorPermissions(t);

  return (
    <Split ratio="1/4">
      <aside>
        <QuickStartTOC activePage={helpRolesAndCallingsPageName} />
      </aside>

      <main>
        <QuickStartWrapper>
          <H2 id="roles-and-callings">{t('rolesAndCallings')}</H2>
          <Space top={spacing32} />
          <H3>{t('rolesInTheUHTool')}</H3>
          <p />
          <p>{t('rolesInTheUHToolP1')}</p>

          <Table striped verticalRuled stickyHeader spacing="condensed">
            <THead>
              <TR>
                <TH />
                <TH>{t('viewStories')}</TH>
                <TH>{t('createStories')}</TH>
                <TH>{t('approveStories')}</TH>
                <TH>{t('sendInvitesLabel')}</TH>
              </TR>
            </THead>
            <TBody>
              <TR>
                <TD>
                  <H5>{t('approvers')}</H5>
                  <p><em>{t('approversText')}</em></p>
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
              </TR>
              <TR>
                <TD>
                  <H5>{t('authors')}</H5>
                  <p><em>{t('authorsText')}</em></p>
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD />
                <TD />
              </TR>
              <TR>
                <TD>
                  <H5>{t('viewers')}</H5>
                  <p><em>{t('viewersText')}</em></p>
                </TD>
                <TD>
                  <Done size={spacing24} />
                </TD>
                <TD />
                <TD />
                <TD />
              </TR>
            </TBody>
          </Table>
          <Space top={spacing32} />

          <H3>{t('callingsWithAuthorPermissions')}</H3>
          <p />
          <H4>{t('stakeLabel')}</H4>
          <Unordered>
            {stakeCallings.map((calling, index) => (
              <Item key={index}>
                {calling}
              </Item>
            ))}
          </Unordered>
          <br />

          <H4>{t('wardLabel')}</H4>
          <Unordered>
            {wardCallings.map((calling, index) => (
              <Item key={index}>
                {calling}
              </Item>
            ))}
          </Unordered>
          <Space top={spacing32} />
          <H4>{t('customCallingsLabel')}</H4>
          <p>{t('customCallingsP1')}</p>
          <p>{t('customCallingsP2')}</p>
          <br />
        </QuickStartWrapper>
      </main>
    </Split>
  );
}

export default withPage({ pageName: helpRolesAndCallingsPageName, headTitle: 'quickStartRolesAndCallings' })(RolesAndCallings);
