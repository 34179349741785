import React, { useEffect, useState } from 'react';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

// Eden
import { FileInput } from '@churchofjesuschrist/eden-form-parts';
import { Drawer, Handle, Body } from '@churchofjesuschrist/eden-drawer';

// Components
import LoadingSpinner from '../../../utils/componentFunctions/loading-spinner';
import InContextHelp from '../../InContextHelp/InContextHelp';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import { RemoveContentModal } from '../../Modals/RemoveContent/RemoveContent';
import { DrawerTitleWithWarning } from './DrawerTitle';
import SuccessToast from 'src/components/Modals/Submitting/SuccessToast';
import ContentPermissionModal from '../../Modals/Permissions/ImagePermissionModal';

// queries
import { addAttachmentsMutation, deleteAttachmentsMutation } from '../../../utils/graphQL_Queries/appMutations';

// styles
import { Line, FloatRight } from '../../../utils/appCSS/appStyles';

// functions
import { setAddAttachmentVariables } from '../../../utils/AppFunctions/formFunctions';

const AttachmentDrawer = ({ story, handleFinish }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [added, setAdded] = useState<any[]>([]);
  const [removed, setRemoved] = useState<any[]>([]);
  const [uploaded, setUploaded] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isDrawDocumentOpen, setIsDrawDocumentOpen] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [addVariables, setAddVariables] = useState<{ attachments?: File[], storyId?: any }>({});
  const { t } = useTranslation('strings');

  const [addAttachments, { loading: addLoading, error: addError }] = useMutation(addAttachmentsMutation);
  const [deleteMutation, { loading: deleteLoading, error: deleteError }] = useMutation(deleteAttachmentsMutation);

  const loading = addLoading || deleteLoading;
  const error = addError ?? deleteError;

  const handleInputChange = (event) => {
    const input = event.target;
    const inputFiles = input.value; // Note: the Eden control does NOT preserve the Azure blob id
    // debugging
    // console.info('handleInputChange, story.attachments: ', story.attachments);
    // console.info('handleInputChange, files (state): ', files);
    // console.info('handleInputChange, inputFiles: ', inputFiles);
    const added = inputFiles.filter((ui) => !story.attachments?.find((attachment) => ui.name === attachment.name));
    const removed = story.attachments?.find((attachment) => !inputFiles.find((ui) => ui.name === attachment.name));

    const toRemove: any[] = [];
    // get matched attachments
    if (removed) {
      toRemove.push(removed);
    }

    // debugging
    // console.info('AttachmentDrawer:');
    // console.table([
    //   {
    //     isAdding: !!added.length,
    //     isRemoving: !!toRemove.length,
    //   },
    // ]);
    // console.info('to be added: ', added);
    // console.info('to be removed: ', toRemove);

    setIsAdding(!!added.length);
    setIsRemoving(!!toRemove.length);
    setFiles(inputFiles);
    setAdded(added); // Note: doesn't contain new Azure blob id
    setRemoved(toRemove);
  };

  const initialize = () => {
    const fileList = story.attachments?.map(({ source, name, id, size }) => source || size // Note: size exists if the attachment was just added (but neither source, nor id exists)
      ? ({
        id,
        name,
        type: 'application/pdf',
        base64String: 'use the file object instead',
        file: new File([source], name, { type: 'application/pdf' }) // Note: following pattern in convertToFileInputs
      })
      : null).filter((x) => x);

    setIsAdding(false);
    setIsRemoving(false);
    setFiles(fileList);
    setIsInitialized(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!isAdding) return;
    const load = async () => {
      const results = await setAddAttachmentVariables(story.id, added);
      setAddVariables(results);
    }

    load();
  }, [added, isAdding]);

  const confirmAdd = async (add) => {
    if (!add) {
      initialize();
    } else {
      setIsAdding(false);
      setAdded([]);
    }
  };

  const confirmRemove = async (remove) => {
    if (!remove) {
      initialize();
    } else {
      await deleteMutation({
        variables: { contentIds: removed.map(r => r?.id ?? `${story.id}/${r.name}`), storyId: story.id }
      });

      // update parent form
      handleFinish({
        files,
        isAdding,
        isDrawDocumentOpen,
        isInitialized,
        isRemoving,
        added,
        removed
      });
      setIsRemoving(false);
      setRemoved([]);
      setDeleted(true);
    }
  };

  const handleAdd = async (add: boolean) => {
    if (!add) initialize();
    else if (addVariables.attachments?.length) {
      // Uploading multiple at the same time results in one or more files being null.
      // Upload one-by-one to make sure each file gets uploaded.
      // This approach seems to work fine for now, loop back if time allows.
      await Promise.all(addVariables.attachments.map(async (attachment) =>
        await addAttachments({
          variables: {
            storyId: addVariables.storyId,
            attachments: [attachment]
          },
          onCompleted: (data) => {
            const newFiles = files.slice(); // shallow copy of state
            const addedItems = data?.addAttachments ?? [];

            addedItems.forEach((itemObj) => {
              // const filename = itemObj.name; // filename (doesn't work if the filename has a space in it)
              const newId = itemObj.id; // contains Azure blob ID. e.g. 'storyId/filename'
              const idParts = newId.split('/'); // split into Story ID / filename
              const filename = idParts[1]; // filename
              const findIndex = newFiles.findIndex(fileObj => fileObj.name === filename); // find it in state

              if (findIndex > -1) {
                const updatedFile = { ...newFiles[findIndex], id: newId }; // file to be updated with new Azure blob id
                newFiles.splice(findIndex, 1, updatedFile); // update copy of state
                setFiles(newFiles);
              }
            });

            // debugging
            // console.info('handleAdd, newFiles: ', newFiles);

            // update parent form
            handleFinish({
              files: newFiles,
              isAdding,
              isDrawDocumentOpen,
              isInitialized,
              isRemoving,
              added,
              removed
            });
          }
        })
      ));

      setUploaded(true);
    }
  }

  useEffect(() => {
    if (!loading && isAdding) {
      confirmAdd(true);
    }
  }, [loading]);

  return (
    <Drawer open={isDrawDocumentOpen}>
      <Handle
        onClick={() => {
          setIsDrawDocumentOpen(!isDrawDocumentOpen);
        }}
      >
        <FloatRight>
          <InContextHelp index={7}>
            {t('calloutDocumentsText')}
          </InContextHelp>
        </FloatRight>
        <DrawerTitleWithWarning
          title={t('formDrawerLabelDocuments')}
          warning={t('documentContentWarningLabel')}
        />
      </Handle>
      <Body>
        <Line />
        {((!isInitialized || isAdding || isRemoving || loading) && !error) && <LoadingSpinner />}
        <FileInput
          name="files"
          data-test-id='pdf-file-upload'
          accept='application/pdf'
          onChange={handleInputChange}
          isDroppable
          multiple
          value={files}
          disabled={(!isInitialized || isAdding || isRemoving || loading)}
        >
          {t('addDocumentBtnLabel', { ns: 'labels' })}
        </FileInput>

        <ContentPermissionModal isActive={isAdding} handleFinish={handleAdd} />
        <RemoveContentModal
          isActive={isRemoving}
          title={t('removeAttachmentTitleLabel')}
          message={t('removeAttachmentMessage')}
          handleFinish={confirmRemove}
        />
        <SuccessToast
          active={uploaded}
          title={t('uploadSuccessLabel')}
          message={t('uploadSuccessMsg')}
          onClose={() => setUploaded(false)}
        />
        <SuccessToast
          active={deleted}
          title={t('removeFileSuccess')}
          message={t('removeFileSuccessMsg')}
          onClose={() => setDeleted(false)}
        />
        {error &&
          <ErrorModal
            isActive={!!error}
            title={''}
            errors={error}
            onRetry={() => { }}
          />
        }
      </Body>
    </Drawer>
  );
};

export default AttachmentDrawer;

AttachmentDrawer.propTypes = {
  story: object,
  handleFinish: func,
};
