import React, { useRef, useState, useCallback, useEffect } from 'react';
import { bool, func, string, object } from 'prop-types';
import styled from 'styled-components';

// eden
import Modal from '@churchofjesuschrist/eden-platform-modal';
import Row from '@churchofjesuschrist/eden-row';
import Stack from '@churchofjesuschrist/eden-stack';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { TextArea } from '@churchofjesuschrist/eden-form-parts';
// import { RichTextEditor, convertContentToHtml } from "@churchofjesuschrist/eden-rich-text-editor";

// components
import SendMessageButton from 'src/components/Buttons/SendMessageButton';

// styles
import { Line } from 'src/utils/appCSS/appStyles';
import { flexRow } from 'src/components/ComponentStyles/div';
import {
  ModalContainer as ModalContainerBase,
  GroupSplit,
} from 'src/components/Feedback/FeedbackFormStyles';
import { CharacterCounter } from 'src/components/Create/Form/Form.style';

// constants
import { MOOD_REWORK } from 'src/utils/appConstants/appConstants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/authReducer';

// functions
// import { remove_HTML_Tags } from "src/utils/AppFunctions/appFunctions";

const ModalContainer = styled(ModalContainerBase)`
  width: 600px;
  border-left: none;
`;

const Group = styled(flexRow)`
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const textLimit = 1000;

const ReworkModal = ({
  handleClose,
  handleCompletion,
  mood,
  isActive = false,
  story
}) => {
  const [content, setContent] = useState('');
  const [contentLength, setContentLength] = useState(0);
  const [formStatus, setFormStatus] = useState('unknown');
  const user = useSelector(selectUser);
  const { t } = useTranslation('strings');

  const closeModal = () => {
    handleClose(); // handle close from calling component as well
  };

  // Use this one for RichTextEditor
  // const handleChange = useCallback(newContent => {
  const handleChange = useCallback(event => {
    const newContent = event.target.value;
    // Use this one for RichTextEditor
    // const newLength = remove_HTML_Tags(convertContentToHtml(newContent)).length;
    setContent(newContent);
    setContentLength(newContent.length);
    // setFormStatus(newLength > 0 && newLength <= textLimit ? "success" : "error");
  }, []);

  const onCompletion = () => {
    // const html = convertContentToHtml(content);
    // console.log("html: ", html);
    closeModal();
    handleCompletion(content); // from calling Component
  };

  useEffect(() => {
    if (['error', 'unknown'].includes(formStatus)) {
      contentLength > 0 && contentLength <= textLimit && setFormStatus('success');
    } else {
      contentLength > textLimit && setFormStatus('error');
      contentLength === 0 && setFormStatus('unknown'); // reset
    }
  }, [contentLength, formStatus]);

  const textareaRef = useRef<any>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  // adjust the buttons, labels, etc. based on the "mood" of the component

  const moodRework = mood === MOOD_REWORK;
  const modalTitle = moodRework ? t('returnForReworkTitle') : t('returnForReworkTitle');
  const emailSubject = moodRework ? t('returnForReworkEmailSubject') : t('returnForReworkEmailSubject');
  const primaryButtonLabel = moodRework ? t('returnStoryBtnLabel') : t('returnStoryBtnLabel');

  return (
    <>
      <Modal active={isActive} onCloseGesture={closeModal}>
        <ModalContainer>
          <Group>
            <H4>{modalTitle}</H4>
          </Group>
          <Line />
          <Stack>
            <span>
              <span>{t('returnForReworkMessage1')}</span>
              <strong>
                {story.authors
                  .map(
                    author =>
                      `${author.name} ${author.position ? '(' + t(author.position) + ')' : ''}`
                  )
                  .join(', ')}
              </strong>
              <span>{t('returnForReworkMessage2')}</span>
            </span>
            <div style={{ fontStyle: ' italic' }}>{t('dearAuthorsLabel')},</div>
            <TextArea
              data-test-id="rework-email-textarea"
              maxLength={textLimit.toString()}
              validityState={formStatus}
              ref={textareaRef}
              pattern="^[A-Z].*"
              placeholder={t('reworkEmailMessagePlaceholder')}
              onChange={handleChange}
            />
            {/* <RichTextEditor
              id="rework-email-editor"
              ref={editorRef}
              availableControls={["bold", "italic", "underline", "blockquote"]}
              placeholder={"Your message about changes needed..."}
              onChange={handleChange}
            /> */}

            <GroupSplit>
              {/* Left */}
              <Text4>
                {t('thankYouLabel')},<br />
                {user.name}
              </Text4>

              {/* Right */}
              <CharacterCounter
                textLength={contentLength}
                textLimit={textLimit}
                sizeError={contentLength >= textLimit}
              />
            </GroupSplit>
            <Line />

            <Row>
              <SendMessageButton
                // message={convertContentToHtml(content)}
                message={content}
                handleCompletion={onCompletion}
                btnLabel={primaryButtonLabel}
                subject={emailSubject}
                story={story}
                disabled={formStatus !== 'success'}
                user={user}
              />

              <Secondary onClick={handleClose}>{t('cancelBtnLabel')}</Secondary>
            </Row>
          </Stack>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ReworkModal;

ReworkModal.propTypes = {
  isActive: bool.isRequired,
  handleClose: func.isRequired,
  handleCompletion: func.isRequired,
  mood: string.isRequired,
  story: object.isRequired,
};
