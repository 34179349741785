const beautify = (words: string) => {
  if (words) {
    try {
      return words
        .replace(/_/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    } catch (e) {
      console.warn(e);
      return words;
    }
  }
  return null;
};

export const capitalize = (word: string) => {
  if (!word) return null;
  try {
    const capLetter = word
      .slice()
      .trim()
      .charAt(0)
      .toUpperCase();
    const restOfWord = word
      .slice()
      .trim()
      .slice(1)
      .toLowerCase();
    return capLetter + restOfWord;
  } catch (e) {
    console.warn(e);
    return word;
  }
};

export const allCaps = (words: string) => {
  try {
    return words
      ? words
        .replace(/_/g, ' ')
        .split('')
        .map(letter => letter.toUpperCase())
        .join('')
        .trim()
      : null;
  } catch (e) {
    console.warn(e);
    return words;
  }
}

export default beautify;
