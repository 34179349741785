import React, { Fragment, useState, useEffect } from 'react';
import { number, object, bool, string, func } from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Eden
import { Secondary } from '@churchofjesuschrist/eden-buttons';

// Components
import PreviewModal from 'src/components/Modals/PreviewModal/PreviewModal';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';

// queries
import { setRefetchVariables } from 'src/utils/graphQL_Queries/queryVariableFormats';
import { saveStoryMutation } from 'src/utils/graphQL_Queries/appMutations';

// constants
import { User } from 'src/models/User';
import {
  pageNameType,
  userType,
} from 'src/utils/appConstants/propTypesConstants';

interface IProps { btnLabel?, index, story?, user?: User, handleSaveStory?, changesToSave?, disabled? };

const PreviewStoryButton = (props: IProps) => {
  const { btnLabel, index, story, user, handleSaveStory, changesToSave, disabled } = props;

  const [isActive, setIsActive] = useState(false);

  const refetchList = setRefetchVariables(user);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsActive(false);
  };

  const [saveStoryFunc, { loading, error: saveError }] = useMutation(saveStoryMutation, {
    refetchQueries: refetchList,
    // variables: { id: story.id }, // variables are set in handleSaveStory method in StoryForm
    awaitRefetchQueries: true,
    // onCompleted: () => setStorySaved(true),
  });

  useEffect(() => {
    if (isActive && changesToSave) {
      handleSaveStory(undefined, saveStoryFunc); // save the story
    }
  }, [isActive, changesToSave, handleSaveStory, saveStoryFunc]);

  if (loading) return <LoadingSpinner />;

  if (saveError) {
    return (
      <ErrorModal
        title={t('errorSavingStoryLabel')}
        onRetry={saveStoryFunc}
        errors={saveError}
        isActive
      />
    );
  }

  return (
    <Fragment>
      <Secondary
        onClick={() => setIsActive(true)}
        data-test-id={`previewBtn${index}`}
        disabled={disabled}
      >
        {btnLabel}
      </Secondary>

      {isActive && (
        <PreviewModal
          isActive={isActive}
          handleClose={handleClose}
          story={story}
        />
      )}
    </Fragment>
  );
};

export default PreviewStoryButton;

PreviewStoryButton.propTypes = {
  currentPageName: pageNameType,
  changesToSave: bool,
  story: object,
  user: userType,
  handleSaveStory: func,
  index: number.isRequired,
  handleFormValidation: func,
  disabled: bool,
  btnLabel: string,
};
