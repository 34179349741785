import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

// Eden
import Grid from '@churchofjesuschrist/eden-grid';
import Stack from '@churchofjesuschrist/eden-stack';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { Info } from '@churchofjesuschrist/eden-alert';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import {
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// Components
import Notification from '../components/NotificationManager/NotificationManager';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import { UnitDropDownSelector, UnitSelectorContainer } from '../components/SelectUnit/SelectUnit';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { HomeAddStoryCard } from 'src/components/Stories/StoryCard/ExampleStoryCard';

// reducers
import { selectUnits } from 'src/reducers/unitsReducer';
import { selectStoriesLoadingCount } from 'src/reducers/storiesReducer';

// styles
import { PageTitle, FloatRight, Box } from 'src/utils/appCSS/appStyles';
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

// queries
import { UPDATE_ACTIVE_UNIT } from 'src/utils/graphQL_Queries/appMutations';

// utilities, constants
import { NAV_STORIES } from 'src/utils/AppFunctions/setUrls';
import { addPageAnalytics, CATEGORY_TYPE_HOME } from '../utils/analytics';
import {
  pageNames,
  MESSAGE_INFO,
  MESSAGE_MIN_WIDTH,
  MESSAGE_MAX_WIDTH
} from '../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import WelcomeHelp from 'src/components/InContextHelp/WelcomeHelp';

const { homePageName } = pageNames;

const MultiUnitNotificationWrapper = styled.div`
  position: fixed;
  margin-top: 96px;
  min-width: ${MESSAGE_MIN_WIDTH};
  max-width: ${MESSAGE_MAX_WIDTH};
  z-index: 2;
  text-align: left;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Home = () => {
  const { t, i18n } = useTranslation('strings');
  const { activeUnitId, canCreate, user } = useUserPermissions();
  const [showAlt, setShowAlt] = React.useState(!canCreate);
  const units = useSelector(selectUnits);
  const loadingCount = useSelector(selectStoriesLoadingCount);
  const [cookies] = useCookies(['UH.wsv']);
  const showWelcome = !cookies['UH.wsv'];
  const router = useHistory();

  const [updateActiveUnit, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ACTIVE_UNIT);

  const handleUnitChange = async (newUnit: string) => {
    await updateActiveUnit({
      variables: {
        id: newUnit
      }
    });

    window.location.reload(); // reload with correct active unit
  };

  const handleClick = (url) => router.push(url);

  useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: homePageName, // always use English
        activePage: homePageName, // used to set primary category
        breadCrumbs: [homePageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_HOME,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <>
      {(updateLoading) && <LoadingSpinner noBackground={loadingCount > 0} />}

      {updateError &&
        <ErrorModal
          title={t('updateUnitErrorMessage')}
          onRetry={() => { }}
          errors={updateError}
          isActive
        />
      }
      <TitleRow>
        <PageTitle>{t('homePageTitleLabel')}</PageTitle>

        <UnitSelectorContainer>
          <div>
            {units?.length > 1 &&
              <React.Fragment>
                <FloatRight>
                  <UnitDropDownSelector
                    units={units}
                    activeUnitId={activeUnitId}
                    changeHandler={async (ev) => await handleUnitChange(ev.target.value)}
                    showId
                    showHelp
                  />
                </FloatRight>
                {/* multi-unit notification */}
                <MultiUnitNotificationWrapper id="multi-unit-notification-wrapper">
                  <Notification
                    notification={{
                      title: t('chooseUnitLabel'),
                      message: t('chooseUnitMessage'),
                      messageType: MESSAGE_INFO,
                    }}
                  />
                </MultiUnitNotificationWrapper>
              </React.Fragment>
            }
          </div>
        </UnitSelectorContainer>
      </TitleRow>

      {(showWelcome && canCreate)
        ? <WelcomeHelp />
        : showAlt && (
          <Box mb={20}>
            <Info onClose={() => setShowAlt(false)}>
              <Stack align="center">
                <H4>{t('welcome')}</H4>
                <Text4>
                  {/* {t('faqTheUnitHistoryToolA4')} */}
                  {t('welcome_description')}
                </Text4>
                <Secondary type="href" onClick={() => handleClick(NAV_STORIES)}>{t('viewStories')}</Secondary>
              </Stack>
            </Info>
          </Box>
        )}

      <Space top={spacing32} />
      <Grid gapSize="16" rowGapSize="16" min="275px">
        {canCreate && (
          <HomeAddStoryCard />
        )}
        <div style={{ height: '250px' }} />
        <div style={{ height: '250px' }} />
      </Grid>
    </>
  )
};

export default withPage({ pageName: homePageName })(Home);
