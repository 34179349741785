import React, { Fragment, useState, useContext } from 'react';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { object, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// eden
import { Secondary as SecondaryBase } from '@churchofjesuschrist/eden-buttons';
import { danger40, spacing16 } from '@churchofjesuschrist/eden-style-constants';

// components
import ReworkModal from 'src/components/Modals/ReworkModal/ReworkModal';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';

// queries
import { unpublishStoryMutation } from 'src/utils/graphQL_Queries/appMutations';
import { setManagerRefetchVariables } from 'src/utils/graphQL_Queries/queryVariableFormats';

// notification context
import NotificationContext from 'src/contexts/NotificationContext';

// constants
import { userType } from 'src/utils/appConstants/propTypesConstants';
import { MESSAGE_SUCCESS, MOOD_REWORK } from 'src/utils/appConstants/appConstants';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/authReducer';
import { useHistory } from 'react-router-dom';

const Secondary = styled(SecondaryBase)`
  /* border: 2px solid ${danger40}; */
  margin-left: ${props => (props.isMobile ? 0 : spacing16)};
  margin-top: ${props => (props.isMobile ? spacing16 : 0)};
  /* max-width: 125px; */
`;

export const ReturnForReworkButton = ({ story, disabled = false }) => {
  const history = useHistory();
  const user = useSelector(selectUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('strings');

  // Notification context
  const { addMessage } = useContext(NotificationContext);

  const handleCompletion = () => {
    setIsModalOpen(false);

    const successTitle = t('returnToReworkSuccessTitle');
    const successMessage = t('returnToReworkSuccessMessage');

    addMessage({
      title: successTitle,
      message: successMessage,
      messageType: MESSAGE_SUCCESS,
    });

    history.push('/admin/manage/PUBLISHED');
  };

  const errorMessage = t('saveErrorMessage');

  // adjust the buttons, labels, etc. based on the "mood" of the component
  const workflowMutation = unpublishStoryMutation;
  const dataTestId = 'unpublishBtnId';
  const workflowTransitionButtonLabel = t('returnForReworkBtnLabel', { ns: 'labels' });
  const refetchList = setManagerRefetchVariables(user);

  // console.table([{ autoTransition, working }]);

  return (
    <Mutation
      mutation={workflowMutation}
      refetchQueries={refetchList}
      variables={{ id: story.id }}
      onCompleted={handleCompletion}
    >
      {(workflowMutationFunc: MutationFunction<any, OperationVariables>, { error, loading }: MutationResult<any>) => {
        if (loading) return <LoadingSpinner />;
        if (error) {
          return (
            <ErrorModal
              title={errorMessage}
              onRetry={workflowMutationFunc}
              errors={error}
              isActive
            />
          );
        }

        return (
          <>
            <Secondary
              onClick={() => setIsModalOpen(true)}
              data-test-id={dataTestId}
              disabled={disabled}
            >
              {workflowTransitionButtonLabel}
            </Secondary>

            {isModalOpen &&
              <ReworkModal
                isActive
                handleClose={() => {
                  setIsModalOpen(false);
                }}
                handleCompletion={async (comments: string) => await workflowMutationFunc({
                  variables: {
                    id: story.id,
                    comments
                  }
                })}
                mood={MOOD_REWORK}
                story={story}
              />
            }
            {/* <ConfirmSubmissionModal
                isActive={isModalOpen}
                handleClose={() => {
                  setIsModalOpen(false);
                }}
                handleCompletion={workflowMutationFunc}
                unitType={activeUnitObj.type}
                mood={MOOD_REWORK}
                storyId={story.id}
              /> */}
          </>
        );
      }}
    </Mutation>
  );
};

export default ReturnForReworkButton;

ReturnForReworkButton.propTypes = {
  story: object.isRequired,
  user: userType,
  activeUnitObj: object,
  disabled: bool,
};
