import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, number } from 'prop-types';

// eden
import Stack from '@churchofjesuschrist/eden-stack';

// styles
import { Bold, A } from 'src/utils/appCSS/appStyles';

// functions
import { setFaqUrl } from 'src/utils/AppFunctions/setUrls';

// constants
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';

const PageSteps = ({ canApprove, unitType }: {canApprove: boolean, unitType: ldsxrm_unittypes}) => {
  const { t } = useTranslation('strings');

  return (
    <Fragment>
      <Stack>
        <section id="step1">{t('calloutStoryCreationStepsStep1', { ns: 'strings' })}</section>

        <section id="step2">
          {t('calloutStoryCreationStepsStep2a', { ns: 'strings' })}
          <Bold>{t('saveAsDraftBtnLabel', { ns: 'labels' })}</Bold>
          {t('calloutStoryCreationStepsStep2b', { ns: 'strings' })}
          <Bold>{canApprove ? t('approveAndPublishBtnLabel', { ns: 'labels' }) : t('submitBtnLabel', { ns: 'labels' })}</Bold>
          {t('calloutStoryCreationStepsStep2c', { ns: 'strings' })}
        </section>

        <section id="step3">
          {canApprove && unitType === ldsxrm_unittypes.Ward && t('calloutStoryCreationStepsStep3Approver', { ns: 'strings' })}
          {canApprove && unitType === ldsxrm_unittypes.Stake && t('calloutStoryCreationStepsStep3StakeApprover', { ns: 'strings' })}
          {!canApprove && unitType === ldsxrm_unittypes.Ward && t('calloutStoryCreationStepsStep3Creator', { ns: 'strings' })}
          {!canApprove && unitType === ldsxrm_unittypes.Stake && t('calloutStoryCreationStepsStep3StakeCreator', { ns: 'strings' })}
        </section>

        {canApprove
          ? null
          : (
            <section id="step4">
              {unitType === ldsxrm_unittypes.Ward && t('calloutStoryCreationStepsStep4', { ns: 'strings' })}
              {unitType === ldsxrm_unittypes.Stake && t('calloutStoryCreationStepsStep4Stake', { ns: 'strings' })}
            </section>
          )}

        <section id="notes">
          {t('calloutStoryCreationStepsStepNotes', { ns: 'strings' })}{' '}
          <A href={setFaqUrl(9, 2)} target="_self" rel="noopener noreferrer">
            {t('calloutStoryCreationStepsStepUrl', { ns: 'strings' })}
          </A>
        </section>
      </Stack>
    </Fragment>
  );
}

export default PageSteps;

PageSteps.propTypes = {
  canApprove: bool,
  unitType: number,
};
