import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';
import { number, string, func, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

// eden
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';

// components
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

// queries
import { saveStoryMutation } from 'src/utils/graphQL_Queries/appMutations';

// notification context
import NotificationContext from 'src/contexts/NotificationContext';

// constants
import { userType } from 'src/utils/appConstants/propTypesConstants';
import { pageNames, MESSAGE_SUCCESS, formValidityState } from 'src/utils/appConstants/appConstants';
import { setSaveStoryVariables } from 'src/utils/AppFunctions/formFunctions';
import { User } from 'src/models/User';
import _ from 'lodash';

const { validityStateErrorLabel } = formValidityState;

const { managePublishPageName, createPageName } = pageNames;

export const SaveStoryButton = ({
  currentPageName,
  user,
  index = 0,
  disabled = false,
  handleSaveStory,
  changesToSave,
  autoSave,
  // handleAutoSave,
  onSave,
  story
}: {
  currentPageName?
  user?: User
  index?
  disabled?
  handleSaveStory?
  changesToSave?
  autoSave?
  // handleAutoSave?,
  onSave
  story
}) => {
  // Note: the reason for a separate autoSave flag is because the props
  // autoSave flag turns off before the save mutation has completed.
  const [autoSaving, setAutoSaving] = useState(false);

  // Notification context
  const { addMessage } = useContext(NotificationContext);

  const { t } = useTranslation();

  const handleCompletion = () => {
    // console.table([{ autoSave, autoSaving }]);
    addMessage({
      title: autoSaving ? t('storyAutoSaveTitle') : t('storySaveDraftTitle'),
      message: autoSaving ? '' : t('storyDraftMessage'),
      messageType: MESSAGE_SUCCESS,
    });

    autoSaving && setAutoSaving(false);
  };

  const [saveStoryFunc, { loading, error }] = useMutation(saveStoryMutation, {
    onCompleted: handleCompletion
  });

  const handleAutoSave = useCallback(_.debounce(async (storyToUpdate, saveStoryFunc: (vars) => any) => {
    const lock = {
      accountId: user?.accountId ?? 0,
      name: user?.name ?? '',
      date: new Date(),
    };

    const storyToSave = { ...storyToUpdate, lock };

    // Note: starting 3/18/2020 setSaveStoryVariables preserves status and lock
    const saveStoryVars: any = setSaveStoryVariables(
      storyToSave
    );

    if (saveStoryVars === validityStateErrorLabel) {
      return null; // exit save story because there is a data problem
    }
    onSave();
    saveStoryFunc
      ? await saveStoryFunc({ variables: { ...saveStoryVars } })
      : {} as any;
  }, 1000), []);

  useEffect(() => {
    if (autoSave && changesToSave) {
      setAutoSaving(true); // turn on local autoSaving flag
      handleAutoSave(story, saveStoryFunc); // autoSave the story
    }
  }, [autoSave, changesToSave, story]);

  if (!autoSaving && loading) return <LoadingSpinner />;

  if (error) {
    return (
      <ErrorModal
        title={t('errorSavingStoryLabel')}
        onRetry={saveStoryFunc}
        errors={error}
        isActive
      />
    );
  }

  return (
    <Fragment>
      {currentPageName === createPageName
        ? (
          <Fragment>
            <Primary
              onClick={e => handleSaveStory(e, saveStoryFunc)}
              data-test-id={`saveStoryBtn${index}`}
              disabled={disabled}
            >
              {t('createContinueBtnLabel', { ns: 'labels' })}
            </Primary>
            <Secondary
              onClick={() => {
                window.history.go(-1);
              }}
              data-test-id={`cancelStoryBtn${index}`}
            >
              {t('createCancelBtnLabel', { ns: 'labels' })}
            </Secondary>
          </Fragment>
        )
        : (
          <React.Fragment>
            <Secondary
              onClick={e => handleSaveStory(e, saveStoryFunc)}
              data-test-id={`saveStoryBtn${index}`}
              disabled={disabled}
            >
              {currentPageName === managePublishPageName ? t('saveBtnLabel', { ns: 'labels' }) : t('saveAsDraftBtnLabel', { ns: 'labels' })}
            </Secondary>
          </React.Fragment>
        )}
    </Fragment>
  );
};

export default SaveStoryButton;

SaveStoryButton.propTypes = {
  currentPageName: string.isRequired,
  handleFormValidation: func.isRequired,
  handleSaveStory: func.isRequired,
  index: number.isRequired,
  user: userType,
  activeUnitObj: object,
  autoSave: bool,
  disabled: bool,
  changesToSave: bool,
};
