// A bar to go at the top of the page when we are proxying
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Warn } from '@churchofjesuschrist/eden-banner';
import { Secondary } from '@churchofjesuschrist/eden-buttons';

import { selectUser } from 'src/reducers/authReducer';

/**
 * A bar to go at the top of the page when we are proxying
 */

const ProxyBar = () => {
  const { t } = useTranslation();
  const proxy = useSelector(selectUser);

  const handleEndProxy = () => {
    window.location.href = '/security/endproxy';
  };

  return (
    <Warn
      description={proxy.name ? `${proxy.name} (${proxy.position}) - ${proxy.unit?.unitName}` : ''}
      title={t('proxyAs')}
      style={{ marginBottom: '2rem' }}
      action={
        <Secondary onClick={handleEndProxy}>{t('proxyEnd')}</Secondary>
      }
    />
  );
};

export default ProxyBar;
