import React from 'react';
import { number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Eden
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import { Edit } from '@churchofjesuschrist/eden-icons';
import { spacing4 } from '@churchofjesuschrist/eden-style-constants';

import { setEditStoryUrl } from 'src/utils/AppFunctions/setUrls';

// constants
import { pageNameType } from 'src/utils/appConstants/propTypesConstants';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

export const EditStoryButton = ({ id, currentPageName, index }: { id: string, currentPageName?, index? }) => {
  const { t } = useTranslation('strings');

  return (
    <Link to={setEditStoryUrl(id, currentPageName)}>
      <Secondary data-test-id={`editStoryBtnId${index}`}>
        <Space end={spacing4}>
          <Edit size="24px" />
        </Space>
        {t('editStoryLabel')}
      </Secondary>
    </Link>
  );
}

export default EditStoryButton;

(EditStoryButton as any).propTypes = {
  id: string.isRequired,
  currentPageName: pageNameType,
  index: number,
  activeUnitObj: object,
};
