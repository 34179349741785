import {
  setManageStoryUrl,
  setMyStoryUrl,
  setCreateStoryUrl,
  setEditStoryUrl,
  setInviteUrl,
  NAV_UPLOAD
} from 'src/utils/AppFunctions/setUrls';
// import { getPermissions } from 'src/components/User/permissions';
import { createBrowserHistory } from 'history';

import { STORY_STATUSES, pageNames } from 'src/utils/appConstants/appConstants';

export const history = createBrowserHistory();
const { DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, RETURNED_STATUS, PENDING_INVITE, INVITES_SENT } = STORY_STATUSES;
const {
  homePageName,
  manageDraftPageName,
  manageInvitesSentPageName,
  managePendingPageName,
  managePublishPageName,
  myStoriesDraftPageName,
  myStoriesPendingPageName,
  myStoriesPublishPageName,
  myStoriesReturnedPageName,
  myStoriesPendingInvitesPageName,
  viewStoryPageName,
  createPageName,
  editPageName,
  invitePageName,
  uploadPageName,
} = pageNames;

export const checkForWorkflowPageChange = (
  user,
  story,
  prevStory,
  currentPageName,
  prevPage
) => {
  if (!user) return '/';

  let newPage = '';

  switch (currentPageName) {
    case viewStoryPageName:
      if (prevStory.status === DRAFT_STATUS && story.status === PUBLISHED_STATUS) {
        newPage = getPageRedirect(homePageName);
      }

      if (prevStory.status === DRAFT_STATUS && story.status === PENDING_STATUS) {
        newPage = getPageRedirect(myStoriesPendingPageName);
      }

      if (prevStory.status === PENDING_STATUS && story.status === PUBLISHED_STATUS) {
        newPage = getPageRedirect(homePageName);
      }

      if (
        prevStory.status === PENDING_STATUS &&
        (story.status === DRAFT_STATUS || story.status === PUBLISHED_STATUS)
      ) {
        newPage = getPageRedirect(currentPageName);
      }
      break;

    case editPageName:
      if (prevStory.status === DRAFT_STATUS && story.status === PENDING_STATUS) {
        newPage = getPageRedirect(prevPage);
      }

      if (prevStory.status === DRAFT_STATUS && story.status === PUBLISHED_STATUS) {
        newPage = getPageRedirect(prevPage);
      }

      if (prevStory.status === PENDING_STATUS && story.status === PUBLISHED_STATUS) {
        newPage = getPageRedirect(prevPage);
      }

      if (
        prevStory.status === PUBLISHED_STATUS &&
        story.status === PUBLISHED_STATUS &&
        prevStory.published !== story.published
      ) {
        newPage = getPageRedirect(prevPage);
      }
      break;

    default:
      break;
  }

  return newPage;
};

export const getPageRedirect = (currentPageName, params = {} as { storyId? }, newPage = '/') => {
  const { storyId } = params;

  switch (currentPageName) {
    case manageDraftPageName:
      newPage = setManageStoryUrl(DRAFT_STATUS);
      break;

    case manageInvitesSentPageName:
      newPage = setManageStoryUrl(INVITES_SENT);
      break;

    case managePublishPageName:
      newPage = setManageStoryUrl(PUBLISHED_STATUS);
      break;

    case managePendingPageName:
      newPage = setManageStoryUrl(PENDING_STATUS);
      break;

    case myStoriesDraftPageName:
      newPage = setMyStoryUrl(DRAFT_STATUS);
      break;

    case myStoriesPendingPageName:
      newPage = setMyStoryUrl(PENDING_STATUS);
      break;

    case myStoriesPublishPageName:
      newPage = setMyStoryUrl(PUBLISHED_STATUS);
      break;

    case myStoriesReturnedPageName:
      newPage = setMyStoryUrl(RETURNED_STATUS);
      break;

    case myStoriesPendingInvitesPageName:
      newPage = setMyStoryUrl(PENDING_INVITE);
      break;

    case homePageName:
      newPage = '/';
      break;

    case createPageName:
      newPage = setCreateStoryUrl();
      break;

    case editPageName:
      newPage = setEditStoryUrl(storyId, myStoriesDraftPageName);
      break;

    case invitePageName:
      newPage = setInviteUrl();
      break;

    case uploadPageName:
      newPage = NAV_UPLOAD;
      break;

    default:
      break;
  }

  return newPage;
};
