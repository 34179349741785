import React, { useEffect } from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import Row from '@churchofjesuschrist/eden-row';
import Stack from '@churchofjesuschrist/eden-stack';
import { H2, H4 } from '@churchofjesuschrist/eden-headings';
import { CircleSelected } from '@churchofjesuschrist/eden-icons';
import { spacing16 } from '@churchofjesuschrist/eden-style-constants';

// Components
import StoryCard from '../../components/Stories/StoryCard/index';

// styles
import { PageTitle, ReadingWidth } from '../../utils/appCSS/appStyles';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { getLeadershipStories, getEventStories } from '../../utils/help/example-stories';
import { TileHeader } from 'src/components/ExamplePages/exampleConstants';
import { withPage } from 'src/contexts/PageContext';
import { setUnitOrgs } from 'src/reducers/unitsReducer';
import { UnitOrganization } from 'src/models/Unit';
import { useDispatch } from 'react-redux';

const { exampleStoriesPageName, exampleStoryInPanel } = pageNames;

const TileContainer = styled.div`
  width: 350px;
`;
const SectionTitleContainer = styled.div`
  margin-bottom: ${spacing16};
`;

const ExamplesPage = () => {
  const { t } = useTranslation('strings');

  const leadershipStories = getLeadershipStories(t);
  const leadershipGood1 = leadershipStories[0]; // leadershipStory2 (in the strings.json file)

  const eventStories = getEventStories(t);
  const eventGood1 = eventStories[0];

  return (
    <>
      <PageTitle>{t('exampleStoriesLabel')}</PageTitle>
      <Stack gapSize="32">
        <section>
          <ReadingWidth>{t('exampleStoriesSubLabel')}</ReadingWidth>
        </section>

        <section>
          <SectionTitleContainer>
            <H2>{t('leadershipReflectionsLabel')}</H2>
          </SectionTitleContainer>

          <TileContainer>
            <TileHeader good invert>
              <div style={{ width: '24px', height: '24px', margin: 'auto 0' }}>
                <CircleSelected size="24" />
              </div>
              <H4>&nbsp;{t('goodExampleLabel')}</H4>
            </TileHeader>

            <StoryCard
              key={leadershipGood1.id}
              currentPageName={exampleStoriesPageName}
              index={0}
              story={leadershipGood1 as any}
            />
          </TileContainer>
        </section>

        <section>
          <SectionTitleContainer>
            <H2>{t('meaningfulEventsLabel')}</H2>
          </SectionTitleContainer>

          <TileContainer>
            <TileHeader good invert>
              <div style={{ width: '24px', height: '24px', margin: 'auto 0' }}>
                <CircleSelected size="24" />
              </div>
              <H4>&nbsp;{t('goodExampleLabel')}</H4>
            </TileHeader>

            <StoryCard
              key={eventGood1.id}
              currentPageName={exampleStoriesPageName}
              index={0}
              story={eventGood1 as any}
            />
          </TileContainer>
        </section>
      </Stack>
    </>
  );
};

export const ExampleStoriesOnly = ({ onClick }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('strings');

  const leadershipStories = getLeadershipStories(t);
  const leadershipGood1 = leadershipStories[0]; // leadershipStory2 (in the strings.json file)

  const eventStories = getEventStories(t);
  const eventGood1 = eventStories[0];

  useEffect(() => {
    // Set example unit orgs
    dispatch(setUnitOrgs(leadershipGood1.organizations.concat(eventGood1.organizations).map(o => ({
      uuid: o.uuid,
      name: o.name
    } as UnitOrganization))));
  }, []);

  return (
    <Row gapSize="32">
      <TileContainer>
        <StoryCard
          key={leadershipGood1.id}
          currentPageName={exampleStoryInPanel}
          index={0}
          story={leadershipGood1 as any}
          togglePanel={onClick}
        />
      </TileContainer>

      <TileContainer>
        <StoryCard
          key={eventGood1.id}
          currentPageName={exampleStoryInPanel}
          index={0}
          story={eventGood1 as any}
          togglePanel={onClick}
        />
      </TileContainer>
    </Row>
  );
}

ExampleStoriesOnly.propTypes = {
  onClick: func.isRequired,
}

export default withPage({ pageName: exampleStoriesPageName, pageTitle: 'exampleStoriesPageTitle' })(ExamplesPage);
