import React, { useMemo, useState } from 'react';
import { string, object } from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// eden
import Row from '@churchofjesuschrist/eden-row';
import Badge from '@churchofjesuschrist/eden-badge';

// components
import PendingStories from '../Stories/DisplayUnpublishedStories/PendingStories';
import DraftStories from '../Stories/DisplayUnpublishedStories/DraftStories';
import ReturnedStories from '../Stories/DisplayUnpublishedStories/ReturnedStories';
import PublishedStories from '../Stories/DisplayUnpublishedStories/PublishedStories';
import InContextHelp from '../InContextHelp/InContextHelp';
import TabInfoHelp from '../InContextHelp/TabInfoHelp';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

// styles
import { TabsContainer, TabItem } from '../Tabs/TabStyles';
import { FloatRight } from '../../utils/appCSS/appStyles';

// constants
import { singleStatusType, userType } from '../../utils/appConstants/propTypesConstants';
import {
  orderByDirections,
  orderByProperties,
  STORY_STATUSES,
  pageNames,
  STORY_STATUS_TYPE
} from '../../utils/appConstants/appConstants';

import { getPageRedirect } from '../Stories/utils/RedirectPages';

// queries
import { getOrganizationsQuery, totalManagerStoriesCountQuery } from '../../utils/graphQL_Queries/appQueries';
import { setStoryByStatusQueryParams, setStoryByInviterQueryParams } from '../../utils/graphQL_Queries/queryVariableFormats';
import { Route, Switch, useHistory } from 'react-router-dom';
import PendingInviteStories from '../Stories/DisplayUnpublishedStories/PendingInviteStories';
import SendInvite from 'src/pages/SendInvite';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { setUnitOrgs } from 'src/reducers/unitsReducer';
import { useAppDispatch } from 'src/utils/appStore';

const { DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, RETURNED_STATUS, PENDING_INVITE, INVITE_TAB, INVITES_SENT } = STORY_STATUSES;
const { ORDER_BY_PUBLISHED, ORDER_BY_MODIFIED } = orderByProperties;
const { DESCENDING } = orderByDirections;

// available tabs for each page
const MYSTORIES_TABS = [DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, RETURNED_STATUS, PENDING_INVITE];
const MANAGE_TABS = [DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, INVITE_TAB, INVITES_SENT];
const DEFAULT_TAB = PUBLISHED_STATUS; // NOTE: this default tab must be in both of the 2 previous arrays

const {
  managePageName,
  manageDraftPageName,
  managePendingPageName,
  managePublishPageName,
  myStoriesDraftPageName,
  myStoriesPendingPageName,
  myStoriesPublishPageName,
  myStoriesReturnedPageName,
  myStoriesPendingInvitesPageName,
  invitePageName,
} = pageNames;

// set the query criteria
const setStoryCriteria = (tab: string, id: string | null = null, inviter: string | null = null) => {
  switch (tab?.toUpperCase()) {
    case PUBLISHED_STATUS:
      return setStoryByStatusQueryParams(
        id,
        PUBLISHED_STATUS,
        [ORDER_BY_PUBLISHED],
        DESCENDING
      );

    case PENDING_STATUS:
      return setStoryByStatusQueryParams(
        id,
        PENDING_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      );

    case RETURNED_STATUS:
      return setStoryByStatusQueryParams(
        id,
        RETURNED_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      );

    case PENDING_INVITE:
      return setStoryByStatusQueryParams(
        id,
        PENDING_INVITE,
        [ORDER_BY_MODIFIED],
        DESCENDING
      );

    case INVITES_SENT:
      return setStoryByInviterQueryParams(
        id,
        inviter,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      );

    case DRAFT_STATUS:
    default:
      return setStoryByStatusQueryParams(
        id,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      );
  }
};

// make sure tab is spelled correctly and available for this page
const tabCheck = (tab, page) => {
  const onManagePage = page === managePageName;
  const activeTabs = onManagePage ? MANAGE_TABS : MYSTORIES_TABS;
  const upperTab = tab.toUpperCase();

  return activeTabs.includes(upperTab) ? upperTab : DEFAULT_TAB;
};

// export TabContent =({ tabName, criteria, pageName, user, activeUnitObj }) => {
// this is the set of variables that the TabContent uses. State changes trigger the query to be re-run.
// const [variables, setVariables] = useState(criteria);
// const [activeTab, setActiveTab] = useState(activeTab);
// const [onManagePage, setOnManagePage] = useState(pageName === managePageName);
// }
export const TabContent = ({ tabName, pageName }) => {
  const { t } = useTranslation('strings');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user, activeUnitObj } = useUserPermissions();
  const [tab, setTab] = useState(tabName);
  const [storyVariables, setStoryVariables] = useState(setStoryCriteria(
    tabName,
    pageName === managePageName ? null : user?.id
  ));
  useQuery(getOrganizationsQuery, {
    onCompleted(data) {
      dispatch(setUnitOrgs(data?.organizations));
    },
  });

  // const handleTabClick = useCallback( tab => {
  const handleTabClick = (newTab: STORY_STATUS_TYPE | string) => {
    const onManagePage = pageName === managePageName;
    const author = onManagePage ? null : user?.id; // don't use author name to limit query unless we are on the My Stories page
    const inviter = onManagePage ? user?.id : null;

    let newPageTab = '';

    const newStoryVariables = setStoryCriteria(newTab, author, inviter);
    switch (newTab) {
      case PUBLISHED_STATUS:
        newPageTab = onManagePage ? managePublishPageName : myStoriesPublishPageName;
        break;

      case PENDING_STATUS:
        newPageTab = onManagePage ? managePendingPageName : myStoriesPendingPageName;
        break;

      case RETURNED_STATUS:
        if (onManagePage) {
          // Should not be on this tab if manage page
          handleTabClick(PENDING_STATUS);
          return;
        }
        newPageTab = myStoriesReturnedPageName;
        break;

      case PENDING_INVITE:
        if (onManagePage) {
          // Should not be on this tab if manage page
          handleTabClick(PENDING_STATUS);
          return;
        }
        newPageTab = myStoriesPendingInvitesPageName;
        break;

      case INVITE_TAB:
        if (!onManagePage) {
          handleTabClick(PENDING_STATUS);
          return
        }
        newPageTab = invitePageName;
        break;

      case DRAFT_STATUS:
      default:
        newPageTab = onManagePage ? manageDraftPageName : myStoriesDraftPageName;
        break;
    }

    setTab(newTab);

    setStoryVariables(newStoryVariables);
    // setVariables(variables);
    //, [variables, tab, setVariables]

    // console.log('TabContent, requesting page redirect to: ', newPageTab);
    history.push(getPageRedirect(newPageTab));
  };

  const onManagePage = pageName === managePageName;
  const tabs = onManagePage ? MANAGE_TABS : MYSTORIES_TABS; // available tabs for this page

  const activeTab = useMemo(() => tabCheck(tab, pageName), [tab, pageName]); // make sure tab is spelled correctly and available for this page
  const criteria = {
    author: onManagePage ? null : user?.id, // don't use author name to limit query unless we are on the My Stories page
    inviter: onManagePage ? user?.id : null,
    // units: user.roles[APPROVER].map(elt => elt.id), // in case the user has APPROVER rights in multiple units
  };

  // active tab
  const isPendingTabActive = activeTab === PENDING_STATUS;
  const isDraftTabActive = activeTab === DRAFT_STATUS;
  const isPublishedTabActive = activeTab === PUBLISHED_STATUS;
  const isReturnedTabActive = activeTab === RETURNED_STATUS;
  const isPendingInvitesTabActive = activeTab === PENDING_INVITE;
  const isInviteTabActive = activeTab === INVITE_TAB;

  // page name (needed by handlePageRedirect)
  const currentDraftPageName = onManagePage ? manageDraftPageName : myStoriesDraftPageName;
  const currentPendingPageName = onManagePage ? managePendingPageName : myStoriesPendingPageName;
  const currentPublishedPageName = onManagePage ? managePublishPageName : myStoriesPublishPageName;
  // page titles
  const currentDraftPageTitle = onManagePage ? t('tabTitleManageDrafts') : t('tabTitleMyStoriesDrafts');
  const currentPendingPageTitle = onManagePage ? t('tabTitleManagePending') : t('tabTitleMyStoriesPending');

  const { data, error } = useQuery(totalManagerStoriesCountQuery, {
    fetchPolicy: 'network-only',
    variables: criteria,
    displayName: 'get-stories-count-query'
  });

  return (
    <>
      {error && <ErrorModal
        title={t('errorGettingCountsLabel')}
        onRetry={() => window.location.reload()}
        errors={error}
        isActive
      />}

      <TabsContainer>
        <FloatRight>
          <InContextHelp index={1} label={t('calloutTabInfoLabel')}>
            <TabInfoHelp
              canApprove={user?.isApprover}
              pageName={pageName}
              unitType={activeUnitObj.unitType}
            />
          </InContextHelp>
        </FloatRight>

        <Row gapSize="0">
          {tabs.includes(DRAFT_STATUS) && (
            <TabItem
              isActive={isDraftTabActive}
              onClick={() => handleTabClick(DRAFT_STATUS)}
            >
              <Row gapSize="8" verticalAlign="center">
                <div>{currentDraftPageTitle}</div>
                <Badge color='greySoft'>
                  {data?.draftStories.total ? data.draftStories.total : ''}
                </Badge>
              </Row>
            </TabItem>
          )}

          {tabs.includes(PENDING_STATUS) && (
            <TabItem
              isActive={isPendingTabActive}
              onClick={() => handleTabClick(PENDING_STATUS)}
            >
              <Row gapSize="8" verticalAlign="center">
                <div>{currentPendingPageTitle}</div>
                {data?.pendingStories.total > 0 &&
                <Badge color={onManagePage ? 'red' : 'greySoft'}>
                  {onManagePage ? data?.pendingStories.total : data?.pendingStories?.total ? data?.pendingStories.total : ''}
                </Badge>
                }
              </Row>
            </TabItem>
          )}

          {tabs.includes(PUBLISHED_STATUS) && (
            <TabItem
              isActive={isPublishedTabActive}
              onClick={() => handleTabClick(PUBLISHED_STATUS)}
            >
              <Row gapSize="8" verticalAlign="center">
                <div>{t('tabTitlePublished')}</div>
                <Badge color='greySoft'>
                  {data?.publishedStories.total ? data.publishedStories.total : ''}
                </Badge>
              </Row>
            </TabItem>
          )}

          {isPendingInvitesTabActive || (tabs.includes(PENDING_INVITE) && data?.pendingInviteStories.total > 0)
            ? (
              <TabItem
                isActive={isPendingInvitesTabActive}
                onClick={() => handleTabClick(PENDING_INVITE)}
              >
                <Row gapSize="8" verticalAlign="center">
                  <div>{t('tabTitlePendingInvites')}</div>
                  {data?.pendingInviteStories.total > 0 &&
                    <Badge color={onManagePage ? 'red' : 'greySoft'}>
                      {data?.pendingInviteStories.total}
                    </Badge>
                  }
                </Row>
              </TabItem>
            )
            : <></>}

          {isReturnedTabActive || (tabs.includes(RETURNED_STATUS) && data?.returnedStories.total > 0)
            ? (
              <TabItem
                isActive={isReturnedTabActive}
                onClick={() => handleTabClick(RETURNED_STATUS)}
              >
                <Row gapSize="8" verticalAlign="center">
                  <div>{t('tabTitleReturned')}</div>
                  {data?.returnedStories.total > 0 &&
                  <Badge color={onManagePage ? 'red' : 'greySoft'}>
                    {data?.returnedStories.total}
                  </Badge>
                  }
                </Row>
              </TabItem>
            )
            : <></>}

          {tabs.includes(INVITE_TAB) && (
            <TabItem
              isActive={isInviteTabActive}
              onClick={() => handleTabClick(INVITE_TAB)}
            >
              <Row gapSize="8" verticalAlign="center">
                <div>{t('navSendInvite')}</div>
              </Row>
            </TabItem>
          )}
        </Row>
      </TabsContainer>

      <Switch>
        <Route exact path="/admin/manage/draft" render={(params) =>
          <DraftStories
            manageStories={onManagePage}
            currentPageName={currentDraftPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/manage/pending" render={(params) =>
          <PendingStories
            canViewPreview={onManagePage}
            currentPageName={currentPendingPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/manage/published" render={(params) =>
          <PublishedStories
            currentPageName={currentPublishedPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/manage/sendinvite" render={(params) =>
          <SendInvite />
        } />
      </Switch>
      <Switch>
        <Route exact path="/admin/my-stories/draft" render={(params) =>
          <DraftStories
            manageStories={onManagePage}
            currentPageName={currentDraftPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/my-stories/pending" render={(params) =>
          <PendingStories
            canViewPreview={onManagePage}
            currentPageName={currentPendingPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/my-stories/published" render={(params) =>
          <PublishedStories
            currentPageName={currentPublishedPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/my-stories/returned" render={(params) =>
          <ReturnedStories
            currentPageName={currentPublishedPageName}
            storyVariables={storyVariables}
          />
        } />
        <Route exact path="/admin/my-stories/pending_invite" render={(params) =>
          <PendingInviteStories
            currentPageName={currentPublishedPageName}
            storyVariables={storyVariables}
          />
        } />
      </Switch>
    </>
  );
}

export default TabContent;

TabContent.propTypes = {
  tabName: singleStatusType,
  // criteria: tabCountVariableType,
  pageName: string.isRequired,
  user: userType,
  activeUnitObj: object,
};
