import React, { Component, Fragment } from 'react';
import { number, node, bool } from 'prop-types';
import styled from 'styled-components';

const ToastContainer = styled.div<{ translate?: any, active?: boolean }>`
  opacity: ${props => (props.active ? '1' : '0')};
  position: fixed;
  bottom: 0px;
  right: 25px;
  transform: translateY(${props => props.translate});
  transition: all 1s ease-out;
  z-index: ${props => (props.active ? 2005 : null)};
  max-width: 530px;
`;

class Toast extends Component<{ active, timeoutTime? }> {
  notificationTimerId?: NodeJS.Timeout;
  state = {
    toastActive: true,
  };

  componentDidMount() {
    if (this.props.timeoutTime) {
      this.notificationTimerId = setTimeout(() => {
        this.setState({ toastActive: false });
      }, this.props.timeoutTime);
    }
  }

  componentWillUnmount() {
    this.notificationTimerId && clearTimeout(this.notificationTimerId);
  }

  render() {
    const { toastActive } = this.state;
    const { active, children, timeoutTime = 0 } = this.props;
    return (
      <Fragment>
        {timeoutTime > 0 && (
          <ToastContainer translate={active ? '-50px' : '100px'} active={toastActive}>
            {children}
          </ToastContainer>
        )}
        {timeoutTime === 0 && (
          <ToastContainer translate={active ? '-50px' : '100px'} active={active}>
            {children}
          </ToastContainer>
        )}
      </Fragment>
    );
  }
}

export default Toast;

(Toast as any).propTypes = {
  timeoutTime: number,
  active: bool,
  children: node,
};
