import React, { Fragment, useState, useEffect } from 'react';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { number, object, bool, string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import { Primary } from '@churchofjesuschrist/eden-buttons';

// components
import { ConfirmSubmissionModal } from 'src/components/Modals/Submitting/SubmissionModal';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import ConfirmPublishModal from '../Modals/Submitting/PublishConfirmation';

// funcitons
import { setSaveStoryVariables } from 'src/utils/AppFunctions/formFunctions';

// queries
import {
  submitStoryMutation,
  publishStoryMutation,
  saveStoryMutation,
} from 'src/utils/graphQL_Queries/appMutations';
import { setManagerRefetchVariables } from 'src/utils/graphQL_Queries/queryVariableFormats';

// notification context
import { useNotificationContext } from '../../contexts/NotificationContext';

// constants
import { userType, storyFormPropType } from 'src/utils/appConstants/propTypesConstants';
import {
  AUTO_TRANSITION,
  MOOD_SUBMIT,
  MOOD_PUBLISH,
  MESSAGE_SUCCESS,
} from 'src/utils/appConstants/appConstants';
import { User } from 'src/models/User';

interface IProps { index: number, mood?, story, user?: User, activeUnitObj?, disabled?, complete?, autoTransition? };

export const WorkflowTransitionButton = (props: IProps) => {
  const { index = 0, mood = '', story, user, activeUnitObj, disabled = false, complete, autoTransition } = props;

  const [state, setState] = useState({
    isConfirmModalOpen: false,
    working: false,
  });

  const { addMessage } = useNotificationContext();
  const { t } = useTranslation('strings');

  const handleCompletion = () => {
    setState((currState) => ({
      ...currState,
      isConfirmModalOpen: false,
    }));

    let successTitle = '';
    let successMessage = '';

    switch (mood) {
      case MOOD_SUBMIT:
        successTitle = t('submittedTabLabel', { ns: 'strings' });
        successMessage = t('submitSuccessMessage', { ns: 'strings' });
        break;

      case MOOD_PUBLISH:
        successTitle = t('publishSuccessTitle', { ns: 'strings' });
        successMessage = t('publishSuccessMessage', { ns: 'strings' });
        break;

      default:
        break;
    }

    // Add message to notification queue (if not in autoTransition mode)
    // !(autoTransition === AUTO_TRANSITION) &&
    addMessage({
      title: successTitle,
      message: successMessage,
      messageType: MESSAGE_SUCCESS,
    });

    complete?.();
  };

  const handleConfirmation = async (story, saveStoryMutationFunc, workflowMutationFunc) => {
    const unlockedStory = { ...story, lock: null }; // unlock

    await saveStoryMutationFunc({
      variables: setSaveStoryVariables(unlockedStory),
    });

    workflowMutationFunc();
  };

  useEffect(() => {
    // prevent multiple instances from running
    autoTransition && setState((currState) => ({ ...currState, working: true }));
  }, []);

  const { isConfirmModalOpen, working } = state;
  const errorMessage = t('saveErrorMessage');
  let workflowMutation;
  let dataTestId;
  let workflowTransitionButtonLabel;
  const refetchList = setManagerRefetchVariables(user);

  // adjust the buttons, labels, etc. based on the "mood" of the component
  switch (mood) {
    case MOOD_SUBMIT:
      workflowMutation = submitStoryMutation;
      dataTestId = `submitBtnId${index}`;
      workflowTransitionButtonLabel = t('submitBtnLabel', { ns: 'labels' });
      break;

    case MOOD_PUBLISH:
      workflowMutation = publishStoryMutation;
      dataTestId = `publishBtn${index}`;
      workflowTransitionButtonLabel = user?.isApprover
        ? t('approveAndPublishBtnLabel', { ns: 'labels' })
        : t('publishBtnLabel', { ns: 'labels' });
      break;

    default:
      break;
  }

  // console.table([{ autoTransition, working }]);

  return (
    <Mutation mutation={saveStoryMutation}>
      {(saveStoryMutationFunc: MutationFunction<any, OperationVariables>, { error, loading }: MutationResult<any>) => {
        if (loading) return <LoadingSpinner />;
        if (error) {
          return (
            <ErrorModal
              title={t('errorSavingStoryLabel', { ns: 'strings' })}
              onRetry={saveStoryMutationFunc}
              errors={error}
              isActive
            />
          );
        }

        return (
          <Mutation
            mutation={workflowMutation}
            refetchQueries={refetchList}
            variables={{ id: story.id }}
            onCompleted={handleCompletion}
          >
            {(workflowMutationFunc: MutationFunction<any, OperationVariables>, { error, loading }: MutationResult<any>) => {
              if (loading) return <LoadingSpinner />;
              if (error) {
                return (
                  <ErrorModal
                    title={errorMessage}
                    onRetry={workflowMutationFunc}
                    errors={error}
                    isActive
                  />
                );
              }

              // autoTransition && console.log("WorkflowTransitionButton, autoTransition active");
              autoTransition && !working && workflowMutationFunc(); // silently auto-invoke the transition (once). No UI. Ignore any errors.

              // Note: this handles when autoTransition is set or undefined
              return autoTransition === AUTO_TRANSITION
                ? null
                : (
                  <Fragment>
                    <Primary
                      onClick={() => {
                        setState((currState) => ({ ...currState, isConfirmModalOpen: true }));
                      }}
                      data-test-id={dataTestId}
                      disabled={disabled}
                    >
                      {workflowTransitionButtonLabel}
                    </Primary>

                    {mood === MOOD_PUBLISH
                      ? <ConfirmPublishModal
                        isActive={isConfirmModalOpen}
                        handleClose={() => {
                          setState((currState) => ({ ...currState, isConfirmModalOpen: false }));
                        }}
                        // Note: all story variables are validated in <StoryForm>
                        // handleCompletion={e => handleSaveStory(e, saveStoryMutationFunc)}
                        handleCompletion={async e => await handleConfirmation(story, saveStoryMutationFunc, workflowMutationFunc)}
                        unitType={activeUnitObj.type}
                        user={user}
                      />
                      : <ConfirmSubmissionModal
                        isActive={isConfirmModalOpen}
                        handleClose={() => {
                          setState((currState) => ({ ...currState, isConfirmModalOpen: false }));
                        }}
                        handleCompletion={async () =>
                          await handleConfirmation(story, saveStoryMutationFunc, workflowMutationFunc)
                        }
                        unitType={activeUnitObj.type}
                        story={story}
                        mood={mood}
                      />
                    }
                  </Fragment>
                );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

export default WorkflowTransitionButton;

WorkflowTransitionButton.propTypes = {
  index: number.isRequired,
  autoTransition: string,
  story: storyFormPropType.isRequired,
  user: userType,
  activeUnitObj: object,
  mood: string,
  disabled: bool,
  handleAutoSave: func,
  complete: func,
};
