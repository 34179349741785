import styled from 'styled-components';

import { white, info40, spacing16, fontSize13 } from '@churchofjesuschrist/eden-style-constants';

import { MCard, flexRow } from 'src/components/ComponentStyles/div';

export const DisplayStoryContainer = styled.div`
  width: 100%;
  padding-top: ${spacing16};
`;

export const A = styled.a`
  text-decoration: none;
`;

export const AddCard = styled(MCard)`
  padding: ${spacing16};
  margin: 0 0 0 0;
  font-size: ${fontSize13};
  background-color: ${info40};
  height: 100%;
  border: none;
`;

export const AddBox = styled(flexRow)`
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  border: 1px ${white} dashed;
  border-radius: 6px;
  padding: ${spacing16};
`;

export const AddButton = styled(flexRow)`
  justify-content: center;
  align-items: center;
  border: 2px ${white} solid;
  background-color: ${info40};
  border-radius: 3px;
`;

export const AddText = styled.div`
  color: ${white};
  padding-left: ${spacing16};
  font-size: 24px;
`;
