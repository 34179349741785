import { Route, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

// functions
import { useSelector } from 'react-redux';
import { selectUserLoading, selectUser, fetchUser, selectAuthenticated } from 'src/reducers/authReducer';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import NotAvailable from 'src/components/notAvailable';
import { useAppDispatch } from 'src/utils/appStore';
import { AppError } from 'src/pages/AppError';

export const Authorize = (props: { children: JSX.Element, suspense?: boolean, adminRoute?: boolean }) => {
  const { children, suspense, adminRoute } = props;
  const user = useSelector(selectUser);
  const authLoading = useSelector(selectUserLoading);
  const authenticated = useSelector(selectAuthenticated);
  const dispatch = useAppDispatch();
  const router = useHistory();
  const location = useLocation();
  const isUpload = location.pathname.includes('/upload');
  const extId = user?.ldsAccountId ?? user?.uuid;
  const appAuthenticated = (user?.id && extId && authenticated) || (authenticated && isUpload && extId);
  useEffect(() => {
    if (!user?.id && !extId) { // same page authorization
      dispatch(fetchUser());
    }
  }, [user, authLoading]);

  useEffect(() => {
    if (authLoading || authenticated || user?.id || (extId && isUpload)) {
      if (adminRoute && !user.isAdmin && !user.isApprover) { // Make sure user has permissions to visit admin page
        router.push('/404');
      }

      return;
    }

    sendToLogin();
  }, [authLoading, authenticated, user, isUpload]);

  const sendToLogin = () => router.push('/login?returnUrl=' + window.location.pathname);

  return (
    <>
      {(!suspense && authLoading) || !authenticated
        ? <LoadingSpinner />
        : (appAuthenticated && (extId || (suspense && authLoading)))
          ? (
            children
          )
          : (
            (!user?.id)
              ? <AppError/>
              : <NotAvailable />
          )}
    </>
  )
}

const AuthorizeRoute = (props: { children?: JSX.Element, component?: React.ComponentType<any>, path?: string, exact?: boolean, suspense?: boolean, adminRoute?: boolean }) => {
  const { children, component: Component, path, suspense, ...rest } = props;

  if (Component) {
    return (
      <Route
        {...rest}
        path={path}
        render={(props) => (
          <Authorize suspense={suspense} adminRoute={rest?.adminRoute}>
            <Component {...props} />
          </Authorize>
        )}
      />
    )
  }
  return (
    <Route
      {...rest}
      path={path}
    >
      <Authorize suspense={suspense} adminRoute={rest?.adminRoute}>
        {children ?? <></>}
      </Authorize>
    </Route>
  )
}

export default AuthorizeRoute;
