import styled from 'styled-components';
import {
  sans as zoram,
  fontSize42,
  fontSize32,
  fontSize20,
  fontSize18,
  fontSize16,
  fontSize14,
  fontSize13,
  fontSize11,
  lineHeight,
  lineHeightTight,
  lineHeightLoose,
  text120,
  grey40,
} from '@churchofjesuschrist/eden-style-constants';

const FontFamily = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${zoram};
  line-height: ${lineHeightTight};
  color: ${text120};
`;

export const H1 = styled(FontFamily)`
  font-size: ${fontSize42};
  font-weight: 700;
`;

export const H2 = styled(FontFamily)`
  font-size: ${fontSize32};
  font-weight: 300;
`;

export const H3 = styled(FontFamily)`
  font-size: ${fontSize20};
  font-weight: 700;
`;

export const H4 = styled(FontFamily)`
  font-size: ${fontSize18};
  font-weight: 600;
`;

export const H5 = styled(FontFamily)`
  font-size: ${fontSize14};
  font-weight: 700;
`;

export const H6 = styled(FontFamily)`
  font-size: ${fontSize13};
  font-weight: 400;
`;

export const P = styled(FontFamily)`
  font-size: 14px;
  font-weight: 400;
`;

export const Text = styled(FontFamily)`
  font-weight: 400;
  line-height: ${lineHeight};
`;

export const Text1 = styled(Text)`
  font-size: ${fontSize18};
  font-weight: 300;
  line-height: ${lineHeightLoose};
`;

export const Text3 = styled(Text)`
  font-size: ${fontSize16};
  line-height: ${lineHeightLoose};
  color: ${grey40};
`;

export const Text4 = styled(Text)`
  font-size: ${fontSize14};
`;

export const Text5 = styled(Text)`
  font-size: ${fontSize13};
`;

export const Text6 = styled(Text)`
  font-size: ${fontSize11};
`;

export const Bold = styled(P)`
  font-weight: 600;
`;
