import React, { useState } from 'react';
import isBrowser from 'is-in-browser';
import { func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import { Clear } from '@churchofjesuschrist/eden-icons';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { fontSize18, spacing16 } from '@churchofjesuschrist/eden-style-constants';
import { TextArea, Label, FieldSet, Radio } from '@churchofjesuschrist/eden-form-parts';
import { ProgressButton } from '@churchofjesuschrist/eden-progress';
import { Text4 } from '@churchofjesuschrist/eden-text';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';

// functions
import { submitFeedback } from '../../services/feedback';

// styles
import {
  FeedbackBoxContainer,
  FeedbackButtonContainer,
  GroupSplit,
  ModalContainer,
  Title,
  BlankContent,
} from './FeedbackFormStyles';
import { Counter } from '../Create/Form/Form.style';

// constants
import { formValidityState } from '../../utils/appConstants/appConstants';
import { determineValidityState } from '../../utils/AppFunctions/formFunctions';
import { FormError } from '../Invite/Invite.style';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/authReducer';
import { usePageContext } from 'src/contexts/PageContext';

const { validityStateUnknownLabel } = formValidityState;
const MAX_CHARACTERS = 1000;

const FeedbackForm = ({ handleFeedbackStatusUpdate }: { handleFeedbackStatusUpdate?: (success: boolean) => void }) => {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const { isMobile } = usePageContext();
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(
    {
      Recepient: '',
      Message: '',
      Type: 'FeatureBug',
      ResponseEmail: '',
      ResponseName: '',
      ResponseWanted: false
    }
  );
  const [formStatus, setFormStatus] = useState(
    {
      hasError: false,
      textValidityState: validityStateUnknownLabel,
      nameValidityState: validityStateUnknownLabel,
      emailValidityState: validityStateUnknownLabel,
    }
  );
  const [isFeedbackFormOpen, setFeedbackOpen] = useState(false);

  const resetFormState = () => {
    setFormData(
      {
        Recepient: '',
        Message: '',
        Type: 'FeatureBug',
        ResponseEmail: '',
        ResponseName: '',
        ResponseWanted: false
      }
    );
    setFormStatus(
      {
        hasError: false,
        textValidityState: validityStateUnknownLabel,
        nameValidityState: validityStateUnknownLabel,
        emailValidityState: validityStateUnknownLabel,
      }
    );
  }

  const toggleFeedbackBox = () => {
    setFeedbackOpen(open => !open);
  }

  const handleCancel = event => {
    // prevents the page from refreshing
    event.preventDefault();

    toggleFeedbackBox();
    resetFormState();
  }

  const handleTextAreaValueChange = (newText: string) => {
    setFormData(data => ({
      ...data,
      Message: newText,
    }));
  }

  const validateTextArea = () => {
    // the following characters aren't allowed to prevent injection: <>
    // see below for the text version of this that shows up in the "invalid chars message"
    const INVALID_TEXT = /[<>&]+/;

    let isTextHaveError = true;
    const feedbackText = formData.Message;

    if (feedbackText.length <= 0 || INVALID_TEXT.test(feedbackText)) {
      isTextHaveError = false;
    }

    setFormStatus(status => ({
      ...status,
      textValidityState: determineValidityState(isTextHaveError),
    }));

    return !isTextHaveError;
  }

  const handleRadioChecked = (ev) => {
    const radio = ev.target;
    setFormData(data => ({
      ...data,
      [radio.name]: radio.value
    }));
  }

  const checkInputForErrors = async () => {
    const feedBackError = await validateTextArea();
    let errorInForm = false;

    if (feedBackError) {
      errorInForm = true;
    }

    setFormStatus(status => ({
      ...status,
      hasError: errorInForm,
    }));

    return errorInForm;
  }

  const handleSubmit = async (event) => {
    // prevents the page from refreshing
    event.preventDefault();

    if (await checkInputForErrors()) {
      setFormStatus(status => ({
        ...status,
        hasError: true,
      }));
    } else {
      const data = createSubmissionObject(user.email);

      setSubmitting(true);
      try {
        await submitFeedback(data);

        if (handleFeedbackStatusUpdate) {
          handleFeedbackStatusUpdate(true);
        }
        resetFormState();
        setFeedbackOpen(false);
      } catch (e) {
        console.error(e);
        if (handleFeedbackStatusUpdate) {
          handleFeedbackStatusUpdate(false);
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  const createSubmissionObject = (currentUserEmail: string) => {
    const data = {
      ...formData,
      ResponseEmail: formData.ResponseEmail || currentUserEmail,
      ResponseWanted: formData.ResponseWanted,
      Type: formData.Type,
      currentPageName: isBrowser && window.location.href,
      role: user.position,
      user: {
        email: user.email,
        username: user.username,
        name: user.name
      }
    }
    return data;
  }

  const INVALID_CHARS = '"&", "<" and ">"';
  const remaingChars = MAX_CHARACTERS - formData.Message.length;

  return (
    <FeedbackBoxContainer show={!!user?.id} isOpen={isFeedbackFormOpen} isMobile={isMobile}>
      <FeedbackButtonContainer>
        <Secondary onClick={toggleFeedbackBox}> {t('feedbackFormBtnLabel')} </Secondary>
      </FeedbackButtonContainer>

      <ModalContainer isMobile={isMobile}>
        <Stack>
          <GroupSplit paddingBottom={spacing16}>
            <Title>{t('feedbackFormTitle')} </Title>
            < Clear size={fontSize18} onClick={handleCancel} />
          </GroupSplit>
        </Stack>

        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit} >
          <Stack>
            <section>
              <Row gapSize="24">
                <FieldSet>
                  <Label>
                    {t('feedbackRadioFeedback')}
                    <Radio
                      data-test-id="feedbackRadioFeedback"
                      name="Type"
                      value="FeatureBug"
                      onChange={handleRadioChecked}
                      checked={formData.Type === 'FeatureBug'}
                      disabled={submitting}
                    />
                  </Label>
                </FieldSet>

                <FieldSet>
                  <Label>
                    {t('feedbackRadioGeneral')}
                    <Radio
                      data-test-id="feedbackRadioGeneral"
                      name="Type"
                      value="GeneralFeedback"
                      onChange={handleRadioChecked}
                      checked={formData.Type === 'GeneralFeedback'}
                      disabled={submitting}
                    />
                  </Label>
                </FieldSet>
              </Row>
            </section>

            <section>
              <Label validityState={formStatus.textValidityState}>
                <TextArea
                  data-test-id="feedbackFormTextArea"
                  maxLength={MAX_CHARACTERS.toString()}
                  name={t('feedbackFormBtnLabel')}
                  onBlur={validateTextArea}
                  onChange={e => handleTextAreaValueChange(e.target.value)}
                  placeholder={t('feedbackFormPlaceholder')}
                  value={formData.Message}
                  disabled={submitting}
                />
              </Label>

              < GroupSplit >
                {/* Left */}
                < Text4 >
                  {t('feedbackFormTextAreaInvalidCharsMsg')}
                  {INVALID_CHARS}
                </Text4>

                {/* Right */}
                <Text4>
                  <Counter error={remaingChars === 0}>
                    {remaingChars + t('feedbackFormCharRemainLabel')}
                  </Counter>
                </Text4>
              </GroupSplit>
            </section>
          </Stack>

          {formStatus.hasError ? (<FormError>{t('missingRequiredFieldsLabel')} </FormError>) : (<BlankContent />)}

          <Row>
            <ProgressButton progressing={submitting}>
              <Primary
                data-test-id="submitFeedbackBtn"
                type="submit"
                value='Submit' // must be in English for code
              >
                {t('submitBtnLabel', { ns: 'labels' })}
              </Primary>
            </ProgressButton>

            <Secondary
              data-test-id="cancelFeedbackBtn"
              value='Cancel' // must be in English for code
              onClick={handleCancel}
              disabled={submitting}
            >
              {t('cancelBtnLabel', { ns: 'labels' })}
            </Secondary>
          </Row>
        </form>
      </ModalContainer>
    </FeedbackBoxContainer>
  );
}

export default FeedbackForm;

(FeedbackForm as any).propTypes = {
  handleFeedbackStatusUpdate: func.isRequired,
  isMobile: bool,
};
