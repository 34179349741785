import React from 'react';
import { useTranslation } from 'react-i18next';

// eden
import Stack from '@churchofjesuschrist/eden-stack';

const PhotoHelp = () => {
  const { t } = useTranslation('');
  return (
    <>
      <Stack>
        <section id="p1">{t('calloutPhotosP1', { ns: 'strings' })}</section>
      </Stack>
    </>
  );
}

export default PhotoHelp;
