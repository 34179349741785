import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

// Eden
import { SidePanels, Panel } from '@churchofjesuschrist/eden-side-panels';

// Components
import SPCReviewPanel from 'src/components/SPCReviewCard/SPCReviewPanel';
import ExampleLeadershipStory from 'src/components/ExamplePages/ExampleLeadershipStory';
import ExampleEventStory from 'src/components/ExamplePages/ExampleEventStory';

const panelWidth = '500px';
const panelWidthWide = '600px';

const StyledSidePanels = styled(SidePanels)`
  height: auto;
`;
const StyledPanel = styled(Panel)`
  z-index: 200 !important;
`;

type PanelType = 'spc' | 'leadershipGood1' | 'eventGood1';

export interface IPanelContext extends IPanelContextLite {
  panel?: string | undefined
}

export interface IPanelContextLite {
  isOpen: boolean
  open: (panel: PanelType) => void
  close: () => void
}

const NothingPanel = () => (<React.Fragment></React.Fragment>);

const PanelContext = createContext<IPanelContext>({
  isOpen: false,
  open: (panel: PanelType) => { },
  close: () => { }
});
PanelContext.displayName = 'PanelContext';

export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error('usePanelContext must be used within its provider.');
  }
  const { panel, ...lite } = context;
  return lite as IPanelContextLite; // don't return 'private' attrs
}

export const PanelProvider = ({ children }: { children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [useWide, setUseWide] = useState(false);
  const [panel, setPanelType] = useState<PanelType>();

  useEffect(() => {
    if (isOpen) {
      window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: document.getElementById('panels')?.offsetTop,
      });
    }
  }, [isOpen]);

  const open = (panel: PanelType) => {
    if (panel) {
      setPanelType(panel);
      setIsOpen(true);
    }
  }
  const close = () => {
    setIsOpen(false);
  }

  const PanelComponent = useMemo(() => {
    switch (panel) {
      case 'spc':
        setUseWide(false);
        return SPCReviewPanel;

      case 'leadershipGood1':
        setUseWide(true);
        return ExampleLeadershipStory;

      case 'eventGood1':
        setUseWide(true);
        return ExampleEventStory;

      default:
        setUseWide(false);
        return NothingPanel;
    }
  }, [panel]);

  return (
    <PanelContext.Provider value={{
      isOpen,
      open,
      close,
      panel
    }}>
      <StyledSidePanels
        stickyHeader={true}
        id="panels"
        endPanel={
          <StyledPanel
            onClose={close}
            open={isOpen}
            preferredMode="overlay"
            panelWidth={useWide ? panelWidthWide : panelWidth}
            panelWidthBreakWidth="500px"
          >
            {PanelComponent && <PanelComponent />}
          </StyledPanel>
        }
      >
        {children}
      </StyledSidePanels>
    </PanelContext.Provider>
  )
};
