import React, { Fragment, useState } from 'react';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { number, func, string, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import { Primary } from '@churchofjesuschrist/eden-buttons';

// components
import { ConfirmSubmissionModal } from '../Modals/Submitting/SubmissionModal';
import LoadingSpinner from '../../utils/componentFunctions/loading-spinner';
import { WorkflowTransitionButton } from './WorkflowTransitionButton';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

// notification context
import { useNotificationContext } from '../../contexts/NotificationContext';

// functions
import { setSaveStoryVariables } from '../../utils/AppFunctions/formFunctions';
import { saveStoryMutation } from '../../utils/graphQL_Queries/appMutations';

// constants
import {
  objectArrayIsRequired,
  userType,
} from '../../utils/appConstants/propTypesConstants';
import { AUTO_TRANSITION, MOOD_SUBMIT, MESSAGE_SUCCESS } from '../../utils/appConstants/appConstants';
import { UserUnit } from 'src/models/Unit';
import { useHistory } from 'react-router-dom';
import { setMyStoryUrl } from 'src/utils/AppFunctions/setUrls';

export const SaveAndSubmitStoryBtn = ({
  user,
  index,
  story,
  activeUnitObj,
  handleAutoSave,
  disabled = false,
  handleFormValidation
}: {
  user?
  index?
  story?
  activeUnitObj?: UserUnit
  handleAutoSave?
  disabled?
  handleFormValidation?
}) => {
  const [isStorySaved, setStorySaved] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { addMessage } = useNotificationContext();

  const history = useHistory();
  const { t } = useTranslation('strings');

  const preSaveValidation = () => {
    const newVariables = setSaveStoryVariables(story);

    if (newVariables === 'error') {
      handleFormValidation(story); // sets form errors
      // console.log("preSaveValidation, newVariables: ", newVariables);
    } else {
      const formValid = handleFormValidation(newVariables.story); // sets form errors (if any)
      // console.log("formValid: ", formValid);
      formValid && setConfirmationModalOpen(formValid); // open confirmation modal
    }
  };

  const onSave = async (e, sensitive, sensitiveComments, saveStoryMutationFunc) => {
    e.preventDefault();
    const newVariables = setSaveStoryVariables({ ...story, sensitive, sensitiveComments }) as any;

    try {
      await saveStoryMutationFunc({
        variables: { ...newVariables, story: { ...newVariables.story, lock: null } },
      });
      handleSubmissionCompletion();
    } catch (error) {
      // additional handling on fail to save
    } finally {
      history.push(setMyStoryUrl('PENDING'));
    }
  };

  const handleSubmissionCompletion = () => {
    setConfirmationModalOpen(false);// close confirmation modal
    setStorySaved(true);

    // Add message to notification queue
    addMessage({
      title: t('storySavedTitle', { ns: 'strings' }),
      message: '',
      messageType: MESSAGE_SUCCESS,
    });
  };

  const errorMessage = t('saveErrorMessage');

  return (
    <Mutation mutation={saveStoryMutation}>
      {(saveStoryMutationFunc: MutationFunction<any, OperationVariables>, { data, error, loading }: MutationResult<any>) => {
        if (loading) return <LoadingSpinner />;
        if (error) {
          return (
            <ErrorModal
              title={errorMessage}
              onRetry={saveStoryMutationFunc}
              errors={error}
              isActive
            />
          );
        }

        // don't turn on autoSubmit until after the save notification has completed (or the user has closed it)
        const autoSubmit = isStorySaved && data && data.saveStory.id;

        return (
          <Fragment>
            <Primary
              onClick={preSaveValidation}
              data-test-id={`submitBtnId${index}`}
              disabled={disabled}
            >
              {t('submitBtnLabel', { ns: 'labels' })}
            </Primary>

            {isConfirmationModalOpen && (
              <ConfirmSubmissionModal
                isActive={isConfirmationModalOpen}
                handleClose={() => {
                  setConfirmationModalOpen(false);
                }}
                handleCompletion={async (e, sensitive, sensitiveComments) => await onSave(e, sensitive, sensitiveComments, saveStoryMutationFunc)}
                unitType={activeUnitObj?.unitType}
                mood={MOOD_SUBMIT}
              />
            )}

            {autoSubmit && (
              <WorkflowTransitionButton
                index={0}
                story={story}
                user={user}
                activeUnitObj={activeUnitObj}
                autoTransition={autoSubmit ? AUTO_TRANSITION : undefined}
                mood={MOOD_SUBMIT}
                complete={handleAutoSave}
              />
            )}
          </Fragment>
        );
      }}
    </Mutation>
  );
}

export default SaveAndSubmitStoryBtn;

(SaveAndSubmitStoryBtn as any).propTypes = {
  attachmentFiles: objectArrayIsRequired,
  currentPageName: string.isRequired,
  handleFormValidation: func.isRequired,
  imageFiles: objectArrayIsRequired,
  index: number.isRequired,
  story: object,
  user: userType,
  activeUnitObj: object,
  disabled: bool,
  handleAutoSave: func,
};
