import React, { Fragment, useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';

// Eden
import { Primary } from '@churchofjesuschrist/eden-buttons';

// components
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

// notification context
import { useNotificationContext } from '../../contexts/NotificationContext';

import {
  setSaveStoryObjectFromInvitation,
  validateDate,
} from 'src/utils/AppFunctions/formFunctions';

// services
import submitInvite from 'src/services/email';

// constants
import {
  MESSAGE_SUCCESS,
  MESSAGE_ERROR,
  MESSAGING_INVITE,
} from 'src/utils/appConstants/appConstants';

// import { setRefetchVariables } from 'app/utils/graphQL_Queries/queryVariableFormats';
import { saveInviteMutation, saveStoryMutation } from 'src/utils/graphQL_Queries/appMutations';
import { User } from 'src/models/User';

interface IProps { validateForm?, clearForm?, invitation?, user?: User };

const SendInviteButton = (props: IProps) => {
  const { validateForm, clearForm, invitation, user } = props;

  const [state, setState] = useState({
    saveStoryError: false,
    missingRequiredFields: false,
    emailStatus: 0,
  });

  const { addMessage } = useNotificationContext();
  const { t } = useTranslation('strings');

  const handleSendInvite = async (e, invitation, user, saveInviteMutationFunc, saveStoryMutationFunc) => {
    e.preventDefault();

    const { title, dateEnd, dateStart, authors } = invitation;

    if (!title || authors.length === 0 || !validateDate(dateStart, dateEnd, true)) {
      setState((currState) => ({ ...currState, missingRequiredFields: true }));
      validateForm(invitation);
      setTimeout(() => setState((currState) => ({ ...currState, missingRequiredFields: false })), 2000);
      return;
    }

    const inviteReq = setSaveStoryObjectFromInvitation(invitation);
    const inviteRes = await saveInviteMutationFunc({ variables: inviteReq }); // save as an INVITE

    if (!inviteRes || Object.keys(inviteRes.data).length === 0 || !inviteRes.data.saveInvite) {
      setState((currState) => ({ ...currState, saveStoryError: true }));
      return;
    }

    const storyReq = { ...inviteReq } as any;
    storyReq.story.id = inviteRes.data.saveInvite.story.id;

    // await saveStoryMutationFunc({ variables: storyReq }); // now save as a DRAFT

    const emailStatusPromises = authors.map(async (author) => {
      if (!author || !author.email || !author.name) {
        return null;
      }

      const status = await submitInvite({
        type: MESSAGING_INVITE,
        name: author.name,
        emailAddress: author.email,
        invitation,
        savedInvitation: inviteRes.data.saveInvite,
        approver: user,
      });

      return status;
    });

    const emailStatuses = await Promise.all(emailStatusPromises);
    const emailSendStatus = emailStatuses.find((status) => status >= 200 && status < 400) || 0;

    setState((currState) => ({ ...currState, emailStatus: emailSendStatus }));

    if (emailSendStatus >= 200 && emailSendStatus < 400) {
      addMessage({
        title: t('invitationSentTitle'),
        message: t('invitationSentMessage'),
        messageType: MESSAGE_SUCCESS,
      });
      clearForm();
      window.scrollTo(0, 0);
    } else {
      console.log('Error sending invite', state.emailStatus);
      addMessage({
        title: t('invitationNotSentTitle'),
        message: t('invitationNotSentMessage'),
        messageType: MESSAGE_ERROR,
      });
    }
  };

  return (
    <Mutation mutation={saveStoryMutation}>
      {(saveStoryMutationFunc, { loading: loading1 }) => (
        <Mutation mutation={saveInviteMutation}>
          {(saveInviteMutationFunc, { loading: loading2 }) => (
            <Fragment>
              {(loading1 || loading2) && <LoadingSpinner />}
              <Primary
                onClick={async e =>
                  await handleSendInvite(
                    e,
                    invitation,
                    user,
                    saveInviteMutationFunc,
                    saveStoryMutationFunc
                  )
                }
                data-test-id={`sendInviteBtn-${invitation.title}`}
              >
                {t('sendInviteBtnLabel', { ns: 'labels' })}
              </Primary>
            </Fragment>
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

export default SendInviteButton;

SendInviteButton.propTypes = {
  invitation: object.isRequired,
  validateForm: func.isRequired,
  clearForm: func.isRequired,
};
