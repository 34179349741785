import { User } from 'src/models/User';

export const fetchUser = async () => {
  const result = await fetch('api/user', {
    method: 'GET',
    headers: {
      Accept: '*/*'
    }
  });
  if (result.ok) {
    return await result.json() as User;
  }
  try {
    const err = await result.text();
    throw new Error(`${result.statusText}:${result.status} - ${err}`);
  } catch (e) {
    console.warn(e);
    throw new Error(`${result.statusText}:${result.status}`);
  }
}
