import React from 'react';
import { AttachedHero } from '@churchofjesuschrist/eden-attached-hero';
import { Primary } from '@churchofjesuschrist/eden-buttons';
import Image from '@churchofjesuschrist/eden-image';
import { Container } from 'reactstrap';

const UploadComplete = () => (
  <Container>
    <AttachedHero
      title="Upload Complete"
      body={
        <p>
          Your submission completed successfully. Thank you.
        </p>
      }
      image={
        <Image
          src="https://assets.ldscdn.org/fc/f5/fcf500d927cc11edbd82eeeeac1e1d4a47b427e8/recruitment_icons.jpeg"
          alt="catalog image"
        />
      }
      callToAction={
        <Primary href="/upload">
          Upload More
        </Primary>
      }
    />
  </Container>
);

export default UploadComplete;
