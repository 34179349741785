import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import Collapse from '@churchofjesuschrist/eden-collapse';
import Resize from '@churchofjesuschrist/eden-resize-observer';
import FadeBase from '@churchofjesuschrist/eden-fade';
import { fontSize18, lineHeightTight, info40 } from '@churchofjesuschrist/eden-style-constants';

// styles
import { AnimatedChevron } from 'src/utils/appCSS/appStyles';

const minHeightFallBack = '24'; // use this when the fade isn't active. Note: 16 was too small, it clipped the font.

const Wrapper = styled.div<{ addBottomPadding?: boolean }>`
  position: relative;
  ${props => (props.addBottomPadding ? 'padding-bottom: 8px' : null)}
`;

const Fade = styled(FadeBase)`
  height: 40px;
  position: absolute;
  top: ${props => props.minHeight};
  left: 0;
  right: 0;
  transform: translateY(-100%);
`;

const CustomHandle = styled.span`
  font-size: ${fontSize18};
  font-weight: 300;
  font-style: normal;
  line-height: ${lineHeightTight};
  color: ${info40};
  cursor: pointer;
`;

const FadedDrawer = (props: { isOpen?: boolean, minHeight?: number, children: JSX.Element | JSX.Element[] | React.ReactText }) => {
  const { isOpen = false, minHeight = 40, children } = props;
  const [isCollapsible, setIsCollapsible] = useState(false);
  const [drawerOpen, setIsOpen] = useState(isOpen);
  const { t } = useTranslation('strings');

  const toggle = () => setIsOpen(prevState => !prevState);

  const handleHeightChange = entry => {
    setIsCollapsible(entry.contentRect.height > minHeight);
  };

  return (
    <Wrapper addBottomPadding={isCollapsible} {...props}>
      <Collapse
        minHeight={(isCollapsible ? minHeight : minHeightFallBack) + 'px'}
        expand={drawerOpen}
      >
        <Resize
          onChange={handleHeightChange}
          render={({ refHandler }) => <div ref={refHandler}>{children}</div>}
        />
      </Collapse>
      {isCollapsible && !drawerOpen ? <Fade minheight={minHeight} /> : null}
      {isCollapsible && (
        <CustomHandle onClick={toggle}>
          <AnimatedChevron active={drawerOpen} /> {drawerOpen ? t('showLessMsg') : t('showMoreMsg')}
        </CustomHandle>
      )}
    </Wrapper>
  );
}

export default FadedDrawer;
