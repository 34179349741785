import React, { Fragment } from 'react';

import Grid from '@churchofjesuschrist/eden-grid';
import { BookmarkOutline } from '@churchofjesuschrist/eden-icons';
import { Text3 } from '@churchofjesuschrist/eden-text';

import StoryCard from 'src/components/Stories/StoryCard/index';
import { DisplayStoryContainer } from 'src/components/DisplayStories/storyStyles';
import SortStoryContainer from 'src/components/SortAndSearchBar/SortStoryContainer';

// constants
import { pageNames } from 'src/utils/appConstants/appConstants';
import { pageNameType } from 'src/utils/appConstants/propTypesConstants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectStories, selectStoriesError } from 'src/reducers/storiesReducer';
import { HomeAddStoryCard } from '../Stories/StoryCard/ExampleStoryCard';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const { viewStoriesPageName, favoritesPageName, myStoriesPageName } = pageNames;

export const MainStories = ({
  pageName
}: {
  pageName: string
}) => {
  const { t } = useTranslation('strings');
  const stories = useSelector(selectStories);
  const error = useSelector(selectStoriesError);
  const { activeUnitObj, canCreate } = useUserPermissions();

  return (
    <Fragment>
      {pageName === viewStoriesPageName && (
        <SortStoryContainer />
      )}
      <Fragment>
        <DisplayStoryContainer data-test-id="displayStories">
          {stories.length === 0 && pageName === favoritesPageName && (
            <Text3>
              {t('noFavoritesLabel1')}
              <BookmarkOutline size="1.5rem" />
              {t('noFavoritesLabel2')}
            </Text3>
          )}
          <Grid gapSize="16" rowGapSize="16" min="275px">
            {canCreate && pageName === viewStoriesPageName && (
              <HomeAddStoryCard />
            )}
            {stories?.length === 0 && pageName === viewStoriesPageName && (
              <Text3>{t('noStoriesHomePublishedLabel')}</Text3>
            )}
            {stories?.length === 0 && pageName === myStoriesPageName && (
              <Text3>{t('noStoriesMyStoriesPublishedLabel')}</Text3>
            )}
            {error && (
              <Text3>{t('noStoriesDataError')}</Text3>
            )}
            {stories?.length > 0 &&
              stories.map((story, index) => (
                <StoryCard
                  key={story.id}
                  story={story}
                  currentPageName={pageName}
                  index={index}
                  activeUnitObj={activeUnitObj}
                />
              ))}
            {stories?.length > 0 &&
              _.range(8).map((val, index) => (
                <div key={index} className={val.toString()}></div>
              ))}
          </Grid>
        </DisplayStoryContainer>
      </Fragment>
    </Fragment>
  );
}

export default MainStories;

(MainStories as any).propTypes = {
  pageName: pageNameType
};
