import React from 'react';
import styled from 'styled-components';

// Eden
import {
  // fontSize42,
  fontSize16,
  // spacing32,
  spacing16,
  info40,
  blue20,
} from '@churchofjesuschrist/eden-style-constants';

// Components
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// styles
import { PageTitle } from '../utils/appCSS/appStyles';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_NOT_FOUND } from '../utils/analytics';
import { pageNames } from '../utils/appConstants/appConstants';
import { useTranslation } from 'react-i18next';
import { withPage } from 'src/contexts/PageContext';

const { errorPageName } = pageNames;

const Text = styled.div`
  font-size: ${fontSize16};
  margin: ${spacing16} 0;
`;

const A = styled.a`
  color: ${info40};
  :hover {
    text-decoration: underline;
    color: ${blue20};
  }
`;

const Page = () => {
  const { t, i18n } = useTranslation('strings');
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: errorPageName, // always use English
        activePage: errorPageName, // used to set primary category
        breadCrumbs: [errorPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_NOT_FOUND,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <>
      <PageTitle>{t('errorGeneralLabel')}</PageTitle>
      <Text>We&apos;re sorry, but the page you&apos;re looking for was not found.</Text>
      <Text>
        Please check the URL for proper spelling and capitalization. If you&apos;re having
        trouble locating a page on churchofjesuschrist.org, try visiting the{' '}
        <A href="https://churchofjesuschrist.org">churchofjesuschrist.org home page</A> or
        enter a search term in the field below.
      </Text>
    </>
  );
};

export default withPage({ pageName: errorPageName, headTitle: 'headPageTitle', pageTitle: 'errorPageTitle' })(Page);
