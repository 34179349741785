import styled from 'styled-components';

// Eden
import { Primary, Danger } from '@churchofjesuschrist/eden-buttons';
import {
  white,
  danger40,
  spacing4,
  spacing8,
  grey15,
  spacing16,
  spacing24,
  spacing32,
  lineHeightTight,
} from '@churchofjesuschrist/eden-style-constants';

// Styles
import { flexRow, flexColumn } from '../../ComponentStyles/div';
import { Bold } from '../../ComponentStyles/elements';

export const StoryContainer = styled(flexRow)`
  margin-top: ${spacing24};
  padding-bottom: ${spacing24};
  border-bottom: 1px solid ${grey15};
  display: grid;
  grid-template-columns: 350px auto;
`;

export const StoryHeader = styled(flexRow) <{ color?: string }>`
  background-color: ${props => (props.color ? props.color : null)};
  height: 44px;
  width: 350px;
  padding-left: ${spacing8};
  margin-bottom: ${spacing8};
  align-items: center;
  color: ${white};
`;

export const LabelContainer = styled(Bold)`
  padding-top: ${spacing4};
  padding-right: ${spacing8};
  line-height: ${lineHeightTight};
`;

export const InfoContainer = styled(flexColumn) <{ isMobile?: boolean }>`
  grid-column-start: 2;
  padding-top: ${spacing32};
  padding-left: ${spacing32};
  /* max-width: ${props => (props.isMobile ? 160 : 356)}px; */
`;

export const AssigneeContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: flex-start;
  margin-bottom: ${spacing16};
`;
export const DateContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: flex-start;
  margin-bottom: ${spacing16};
`;

export const Buttons = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  padding-top: ${props => (props.isMobile ? spacing16 : spacing32)};
  align-items: center;
`;

export const PrimaryButton = styled(Primary)`
  max-width: 125px;
`;

export const DeleteButton = styled(Danger) <{ isMobile?: boolean }>`
  border: 2px solid ${danger40};
  margin-left: ${props => (props.isMobile ? 0 : spacing16)};
  margin-top: ${props => (props.isMobile ? spacing16 : 0)};
  max-width: 125px;
`;
