import React, { Fragment, useState } from 'react';
import { number, func, string, object, bool } from 'prop-types';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';

// eden
import { Primary } from '@churchofjesuschrist/eden-buttons';

// components
import LoadingSpinner from '../../utils/componentFunctions/loading-spinner';
import { WorkflowTransitionButton } from './WorkflowTransitionButton';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';

// functions
import { setSaveStoryVariables } from '../../utils/AppFunctions/formFunctions';

// notification context
import { useNotificationContext } from '../../contexts/NotificationContext';

// constants
import { userType } from '../../utils/appConstants/propTypesConstants';
import { User } from 'src/models/User';

// queries
import { saveStoryMutation } from '../../utils/graphQL_Queries/appMutations';

// strings
import {
  pageNames,
  AUTO_TRANSITION,
  MESSAGE_SUCCESS,
  MOOD_PUBLISH,
  STORY_STATUSES,
} from '../../utils/appConstants/appConstants';

// strings
import ConfirmPublishModal from '../Modals/Submitting/PublishConfirmation';
import { useHistory } from 'react-router-dom';
import { getPageRedirect } from '../Stories/utils/RedirectPages';

const { PUBLISHED_STATUS } = STORY_STATUSES;
const { managePublishPageName } = pageNames;

export const SaveAndPublishStoryBtn = ({
  user,
  index = 0,
  story,
  currentPageName,
  activeUnitObj,
  disabled = false,
  handleFormValidation
}: {
  user?: User
  index?
  story?
  currentPageName?
  activeUnitObj?
  disabled?
  handleFormValidation?
}) => {
  const { addMessage } = useNotificationContext();
  const [isStorySaved, setStorySaved] = useState(false);
  const [isConfirmationModalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation('strings');

  const preSaveValidation = () => {
    const newVariables = setSaveStoryVariables(story);

    if (newVariables === 'error') {
      handleFormValidation(story); // sets form errors
      // console.log("preSaveValidation, newVariables: ", newVariables);
    } else {
      const formValid = handleFormValidation(newVariables.story); // sets form errors (if any)
      // console.log("formValid: ", formValid);
      formValid && setModalOpen(formValid); // open confirmation modal
    }
  };

  const onConfirm = async (e, saveStoryMutationFunc) => {
    e.preventDefault();
    const newVariables = setSaveStoryVariables(story) as any;

    try {
      await saveStoryMutationFunc({
        variables: { ...newVariables, story: { ...newVariables.story, lock: null } },
      });
      handleCompletion();
    } catch (error) {
      // additional handling on fail to save
    }
  };

  const onPublish = () => {
    history.push(getPageRedirect(managePublishPageName));
  }

  const handleCompletion = () => {
    setStorySaved(true);
    setModalOpen(false);

    addMessage({
      title: t('storySavedTitle', { ns: 'strings' }),
      message: '',
      messageType: MESSAGE_SUCCESS,
    });
  };

  return (
    <Mutation mutation={saveStoryMutation}>
      {(saveStoryMutationFunc: MutationFunction<any, OperationVariables>, { data, error, loading }: MutationResult<any>) => {
        if (loading) return <LoadingSpinner />;
        if (error) {
          return (
            <ErrorModal
              title={t('errorSavingStoryLabel', { ns: 'strings' })}
              onRetry={saveStoryMutationFunc}
              errors={error}
              isActive
            />
          );
        }

        // don't turn on autoPublish until after successful save
        const autoPublish = isStorySaved && data && data.saveStory.id;
        // isStorySaved && console.log("SaveAndPublishStoryBtn, story has been saved.");
        // autoPublish && console.log("SaveAndPublishStoryBtn, autoPublish is on.");

        return (
          <Fragment>
            <Primary
              data-test-id={`publishBtn${index}`}
              // onClick={() => this.setState({ isConfirmationModalOpen: true })}
              onClick={preSaveValidation}
              disabled={disabled}
            >
              {currentPageName !== managePublishPageName
                ? t('approveAndPublishBtnLabel', { ns: 'labels' })
                : story.status === PUBLISHED_STATUS
                  ? t('saveAndRepublishBtnLabel', { ns: 'labels' })
                  : t('saveAndResubmitBtnLabel', { ns: 'labels' })
              }
            </Primary>

            <ConfirmPublishModal
              isActive={isConfirmationModalOpen}
              handleClose={() => {
                setModalOpen(false);
              }}
              // Note: all story variables are validated in <StoryForm>
              // handleCompletion={e => this.props.handleSaveStory(e, saveStoryMutationFunc)}
              handleCompletion={async e => await onConfirm(e, saveStoryMutationFunc)}
              unitType={activeUnitObj.unitType}
              user={user}
            />

            {autoPublish && (
              <WorkflowTransitionButton
                index={0}
                story={story}
                user={user}
                activeUnitObj={activeUnitObj}
                autoTransition={autoPublish ? AUTO_TRANSITION : undefined}
                mood={MOOD_PUBLISH}
                complete={onPublish}
              />
            )}
          </Fragment>
        );
      }}
    </Mutation>
  );
}

export default SaveAndPublishStoryBtn;

SaveAndPublishStoryBtn.propTypes = {
  currentPageName: string.isRequired,
  handleFormValidation: func.isRequired,
  index: number.isRequired,
  story: object,
  user: userType,
  activeUnitObj: object,
  disabled: bool,
  handleAutoSave: func,
};
