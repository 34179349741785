import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNotificationContext } from 'src/contexts/NotificationContext';
import * as uploadReducer from 'src/reducers/uploadReducer';
import { readBlockToBlob } from 'src/services/file';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from 'src/utils/appConstants/appConstants';
import { useAppDispatch } from 'src/utils/appStore'

const ChunkSize = 1024 * 1024 * 2; // 2mb

/** Hook that handles uploading of file slices (chunks). (default is 2mb chunks) */
export const useFileUpload = (chunkSize = ChunkSize) => {
  const { t } = useTranslation();
  const [allComplete, setAllComplete] = useState(false);
  const [offset, setOffset] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [fileIndex, setFileIndex] = useState(-1);
  const [formData, setFormData] = useState({});
  const [completedFiles, setCompletedFiles] = useState<string[]>([]);
  const uploadFile = useSelector(uploadReducer.selectUploadFile);
  const uploadId = useSelector(uploadReducer.selectUploadId);
  const uploadComplete = useSelector(uploadReducer.selectUploadComplete);
  const uploadedChunk = useSelector(uploadReducer.selectUploadedChunk);
  const uploadError = useSelector(uploadReducer.selectUploadError);
  const uploading = useSelector(uploadReducer.selectUploading);
  const dispatch = useAppDispatch();
  const { addMessage } = useNotificationContext();

  const indexLength = files.length - 1;
  const file = fileIndex > -1 && fileIndex < files.length ? files[fileIndex] : null;
  const continueNext = file && uploadedChunk && uploading && !uploadComplete && !uploadError;

  const nextChunk = useCallback((file: File, offset: number, uploadId: string, formData = {}) => {
    dispatch(uploadReducer.setUploadPercent({ percent: offset / file.size, name: file.name })); // Update uploaded percent
    const block = readBlockToBlob(offset, chunkSize, file, file.size); // Read chunk
    setOffset(block.offset);
    dispatch(uploadReducer.uploadChunk({
      // file: arrayBufferToBase64(block.result),
      file: block.result,
      name: file.name,
      offset,
      complete: block.success,
      uploadId,
      ...formData,
    }));
  }, [dispatch, chunkSize]);

  useEffect(() => {
    if (continueNext) {
      nextChunk(file, offset, uploadId, formData);
    }
  }, [continueNext, file, uploadId, offset, formData, nextChunk]);

  useEffect(() => {
    if (uploadError) {
      addMessage({ title: t('uploadErrorLabel'), message: t('uploadErrorTemplate', { file: uploadFile, message: uploadError }), messageType: MESSAGE_ERROR });
      setOffset(0);
      _unbindToClose();
    }
  }, [uploadError, uploadFile]);

  useEffect(() => {
    if (uploadComplete) {
      addMessage({ title: t('uploadSuccessLabel'), message: t('uploadingCompleteTemplate', { file: uploadFile }), messageType: MESSAGE_SUCCESS });
      dispatch(uploadReducer.setUploadComplete(false));
      setOffset(0);
      _unbindToClose();
      setCompletedFiles(files => [...files, uploadFile]);

      if (fileIndex < indexLength) {
        setFileIndex(i => i + 1);
        nextChunk(files[fileIndex + 1], 0, '', formData);
      } else {
        setFiles([]);
        setAllComplete(true);
      }
    }
  }, [uploadComplete, uploadFile, indexLength, dispatch, fileIndex, nextChunk, files, t, formData]);

  /** Add message before leaving page. */
  const _bindToClose = () => {
    window.onbeforeunload = (e) => {
      e.returnValue = 'There are files being uploaded. Leaving the page will cause the upload to fail. Are you sure you want to leave?'; // never shown
    }
  }

  /** Remove onbeforeunload event. */
  const _unbindToClose = () => {
    window.onbeforeunload = (e) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/dot-notation
      delete e['returnValue'];
    }
  }

  const startUpload = async (formData: any) => {
    if (!files?.length) return;
    setOffset(0);
    setFormData(formData ?? {});
    _bindToClose();
    setFileIndex(0);
    nextChunk(files[0], 0, '', formData ?? {});
  }

  const stageFiles = (files: File[]) => {
    setFiles(files);
    setFileIndex(0);
  }

  return {
    startUpload,
    stageFiles,
    files,
    allComplete,
    completedFiles,
    fileIndex
  }
}
