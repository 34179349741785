import React, { useState } from 'react';
import { array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Validator, TypeAhead, InputGroup } from '@churchofjesuschrist/eden-form-parts';
import { Icon } from '@churchofjesuschrist/eden-buttons';
import { Search } from '@churchofjesuschrist/eden-icons';
import { grey15 } from '@churchofjesuschrist/eden-style-constants';

import styled from 'styled-components';

const StyledInputGroup = styled(InputGroup)`
  &::after {
    border-color: ${grey15};
  }
`;

const LightSearch = styled(Search)`
  color: ${grey15};
`

const SearchBox = ({ handleSearchTextChange, stories }) => {
  const { t } = useTranslation('strings');
  const [formData, setFormData] = useState({ search: '' });

  const determineValidityState = validityState => {
    const valid = validityState.valid;
    return valid === true ? 'success' : valid === false ? 'error' : 'unknown';
  };

  const changeHandler = event => {
    const { value } = event.target;

    setFormData({
      search: value,
    });

    if (stories.find(s => s.title === value)) {
      handleSearchTextChange(value);
    }
  };

  const doSearch = () => {
    handleSearchTextChange(formData.search);
  };

  return (
    <Validator>
      {({ checkValidity, validity: { search } }) => (
        <form
          noValidate
          onSubmit={event => {
            event.preventDefault();

            if (checkValidity(event) === false) return;

            doSearch();
          }}
        >
          <StyledInputGroup validityState={determineValidityState(search)}>
            <TypeAhead
              name="search"
              required
              value={formData.search}
              onChange={changeHandler}
              placeholder={t('searchPlaceHolderText')}
            >
              {stories.map(s => (
                <option key={s.id}>{s.title}</option>
              ))}
            </TypeAhead>
            <Icon onClick={() => doSearch()} size="small">
              <LightSearch />
            </Icon>
          </StyledInputGroup>
        </form>
      )}
    </Validator>
  );
};

export default SearchBox;

SearchBox.propTypes = {
  stories: array,
  handleSearchTextChange: func,
};
