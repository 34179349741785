import React, { useEffect, useState, Fragment } from 'react';
import { MutationFunction, MutationResult, OperationVariables, useMutation } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { bool, func, object, string, array, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// eden
import { Error } from '@churchofjesuschrist/eden-alert';
import { Danger, Secondary } from '@churchofjesuschrist/eden-buttons';
import Toast from '../../../utils/componentFunctions/toast';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { black } from '@churchofjesuschrist/eden-style-constants';

// Components
import ErrorModal from '../ErrorModal/ErrorModal';

// functions
import LoadingSpinner from '../../../utils/componentFunctions/loading-spinner';

// styles
import { AlertClickBlocker, Bold } from '../../../utils/appCSS/appStyles';

// queries
import { deleteStoryMutation } from '../../../utils/graphQL_Queries/appMutations';
import { getStoryByIdQuery } from '../../../utils/graphQL_Queries/appQueries';
import { setManagerRefetchVariables } from '../../../utils/graphQL_Queries/queryVariableFormats';

// constants
import { selectUser } from 'src/reducers/authReducer';

export const RemoveContentModal = ({
  isActive,
  title,
  message,
  handleFinish,
  deleteMutation
}: {
  isActive
  title
  message
  handleFinish
  deleteMutation?
}) => {
  const [active, setActive] = useState(isActive);
  const { t } = useTranslation();

  const finish = (remove?) => {
    handleFinish(remove);
    setActive(false);
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  // const [deleteMutation, { loading, error }] = useMutation(mutation ?? "", {
  //   variables
  // });

  const handleDelete = () => {
    if (deleteMutation) {
      deleteMutation();
    } else {
      finish(true);
    }
  }

  return (
    <Fragment>
      {active && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}
      <Toast active={active}>
        <Error onClose={finish}>
          <Stack>
            <H4>{title}</H4>
            <Text4>{message}</Text4>
            <Row>
              <Fragment>
                <Danger
                  onClick={() => handleDelete()}
                  data-test-id="button_delete-confirmation"
                >
                  {t('removeBtnLabel')}
                </Danger>
              </Fragment>
              <Secondary onClick={() => finish()}>{t('cancelBtnLabel')}</Secondary>
            </Row>
          </Stack>
        </Error>
      </Toast>
    </Fragment>
  );
};

export const RemoveExistingItemAlert = ({
  handleRemoval, handleCancel, variables, isActive,
  mutation,
  removalMessage,
  removalTitle,
  itemId
}: {
  handleRemoval
  handleCancel
  variables
  isActive
  mutation
  removalMessage
  removalTitle
  itemId
}) => {
  const { storyId } = variables;
  const { t } = useTranslation();

  const [deleteMutation, { loading, error }] = useMutation<{ deleteStory: boolean }>(mutation, {
    refetchQueries: [
      {
        query: getStoryByIdQuery,
        variables: { id: storyId },
      },
    ]
  });

  const handleItemRemoval = () => {
    deleteMutation({
      variables: { ...variables },
    });

    handleRemoval(itemId);
    handleCancel();
  };

  return (
    <Fragment>
      {isActive && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}

      <Toast active={isActive}>
        <Error onClose={handleCancel}>
          <Stack>
            <H4>{removalTitle}</H4>
            <Text4>{removalMessage}</Text4>
            <Row>
              {loading && <LoadingSpinner windowLevel={false} />}
              {error &&
                <ErrorModal
                  title={t('errorGeneralLabel')}
                  onRetry={deleteMutation}
                  errors={error}
                  isActive
                />
              }
              <Danger onClick={handleItemRemoval}>
                {t('removeBtnLabel')}
              </Danger>
              <Secondary onClick={handleCancel}>{t('cancelBtnLabel')}</Secondary>
            </Row>
          </Stack>
        </Error>
      </Toast>
    </Fragment>
  );
}

export const RemoveStoryModal = ({
  isActive,
  storyTitle,
  handleCancel,
  delVariables,
  handlePostRemoval,
}: {
  isActive
  storyTitle
  handleCancel
  delVariables
  handlePostRemoval
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const refetchList = setManagerRefetchVariables(user);
  const [deleteMutation, { loading }] = useMutation<{ deleteStory: boolean }>(deleteStoryMutation, {
    refetchQueries: refetchList,
    onError: (errors) => {
      console.error(errors[0].message);
      handlePostRemoval(true);
    },
    onCompleted: (data) => {
      handlePostRemoval(!data?.deleteStory);
    }
  })

  return (
    <Fragment>
      {isActive && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}
      <Toast active={isActive}>
        <Error onClose={handleCancel}>
          <Stack>
            <H4>{t('removeStoryTitle')}</H4>
            <Text4>
              {t('removeStoryMessage1')} &quot;<Bold>{storyTitle}</Bold>&quot; {t('removeStoryMessage2')}
            </Text4>
            <Row>
              {loading && <LoadingSpinner windowLevel={false} />}
              <Danger
                onClick={async e => {
                  e.preventDefault();
                  const response = await deleteMutation({ variables: delVariables });
                  if (response.errors?.length) {
                    console.error(response.errors[0].message);
                    handlePostRemoval(true);
                  } else {
                    handlePostRemoval(response.data?.deleteStory !== true);
                  }
                }}
                data-test-id="button_delete-confirmation"
              >
                {t('removeBtnLabel')}
              </Danger>
              <Secondary onClick={handleCancel}>{t('cancelBtnLabel')}</Secondary>
            </Row>
          </Stack>
        </Error>
      </Toast>
    </Fragment>
  );
}

export const RemoveAdminModal = ({
  isActive,
  title,
  message,
  handleFinish,
  adminId,
  mutation,
  refetchList = []
}: {
  isActive
  title
  message
  handleFinish
  adminId
  mutation
  refetchList: any[]
}) => {
  const { t } = useTranslation();

  const finish = (remove?) => {
    handleFinish(remove);
  };

  const handleCompletion = () => {
    finish(true);
  };

  return (
    <Fragment>
      {isActive && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}
      <Toast active={isActive}>
        <Error onClose={() => finish()}>
          <Stack>
            <H4>{title}</H4>
            <Text4>{message}</Text4>
            <Row>
              {mutation && (
                <Mutation
                  mutation={mutation}
                  variables={{ id: adminId }}
                  onCompleted={handleCompletion}
                  refetchQueries={refetchList}
                >
                  {(deleteMutation: MutationFunction<any, OperationVariables>, { loading, error }: MutationResult<any>) => (
                    <Fragment>
                      {loading && <LoadingSpinner />}
                      {error && (
                        <ErrorModal
                          title={t('errorDeletingAdminLabel')}
                          onRetry={deleteMutation}
                          errors={error}
                          isActive
                        />
                      )}
                      <Danger
                        data-test-id="button-delete-confirmation"
                        onClick={() => {
                          deleteMutation();
                        }}
                      >
                        {t('removeBtnLabel')}
                      </Danger>
                    </Fragment>
                  )}
                </Mutation>
              )}
              <Secondary data-test-id="button-cancel-confirmation" onClick={() => finish(false)}>
                {t('cancelBtnLabel')}
              </Secondary>
            </Row>
          </Stack>
        </Error>
      </Toast>
    </Fragment>
  );
};

RemoveAdminModal.propTypes = {
  title: string,
  message: string,
  mutation: object,
  handleFinish: func,
  isActive: bool,
  adminId: number,
  refetchList: array
};

(RemoveExistingItemAlert as any).propTypes = {
  variables: object,
  handleRemoval: func,
  handleCancel: func,
  isActive: bool,
  mutation: object,
  removalMessage: string,
  removalTitle: string,
  itemId: string,
};

(RemoveStoryModal as any).propTypes = {
  isActive: bool,
  storyTitle: string,
  handleCancel: func,
  delVariables: object,
  activeUnitObj: object,
  handlePostRemoval: func,
};

export default RemoveStoryModal;
