import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { fontSize16, spacing16 } from '@churchofjesuschrist/eden-style-constants';

import { pageNames } from 'src/utils/appConstants/appConstants';
import { usePageContext } from '../contexts/PageContext';
import { PageTitle } from '../utils/appCSS/appStyles';

const { nonPilotPageName } = pageNames;

const Text = styled.div`
  font-size: ${fontSize16};
  margin: ${spacing16} 0;
`;

const NotAvailable = () => {
  const { t } = useTranslation('strings');
  usePageContext(nonPilotPageName);

  return (
    <>
      <PageTitle>{t('nonPilotTitle')}</PageTitle>
      <Text>
        {t('notAuthorizedMessage1')}
      </Text>
      <Text>
        {t('notAuthorizedMessage2')}
      </Text>
    </>
  );
};

export default NotAvailable;
