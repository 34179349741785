import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import storiesReducer from './storiesReducer';
import unitsReducer from './unitsReducer';
import uploadReducer from './uploadReducer';

export const reducers = combineReducers({
  auth: authReducer,
  stories: storiesReducer,
  units: unitsReducer,
  upload: uploadReducer
});
