import React from 'react';
import { Success } from '@churchofjesuschrist/eden-alert';
import Stack from '@churchofjesuschrist/eden-stack';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Text4 } from '@churchofjesuschrist/eden-text';

import Toast from '../../../utils/componentFunctions/toast';

const SuccessToast = ({ active, title, message, onClose }: { active: boolean, title: string, message: string, onClose?: () => void }) => {
  return (
    <Toast active={active}>
      <Success onClose={onClose}>
        <Stack>
          <H4>{title}</H4>
          <Text4>{message}</Text4>
        </Stack>
      </Success>
    </Toast>
  )
};

export default SuccessToast;
