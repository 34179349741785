export type UnitTypes = 'STAKE' | 'DISTRICT' | 'WARD' | 'BRANCH';

export interface UnitBase {
  id: string
  type: UnitTypes
  // dateAdded: Date;
  // status: String;
  parentId: String
  unitNumber: String
}

export interface Unit extends UnitBase {
  name: string
}

export interface UserUnit extends UnitBase {
  unitName: string
  unitType: number
}

export interface UnitOrganization {
  // id: string;
  uuid: string
  name: string
  type: string
  unitName: string
  unitUuid: string
  unitType: UnitType
  unitTypeName: string
}

export const enum UnitType {
  Stake = 5,
  District,
  Ward,
  Branch
}
