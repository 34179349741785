import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// eden
import { Warn } from '@churchofjesuschrist/eden-alert';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Label, Checkbox } from '@churchofjesuschrist/eden-form-parts';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { spacing8, black } from '@churchofjesuschrist/eden-style-constants';

// components
import Toast from 'src/utils/componentFunctions/toast';

// styles
import { AlertClickBlocker } from 'src/utils/appCSS/appStyles';
import { A } from 'src/components/Create/Form/Form.style';

// constants
import { getContentSubmissionPolicyURL } from 'src/utils/AppFunctions/setUrls';

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: [check] auto [text] 1fr;
  grid-template-rows: auto;
`;

const Check = styled.div`
  grid-column-start: check;
  grid-column-end: check;
`;

const Terms = styled.div`
  grid-column-start: text;
  grid-column-end: text;
  padding-left: ${spacing8};
`;

const ImagePermissionModal = ({ isActive, handleFinish }) => {
  const { t } = useTranslation('strings');
  // when the content submission policy is available in additional languages
  // const { t, i18n } = useTranslation('strings');
  // const language = i18n.language;
  // const termsUrl = getContentSubmissionPolicyURL(language);
  const termsUrl = getContentSubmissionPolicyURL(); // defaults to English

  const [active, setActive] = useState(isActive);
  const [isPermissionImageGranted, setIsPermissionImageGranted] = useState(false);

  const finish = (flag: boolean) => {
    handleFinish(flag);
    setActive(false);
    setIsPermissionImageGranted(false);
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <Fragment>
      {active && <AlertClickBlocker opacity={0.6} color={black} zIndex={2} />}

      <Toast active={active}>
        <Warn>
          <Stack>
            <H4>{t('contentPermissionTitleLabel')}</H4>

            <CheckboxGrid>
              <Check>
                <Checkbox
                  checked={isPermissionImageGranted}
                  onChange={() => setIsPermissionImageGranted(!isPermissionImageGranted)}
                  name="permission"
                  id="image_permission"
                  value="permissionGiven"
                />
              </Check>

              <Terms>
                <Label>
                  {t('imagePermissionCheckBoxLabel1')}
                  <A href={termsUrl} target="_blank" rel="noopener noreferrer">
                    {t('termsOfUsePolicyLabel')}
                  </A>
                  {t('imagePermissionCheckBoxLabel2')}
                </Label>
              </Terms>
            </CheckboxGrid>

            <Row>
              <Primary
                type="button"
                disabled={!isPermissionImageGranted}
                onClick={() => finish(true)}
              >
                {t('imagePermissionAcceptedBtnLabel', { ns: 'labels' })}
              </Primary>
              <Secondary type="button" onClick={() => finish(false)}>
                {t('cancelBtnLabel', { ns: 'labels' })}
              </Secondary>
            </Row>
          </Stack>
        </Warn>
      </Toast>
    </Fragment>
  );
};

export default ImagePermissionModal;

ImagePermissionModal.propTypes = {
  isActive: bool,
  handleFinish: func,
};
