// import striptags from 'striptags';

const sendEmail = async (data: any) => {
  console.warn('Email messaging not yet supported!!');
  return await Promise.resolve(200);
  // return await fetch('/email/api', {
  //   method: 'POST',
  //   mode: 'cors',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Accept: 'application/json',
  //   },
  //   body: striptags(JSON.stringify(data), [], ''),
  // })
  //   .then(response => (response.ok ? response : Promise.reject(response)))
  //   .then(responseData => responseData.status)
  //   .catch(error => error.status);
};

export default sendEmail;
