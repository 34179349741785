import React from 'react';
import { useHistory } from 'react-router-dom';

// Eden
import { white, spacing32 } from '@churchofjesuschrist/eden-style-constants';
import { Add } from '@churchofjesuschrist/eden-icons';

// components
import { AddCard, AddBox, AddButton, AddText } from 'src/components/DisplayStories/storyStyles';
import { getPageRedirect } from 'src/components/Stories/utils/RedirectPages';
import { pageNames } from 'src/utils/appConstants/appConstants';
import { useTranslation } from 'react-i18next';

const { createPageName } = pageNames;

export const AddStoryCard = () => {
  const { t } = useTranslation('strings');
  const history = useHistory();

  const handleClick = () => {
    const redirect = getPageRedirect(createPageName);
    history.push(redirect);
  };

  return (
    <AddCard
      key="AddStory"
      data-test-id="addAStoryCard"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <AddBox>
        <AddButton>
          <Add color={white} size={spacing32} />
        </AddButton>
        <AddText>{t('createStoryLabel')}</AddText>
      </AddBox>
    </AddCard>
  );
};

export default AddStoryCard;
