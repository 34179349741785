// queries
import {
  getStoriesQuery,
  totalStoriesCountQuery,
  homePageQuery,
  getStoryManagerQuery,
  totalManagerStoriesCountQuery,
} from '../../utils/graphQL_Queries/appQueries';

// constants
import {
  appConstants,
  orderByDirections,
  orderByProperties,
  STORY_STATUSES,
  UNIT_STATUSES,
} from '../../utils/appConstants/appConstants';

const { DEFAULT_NUM_UNITS } = appConstants;
const { ORDER_BY_MODIFIED, ORDER_BY_PUBLISHED, ORDER_BY_NAME } = orderByProperties;
const { DESCENDING, ASCENDING } = orderByDirections;
const { DRAFT_STATUS, PENDING_STATUS, PUBLISHED_STATUS, RETURNED_STATUS } = STORY_STATUSES;
const { STATUS_ACTIVE } = UNIT_STATUSES;

export const setTabCountQueryParams = ({ author }: { author? }) => {
  return {
    author
  };
};

export const setStoryByStatusQueryParams = (
  author,
  status,
  properties = [ORDER_BY_MODIFIED],
  direction = DESCENDING,
  refresh?
) => {
  return {
    criteria: {
      authoredBy: author,
      status,
    },
    orderBy: { properties, direction },
    refresh,
  };
};

export const setStoryByInviterQueryParams = (
  author,
  inviter,
  status,
  properties = [ORDER_BY_MODIFIED],
  direction = DESCENDING,
  refresh?
) => {
  return {
    criteria: {
      authoredBy: author,
      inviter,
      status,
    },
    orderBy: { properties, direction },
    refresh,
  };
};

export const setSortingStoryQueryParams = (
  parentUnit,
  author,
  status,
  properties = [ORDER_BY_MODIFIED],
  direction = DESCENDING,
  organizations = ['']
) => {
  return {
    criteria: {
      parentUnit,
      authoredBy: author,
      status,
      organizations,
    },
    orderBy: { properties, direction },
  };
};

export const setHomePageStoryQueryParams = (
  searchTerms = '',
  organizations = [''],
  confidential = false,
  filterFavorites = false,
  properties = [ORDER_BY_PUBLISHED],
  direction = DESCENDING,
  refresh?
) => {
  return {
    searchTerms,
    organizations,
    confidential,
    filterFavorites,
    properties,
    direction,
    refresh,
  };
};

export const setMyStoriesPublishedPageQueryParams = (
  author,
  searchTerms = '',
  organizations = [''],
  confidential = false,
  properties = [ORDER_BY_PUBLISHED],
  direction = DESCENDING
) => {
  return {
    author,
    searchTerms,
    organizations,
    confidential,
    properties,
    direction,
  };
};

export const setUnitsQueryParams = (
  startHere: number,
  unitType?: string,
  unitId?: string,
  unitStatus: string = STATUS_ACTIVE
) => {
  return {
    offset: startHere,
    first: DEFAULT_NUM_UNITS,
    criteria: {
      type: unitType,
      id: unitId,
      status: unitStatus,
    },
    orderBy: { properties: [ORDER_BY_NAME], direction: ASCENDING },
  };
};

export const setRefetchVariables = (user) => {
  const refetchListWithVariables = [
    {
      query: totalStoriesCountQuery,
      variables: setTabCountQueryParams({}),
    },
    {
      query: totalStoriesCountQuery,
      variables: setTabCountQueryParams({ author: user.id }),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        null,
        PUBLISHED_STATUS,
        [ORDER_BY_PUBLISHED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        null,
        PENDING_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        null,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        PUBLISHED_STATUS,
        [ORDER_BY_PUBLISHED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        PENDING_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        RETURNED_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoriesQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: homePageQuery,
      variables: setHomePageStoryQueryParams(
        '', // search terms
        [], // organizations
        false, // confidential
        false, // show favorites
        [ORDER_BY_PUBLISHED], // properties (for sorting)
        DESCENDING // direction (for sort order)
      ),
    },
  ];

  return refetchListWithVariables;
};

export const setManagerRefetchVariables = (user) => {
  const refetchListWithVariables = [
    {
      query: totalManagerStoriesCountQuery,
      variables: setTabCountQueryParams({}),
    },
    {
      query: totalManagerStoriesCountQuery,
      variables: setTabCountQueryParams({ author: user.id }),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        null,
        PUBLISHED_STATUS,
        [ORDER_BY_PUBLISHED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        null,
        PENDING_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        null,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        PUBLISHED_STATUS,
        [ORDER_BY_PUBLISHED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        PENDING_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        RETURNED_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: getStoryManagerQuery,
      variables: setStoryByStatusQueryParams(
        user.id,
        DRAFT_STATUS,
        [ORDER_BY_MODIFIED],
        DESCENDING
      ),
    },
    {
      query: homePageQuery,
      variables: setHomePageStoryQueryParams(
        '', // search terms
        [], // organizations
        false, // confidential
        false, // show favorites
        [ORDER_BY_PUBLISHED], // properties (for sorting)
        DESCENDING // direction (for sort order)
      ),
    },
  ];

  return refetchListWithVariables;
};
