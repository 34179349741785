import React from 'react';
import { string, number } from 'prop-types';

import TabStoryCard from '../StoryCard/TabStoryCard';

// constants
import { Story } from 'src/models/Story';
import {
  pageNameType,
  singleStatusType,
  viewStoryPropType,
} from '../../../utils/appConstants/propTypesConstants';

const StoryContent = ({ currentPageName, index, status, story, title }: { currentPageName: string, index: number, status?: string, story: Story, title?: string }) => {
  return (
    <div id={`storyContent${index}`}>
      <TabStoryCard
        story={story}
        currentPageName={currentPageName}
      />
    </div>
  );
}

export default StoryContent;

(StoryContent as any).propTypes = {
  currentPageName: pageNameType,
  index: number,
  status: singleStatusType,
  story: viewStoryPropType,
  title: string, // it's singleStatusType, but it may change to an actual title
};
