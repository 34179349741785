import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import UnitBadge from '../../components/UnitBadge';
import { UnitSelectorContainer } from '../../components/SelectUnit/SelectUnit';

// styles
import { PageTitle, FloatRight, TitleRow, Line } from '../../utils/appCSS/appStyles';

// utilities, constants
import { addPageAnalytics, CATEGORY_TYPE_CREATE } from 'src/utils/analytics';
import { pageNames } from '../../utils/appConstants/appConstants';
import StoryForm from '../../components/Create/Form/StoryForm';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { withPage } from 'src/contexts/PageContext';

const { homePageName, createPageName } = pageNames;

const CreateStoryPage = () => {
  const { activeUnitObj, user } = useUserPermissions();

  const { t, i18n } = useTranslation('strings');

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: createPageName, // always use English
        activePage: createPageName, // used to set primary category
        breadCrumbs: [homePageName, createPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_CREATE,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <>
      <div style={{ paddingTop: '2rem' }}>
        <UnitBadge unit={activeUnitObj}></UnitBadge>
      </div>
      <TitleRow>
        <PageTitle paddingTop="0px">{t('createStoryLabel')}</PageTitle>
        <UnitSelectorContainer>
          <FloatRight cursor="auto">
            {`${activeUnitObj.unitName} (${activeUnitObj.unitNumber})`}
            {/* currentUnits.length > 1 && (
                          <UnitDropDownSelector
                            units={currentUnits}
                            activeUnitId={activeUnitObj.id}
                            changeHandler={handleUnitChange}
                            strings={strings}
                            showId
                            // showHelp
                          />
                      ) */}
          </FloatRight>
        </UnitSelectorContainer>
      </TitleRow>

      <Line />

      <StoryForm
        isCreatePage
        prevPageName={createPageName}
      />
    </>
  );
};

export default withPage({ pageName: createPageName, pageTitle: 'createStoryPageTitle' })(CreateStoryPage);
