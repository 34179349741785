import React from 'react';
import { number, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Eden
import { Secondary } from '@churchofjesuschrist/eden-buttons';

// functions
import { setViewStoryUrl } from 'src/utils/AppFunctions/setUrls';
import { storyIdType } from 'src/utils/appConstants/propTypesConstants';

export const ViewStoryBtn = ({ currentPageName, index, storyId, activeUnitObj }: { currentPageName?, index?, storyId?, activeUnitObj? }) => {
  const router = useHistory();
  const { t } = useTranslation('labels');

  return (
    <Secondary
      onClick={() => router.push(setViewStoryUrl(storyId, currentPageName))}
      data-test-id={`viewStoryBtn${index}`}
    >
      {t('viewStoryBtnLabel')}
    </Secondary>
  );
}

export default ViewStoryBtn;

ViewStoryBtn.propTypes = {
  currentPageName: string.isRequired,
  index: number.isRequired,
  storyId: storyIdType,
};
