import React from 'react';
import { MutationFunction, MutationResult, OperationVariables } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';

// eden
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import { Warn } from '@churchofjesuschrist/eden-alert';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';
import Row from '@churchofjesuschrist/eden-row';
import Stack from '@churchofjesuschrist/eden-stack';
import Modal from '@churchofjesuschrist/eden-platform-modal';

// queries
import { saveStoryMutation } from 'src/utils/graphQL_Queries/appMutations';

// constants
import { func } from 'prop-types';

const NotSavedModal = ({ handleContinueSurf, handleCancelSurf }: { handleContinueSurf?, handleCancelSurf? }) => {
  const { t } = useTranslation();

  return (
    <Mutation mutation={saveStoryMutation}>
      {(saveStoryMutationFunc: MutationFunction<any, OperationVariables>, { error, loading }: MutationResult<any>) => {
        if (loading) return <LoadingSpinner />;
        if (error) {
          return (
            <ErrorModal
              title={t('saveErrorMessage')}
              onRetry={saveStoryMutationFunc}
              errors={error}
              isActive
            />
          );
        }

        return (
          <Modal active={true} onCloseGesture={() => handleCancelSurf()}>
            <Warn>
              <Stack>
                <span>{t('unsavedChangesTitle', { ns: 'strings' })}</span>
                <span>{t('unsavedChangesBody', { ns: 'strings' })}</span>
                <Row>
                  <Primary onClick={e => handleContinueSurf(e, saveStoryMutationFunc)}>
                    {t('saveAsDraftBtnLabel', { ns: 'labels' })}
                  </Primary>
                  <Secondary onClick={() => handleCancelSurf()}>
                    {t('cancelBtnLabel', { ns: 'labels' })}
                  </Secondary>
                </Row>
              </Stack>
            </Warn>
          </Modal>
        );
      }}
    </Mutation>
  );
}

export default NotSavedModal;

(NotSavedModal as any).propTypes = {
  handleContinueSurf: func.isRequired,
  handleCancelSurf: func.isRequired,
};
