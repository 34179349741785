import React, { useState } from 'react';

import Stack from '@churchofjesuschrist/eden-stack';
import { Label } from '@churchofjesuschrist/eden-form-parts';
import { pageNames } from 'src/utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import Form from 'src/components/Form/Form';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';
import { IUnitAccess } from 'src/models/UnitAccess';
import { useHistory } from 'react-router-dom';
import { VALIDATE_PROXY } from 'src/utils/graphQL_Queries/appMutations';
import { PROXY_USERS_QUERY, UNIT_SEARCH } from 'src/utils/graphQL_Queries/appQueries';
import { RadioField } from 'src/components/Form/RadioField';
import SelectField from 'src/components/Form/SelectField';
import { determineValidityState } from 'src/utils/formUtil';
import { PageTitle } from 'src/utils/appCSS/appStyles';

const { changeRolePageName } = pageNames;

let filterTimeout: NodeJS.Timeout;
const isSafari =
  !navigator.userAgent.includes('Chrome') &&
  navigator.userAgent.includes('Safari');
const fieldList = ['calling', 'unitName', 'level', 'userSearch'];

const ChangeRole = () => {
  const { t } = useTranslation('labels');
  const router = useHistory();
  const [unitSearchResults, setUnitSearchResults] = useState<any[]>([]);
  const [userSearchResults, setUserSearchResults] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    level: '',
    unitId: '',
    userId: '',
    unitName: '',
    userSearch: '',
  });
  const [submit] = useMutation(VALIDATE_PROXY);
  const [searchUnits, { loading: unitsLoading }] = useLazyQuery(UNIT_SEARCH);
  const [searchUsers, {
    loading: usersListLoading,
  }] = useLazyQuery<{ proxyUsers: IUnitAccess[] }>(PROXY_USERS_QUERY);

  const onSubmit = async (formData) => {
    const { data } = await submit({
      variables: {
        uuid: formData.userId,
      },
    });

    if (!data?.validateProxy) return;

    // do a full page navigation (not router.push) to clear any cached info for the logged in user in favor of the proxy user
    window.location.href = '/';
  };
  const onCancel = () => {
    router.push('/');
  };

  const onUnitSearch = (event, ref: React.MutableRefObject<HTMLInputElement>) => {
    const searchString = event.target.value;
    setFormData({
      ...formData,
      unitName: searchString
    });
    // first check to see if we have a direct match of the string in the unitList. If so, use that and don't call the API for units. Otherwise, call the API.
    const unitListMatch = unitSearchResults?.find(unit => unit.name === searchString);
    if (unitListMatch) {
      // Clear out the list so it doesn't show up when the input is focused
      setUnitSearchResults([]);
      setFormData({
        ...formData,
        unitName: unitListMatch.name,
        unitId: unitListMatch.id
      });
    } else if (
      searchString?.length > 2
    ) {
      // It wasn't a match, so this must be a new search
      // Debounce it so we aren't sending out a request for every keystroke
      clearTimeout(filterTimeout);

      const searchStringWildcard = searchString + '%';
      filterTimeout = setTimeout(() => {
        doSearch(searchStringWildcard, ref);
      }, 500);
    } else {
      // no match and search string doesn't match criteria, so clear out some stuff
      setUnitSearchResults([]);
    }
  }

  const onUserSearch = (event, ref: React.MutableRefObject<HTMLInputElement>) => {
    const searchString = event.target.value;
    setFormData({
      ...formData,
      userSearch: searchString
    });
    // first check to see if we have a direct match of the string in the unitList. If so, use that and don't call the API for units. Otherwise, call the API.
    const userListMatch = userSearchResults?.find(user => user.name === searchString);
    if (userListMatch) {
      // Clear out the list so it doesn't show up when the input is focused
      setUserSearchResults([]);
      setFormData({
        ...formData,
        userSearch: userListMatch.name,
        userId: userListMatch.id
      });
    } else if (
      searchString?.length > 2
    ) {
      // It wasn't a match, so this must be a new search
      // Debounce it so we aren't sending out a request for every keystroke
      clearTimeout(filterTimeout);

      filterTimeout = setTimeout(() => {
        doUserSearch(searchString, ref);
      }, 500);
    } else {
      // no match and search string doesn't match criteria, so clear out some stuff
      setUserSearchResults([]);
    }
  }

  const doSearch = async (searchString: string, ref: React.MutableRefObject<HTMLInputElement>) => {
    const unitTypes = formData.level?.split(',').map(l => parseInt(l));
    const { data } = await searchUnits({
      variables: {
        search: searchString,
        types: unitTypes
      }
    });
    const results: { id?, type?, name }[] = (data.searchUnits ?? []).map((r, i) => ({
      id: r.uuid,
      name: r.name + ' (' + r.unitNumber + ')',
      type: r.type
      // name: (i + 1) + ' - ' + r.fullName + ' - ' + r.membershipUnit.unitName
    }));

    // TODO: Re-evaluate if this is still needed every so often. Safari has a different issue where it won't dynamically update the displayed datalist without blurring and focusing the field. so the hack below takes care of it https://bugs.webkit.org/show_bug.cgi?id=201121
    if (isSafari) {
      const theInput = ref?.current;
      const theDatalist = theInput.nextElementSibling;
      const listName = `list${new Date().getTime()}`;
      theInput.setAttribute('list', listName);
      theDatalist?.setAttribute('id', listName);
      setTimeout(() => {
        // setDatalistID(unitSearchString);
        theInput.blur();
        theInput.focus();
        theInput.click();
        console.log('safari patched', theInput);
      }, 500);
    }
    setUnitSearchResults(results);
  }

  const doUserSearch = async (searchString: string, ref: React.MutableRefObject<HTMLInputElement>) => {
    const { data } = await searchUsers({
      variables: {
        search: searchString,
        unitUuid: formData.unitId
      }
    });
    const results: { id?, name }[] = (data?.proxyUsers ?? []).map((r, i) => ({
      id: r.uuid,
      name: r.fullName
    }));

    // TODO: Re-evaluate if this is still needed every so often. Safari has a different issue where it won't dynamically update the displayed datalist without blurring and focusing the field. so the hack below takes care of it https://bugs.webkit.org/show_bug.cgi?id=201121
    if (isSafari) {
      const theInput = ref?.current;
      const theDatalist = theInput.nextElementSibling;
      const listName = `list${new Date().getTime()}`;
      theInput.setAttribute('list', listName);
      theDatalist?.setAttribute('id', listName);
      setTimeout(() => {
        theInput.blur();
        theInput.focus();
        theInput.click();
        console.log('safari patched', theInput);
      }, 500);
    }
    setUserSearchResults(results);
  }

  return (
    <>
      <PageTitle>{t('changeRole')}</PageTitle>
      <Form
        fieldList={fieldList}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        onCancel={onCancel}
        afterSuccess={onCancel}
        primaryErrorText={t('proxyValidationError')}
        primaryDisabled={!formData.userId}
      >
        {({ commonFormProps }) => {
          return (
            <Stack gapSize="32">
              <RadioField
                name="level"
                label={`${t('changeRoleLevel')} ${t('requiredLabel')}`}
                values={[
                  // RadioField expects strings, so can't send values in as an array
                  {
                    value: `${ldsxrm_unittypes.Ward},${ldsxrm_unittypes.Branch}`,
                    label: t('wardBranch'),
                  },
                  {
                    value: `${ldsxrm_unittypes.Stake},${ldsxrm_unittypes.District},${ldsxrm_unittypes.Mission}`,
                    label: t('stakeDistrict'),
                  }
                ]}
                {...commonFormProps}
                onChange={(event) => {
                  // set the level and clear out other data
                  setFormData({
                    ...formData,
                    unitName: '',
                    unitId: '',
                    level: event.target.value,
                  });
                }}
              />
              {
                // comparing to the strings created for the RadioField, so have to recreate them
                [
                  `${ldsxrm_unittypes.Area}`,
                  `${ldsxrm_unittypes.Stake},${ldsxrm_unittypes.District},${ldsxrm_unittypes.Mission}`,
                  `${ldsxrm_unittypes.Ward},${ldsxrm_unittypes.Branch}`,
                ].includes(formData.level) && (
                  <>
                    <Label id="roleUnitLabel" validityState={determineValidityState(commonFormProps.validity?.unitName)} style={{ maxWidth: '500px' }}>
                      {t('changeRoleUnit')}
                      <SelectField
                        {...commonFormProps}
                        name="unitName"
                        type="typeahead"
                        autoComplete="off"
                        loading={unitsLoading}
                        onChange={(event) => onUnitSearch(event, commonFormProps.refs.unitName)}
                        options={unitSearchResults?.map(u => ({
                          label: u.id,
                          value: u.name
                        }))}
                      />
                    </Label>

                    {formData.unitId &&
                      <Label id="roleUserLabel" validityState={determineValidityState(commonFormProps.validity?.userSearch)} style={{ maxWidth: '500px' }}>
                        {t('changeRoleUser')}
                        <SelectField
                          {...commonFormProps}
                          name="userSearch"
                          type="typeahead"
                          autoComplete="off"
                          loading={usersListLoading}
                          onChange={(event) => onUserSearch(event, commonFormProps.refs.userSearch)}
                          options={userSearchResults?.map(u => ({
                            label: u.id,
                            value: u.name
                          }))}
                        />
                      </Label>
                    }
                  </>
                )
              }
            </Stack>
          );
        }}
      </Form>
    </>
  );
};

export default withPage({ pageName: changeRolePageName, pageTitle: 'changeRolePageTitle' })(ChangeRole);
