import styled from 'styled-components';
import i18next from 'i18next';

// Eden
import {
  white,
  black,
  confirmation10,
  danger10,
  spacing8,
} from '@churchofjesuschrist/eden-style-constants';

export const goodColor = confirmation10;
export const badColor = danger10;
export const TileHeader = styled.div<{ good?: boolean, invert?: boolean }>`
  height: 40px;
  padding: ${spacing8};
  margin-bottom: ${spacing8};
  background-color: ${props => (props.good ? goodColor : badColor)};
  color: ${props => (props.invert ? black : white)};
  box-shadow: 1px 1px 5px 1px rgba(74, 74, 74, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// NB: the content in getMeaningfulExampleStories is not used. It was left here so the app will build.
// we used the lables from story2 keys.

// Note: t must be passed in
export const getMeaningfulExampleStories = (t = i18next.t) => (
  [
    {
      exampleId: 'primaryProgram',
      drawerTitle: t('leadershipStory2DrawerTitle'),
      title: t('leadershipStory2Title'),
      date: '14 Oct. 2018',
      organizations: ['Primary'],
      authors: 'Jean Simpson',
      paragraphs: [
        {
          paragraphNumber: 0,
          text: t('leadershipStory2P00'),
          bulletPoints: [],
        },
      ],
      images: [],
    },
  ]);

export const getLeaershipReflectionStories = (t = i18next.t) => (
  [
    {
      exampleId: 'yearInReview',
      drawerTitle: t('leadershipStory2DrawerTitle'),
      title: t('leadershipStory2Title'),
      date: '13 Jan. 2017',
      organizations: ['Branch Presidency'],
      authors: 'Gamali Akoeshihu',
      paragraphs: [
        {
          paragraphNumber: 0,
          text: t('leadershipStory2P00'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 1,
          text: t('leadershipStory2P01'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 2,
          text: t('leadershipStory2P02'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 3,
          text: t('leadershipStory2P03'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 4,
          text: t('leadershipStory2P04'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 5,
          text: t('leadershipStory2P05'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 6,
          text: t('leadershipStory2P06'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 7,
          text: t('leadershipStory2P07'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 8,
          text: t('leadershipStory2P08'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 9,
          text: t('leadershipStory2P09'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 10,
          text: 'COMPILED BY: GAMALI AKOESHIHU (BRANCH PRESIDENT',
          bulletPoints: [],
        },
      ],
      images: [
        {
          altText: 'Figure 1: Adult literacy class, taught by Sister Awesi Ampaw',
          filePath: '/static/images/yearInReview_Figure_1_Adult_literacy_class.jpg',
        },
        {
          altText: 'Figure 2: Literacy class in our new meetinghouse',
          filePath: '/static/images/yearInReview_Figure_2_Literacy_class.jpg',
        },
        {
          altText: 'Figure 3: Clean-up service project at the Hohoe main lorry station',
          filePath: '/static/images/yearInReview_Figure_3_Clean_up_service.jpg',
        },
        {
          altText: 'Figure 4: New meetinghouse, 10 July 2022',
          filePath: '/static/images/yearInReview_Figure_4_New_meetinghouse.jpg',
        },
        {
          altText: 'Figure 5: Dedication on 10 July 2022',
          filePath: '/static/images/yearInReview_Figure_5_Dedication_on.jpg',
        },
      ],
    },
    {
      exampleId: 'miracleInJuneWithNancyBower',
      drawerTitle: t('leadershipStory3DrawerTitle'),
      title: t('leadershipStory3Title'),
      date: '31 Dec. 2018',
      organizations: ['Bishopric'],
      authors: 'Wade Crossley',
      paragraphs: [
        {
          paragraphNumber: 0,
          text: t('leadershipStory3P00'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 1,
          text: t('leadershipStory3P01'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 2,
          text: t('leadershipStory3P02'),
          bulletPoints: [],
        },
        {
          paragraphNumber: 3,
          text: t('leadershipStory3P03'),
          bulletPoints: [],
        },
      ],
      images: [
        {
          altText: 'Figure 1: Nancy and Jim Bower at their home.',
          filePath: '/static/images/miracleInJuneWithNancyBower_Figure_1_Nancy_and_Jim_Bower.jpg',
        },
      ],
    },
  ]);

export const sampleOrganizations = [
  {
    id: '187ff8c9-878c-ed11-81ac-6045bd0061eb',
    name: 'Aaronic Priesthood Quorums',
    uuid: '1a562ca9-f485-4b67-9d7f-237749f25c3c',
    unitUuid: null,
    unitName: null
  },
  {
    id: '507ff8c9-878c-ed11-81ac-6045bd0061eb',
    name: 'Young Women',
    uuid: '430fd4d8-fdba-40b7-8bb8-6b29872308ff',
    unitUuid: null,
    unitName: null
  },
  {
    id: '151c41b7-ea8d-ed11-81ac-6045bd0069de',
    name: 'Relief Society',
    uuid: 'f9be4c99-1014-41da-bee9-6ace333051a8',
    unitUuid: null,
    unitName: null
  },
  {
    id: '171c41b7-ea8d-ed11-81ac-6045bd0069de',
    name: 'Primary',
    uuid: 'f2f4655d-34d2-476e-99e1-bda99b27f8a1',
    unitUuid: null,
    unitName: null
  },
  {
    id: '191c41b7-ea8d-ed11-81ac-6045bd0069de',
    name: 'Sunday School',
    uuid: '1c1f2470-d05b-4071-905d-73a3130be03a',
    unitUuid: null,
    unitName: null
  },
  {
    id: '34399e9e-5076-ed11-81aa-6045bd006d32',
    name: 'Bishopric',
    uuid: 'fb8e159d-4c18-4a6b-ae6d-23e30e32b8af',
    unitUuid: null,
    unitName: null
  },
  {
    id: '59399e9e-5076-ed11-81aa-6045bd006d32',
    name: 'Elders Quorum',
    uuid: 'a2a13993-1f6b-4338-a63a-1a3670939502',
    unitUuid: null,
    unitName: null
  }
];
