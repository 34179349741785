import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Constants
import { STORY_STATUSES } from 'src/utils/appConstants/appConstants';
import {
  usePositionConstants
} from 'src/utils/appConstants/positionsConstants';

import moment from 'moment';
import { convertHtmlToContent } from '@churchofjesuschrist/eden-rich-text-editor';
import { Story } from 'src/models/Story';

// functions
import { formattedDisplayName } from '../../utils/AppFunctions/appFunctions';
import { IAuthor } from 'src/models/Author';
import { ldsxrm_unittypes } from 'src/common/enums/ldsxrm_churchunit';
import { ldsxrm_positions } from 'src/common/enums/ldsxrm_churchposition';

const { CREATED_STATUS, DRAFT_STATUS, REQUESTED_STATUS, RETURNED_STATUS } = STORY_STATUSES;

const normalizeFiles = async (fileList) => {
  const filesToSave: File[] = [];
  if (!fileList) {
    return [];
  }

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    const fetchinResponse = await fetch(file.base64String);

    if (!fetchinResponse.blob) continue;

    const blob = await fetchinResponse.blob();
    const blobbyFile = new File([blob], file.name, {
      type: blob.type,
    });
    filesToSave.push(blobbyFile);
  }

  return filesToSave;
};

export const normalizeAuthors = authors => {
  if (!authors || !authors.length) {
    return [];
  }

  return authors.map(author => {
    return {
      uuid: author.uuid,
      accountId: author.accountId,
      email: author.email,
      name: author.name,
      position: author.position,
    };
  });
};

export const normalizeImageFiles = images => {
  return images?.map(imageData => {
    return {
      id: imageData.id,
      alt: imageData.alt ? imageData.alt : imageData.name,
      sources: imageData.sources,
    };
  }) || [];
};

export const normalizeDocumentFiles = documents => {
  return documents?.map(documentData => {
    return {
      id: documentData.id,
      name: documentData.name,
      source: documentData.source ? documentData.source : '',
      type: documentData.type,
    };
  }) || [];
};

export const determineValidityState = validityState => {
  return validityState === true ? 'success' : validityState === false ? 'error' : 'unknown';
};

export const validateDate = (startDate: string | null, endDate: string | null, isInvite = false, minDate?: string, maxDate?: string) => {
  // for testing. can remove after we are certain this is working correctly.
  // console.log('compareDesc(endDate, maxDate): ', compareDesc(endDate, maxDate));
  // console.log('compareDesc(startDate, endDate): ', compareDesc(startDate, endDate));
  const isStartDateValid = startDate && (isInvite || !minDate || compareAsc(parseISO(startDate || ''), parseISO(minDate || '')) > -1);
  const isEndDateValid =
    !endDate ||
    !maxDate ||
    (isInvite && !startDate) ||
    (compareDesc(parseISO(endDate), parseISO(maxDate || '')) > -1 && compareDesc(parseISO(startDate ?? ''), parseISO(endDate)) > -1);
  if (isStartDateValid && isEndDateValid) {
    return true;
  }
  return false;
};

export const validateForm = (content: { authors: any[], title: string, dateEnd: string, dateStart: string, body: any }, formName, minDate?: string, maxDate?: string) => {
  const { authors, title, dateEnd, dateStart, body } = content;

  const isInvite = formName === 'invite';

  const isDateValid = validateDate(dateStart, dateEnd, isInvite, minDate, maxDate);

  const textLength = convertHtmlToContent(body)
    .blocks.map(b => b.text.length)
    .reduce((total: number, next: number) => total + next);

  const formValidityState = {
    authorValidityState: determineValidityState(authors?.length > 0),
    dateValidityState: determineValidityState(isDateValid),
    summaryValidityState: determineValidityState(isInvite || textLength > 0),
    titleValidityState: determineValidityState(title?.length > 0),
  };

  return formValidityState;
};

export const moveEndDateToStartDate = (startDate, endDate) => {
  // Ensure that there is never a start date without and end date
  let newDateStart = startDate;
  let newDateEnd = endDate;

  if (!startDate && endDate) {
    newDateStart = endDate;
    newDateEnd = null;
  }

  return { dateStart: newDateStart, dateEnd: newDateEnd };
};

export const setStoryObject = (story: Story) => {
  const { dateEnd, dateStart } = story;
  const adjustedDate = moveEndDateToStartDate(dateStart, dateEnd);

  const newDateEnd = adjustedDate.dateEnd === undefined ? null : moment(adjustedDate.dateEnd).toISOString();
  const newDateStart = moment(adjustedDate.dateStart).toISOString();

  const variables = {
    authors: normalizeAuthors(story.authors),
    personTags: story.personTags,
    body: story.body,
    confidential: false,
    dateStart: newDateStart === 'Invalid date' ? null : newDateStart,
    dateEnd: newDateEnd === 'Invalid date' ? null : newDateEnd,
    id: story.id,
    status: !story.status || story.status === CREATED_STATUS ? DRAFT_STATUS : story.status,
    lock: story.lock,
    organizations: story.organizations,
    title: story.title,
    unit: normalizeUnit(story.unit),
    sensitive: story.sensitive,
    sensitiveComments: story.sensitiveComments,
  };

  return variables;
};

export const normalizeUnit = currentParentUnit => {
  if (!currentParentUnit) return null;
  const newParentUnit = {
    id: currentParentUnit.id,
    name: currentParentUnit.name,
    type: currentParentUnit.type,
  };

  return newParentUnit;
};

export const setSaveStoryObjectFromInvitation = invitation => {
  const normalizedImages = normalizeImageFiles([]);
  const normalizedAttachments = normalizeDocumentFiles([]);

  const { dateEnd, dateStart } = invitation;
  const adjustedDate = moveEndDateToStartDate(dateStart, dateEnd);

  const newDateStart = adjustedDate.dateStart
    ? new Date(adjustedDate.dateStart).toISOString()
    : null;
  const newDateEnd = adjustedDate.dateEnd ? new Date(adjustedDate.dateEnd).toISOString() : null;

  const result = {
    documents: [],
    images: [],
    story: {
      attachments: normalizedAttachments,
      authors: normalizeAuthors(invitation.authors),
      body: '',
      notes: invitation.notes,
      confidential: false,
      dateEnd: newDateEnd,
      dateStart: newDateStart,
      images: normalizedImages,
      organizations: invitation.organizations,
      title: invitation.title,
      unit: normalizeUnit(invitation.unit),
    },
  };

  return result;
};

export const convertToFileData = async (url: string) =>
  await new Promise(resolve => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = e => {
        resolve(e.target?.result);
      };
    };
    request.send();
  });

export const convertToFile = async (url: string, name: string) => {
  const res = await fetch(url)
  const buf = await res.arrayBuffer();
  return new File([buf], name);
}

export const convertToFileInputs = files => {
  const fileInputs: any[] = [];

  const third = files.length / 3;
  for (let i = 0; i < third; i++) {
    // names are in the first third of promises
    const nextName = files[i];
    // data is in the second third
    const nextData = files[i + third];
    // files are in the last third
    const nextFile = files[i + third + third];

    const fileInput = {
      name: nextName,
      base64String: nextData,
      file: nextFile,
    };
    fileInputs.push(fileInput);
  }

  return fileInputs;
};

export const getMainImage = (story: Story) => {
  if (!story.image && !story.images?.length) {
    return '';
  }
  const thumb = story.image ?? story.images?.find(i => i.preview) ?? story.images?.[0];
  if (!thumb?.sources?.length) {
    return story.mainImageSource;
  }
  return thumb.sources[0];
};

export const setSaveStoryVariables = (story) => {
  const { textLength } = story;
  const storyInputObject = setStoryObject(story);
  const { title, authors, dateStart, dateEnd } = storyInputObject;

  const isDateValid = validateDate(dateStart, dateEnd);

  if (
    ![CREATED_STATUS, DRAFT_STATUS, REQUESTED_STATUS, RETURNED_STATUS].includes(storyInputObject.status) &&
    (!title || authors.length === 0 || !isDateValid || textLength === 0)
  ) {
    return 'error';
  } else {
    const variables = {
      story: storyInputObject
    };

    return variables;
  }
};

export const setAddImageVariables = async (storyId, imageFiles) => {
  const files = await normalizeFiles(imageFiles);
  return {
    images: files,
    storyId
  };
}

export const setAddAttachmentVariables = async (storyId, attachmentFiles) => {
  const files = await normalizeFiles(attachmentFiles);
  return {
    attachments: files,
    storyId
  };
}

export const useFormFunctions = () => {
  const { WARD_AUXILIARIES_OBJ, STAKE_AUXILIARIES_OBJ, BRANCH_AUXILIARIES_OBJ, DISTRICT_AUXILIARIES_OBJ } = usePositionConstants();
  const { t } = useTranslation('strings');
  let OBJ: {
    auxiliaryName: string
    positions: {
      displayName: string
      positionName: string
      id: ldsxrm_positions
    }[]
  }[] = [];
  const formatPersonObj = (authors: IAuthor[], activeUnitType: ldsxrm_unittypes, isEmailDependent = false) => {
    switch (activeUnitType) {
      case ldsxrm_unittypes.Stake:
        OBJ = STAKE_AUXILIARIES_OBJ;
        break;
      case ldsxrm_unittypes.Ward:
        OBJ = WARD_AUXILIARIES_OBJ;
        break;
      case ldsxrm_unittypes.District:
        OBJ = DISTRICT_AUXILIARIES_OBJ;
        break;
      case ldsxrm_unittypes.Branch:
        OBJ = BRANCH_AUXILIARIES_OBJ;
        break;
      default:
        break;
    }
    const peopleToDisplay = OBJ.map(aux => {
      const formatedPositions: any[] = [];
      let missingPositions = aux.positions;

      aux.positions.forEach(pos => {
        authors.filter(author => pos.id === author.position && activeUnitType === author.unitType).forEach(author => {
          const simpleName = formattedDisplayName(author.formattedName);

          if (isEmailDependent && !author.email) {
            formatedPositions.push({
              id: pos.id,
              auxiliaryName: aux.auxiliaryName,
              isChecked: false,
              isDisabled: true,
              name: `${simpleName} - ${t('noEmailLabel')}`,
              value: pos.positionName,
              accountId: author.accountId,
              uuid: author.uuid
            });
          } else {
            formatedPositions.push({
              id: pos.id,
              auxiliaryName: aux.auxiliaryName,
              isChecked: false,
              isDisabled: false,
              name: aux.auxiliaryName ? simpleName : `${simpleName} - ${pos.displayName}`,
              value: pos.positionName,
              accountId: author.accountId,
              uuid: author.uuid
            });
          }

          // remove item from missingPositions
          missingPositions = missingPositions.filter(el => el.id !== author.position);
        });
      });

      missingPositions.forEach(pos => {
        formatedPositions.push({
          id: pos.id,
          auxiliaryName: aux.auxiliaryName,
          isChecked: false,
          isDisabled: true,
          name: `${pos.displayName} - ${t('notAssignedLabel')}`,
          value: pos.positionName,
        });
      });

      return {
        auxiliaryName: aux.auxiliaryName,
        positions: formatedPositions,
      };
    });

    return peopleToDisplay;
  };

  return {
    formatPersonObj
  };
}
