export enum AuthorStatus {
  Active = 1,
  Removed = 881930000,
  Invited = 881930001,
  Inactive = 2
}

export interface IAuthor {
  status: AuthorStatus
  name: string
  formattedName: string
  accountId: string
  email?: string
  position?: number
  uuid?: string
  unitNumber?: string
  unitType?: number
}
