import styled from 'styled-components';
import {
  spacing4,
  spacing8,
  spacing16,
  spacing48,
} from '@churchofjesuschrist/eden-style-constants';
import { MCard, flexRow } from 'src/components/ComponentStyles/div';

export const mediumScreen = 800;

export const Card = styled(MCard)`
  position: relative;
  width: 100%;
  padding: ${spacing16};
  padding-bottom: ${spacing4};
  :hover {
    box-shadow: 2px 2px 5px 1px rgba(74, 74, 74, 0.5);
  }
`;

export const VerticalTileWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .unit-badge {
    position: absolute;
    /* bottom: ${spacing16}; */  /* Note: this is off by 2px */
    bottom: 1.1rem;
    right: ${spacing48};
  }

  .favorite {
    position: absolute;
    bottom: ${spacing16};
    right: ${spacing16};
  }

  img {
    height: unset;
    width: unset;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const Favorite = styled(flexRow) <{ hasImage?: boolean }>`
  position: absolute;
  right: 11px;
  display: flex;
  justify-content: flex-end;
  top: ${props => (props.hasImage ? 103 : spacing8)}px;
  @media (min-width: ${mediumScreen}px) {
    top: ${props => (props.hasImage ? 210 : spacing8)}px;
  }
`;

export const ImageLink = styled.a`
  text-align: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 165px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`;
