import i18next from 'i18next';

// Note: t must be passed in
export const getScriptureList = (t = i18next.t) => {
  return [
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/bofm-title?lang=eng',
      linkText:
        t('scriptureBofmTitlePage'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/1-ne/6?lang=eng&id=p4-p6#p4',
      linkText:
        t('scripture1Nephi646'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/2-ne/33?lang=eng&id=p4#p4',
      linkText:
        t('scripture2Nephi334'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/jacob/4?lang=eng&id=p3-p4#p3',
      linkText:
        t('scriptureJacob434'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/jarom/1?lang=eng&id=p9#p9',
      linkText:
        t('scriptureJarom19'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/jarom/1?lang=eng&id=p9#p9',
      linkText:
        t('scriptureOmni16'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/w-of-m/1?lang=eng&id=p5-p6#p5',
      linkText:
        t('scriptureWOM156'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/bofm/alma/37?lang=eng&id=p8#p8',
      linkText:
        t('scriptureAlma378'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/69?lang=eng&id=p3,p8#p3',
      linkText:
        t('scriptureDC6938'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/dc-testament/dc/85?lang=eng&id=p1-p2#p1',
      linkText:
        t('scriptureDC8512'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/scriptures/ot/mal/3?lang=eng&id=p16#p1',
      linkText:
        t('scriptureMalachi316'),
    },
  ];
}

export const getConferenceTalksList = (t = i18next.t) => {
  return [
    {
      href: 'https://www.churchofjesuschrist.org/study/general-conference/2007/10/o-remember-remember?lang=eng',
      linkText:
        t('conferenceTalkORememberEyring'),
    },
    {
      href: 'https://www.churchofjesuschrist.org/study/general-conference/2007/04/remember-and-perish-not?lang=eng',
      linkText:
        t('conferenceTalkRememberPerishNotJensen'),
    },
  ];
}

export const getWardCallingsWithAuthorPermissions = (t = i18next.t) => {
  return [
    t('BISHOPRIC'),
    t('WARD_EXECUTIVE_SECRETARY'),
    t('WARD_ASSISTANT_EXECUTIVE_SECRETARY'),
    t('WARD_CLERK'),
    t('WARD_ASSISTANT_CLERK'),
    t('HISTORY_SPECIALIST'),
    t('ELDERS_QUORUM_PRESIDENCY_INCLUDING_SECRETARY'),
    t('RELIEF_SOCIETY_PRESIDENCY_INCLUDING_SECRETARY'),
    t('AARONIC_PRIESTHOOD_QUORUM_ADVISERS'),
    t('YOUNG_WOMEN_PRESIDENCY_INCLUDING_SECRETARY'),
    t('YOUNG_WOMEN_CLASS_ADVISER'),
    t('SUNDAY_SCHOOL_PRESIDENCY_INCLUDING_SECRETARY'),
    t('PRIMARY_PRESIDENCY_INCLUDING_SECRETARY'),
    t('WARD_MISSION_LEADER'),
    t('WARD_TEMPLE_FAMILY_HISTORY_LEADER'),
  ];
}

export const getStakeCallingsWithAuthorPermissions = (t = i18next.t) => {
  return [
    t('STAKE_PRESIDENCY'),
    t('STAKE_EXECUTIVE_SECRETARY'),
    t('STAKE_CLERK'),
    t('STAKE_ASSISTANT_CLERK'),
    t('HISTORY_SPECIALIST'),
    t('STAKE_HIGH_COUNCILORS'),
    t('STAKE_RELIEF_SOCIETY_PRESIDENCY_INCLUDING_SECRETARY'),
    t('STAKE_YOUNG_MEN_PRESIDENCY_INCLUDING_SECRETARY'),
    t('STAKE_YOUNG_WOMEN_PRESIDENCY_INCLUDING_SECRETARY'),
    t('STAKE_SUNDAY_SCHOOL_PRESIDENCY_INCLUDING_SECRETARY'),
    t('STAKE_PRIMARY_PRESIDENCY_INCLUDING_SECRETARY'),
    t('STAKE_TEMPLE_AND_FAMILY_HISTORY_CONSULTANT'),
    t('COMMUNICATION_DIRECTOR'),
    t('ASSISTANT_COMMUNICATION_DIRECTOR'),
    t('COMMUNICATION_SPECIALIST'),
  ];
}
