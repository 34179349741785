import React from 'react';
import styled from 'styled-components';
import { array, func, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import BaseRow from '@churchofjesuschrist/eden-row';
import Tag from '@churchofjesuschrist/eden-tag';
import { spacing8 } from '@churchofjesuschrist/eden-style-constants';

const Row = styled(BaseRow)`
  padding-top: ${spacing8};
`;

const DisplayTags = ({
  tagArray = [] as any[],
  removeTag = (id) => { },
  removeAll = () => { },
  showCloseBtn = false,
  idField = 'uuid', // default for organizations, but person needs to use id (this allows it to be overridden)
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      {tagArray.map((tag) => (
        <Tag showClose={showCloseBtn} key={tag[idField]} onClick={() => removeTag(tag[idField])}>
          {tag?.name ?? ''}
        </Tag>
      ))}
      {tagArray.length
        ? (
          <Tag showClose={true} key="all" onClick={() => removeAll()}>
            {t('clearAllTagLabel')}
          </Tag>
        )
        : null}
    </Row>
  )
};

export default DisplayTags;

DisplayTags.propTypes = {
  tagArray: array,
  removeTag: func,
  removeAll: func,
  showCloseBtn: bool,
  idField: string,
};
