import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Eden
import MediaQuery from '@churchofjesuschrist/eden-media-query';

// Components
import Layout from 'src/components/Layout';

// constants
import {
  DEVICE_SIZES,
  DEVICE_XSMALL,
  DEVICE_SMALL,
  DEVICE_SMALL_QUERY,
  DEVICE_MEDIUM,
  DEVICE_MEDIUM_QUERY,
  DEVICE_LARGE,
  DEVICE_LARGE_QUERY,
  DEVICE_XLARGE,
  DEVICE_XLARGE_QUERY,
} from '../utils/appConstants/appConstants';

const PageContext = createContext({
  pageName: '',
  headTitle: '',
  localeCode: { code: '' } as Locale,
  setPage: (page: string) => { },
  setTitle: (page: string) => { },
  isMobile: false
});
PageContext.displayName = 'PageContext';

const initialDeviceState = {
  [DEVICE_XSMALL]: true, // always true
  [DEVICE_SMALL]: false,
  [DEVICE_MEDIUM]: false,
  [DEVICE_LARGE]: false,
  [DEVICE_XLARGE]: false,
};

export const PageProvider = ({ children }: any) => {
  const [deviceSizes, setDeviceSizes] = useState(initialDeviceState);
  const [pageName, setPageName] = useState('');
  const [headTitle, setHeadTitle] = useState('');
  const [localeCode, setLocaleCode] = useState({ code: '' });

  useEffect(() => {
    const getLocale = async () => {
      const response = await fetch('api/locale');
      if (response.ok) {
        const localeCode = await response.text();
        setLocaleCode({ code: localeCode });
      } else {
        // handle error
        setLocaleCode({ code: 'en-US' }); // fallback
      }
    }

    getLocale();
  }, []);

  const setPage = (page: string) => {
    setPageName(page);
  }
  const setTitle = (title: string) => {
    setHeadTitle(title);
    window.document.title = title;
  }

  const handleMediaQueryChange = ({ size, matches }) => {
    if (deviceSizes[size] !== matches) {
      // console.log({ size, matches, media });
      setDeviceSizes((currState) => ({ ...currState, [size]: matches }));
    }
  };

  const deviceSize = DEVICE_SIZES.findLast((size: string) => (deviceSizes[size])) as string;

  // Note: this provides a global "isMobile" boolean that can be passed to each component below.
  // If this does not provide enough granularity for any given page, you can pass "deviceSize"
  // property to the pages, and then modity this next line as needed (in each page).
  // e.g. const isMobile = [DEVICE_XSMALL, DEVICE_SMALL, DEVICE_MEDIUM].includes(deviceSize);
  const isMobile = [DEVICE_XSMALL].includes(deviceSize);

  return (
    <>
      <MediaQuery
        media={DEVICE_SMALL_QUERY}
        onChange={({ matches }) =>
          handleMediaQueryChange({ size: DEVICE_SMALL, matches })
        }
      />
      <MediaQuery
        media={DEVICE_MEDIUM_QUERY}
        onChange={({ matches }) =>
          handleMediaQueryChange({ size: DEVICE_MEDIUM, matches })
        }
      />
      <MediaQuery
        media={DEVICE_LARGE_QUERY}
        onChange={({ matches }) =>
          handleMediaQueryChange({ size: DEVICE_LARGE, matches })
        }
      />
      <MediaQuery
        media={DEVICE_XLARGE_QUERY}
        onChange={({ matches }) =>
          handleMediaQueryChange({ size: DEVICE_XLARGE, matches })
        }
      />
      <PageContext.Provider value={{
        pageName,
        headTitle,
        localeCode,
        setPage,
        setTitle,
        isMobile
      }}>
        <Layout>
          {children}
        </Layout>
      </PageContext.Provider>
    </>
  )
}

export const usePageContext = (pageName?: string) => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePageContext must be used within its provider.');
  }
  if (pageName) {
    context.setPage(pageName);
  }
  return context;
}

interface IPageHOCProps {
  /** The page identifier. */
  pageName: string
  /** The head (parent) title key (this will be used for translations (ns: 'labels')). Default: headPageTitle */
  headTitle?: string
  /** The page title key (this will be used for translations (ns: 'labels')). */
  pageTitle?: string
}

export const withPage = ({ pageName, headTitle = 'headPageTitle', pageTitle }: IPageHOCProps) => {
  return function withPageHOC(WrappedComponent: React.ComponentType<React.ComponentProps<any>>) {
    return function PageHOC(otherProps) {
      const { setTitle, setPage } = usePageContext();
      const { t } = useTranslation('labels');

      useEffect(() => {
        let title = '';
        if (pageName) {
          setPage(pageName);
        }
        if (headTitle) {
          title = t(headTitle);
          if (pageTitle) {
            title += ` | ${t(pageTitle)}`;
          }
        } else if (pageTitle) {
          title = t(pageTitle);
        }
        if (title) {
          setTitle(title);
        }
      });

      return (
        <WrappedComponent {...otherProps} />
      )
    }
  }
}

export const withLayout = (WrappedComponent: React.ComponentType<React.ComponentProps<any>>) => {
  return function LayoutHOC(otherProps = {}) {
    return (
      <Layout>
        <WrappedComponent {...otherProps} />
      </Layout>
    )
  }
}
