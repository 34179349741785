import React, { useEffect } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Eden
import Stack from '@churchofjesuschrist/eden-stack';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { CircleSelected } from '@churchofjesuschrist/eden-icons';
import { spacing32 } from '@churchofjesuschrist/eden-style-constants';

// Components
import DisplayStory from '../../components/View/DisplayStory';

// styles
import { TileHeader } from 'src/components/ExamplePages/exampleConstants';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { getLeadershipStories, getEventStories } from '../../utils/help/example-stories';
import { usePageContext } from 'src/contexts/PageContext';
import { Authorize } from 'src/components/Authentication/AuthorizeRoute';
import { Story } from 'src/models/Story';

const { exampleStoryPageName } = pageNames;

const SectionTitleContainer = styled.div`
  margin-top: ${spacing32};
`;

// NB: this is used in conjunction with the content in example-stories
export const getExampleStoryObject = (id) => {
  const { t } = useTranslation('');

  const leadershipStories = getLeadershipStories(t);
  const leadershipGood1 = leadershipStories[0];

  const eventStories = getEventStories(t);
  const eventGood1 = eventStories[0];

  let subTitleLabel = '';
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  let exampleStory: Story = {} as Story;
  let exampleLabel = '';
  let goodOrBad = false;
  let toolTipMessages: string[] = [];
  let Icon;

  switch (id) {
    case 'leadershipGood1':
      subTitleLabel = t('leadershipReflectionLabel');
      exampleStory = leadershipGood1;
      exampleLabel = t('goodExampleLabel');
      goodOrBad = true;
      Icon = CircleSelected;
      toolTipMessages = [
        t('goodExampleTooltip1'), // title
        t('goodExampleTooltip2'), // organizations
        t('goodExampleTooltip3'), // images
        t('goodExampleTooltip4'), // body
        t('goodExampleTooltip5'), // PDFs
      ];
      break;

    case 'eventGood1':
      subTitleLabel = t('meaningfulEventsLabel');
      exampleStory = eventGood1;
      exampleLabel = t('goodExampleLabel');
      Icon = CircleSelected;
      toolTipMessages = [
        t('goodEventTooltip1'), // title
        t('goodEventTooltip2'), // organizations
        t('goodEventTooltip3'), // images
        t('goodEventTooltip4'), // body
        t('goodEventTooltip5'), // PDFs
      ];
      goodOrBad = true;
      break;

    default:
      subTitleLabel = t('meaningfulEventsLabel');
      exampleStory = eventGood1;
      exampleLabel = t('goodExampleLabel');
      Icon = CircleSelected;
      toolTipMessages = [
        t('goodEventTooltip1'), // title
        t('goodEventTooltip2'), // organizations
        t('goodEventTooltip3'), // images
        t('goodEventTooltip4'), // body
        t('goodEventTooltip5'), // PDFs
      ];
      goodOrBad = true;
      break;
  }
  return {
    subTitleLabel,
    exampleStory,
    exampleLabel,
    goodOrBad,
    Icon,
    toolTipMessages,
  }
}

const Example = (props: any) => {
  const { setTitle } = usePageContext(exampleStoryPageName);
  const { id } = props.match.params;

  const {
    subTitleLabel,
    exampleStory,
    exampleLabel,
    goodOrBad,
    Icon,
    toolTipMessages,
  } = getExampleStoryObject(id);
  const { t } = useTranslation('');

  useEffect(() => {
    setTitle(`${t('exampleStoryPageTitle', { ns: 'labels' })} | ${subTitleLabel}: ${exampleLabel} | ${t('headPageTitle', { ns: 'labels' })}`);
  }, [subTitleLabel, exampleLabel, setTitle]);

  return (
    <Authorize>
      <Stack gapSize="0">
        <section>
          <SectionTitleContainer>
            <TileHeader good={goodOrBad} invert>
              <div style={{ width: '24px', height: '24px', margin: 'auto 0' }}>
                <Icon size="24" />
              </div>
              <H4>
                &nbsp; {exampleLabel}
              </H4>
            </TileHeader>
          </SectionTitleContainer>
        </section>

        <section>
          <DisplayStory
            example
            good={goodOrBad}
            toolTipMessages={toolTipMessages}
            story={exampleStory}
          />
        </section>
      </Stack>
    </Authorize>
  );
};

getExampleStoryObject.propTypes = {
  id: string.isRequired,
}

export default Example;
