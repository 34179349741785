import { differenceInHours } from 'date-fns';
import { MAX_LOCK_DURATION, STORY_STATUSES } from '../../../utils/appConstants/appConstants';
import { useSelector } from 'react-redux';
import { selectStory } from 'src/reducers/storiesReducer';
import { selectUser } from 'src/reducers/authReducer';
import { Story } from 'src/models/Story';

const {
  RETURNED_STATUS
} = STORY_STATUSES;

export const useStory = (newStory?) => {
  const story = newStory ? {} as Story : useSelector(selectStory);
  const user = useSelector(selectUser);

  const badOrMissingStory = !story || Object.keys(story).length === 0;
  const wardCouncil = story?.authors ?? [{}];
  const isLocked = story?.lock && story.lock.accountId !== user?.accountId;
  const lock = story?.lock?.date;
  const lockDuration = isLocked && lock && differenceInHours(new Date(), lock);
  const isUnlockable = lockDuration ? lockDuration > MAX_LOCK_DURATION : true;
  const inRework = story?.status === RETURNED_STATUS && (story.submitted || story.published);

  return {
    story,
    badOrMissingStory,
    wardCouncil,
    isLocked,
    lockDuration,
    isUnlockable,
    inRework
  }
}
