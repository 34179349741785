import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from '../models/User';
import * as UserService from '../services/user';

export const fetchUser = createAsyncThunk('auth/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const result = await UserService.fetchUser();
      // console.info(result);
      return result;
    } catch (err: any) {
      console.error(err);
      return rejectWithValue(err?.message ?? err);
    }
  })

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      favorites: [] as string[]
    } as User,
    loading: true,
    userError: '',
    authenticated: false
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload?.user;
      state.loading = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchUser.pending, (state, _) => {
        state.loading = true;
        state.userError = '';
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.authenticated = true;
        state.userError = '';
      })
      .addCase(fetchUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.userError = payload as string;
        if (payload === 'Failed to fetch') {
          state.authenticated = false;
        }
      })
});

// Action creators are generated for each case reducer function
export const { setUser } = authSlice.actions;

export const selectUser = (state) => (state.auth.user as User)?.proxy ?? state.auth.user as User;
export const selectUserLoading = (state) => state.auth.loading as boolean;
export const selectAuthenticated = (state) => state.auth.authenticated as boolean;
export const selectUserError = (state) => state.auth.userError as string;

export default authSlice.reducer;
