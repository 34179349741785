import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import Split from '@churchofjesuschrist/eden-split';
import { Unordered, Item } from '@churchofjesuschrist/eden-list';
import { H2, H3 } from '@churchofjesuschrist/eden-headings';
import {
  info40,
  blue20,
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// components
import QuickStartTOC from './QuickStartTOC';
import { QuickStartWrapper } from '../../utils/appCSS/appStyles';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';

// Utilities
import { addQueryParam } from 'src/utils/AppFunctions/appFunctions';
import { getScriptureList, getConferenceTalksList } from 'src/utils/AppFunctions/doctrineResources';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

const { helpDoctrinalResourcesPageName } = pageNames;

const ALink = styled.a`
  color: ${info40};
  :hover {
    text-decoration: underline;
    color: ${blue20};
    cursor: pointer;
  }
`;

export const DoctrinalResources = ({ match }: any) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const scriptures = getScriptureList(t);
  const talks = getConferenceTalksList(t);

  return (
    <Split ratio="1/4">
      <aside>
        <QuickStartTOC activePage={helpDoctrinalResourcesPageName} />
      </aside>

      <main>
        <QuickStartWrapper>
          <H2 id="doctrincal-resources">{t('doctrinalResources')}</H2>
          <Space top={spacing32} />
          <H3>{t('scriptureReferences')}</H3>
          <p />
          <Unordered>
            {scriptures.map((scripture, index) => (
              <Item key={index}>
                <ALink
                  href={addQueryParam({ url: scripture.href, host: undefined, key: 'lang', value: language }).toString()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {scripture.linkText}
                </ALink>
              </Item>
            ))}

          </Unordered>
          <br />

          <H3>{t('conferenceAddresses')}</H3>
          <p />
          <Unordered>
            {talks.map((talk, index) => (
              <Item key={index}>
                <ALink
                  href={addQueryParam({ url: talk.href, host: undefined, key: 'lang', value: language }).toString()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {talk.linkText}
                </ALink>
              </Item>
            ))}
          </Unordered>
        </QuickStartWrapper>
      </main>
    </Split>
  );
}

export default withPage({ pageName: helpDoctrinalResourcesPageName, headTitle: 'quickStartDoctrinalResources' })(DoctrinalResources);
