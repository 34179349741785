import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Eden
import Row from '@churchofjesuschrist/eden-row';
import Stack from '@churchofjesuschrist/eden-stack';
import Split from '@churchofjesuschrist/eden-split';
import Image from '@churchofjesuschrist/eden-image';
import { H2, H3 } from '@churchofjesuschrist/eden-headings';
import HtmlFragment from '@churchofjesuschrist/eden-html-fragment';
import {
  spacing32,
} from '@churchofjesuschrist/eden-style-constants';

// components
import QuickStartTOC from './QuickStartTOC';
import { QuickStartWrapper } from '../../utils/appCSS/appStyles';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

// utilities, constants
import { withPage } from 'src/contexts/PageContext';
import { getPDFandVTT } from 'src/utils/AppFunctions/appFunctions';
import { addPageAnalytics, CATEGORY_TYPE_HELP } from 'src/utils/analytics';
import { pageNames, SHARE_STORIES_OF_FAITH, PURPOSE_AND_VALUE, UH_TOOL } from '../../utils/appConstants/appConstants';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

const ShadowwImage = styled(Image)`
  box-shadow: 2px 2px 5px 2px rgba(74, 74, 74, 0.25);
  border-radius: 2px;
`;

const { helpPageName, helpQuickStartGuidePageName } = pageNames;

export const QuickStart1 = ({ match }: any) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const pdfAndVtt = getPDFandVTT(language);
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: helpPageName, // always use English
        activePage: helpQuickStartGuidePageName, // used to set primary category
        breadCrumbs: [helpPageName, helpQuickStartGuidePageName],
        language, // app language
        categoryType: CATEGORY_TYPE_HELP,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  return (
    <Split ratio="1/4">
      <aside>
        <QuickStartTOC activePage={helpQuickStartGuidePageName} />
      </aside>

      <main>
        <QuickStartWrapper>
          <H2 id="intro">{t('introduction')}</H2>
          <Space top={spacing32} />
          <Stack>
            <H3>{t('weNeedYourStories')}</H3>
            <div>{t('weNeedYourStoriesP1')}</div>
            <div>{t('weNeedYourStoriesP2')}</div>

            <H3>{t('wardStakeHistoriesStoriesFaith')}</H3>
            <div>{t('wardStakeHistoriesStoriesFaithP1')}</div>
            <video
              controls
              src='https://media2.ldscdn.org/assets/information-technology/annual-history-interface/2021-08-0030-stories-of-faith-1080p-eng.mp4'
              width={'95%'}
              poster={'/static/images/share-stories-faith-thumnbnail.png'}
              crossOrigin="anonymous"
            >
              <track default kind='subtitles' srcLang={language} src={pdfAndVtt[SHARE_STORIES_OF_FAITH]} />
            </video>
            <div />
            <div>{t('wardStakeHistoriesStoriesFaithP2')}</div>

            <Row gapSize="64">
              <div style={{ width: '144px' }}>
                <a
                  href={pdfAndVtt[PURPOSE_AND_VALUE]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ShadowwImage
                    disableLazy
                    src='/static/images/purpose-value-ward-stake-histories-thumb.png'
                    alt={t('purposeAndValueWardStakeHistories')}
                    width="144"
                  />
                  <br />
                  <br />
                  {t('purposeAndValueWardStakeHistories')}
                </a>
              </div>

              <div style={{ width: '144px' }}>
                <a
                  href={pdfAndVtt[UH_TOOL]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ShadowwImage
                    disableLazy
                    src='/static/images/new-way-ward-stake-histories-thumb.png'
                    alt={t('newWayForward')}
                    width="144"
                  />
                  <br />
                  <br />
                  {t('newWayForward')}
                </a>
              </div>

              <HtmlFragment content={t('wardStakeHistoriesStoriesFaithP3')} />
            </Row>
          </Stack>
        </QuickStartWrapper>
      </main>
    </Split>
  );
}

export default withPage({ pageName: helpQuickStartGuidePageName, headTitle: '' })(QuickStart1);
