import React, { useEffect } from 'react';
import { withLayout } from 'src/contexts/PageContext';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';

const Login = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const returnUrl = params.get('returnUrl') ?? '/';
    window.location.assign('/security/logon?returnUrl=' + returnUrl);
  }, []);
  return (
    <LoadingSpinner />
  );
};

export default withLayout(Login);
