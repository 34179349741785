import { arrayOf, bool, number, object, oneOf, shape, string, oneOfType } from 'prop-types';

import {
  appConstants,
  orderByDirections,
  orderByProperties,
  pageNames,
  STORY_STATUSES,
} from 'src/utils/appConstants/appConstants';
import { convertObjToArray } from 'src/utils/AppFunctions/appFunctions';

const { UNITS } = appConstants;

export const authoredByType = string;
export const bodyType = string;
export const confidentialType = bool;
export const lockedType = bool;
export const dateType = string;
export const imageSourceType = string;
export const languageType = string;
export const nameType = string;
export const personIdType = string;
export const positionType = oneOfType([number, string]);
export const sourceType = string;
export const storyIdType = string;
export const textType = string;
export const titleType = string;
export const emailType = string;
export const unitIdType = string.isRequired;
// export const urlStringType = string;
// export const renderPropType = func;
// export const docSourceType = string;

export const attachmentType = shape({ name: nameType, source: sourceType, type: oneOf(['PDF']) });

export const authorType = arrayOf(
  shape({ accountId: number, name: nameType, position: positionType })
);

export const fileType = shape({ name: string, size: number, type: oneOf(['image/jpeg', 'PDF']) });

export const imageTypeWithData = shape({
  alt: string,
  sources: arrayOf(imageSourceType),
  type: oneOf(['image/jpeg']),
});

export const objectArray = arrayOf(object);

export const objectArrayIsRequired = arrayOf(object).isRequired;

export const imageTypeWithoutData = objectArrayIsRequired;

export const organizationsType = arrayOf(shape({ id: string, name: string, uuid: string }));

export const publishStoryVariables = shape({ id: storyIdType }).isRequired;

export const submitStoryVariables = shape({ id: storyIdType }).isRequired;

export const singleUnitType = oneOf(UNITS);

// export const propertiesType = oneOf(convertObjToArrayByKey(appConstants, 'ORDER_BY'));
// export const propertiesType = arrayOf(string);
export const propertiesType = arrayOf(oneOf(convertObjToArray(orderByProperties)));
export const directionType = oneOf(convertObjToArray(orderByDirections));
export const pageNameType = oneOf(convertObjToArray(pageNames));

export const singleStatusType = oneOf(convertObjToArray(STORY_STATUSES));

export const unitType = shape({
  id: unitIdType,
  name: nameType,
  type: singleUnitType,
});

export const storyFormPropType = shape({
  attachments: objectArray,
  authors: authorType,
  body: bodyType,
  confidential: confidentialType,
  locked: lockedType,
  dateEnd: dateType,
  dateStart: dateType,
  id: storyIdType,
  images: objectArray,
  organizations: organizationsType,
  parentUnit: unitType,
  title: titleType,
  unit: unitType,
});

export const viewStoryPropType = shape({
  attachments: objectArray,
  authors: authorType,
  body: bodyType,
  confidential: confidentialType,
  locked: lockedType,
  created: dateType,
  dateEnd: dateType,
  dateStart: dateType,
  id: storyIdType,
  images: objectArray,
  modified: dateType,
  organizations: organizationsType,
  parentUnit: unitType,
  published: dateType,
  status: singleStatusType,
  submitted: dateType,
  title: titleType,
  unit: unitType,
});

export const pendingStoryVarPropType = shape({
  first: number,
  offset: number,
  criteria: shape({
    unit: string,
    parentUnit: unitType, // no longer required
    authoredBy: authoredByType,
    status: singleStatusType,
  }),
  orderBy: shape({ properties: propertiesType, direction: directionType }),
});

export const tabCountVariableType = shape({
  authorName: authoredByType,
  parentUnit: number,
  unitIdType: number,
  units: arrayOf(number),
});

export const userType = shape({
  accountId: number,
  authenticated: bool,
  canApprove: bool,
  canEdit: bool,
  canView: bool,
  email: string,
  favorites: arrayOf(storyIdType),
  isAdmin: bool,
  name: nameType,
  parentUnit: unitType,
  position: positionType,
  preferredLanguage: languageType,
  unit: unitType,
});

// BL NOTE: all of these were in the app/components/Types/index.js file
// I couldn't find anywhere that they were used. In some cases they
// were duplicates of things defined in this file. They are included
// here for reference. THey can be deleted once we are certain they aren't
// needed.

// export const favoriteActionType = oneOf(['ADD', 'REMOVE']);

// export const topicType = string;

// export const directionType = oneOf(['ASC', 'DESC']);

// export const orderType = shape({
//   properties: propertiesType,
//   direction: directionType,
// });

// export const organizationsType = arrayOf(organizations);

// export const organizations = oneOf([
//   'BISHOPRIC',
//   'ELDERS_QUORUM',
//   'RELIEF_SOCIETY',
//   'PRIMARY',
//   'SUNDAY_SCHOOL',
//   'YOUNG_MEN',
//   'YOUNG_WOMEN',
// ]);

// export const singleUnitType = oneOf([UNIT_STAKE, UNIT_WARD, 'stake', 'ward']);

// export const favoriteType = arrayOf(storyIdType);

// export const statusType = oneOf(['REQUESTED', 'DRAFT', 'PENDING', 'PUBLISHED']);

// export const positionType = oneOf([
//   'BISHOP',
//   'BISHOPRIC_FIRST_COUNSELOR',
//   'BISHOPRIC_SECOND_COUNSELOR',
//   'ELDERS_QUORUM_PRESIDENT',
//   'PRIMARY_PRESIDENT',
//   'YOUNG_MEN_PRESIDENT',
//   'YOUNG_WOMEN_PRESIDENT',
// ]);
// export const personType = shape({
//   accountId: personIdType,
//   name: nameType,
//   email: emailType,
//   position: positionType,
//   stories: arrayOf(fullStoryType),
// });

// export const imageType = shape({
//   alt: string,
//   sources: arrayOf(imageSourceType),
// });

// export const authorType = arrayOf(
//   shape({
//     accountId: personIdType,
//     name: nameType,
//   })
// );

// export const unitType = shape({
//   type: singleUnitType,
//   id: unitIdType,
//   name: nameType,
// });

// export const attachmentType = shape({
//   name: nameType,
//   source: sourceType,
//   type: oneOf(['PDF']),
// });

// export const storyTagType = shape({
//   id: storyIdType,
//   name: nameType,
// });

// export const fullStoryType = shape({
//   authors: authorType,
//   attachments: attachmentType,
//   body: bodyType,
//   created: dateType,
//   confidential: confidentialType,
//   dateStart: dateType,
//   dateEnd: dateType,
//   id: storyIdType,
//   images: arrayOf(imageType),
//   mainImageSource: imageSourceType,
//   modified: dateType,
//   organizations: organizationsType,
//   parentUnit: unitType,
//   published: dateType,
//   status: statusType,
//   submitted: dateType,
//   title: titleType,
//   topics: arrayOf(topicType),
//   unit: unitType,
// });
