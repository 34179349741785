import React from 'react';
import styled from 'styled-components';

import {
  grey2,
  grey15,
  info40,
  sans as zoram,
  spacing32,
  spacing16,
} from '@churchofjesuschrist/eden-style-constants';
import { H1 as H1Base, H2 } from '@churchofjesuschrist/eden-headings';
import { Text3 } from '@churchofjesuschrist/eden-text';
import { ChevronDown } from '@churchofjesuschrist/eden-icons';
import { Label } from '@churchofjesuschrist/eden-form-parts';
import { Compact } from '@churchofjesuschrist/eden-vertical-tiles';

interface IBoxParams {
  m?: number
  mt?: number
  mb?: number
  mr?: number
  ml?: number
  p?: number
  pt?: number
  pb?: number
  pr?: number
  pl?: number
  flex?: boolean
}

export const Box = styled.div<IBoxParams>`
  ${(props) => (props.m ? `margin: ${props.m}px;` : '')}
  ${(props) => (props.mt ? `margin-top: ${props.mt}px;` : '')}
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px;` : '')}
  ${(props) => (props.mr ? `margin-right: ${props.mr}px;` : '')}
  ${(props) => (props.ml ? `margin-left: ${props.ml}px;` : '')}
  ${(props) => (props.p ? `padding: ${props.p}px;` : '')}
  ${(props) => (props.pt ? `padding-top: ${props.pt}px;` : '')}
  ${(props) => (props.pb ? `padding-bottom: ${props.pb}px;` : '')}
  ${(props) => (props.pr ? `padding-right: ${props.pr}px;` : '')}
  ${(props) => (props.pl ? `padding-left: ${props.pl}px;` : '')}
  ${(props) => (props.flex ? 'flex: 1' : '')}
`;

export const A = styled.a`
  text-decoration: none;
  color: ${props => (props.color ? props.color : info40)};
  cursor: pointer;
`;

export const AlertClickBlocker = styled.div<{ color: string, opacity: number, zIndex: number }>`
  background-color: ${props => (props.color ? props.color : grey2)};
  bottom: 0;
  height: 200%;
  left: -200;
  opacity: ${props => (props.opacity ? props.opacity : 0)};
  position: fixed;
  right: 0;
  top: -200;
  width: 200%;
  z-index: ${props => (props.zIndex ? 2000 + props.zIndex : 2000)};
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const FloatRight = styled.div<{ cursor?: string }>`
  float: right;
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
`;

export const DropDownContent = styled.div`
  box-sizing: border-box;
  font-family: ${zoram};
  /* justify-content: space-between; */
  padding: ${spacing16};
`;

export const HorizontalDropdowns = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HorizontalDropdown = styled.div``;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Line = styled.div<{ marginTop?: string, paddingTop?: string }>`
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  border-top: 1px solid ${grey15};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : spacing16)};
  width: 100%;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
`;

export const PageContainer = styled.div`
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const PageTitle = styled(H1Base)`
  padding-bottom: ${spacing32};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : spacing32)};
  width: 100%;
`;

export const PageSubTitle = styled(H2)`
  padding-bottom: ${spacing32};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : spacing32)};
  width: 100%;
`;

export const DropDownSize = styled.div<{ cardWidth?: number }>`
  position: absolute;
  z-index: 2;
`;

export const ScrollContent = styled.div<{ boxHeight?: string }>`
  position: relative;
  overflow: auto;
  height: ${props => (props.boxHeight ? props.boxHeight : '500px')};
  border-bottom: 2px solid ${grey15};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

// destructure out the 'active' property and don't pass it on (because it causes a React warning on the svg)
export const AnimatedChevron = styled(
  // eslint-disable-next-line no-unused-vars
  ({ active = false, ...props }) => <ChevronDown {...props} />
)`
  transform: ${props => (props.active ? 'rotateZ(180deg)' : 'rotateZ(0)')};
  transition: transform 200ms;
`;

export const ReadingWidth = styled.div`
  max-width: 42rem;
`;

export const QuickStartWrapper = styled.div`
  margin: 32px auto 0;
  max-width: 64rem;
`;

export const MarkdownWrapper = styled.div`
  hr {
    border-width: 1px 0 0 0;
    border-color: ${grey15};
    border-style: solid;
  }
  h3 {
    margin: 32px 0 8px 0;
  }
  & > div > table {
    margin: 8px 0;
  }
  & > div > div {
    margin: 0 0 8px 0;
    line-height: 1.6;
  }
  & > div > h3,
  & > div > table,
  & > div > ol,
  & > div > div {
    max-width: 42rem;
  }
  tr {
    line-height: 1.6;
  }
`;

export const CheckboxLabel = styled(Label)`
  display: flex;
  gap: 0.5rem;
`
export const AnchorText3 = styled(Text3)`
  color: rgb(21, 116, 147);
  cursor: pointer;
`;

// Overwrite the ootb light-rays 180deg rotation
export const StyledCompactCard = styled(Compact)`
  div:has(> img)::after {
    background: linear-gradient( to bottom,rgba(0,0,0,0.25) 0,rgba(0,0,0,0) 100% );
    bottom: auto;
    top: 0;
  }
  svg {
    transform: none;
    bottom: auto;
    top: -1px;
  }
`;
