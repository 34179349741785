import { createSlice } from '@reduxjs/toolkit';
import { Story, StoryVariables } from 'src/models/Story';
import {
  orderByDirections,
  orderByProperties,
} from '../utils/appConstants/appConstants';
import { setHomePageStoryQueryParams } from '../utils/graphQL_Queries/queryVariableFormats';

const { ORDER_BY_PUBLISHED } = orderByProperties;
const { DESCENDING } = orderByDirections;

const homeVariables = setHomePageStoryQueryParams(
  '', // search terms
  [], // organizations
  // user.viewerUnits.map(elt => elt.id), // units
  // [appParams.id], // currently selected unit
  // [activeUnitObj.id], // default unit
  false, // confidential
  false, // show favorites
  [ORDER_BY_PUBLISHED], // default properties (for sorting)
  DESCENDING // default direction (for sort order)
);

export const storiesSlice = createSlice({
  name: 'stories',
  initialState: {
    stories: [],
    loading: false,
    storiesError: false,
    total: 0,
    story: {},
    storyLoading: false,
    loadingCount: 0,
    variables: homeVariables
  },
  reducers: {
    setStories: (state, action) => {
      state.stories = action.payload.content;
      state.total = action.payload.total;
      state.loading = false;
      state.storiesError = false;
      state.loadingCount++;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStoryLoading: (state, action) => {
      state.storyLoading = action.payload;
    },
    setStory: (state, action) => {
      state.story = action.payload.story;
      state.storyLoading = false;
    },
    setStoriesError: (state, action) => {
      state.storiesError = action.payload?.message;
    },
    setStoriesVariables: (state, action) => {
      state.variables = action.payload;
    },
    addStoriesVariables: (state, action) => {
      state.variables = { ...state.variables, ...action.payload };
    }
  },
});

// Action creators are generated for each case reducer function
export const { setStories, setLoading, setStoryLoading, setStory, setStoriesError, setStoriesVariables, addStoriesVariables } = storiesSlice.actions;

export const selectStories = (state) => (state.stories.stories ?? []) as Story[];
export const selectStoriesLoading = (state) => state.stories.loading as boolean;
export const selectStoriesLoadingCount = (state) => state.stories.loadingCount as number;
export const selectStoriesError = (state) => state.stories.storiesError as boolean;
export const selectTotal = (state) => (state.stories.total ?? 0) as number;
export const selectStory = (state) => (state.stories.story ?? {}) as Story;
export const selectStoryLoading = (state) => state.stories.storyLoading as boolean;
export const selectStoriesVariables = (state) => state.stories.variables as StoryVariables;

export default storiesSlice.reducer;
