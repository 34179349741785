import React from 'react';
import { Switch } from 'react-router-dom';

// Components
// import ExamplesPage from './ExamplesPage'; // TODO: more cleanup before we can delete this file
// import Example from './example'; // TODO: more cleanup before we can delete this file (which is still in use)
import FAQ from './FAQ';
import QuickStart from './QuickStart';
import CreatingStories from './CreatingStories';
import ApprovingStories from './ApprovingStories';
import RolesAndCallings from './RolesAndCallings';
import DoctrinalResources from './DoctrinalResources';
import AuthorizeRoute from 'src/components/Authentication/AuthorizeRoute';
import Error from 'src/pages/Error';

// constants
import { NAV_QUICK_START_GUIDE, setQSTopicPageUrl } from 'src/utils/AppFunctions/setUrls';

const HelpRoutes = () => (
  <Switch>
    {/* <AuthorizeRoute path='/help/examples' component={ExamplesPage} /> */}
    {/* <AuthorizeRoute path='/help/example/:id' component={Example} /> */}
    <AuthorizeRoute path='/help/faq' component={FAQ} />
    <AuthorizeRoute path={NAV_QUICK_START_GUIDE} component={QuickStart} />
    <AuthorizeRoute path={setQSTopicPageUrl('create')} component={CreatingStories} />
    <AuthorizeRoute path={setQSTopicPageUrl('approve')} component={ApprovingStories} />
    <AuthorizeRoute path={setQSTopicPageUrl('roles')} component={RolesAndCallings} />
    <AuthorizeRoute path={setQSTopicPageUrl('resources')} component={DoctrinalResources} />
    <AuthorizeRoute component={Error} />
  </Switch>
)

export default HelpRoutes;
