import gql from 'graphql-tag';

export const getUserQuery = gql`
  fragment PartialUser on User {
    id
    accountId
    ldsAccountId
    name
    email
    position
    authenticated
    favorites
    unit {
      id
      name
      type
      unitNumber
    }
    parentUnit {
      id
      name
      type
      unitNumber
    }
    isAdmin
    isApprover
    isContributor
    isViewer
    isReporter
  }
  query getUserQuery {
    user {
      ...PartialUser
      proxy {
        ...PartialUser
      }
    }
  }
`;

export const getOrganizationsQuery = gql`
  query getOrganizationsQuery {
    organizations {
      id
      uuid
      name
      type
      unitType
      unitTypeName
      unitUuid
      unitName
    }
  }
`;

export const getAuthorsQuery = gql`
  query getAuthorsQuery {
    authors {
      uuid
      accountId
      name
      email
      position
      formattedName
      status
      unitNumber
      type
      unitType
    }
  }
`;

export const displayStoryQuery = gql`
  query displayStoryQuery($id: Guid!) {
    story(id: $id) {
      id
      dateStart
      dateEnd
      title
      authors {
        id
        accountId
        uuid
        name
        email
        position
        status
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
        name
        preview
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      lock {
        accountId
        name
        date
      }
      confidential
      sensitive
      sensitiveComments
      status
      created
      modified
      submitted
      published
      approver {
        id
        accountId
        name
        position
      }
      approverComments
      flagForReview
      personTags {
        id
        uuid
        name
      }
    }
  }
`;

// Note: deprecate this query
export const getStoryFullQuery = gql`
  query getStoryFullQuery($criteria: StoryCriteria, $orderBy: OrderBy) {
    stories(criteria: $criteria, orderBy: $orderBy) {
      content {
        id
        dateStart
        dateEnd
        title
        authors {
          id
          uuid
          accountId
          name
          email
          position
          status
        }
        body
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        images {
          id
          sources
          alt
          name
          preview
        }
        attachments {
          id
          name
          source
          type
        }
        unit {
          id
          name
          type
        }
        confidential
        status
        created
        modified
        submitted
        published
      }
    }
  }
`;

export const homePageQuery = gql`
  query HomePageQuery(
    $searchTerms: String
    $organizations: [String]
    $units: [String]
    $confidential: Boolean
    $filterFavorites: Boolean
    $properties: [String]!
    $direction: Direction
  ) {
    stories(
      criteria: {
        qtext: $searchTerms
        organizations: $organizations
        filterFavorites: $filterFavorites
        units: $units
        confidential: $confidential
        status: PUBLISHED
      }
      orderBy: { properties: $properties, direction: $direction }
    ) {
      total
      content {
        id
        dateStart
        dateEnd
        title
        authors {
          id
          uuid
          accountId
          name
          email
          position
          status
        }
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        image {
          id
          sources
          alt
          name
          preview
        }
        unit {
          id
          name
          type
        }
        confidential
        status
        created
        modified
        submitted
        published
      }
    }
  }
`;

export const MyStoriesPublishedPageQuery = gql`
  query MyStoriesPublishedPageQuery(
    $author: Guid
    $searchTerms: String
    $organizations: [String]
    $confidential: Boolean
    $properties: [String]!
    $direction: Direction
  ) {
    stories(
      criteria: {
        authoredBy: $author
        qtext: $searchTerms
        organizations: $organizations
        confidential: $confidential
        status: PUBLISHED
      }
      orderBy: { properties: $properties, direction: $direction }
    ) {
      total
      content {
        id
        dateStart
        dateEnd
        title
        authors {
          id
          uuid
          accountId
          name
          email
          position
          status
        }
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        images {
          id
          sources
          alt
          name
          preview
        }
        unit {
          id
          name
          type
        }
        confidential
        status
        created
        modified
        submitted
        published
      }
    }
  }
`;

export const totalStoriesCountQuery = gql`
  query totalStoriesCountQuery(
    $author: Guid
    $inviter: Guid
  ) {
    draftStories: storiesCount(
      criteria: {
        authoredBy: $author
        status: DRAFT
      }
    ) {
      total
    }
    invitesSent: storiesCount(
      criteria: {
        inviter: $inviter
        status: DRAFT
      }
    ) {
      total
    }
    pendingStories: storiesCount(
      criteria: {
        authoredBy: $author
        status: PENDING
      }
    ) {
      total
    }
    publishedStories: storiesCount(
      criteria: {
        authoredBy: $author
        status: PUBLISHED
      }
    ) {
      total
    }
    returnedStories: storiesCount(
      criteria: {
        authoredBy: $author
        status: RETURNED
      }
    ) {
      total
    }
    pendingInviteStories: storiesCount(
      criteria: {
        authoredBy: $author
        status: PENDING_INVITE
      }
    ) {
      total
    }
  }
`;

export const totalManagerStoriesCountQuery = gql`
  query totalManagerStoriesCountQuery(
    $author: Guid
    $inviter: Guid
  ) {
    draftStories: storyManagerCount(
      criteria: {
        authoredBy: $author
        status: DRAFT
      }
    ) {
      total
    }
    invitesSent: storyManagerCount(
      criteria: {
        inviter: $inviter
        status: DRAFT
      }
    ) {
      total
    }
    pendingStories: storyManagerCount(
      criteria: {
        authoredBy: $author
        status: PENDING
      }
    ) {
      total
    }
    publishedStories: storyManagerCount(
      criteria: {
        authoredBy: $author
        status: PUBLISHED
      }
    ) {
      total
    }
    returnedStories: storyManagerCount(
      criteria: {
        authoredBy: $author
        status: RETURNED
      }
    ) {
      total
    }
    pendingInviteStories: storyManagerCount(
      criteria: {
        authoredBy: $author
        status: PENDING_INVITE
      }
    ) {
      total
    }
  }
`;

export const getStoryByIdQuery = gql`
  query getStoryByIdQuery($id: Guid!) {
    story(id: $id) {
      id
      dateStart
      dateEnd
      title
      authors {
        id
        uuid
        accountId
        name
        email
        position
        status
      }
      body
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      mainImageSource
      images {
        id
        sources
        alt
        name
        preview
      }
      attachments {
        id
        name
        source
        type
      }
      unit {
        id
        name
        type
      }
      lock {
        accountId
        name
        date
      }
      confidential
      status
      created
      modified
      submitted
      published
      modifiedBy
      modifiedById
      modifiedByOrganization
      approver {
        id
        accountId
        name
        position
      }
      approverComments
      flagForReview
      sPCReviewCompleted
      sensitive
      sensitiveComments
      notes
      inviter {
        id
        name
        position
      }
      personTags {
        id
        uuid
        name
      }
    }
  }
`;

export const emptyQuery = gql`
  query emptyQuery($criteria: StoryCriteria) {
    stories(criteria: $criteria) {
      total
      content {
        title
      }
    }
  }
`;

export const getStoriesQuery = gql`
  query getStoriesQuery($criteria: StoryCriteria, $orderBy: OrderBy) {
    stories(criteria: $criteria, orderBy: $orderBy) {
      total
      content {
        id
        dateStart
        dateEnd
        title
        body
        authors {
          id
          uuid
          accountId
          name
          email
          position
          status
        }
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        images {
          id
          sources
          alt
          name
          preview
        }
        unitId
        lock {
          accountId
          name
          date
        }
        confidential
        sensitive
        sensitiveComments
        status
        created
        modified
        submitted
        published
        flagForReview
      }
    }
  }
`;

export const getStoryManagerQuery = gql`
  query getStoryManagerQuery($criteria: StoryCriteria, $orderBy: OrderBy) {
    storyManager(criteria: $criteria, orderBy: $orderBy) {
      total
      content {
        id
        dateStart
        dateEnd
        title
        body
        authors {
          id
          uuid
          accountId
          name
          email
          position
          status
        }
        organizations {
          id
          name
          uuid
          unitUuid
          unitName
        }
        topics {
          id
          name
        }
        mainImageSource
        images {
          id
          sources
          alt
          name
          preview
        }
        unitId
        lock {
          accountId
          name
          date
        }
        confidential
        sensitive
        sensitiveComments
        status
        created
        modified
        submitted
        published
        flagForReview
      }
    }
  }
`;

export const TYPE_AHEAD = gql`
  query typeAhead($searchTerms: String) {
    stories(criteria: { qtext: $searchTerms, status: PUBLISHED }) {
      total
      content {
        id
        title
      }
    }
  }
`;

// e.g. typeAhead variables
// {
//   'first': 1000,
//   'searchTerms': 'halibut'
// }

export const ACTIVITY_REPORT_QUERY = gql`
  query activityReportQuery(
    $criteria: StoryHistoryCriteria
    $useOrganizationTypes: Boolean
    $reportType: ActivityReportType
  ) {
    activityReport(
      criteria: $criteria
      useOrganizationTypes: $useOrganizationTypes
      reportType: $reportType
    ) {
      units {
        id
        name
        type
        unitNumber
        totals {
          status
          total
        }
      }
    }
  }
`;

export const UNITS_NO_CHILDREN_QUERY = gql`
  query unitsNoChildrenQuery(
    $criteria: UnitCriteria
    $orderBy: OrderBy
    $first: Int
    $offset: Int
  ) {
    units(criteria: $criteria, orderBy: $orderBy, first: $first, offset: $offset) {
      id
      name
      type
      # dateAdded
      # status
      parentId
      unitNumber
    }
  }
`;

export const UNITS_WITH_CHILDREN_QUERY = gql`
  query unitsWithChildrenQuery(
    $criteria: UnitCriteria
    $orderBy: OrderBy
    $first: Int
    $offset: Int
  ) {
    units(criteria: $criteria, orderBy: $orderBy, first: $first, offset: $offset) {
      id
      name
      type
      # dateAdded
      # status
      parentId
      unitNumber
      childUnits {
        id
        name
        type
        # dateAdded
        # status
        parentId
        unitNumber
      }
    }
  }
`;

export const CHILD_UNITS_QUERY = gql`
  query childUnitsQuery($units: [Guid]) {
    childUnits(unitIds: $units) {
      id
      name
      type
    }
  }
`;

export const storyHistoriesQuery = gql`
  query storyHistoriesQuery($criteria: StoryHistoryCriteria) {
    activityReport(criteria: $criteria) {
      id
      storyId
      authors {
        id
        uuid
        accountId
        name
        email
        position
        status
      }
      organizations {
        id
        name
        uuid
        unitUuid
        unitName
      }
      topics {
        id
        name
      }
      unit {
        id
        name
        type
      }
      status
      date
      modifiedBy
      modifiedById
      modifiedByOrganization
    }
  }
`;

// accounts lookup
export const GET_ACCOUNTS_QUERY = gql`
  query getAdminQuery(
    $first: Int
    $criteria: AccountCriteria
    $properties: [String]!
    $direction: Direction
  ) {
    accounts(
      first: $first
      criteria: $criteria
      orderBy: { properties: $properties, direction: $direction }
    ) {
      content {
        ldsAccountId
        username
        displayName
        roles
        dateAdded
      }
      total
    }
  }
`;

// admin lookup
export const ADMIN_LOOKUP_QUERY = gql`
  query adminLookupQuery($adminName: String!) {
    adminLookup(name: $adminName) {
      id
      name
    }
  }
`;

// member lookup
export const MEMBER_LOOKUP_QUERY = gql`
  query memberLookupQuery($id: Long!) {
    member(accountId: $id) {
      accountId
      username
      enabled
      validatedMember {
        member {
          simpleMemberData {
            preferredName
            formattedName
            individualId
            sortName
          }
        }
        organization {
          unitNbr
          organizationType
          organizationName
        }
        assignments {
          organizationId
          positionType
          organization {
            unitNbr
            organizationType
            organizationName
          }
        }
      }
    }
  }
`;

export const MEMBER_SEARCH_QUERY = gql`
  query searchMembers($name: String!) {
    search(name: $name) {
      uuid
      firstName
      lastName
      fullName
      membershipUnit {
        uuid
        unitNumber
        unitName
      }
    }
  }
`;

export const UNIT_SEARCH = gql`
  query searchUnits($search: String!, $types: [Int!]) {
    searchUnits(search: $search, types: $types) {
      id
      parentId
      uuid
      unitNumber
      name
      type
      unitType
    }
  }
`;

export const PROXY_USERS_QUERY = gql`
  query proxyUsers($unitUuid: String, $search: String) {
    proxyUsers(unitUuid: $unitUuid, search: $search) {
      uuid
      firstName
      lastName
      fullName
      membershipUnit {
        uuid
        unitNumber
        unitName
      }
    }
  }
`;
