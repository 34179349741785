import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Eden
import { spacing32 } from '@churchofjesuschrist/eden-style-constants';
import { SideNavigation, NavigationGroup, NavigationBlockLink as NavigationBlockLinkBase, NavigationBlockHeading } from '@churchofjesuschrist/eden-side-navigation';

// Utilities
import { setQSTopicAnchor, setQSTopicPageUrl } from 'src/utils/AppFunctions/setUrls';

// constants
import { pageNames } from '../../utils/appConstants/appConstants';

// styles
import { Space } from 'src/components/Feedback/FeedbackFormStyles';

const { helpApprovingStoriesPageName, helpCreatingStoriesPageName, helpRolesAndCallingsPageName, helpDoctrinalResourcesPageName, helpQuickStartGuidePageName } = pageNames;

const NavigationBlockLink = styled(NavigationBlockLinkBase)`
cursor: pointer;
`;

export const QuickStartTOC = ({ activePage }) => {
  const { t } = useTranslation();
  const router = useHistory();

  const handleClick = (url) => router.push(url);

  return (
    <SideNavigation>
      <Space top={spacing32} />
      <NavigationBlockHeading label={t('quickStartGuide')} >
        <NavigationGroup>
          <NavigationBlockLink active={activePage === helpQuickStartGuidePageName} onClick={() => handleClick(setQSTopicAnchor('intro'))}>{t('introduction')}</NavigationBlockLink>
          <NavigationBlockLink active={activePage === helpCreatingStoriesPageName} onClick={() => handleClick(setQSTopicPageUrl('create'))}>{t('creatingStories')}</NavigationBlockLink>
          <NavigationBlockLink active={activePage === helpApprovingStoriesPageName} onClick={() => handleClick(setQSTopicPageUrl('approve'))}>{t('approvingStories')}</NavigationBlockLink>
          <NavigationBlockLink active={activePage === helpRolesAndCallingsPageName} onClick={() => handleClick(setQSTopicPageUrl('roles'))}>{t('rolesAndCallings')}</NavigationBlockLink>
          <NavigationBlockLink active={activePage === helpDoctrinalResourcesPageName} onClick={() => handleClick(setQSTopicPageUrl('resources'))}>{t('doctrinalResources')}</NavigationBlockLink>
        </NavigationGroup>
      </NavigationBlockHeading>
    </SideNavigation>
  );
}

QuickStartTOC.propTypes = {
  activePage: string,
}

export default QuickStartTOC;
