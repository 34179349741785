/****** ldsxrm_churchposition ******
 * Auto-generated - do not modify.
 * To regenerate, run 'npm run enums'
 */

export const enum ldsxrm_positions {
  Acting_Branch_Leader = 363,
  Acting_District_Leader = 362,
  Acting_Mission_Leader = 359,
  Acting_Stake_Leader = 361,
  Acting_Ward_Leader = 364,
  Area_Executive_Secretary = 50,
  Area_Presidency_First_Counselor = 7,
  Area_Presidency_Second_Counselor = 8,
  Area_President = 6,
  Area_Seventy = 11,
  Area_Welfare_Manager = 123,
  Area_Welfare_Services_Finance_Manager = 288,
  Assistant_Secretary_to_the_First_Presidency = 1102,
  Assistant_Secretary_to_the_Quorum_of_the_Twelve = 1182,
  Bishop = 4,
  Bishopric_First_Counselor = 54,
  Bishopric_Second_Counselor = 55,
  Branch_Assistant_Clerk = 790,
  Branch_Assistant_Executive_Secretary = 47149789,
  Branch_Assistant_Executive_Secretary0 = 47149790,
  Branch_Clerk = 789,
  Branch_Executive_Secretary = 1278,
  Branch_Mission_Leader = 3109896,
  Branch_Presidency_First_Counselor = 59,
  Branch_Presidency_Second_Counselor = 60,
  Branch_President = 12,
  Branch_Temple_and_Family_History_Consultant = 1977,
  Branch_Temple_and_Family_History_Leader = 1971,
  CHQ_Executive_Assistant = 1405,
  Church_Communication_Assistant_Director = 725,
  Church_History_Specialist = 5734,
  Communication_Director = 724,
  Communication_Specialist = 726,
  Deacons_Quorum_Adviser = 164,
  District_Assistant_Clerk = 69,
  District_Assistant_Executive_Secretary = 3770609,
  District_Assistant_Executive_Secretary1 = 47149792,
  District_Clerk = 68,
  District_Councilor = 1553,
  District_Executive_Secretary = 70,
  District_Family_History_Leader = 852,
  District_Presidency_First_Counselor = 66,
  District_Presidency_Second_Counselor = 67,
  District_President = 5,
  District_Primary_First_Counselor = 1388,
  District_Primary_President = 1381,
  District_Primary_Second_Counselor = 1389,
  District_Primary_Secretary = 1390,
  District_Relief_Society_Board_Member = 1434,
  District_Relief_Society_First_Counselor = 1431,
  District_Relief_Society_President = 1430,
  District_Relief_Society_Second_Counselor = 1432,
  District_Relief_Society_Secretary = 1433,
  District_Sunday_School_First_Counselor = 1445,
  District_Sunday_School_President = 1444,
  District_Sunday_School_Second_Counselor = 1446,
  District_Sunday_School_Secretary = 1447,
  District_Temple_and_Family_History_Consultant = 1456,
  District_Young_Men_First_Counselor = 1436,
  District_Young_Men_President = 1435,
  District_Young_Men_Second_Counselor = 1437,
  District_Young_Men_Secretary = 1438,
  District_Young_Women_First_Counselor = 1440,
  District_Young_Women_President = 1439,
  District_Young_Women_Second_Counselor = 1441,
  District_Young_Women_Secretary = 1442,
  Elders_Quorum_First_Counselor = 139,
  Elders_Quorum_President = 138,
  Elders_Quorum_Second_Counselor = 140,
  Elders_Quorum_Secretary = 141,
  Employment_Associate_Manager = 122,
  Employment_Resource_Center_Manager = 120,
  Enabler = 623,
  Executive_Assistant_Secretary = 1448,
  General_Authority_Secretary = 1175,
  General_Authority_Seventy = 31,
  High_Priests_Group_Leader = 133,
  History_Specialist = 689,
  Mission_Clerk = 65,
  Mission_Executive_Secretary = 64,
  Mission_Presidency_Counselor = 1681,
  Mission_Presidency_First_Counselor = 38,
  Mission_Presidency_Second_Counselor = 39,
  Mission_President = 9,
  Mission_Public_Affairs_Specialist = 161401,
  PEF_Area_SR_Manager = 1793,
  PEF_Loan_Review_Specialist = 936,
  PEF_Missionary = 937,
  PEF_SR_Operations_Manager = 1661,
  PEF_Support_Center_Supervisor = 1635,
  Presidency_of_the_Seventy_President = 30,
  President_of_the_Church = 19,
  Priests_Quorum_Adviser = 162,
  Primary_First_Counselor = 211,
  Primary_President = 210,
  Primary_Second_Counselor = 212,
  Primary_Secretary = 213,
  Quorum_of_the_Twelve_Acting_President = 24,
  Quorum_of_the_Twelve_Member = 25,
  Quorum_of_the_Twelve_President = 23,
  Quorum_of_the_Twelve_Secretary = 1184,
  Relief_Society_Assistant_Secretary = 1900,
  Relief_Society_First_Counselor = 144,
  Relief_Society_President = 143,
  Relief_Society_Second_Counselor = 145,
  Relief_Society_Secretary = 146,
  S_I_Administrator_of_Seminaries_Institutes_of_Religion = 80,
  S_I_Area_Director = 73,
  S_I_Assistant_to_the_Commissioner = 56507,
  S_I_Coordinator = 79,
  S_I_Division_Director = 82,
  S_I_Institute_Director = 75,
  S_I_PT_Teacher_Administration = 897,
  S_I_Preservice_Director = 1055,
  S_I_Principal_Church_School = 950,
  S_I_Regional_Director = 76,
  S_I_Seminary_Instructor = 77,
  S_I_Seminary_Principal = 74,
  S_I_Support_Specialist = 89,
  S_I_Support_Specialist_Senior = 90,
  SRS_Manager = 5634,
  SRS_PEF_Director = 56505,
  SRS_PEF_Manager = 3338000,
  Secretary_to_the_First_Presidency = 1101,
  Secretary_to_the_Quorum_of_the_Twelve = 1181,
  Service_Center_Liaison = 1525,
  Service_Center_Manager = 382,
  Stake_Assistant_Clerk = 53,
  Stake_Assistant_Executive_Secretary = 3770599,
  Stake_Assistant_Executive_Secretary2 = 47149791,
  Stake_Clerk = 52,
  Stake_Executive_Secretary = 51,
  Stake_Family_History_Leader = 1378,
  Stake_High_Councilor = 94,
  Stake_Mission_Presidency_First_Counselor = 736,
  Stake_Mission_Presidency_Second_Counslr = 738,
  Stake_Mission_President = 735,
  Stake_Mission_Secretary = 739,
  Stake_Presidency_First_Counselor = 2,
  Stake_Presidency_Second_Counselor = 3,
  Stake_President = 1,
  Stake_Primary_First_Counselor = 1383,
  Stake_Primary_President = 1380,
  Stake_Primary_Second_Counselor = 1384,
  Stake_Primary_Secretary = 1385,
  Stake_Relief_Society_Board_Member = 745,
  Stake_Relief_Society_First_Counselor = 742,
  Stake_Relief_Society_President = 741,
  Stake_Relief_Society_Second_Counselor = 743,
  Stake_Relief_Society_Secretary = 744,
  Stake_Sunday_School_First_Counselor = 757,
  Stake_Sunday_School_President = 756,
  Stake_Sunday_School_Second_Counselor = 758,
  Stake_Sunday_School_Secretary = 759,
  Stake_Temple_and_Family_History_Consultant = 1375,
  Stake_Young_Men_First_Counselor = 747,
  Stake_Young_Men_President = 746,
  Stake_Young_Men_Second_Counselor = 748,
  Stake_Young_Men_Secretary = 749,
  Stake_Young_Women_First_Counselor = 752,
  Stake_Young_Women_President = 751,
  Stake_Young_Women_Second_Counselor = 753,
  Stake_Young_Women_Secretary = 755,
  Sunday_School_First_Counselor = 205,
  Sunday_School_President = 204,
  Sunday_School_Second_Counselor = 206,
  Sunday_School_Secretary = 207,
  Teachers_Quorum_Adviser = 163,
  Temple_and_Family_History_Consultant = 118,
  Transitional_Services_Manager = 56508,
  Vol_Employment_Resource_Center_Manager = 121,
  WEL_Division_Director = 659,
  Ward_Assistant_Clerk = 58,
  Ward_Assistant_Executive_Secretary = 47149787,
  Ward_Assistant_Executive_Secretary3 = 47149788,
  Ward_Clerk = 57,
  Ward_Executive_Secretary = 56,
  Ward_Mission_Leader = 221,
  Ward_Temple_and_Family_History_Consultant = 238,
  Ward_Temple_and_Family_History_Leader = 1970,
  Welfare_Accounting_Manager = 1124,
  Welfare_Bishops_Central_Storehouse_Manager = 340,
  Welfare_Cannery_Manager = 304,
  Welfare_DI_Region_Manager = 409,
  Welfare_DI_Thrift_Store_Manager = 413,
  Welfare_Development_Counselor = 2377750,
  Welfare_Director = 323,
  Welfare_Farm_Financial_Clerk = 321,
  Welfare_Farm_Manager = 306,
  Welfare_Field_Operations_Manager = 1262,
  Welfare_Grain_Storage_Facility_Manager = 313,
  Welfare_Home_Storage_Center_Manager = 305,
  Welfare_Humanitarian_Center_Manager = 533,
  Welfare_Humanitarian_Cntry_Senior_Missionary = 519,
  Welfare_Humanitarian_Country_Director = 517,
  Welfare_Large_Cannery_Technical_Manager = 325,
  Welfare_Livestock_Hdq_Manager = 1268,
  Welfare_Manager_of_Administration = 875,
  Welfare_Office_Manager = 1218,
  Welfare_PD_Field_Manager = 315,
  Welfare_Processing_Small_Canneries_Mgr = 326,
  Welfare_Processing_Facility_Controller = 874,
  Welfare_Processing_Facility_Manager = 303,
  Welfare_Quality_Assurance_Manager = 341,
  Welfare_Ranch_Manager = 309,
  Welfare_Senior_Missionary = 1205,
  Welfare_Senior_Technical_Manager = 329,
  Welfare_Storehouse_Manager = 302,
  Welfare_Volunteer_Bishops_Storehouse_Manager = 626,
  Young_Men_First_Counselor = 159,
  Young_Men_President = 158,
  Young_Men_Second_Counselor = 160,
  Young_Men_Secretary = 161,
  Young_Women_Class_Advisor = 2071,
  Young_Women_First_Counselor = 184,
  Young_Women_President = 183,
  Young_Women_Second_Counselor = 185,
  Young_Women_Secretary = 186,
}
export const enum ldsxrm_churchposition_statecode {
  Active = 0,
  Inactive = 1,
}
export const enum ldsxrm_churchposition_statuscode {
  Active = 1,
  Inactive = 2,
}
