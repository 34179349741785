import { blue30, yellow30, spacing16 } from '@churchofjesuschrist/eden-style-constants';
import Badge from '@churchofjesuschrist/eden-badge';
import styled from 'styled-components';

export const WardDescriptor = styled(Badge)`
  background: ${blue30};
`;

export const StakeDescriptor = styled(Badge)`
  background: ${yellow30};
`;

export const Descriptor = styled.div`
  line-height: ${spacing16};
`;
