import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// Components
import DisplayStories from '../../components/DisplayStories/DisplayStories';
import { UnitSelectorContainer } from '../../components/SelectUnit/SelectUnit';

// styles
import { PageTitle, FloatRight, TitleRow, Line } from '../../utils/appCSS/appStyles';

// queries
import { setUnitsQueryParams } from '../../utils/graphQL_Queries/queryVariableFormats';

// utilities, constants
import { pageNames } from '../../utils/appConstants/appConstants';
import { withPage } from 'src/contexts/PageContext';
import { addPageAnalytics, CATEGORY_TYPE_FAVORITES } from 'src/utils/analytics';
import { UNITS_NO_CHILDREN_QUERY } from 'src/utils/graphQL_Queries/appQueries';
import { useUserPermissions } from 'src/hooks/useUserPermissions';

const { favoritesPageName } = pageNames;

const FavoritesPage = () => {
  const { activeUnitObj } = useUserPermissions();
  const { t, i18n } = useTranslation('strings');
  const { user } = useUserPermissions();

  React.useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: favoritesPageName, // always use English
        activePage: favoritesPageName, // used to set primary category
        breadCrumbs: [favoritesPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_FAVORITES,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  useQuery(UNITS_NO_CHILDREN_QUERY, {
    variables: setUnitsQueryParams(0, '')
  });

  return (
    <>
      <TitleRow>
        <PageTitle>{t('favoritesLabel')}</PageTitle>
        <UnitSelectorContainer>
          <FloatRight cursor="auto">
            {`${activeUnitObj.unitName} (${activeUnitObj.unitNumber})`}
          </FloatRight>
        </UnitSelectorContainer>
      </TitleRow>

      <Line />

      <DisplayStories
        pageName={favoritesPageName}
      />
    </>
  )
};

export default withPage({ pageName: favoritesPageName, pageTitle: 'favoritesPageName' })(FavoritesPage);
