import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Eden
import Card from '@churchofjesuschrist/eden-card';
import Stack from '@churchofjesuschrist/eden-stack';
import Split from '@churchofjesuschrist/eden-split';
import Image from '@churchofjesuschrist/eden-image';
import { Text3 } from '@churchofjesuschrist/eden-text';
import { blue30, highlightBlue } from '@churchofjesuschrist/eden-style-constants';
import { A11y, Secondary } from '@churchofjesuschrist/eden-buttons';
import { H1 } from '@churchofjesuschrist/eden-headings';
import { Done } from '@churchofjesuschrist/eden-icons';

// styles
import styled from 'styled-components';

import { useCookies } from 'react-cookie';

// constants
import { NAV_QUICK_START_GUIDE } from 'src/utils/AppFunctions/setUrls';

const WelcomeContainer = styled(Split)`
  padding: 1rem;
  background: ${highlightBlue};
`;

const IHeader = styled(H1)`
  font-style: italic;
  font-weight: 100;
`;

const LightText = styled(Text3)`
  font-weight: 100;
`

const ActionGhostButton = styled(A11y)`
  color: ${blue30};
`;

const HelpCard = styled(Card)`
  padding: 1rem;
`;

const WelcomeHelp = () => {
  const router = useHistory();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['UH.wsv']);

  const hide = () => {
    setCookie('UH.wsv', true);
  }

  const handleClick = (url) => router.push(url);

  return (
    <WelcomeContainer ratio="2/3">
      <Stack>
        <IHeader>{t('welcome')}</IHeader>
        <LightText>{t('welcome_description')}</LightText>
        <ActionGhostButton onClick={hide}>
          <Done /> {t('doNotNeedToSee')}
        </ActionGhostButton>
      </Stack>
      <HelpCard>
        <Split ratio="1/3" min="5rem">
          <div>
            <Image
              src="/static/images/taiwan_family_ipad_thumb.jpg"
              alt={t('inspirationCardAlt')}
              style={{ flex: 'none', maxHeight: 80 }} />
          </div>
          <Stack>
            <LightText>{t('readyToGetStarted')}</LightText>
            <Secondary onClick={() => handleClick(NAV_QUICK_START_GUIDE)}>{t('quickStartGuide')}</Secondary>
          </Stack>
        </Split>
      </HelpCard>
    </WelcomeContainer>
  );
}

export default WelcomeHelp;
