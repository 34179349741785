import styled from 'styled-components';

// eden
import Card from '@churchofjesuschrist/eden-card';
import {
  fontSize14,
  fontSize18,
  fontSize20,
  blue20,
  danger10,
  grey10,
  grey40,
  white,
  lineHeight,
  lineHeightTight,
  spacing8,
  spacing16,
  spacing32,
  text120,
  fontSize16,
  sans as zoram,
} from '@churchofjesuschrist/eden-style-constants';
import { mediaBreakGutter } from '@churchofjesuschrist/eden-styled-helpers';

// constants
export const size0 = '0rem'; /* 0px */
export const size1 = '0.0625rem'; /* 1px */
export const size2 = '0.125rem'; /* 2px */
export const size12 = '0.75rem'; /* 12px */
export const size23 = '1.4375rem'; /* 23px */
export const size24 = '1.5rem'; /* 24px */
export const size70 = '4.375rem'; /* 70px */
export const size100 = '6.25rem'; /* 100px */
export const size248 = '15.5rem'; /* 248px */
export const size512 = '32rem'; /* 512px */
export const size519 = '32.4375rem'; /* 519px */
export const size552 = '34.5rem'; /* 552px */

/* Negative Values */
export const sizeNeg70 = '-4.375rem'; /* -70px */
export const sizeNeg552 = '-34.5rem'; /* -552px */

export const grey333333 = '#333333';

const defaultDesktopWidth = size552;
const defaultMobileWidth = '100vw';

export const ErrorMessage = styled.div`
  /* width: ${size519}; */
  /* height: ${fontSize20}; */
  /* font-size: ${fontSize14}; */
  /* font-weight: normal; */
  /* line-height: ${lineHeight}; */
  color: ${danger10};
  padding: ${spacing8};
`;

export const FeedbackBoxContainer = styled(Card)`
  width: ${props => props.isMobile ? defaultMobileWidth : defaultDesktopWidth};
  position: fixed;
  bottom: ${size24};
  z-index: 1;
  display: ${props => props.show ? 'initial' : 'none'};
  right: ${props => (props.isOpen ? size0 : sizeNeg552)};
  transition-duration: 0.5s;
`;

export const FeedbackButtonContainer = styled.div`
  position: absolute;
  background-color: ${white};
  bottom: ${size100};
  left: ${sizeNeg70};
  transform: rotate(-90deg);
`;

export const GroupSplit = styled.div<{ groupAlign?: string, backgroundColor?: string, paddingTop?: string, paddingBottom?: string, cursor?: string }>`
  justify-content: space-between;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.groupAlign ? props.groupAlign : 'flex-start')};
  flex-wrap: wrap;
  color: ${props => (props.color ? props.color : 'inherit')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'inherit')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0px')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0px')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
`;

export const ItemGroup = styled.div<{ groupAlign?: string, backgroundColor?: string, paddingTop?: string, paddingBottom?: string, cursor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.groupAlign ? props.groupAlign : 'flex-start')};
  flex-wrap: wrap;
  color: ${props => (props.color ? props.color : 'inherit')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'inherit')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0px')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0px')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  height: ${spacing32};
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

export const InputLabel = styled.span`
  color: ${text120};
  font-size: ${fontSize14};
  line-height: ${lineHeightTight};
  padding-left: ${spacing8};
`;

export const ModalContainer = styled.div<{ isMobile?: boolean }>`
  width: ${props => props.isMobile ? defaultMobileWidth : defaultDesktopWidth};
  padding: ${spacing16};
  border-left: ${spacing8} solid ${blue20};
`;

export const Note = styled.div`
  width: ${size512};
  height: ${fontSize20};
  font-style: italic;
  color: ${grey40};
  margin-top: ${spacing8};
`;

export const HR = styled.hr<{ size?: string }>`
  border-top: ${props =>
    props.size ? `${props.size} solid ${grey10}` : `${size2} solid ${grey10}`};
`;

export const BlankContent = styled.div`
  margin: ${spacing8};
  padding: 11px;
  font: normal normal normal ${fontSize16} / ${lineHeight} ${zoram};
`;

export const Title = styled.div`
  font-size: ${fontSize18};
  line-height: ${lineHeightTight};
  color: ${text120};
`;

export const Space = styled.div<{ top?: number, start?: number, end?: number, bottom?: number }>`
  ${props => (props.top ? `padding-top: ${props.top}` : null)};
  ${props => (props.start ? mediaBreakGutter(props.start) : null)};
  ${props => (props.end ? mediaBreakGutter(props.end) : null)};
  ${props => (props.bottom ? `padding-bottom: ${props.bottom}` : null)};
`;
