import styled from 'styled-components';

import { flexRow } from 'src/components/ComponentStyles/div';

export const Favorite = styled(flexRow)<{ hasImage?: boolean }>`
  position: absolute;
  right: 11px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;
