import React, { useState } from 'react';
import { func, object, string, bool } from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';

// eden
import Modal from '@churchofjesuschrist/eden-platform-modal';
import { Warn, Info } from '@churchofjesuschrist/eden-alert';
import Stack from '@churchofjesuschrist/eden-stack';
import Row from '@churchofjesuschrist/eden-row';
import { H4 } from '@churchofjesuschrist/eden-headings';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { Primary, Secondary } from '@churchofjesuschrist/eden-buttons';
import { yellow10 } from '@churchofjesuschrist/eden-style-constants';
import { Body, Drawer, Handle } from '@churchofjesuschrist/eden-drawer';

// Components
import { usePageContext } from 'src/contexts/PageContext';

// functions
import { formatDate } from 'src/utils/AppFunctions/appFunctions';

const defaultWidth = '530px';

const MessageContainer = styled.div<{ width?: string }>`
  width: ${props => (props.width ? props.width : defaultWidth)};
`;

export const LockedByMessage = ({ lockObj }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {t('storyIsEditedByLabel')} <strong>{lockObj.name}</strong>.{' '}
      {t('pleaseTryAgainLaterLabel')}
    </React.Fragment>
  )
};

export const LockedUntilMesssage = ({ lockObj }) => {
  const { t } = useTranslation();
  const { localeCode } = usePageContext();
  return (
    <React.Fragment>
      {t('storyWillUnlockLabel')}
      <strong>{formatDate(lockObj.date, localeCode, 'MMM d, h:mm a')}</strong>
    </React.Fragment>
  )
};

export const StoryLocked = ({
  isActive,
  lockObj,
  isUnlockable,
  handleClose,
  handleUnlock,
  width = defaultWidth,
}) => {
  const [isModalActive, toggleModal] = useState(isActive);
  const [isOpen, toggleDrawer] = useState(false);

  const handleToggle = () => toggleDrawer(!isOpen);
  const handleModalClose = () => {
    toggleModal(false);
    handleClose();
  };
  const handleProceedToUnlock = () => {
    toggleModal(false);
    handleUnlock();
  };

  const { t } = useTranslation();
  const modalTitle = isUnlockable ? t('storyCanBeUnlockedLabel') : t('storyIsLockedLabel');
  const ModalType = isUnlockable ? Info : Warn;

  return (
    <Modal active={isModalActive} onCloseGesture={handleModalClose}>
      <MessageContainer width={width}>
        <ModalType onClose={handleModalClose}>
          <Stack>
            <H4>{modalTitle}</H4>
            <Text4>
              <div>
                <p>
                  {isUnlockable
                    ? `${lockObj.name} ${t('storyLockPeriodExceeded')}`
                    : <LockedByMessage lockObj={lockObj} />}
                </p>
              </div>
            </Text4>
            {!isUnlockable && (
              <Drawer open={isOpen} onToggle={handleToggle}>
                <Body>
                  <Text4>
                    <p>{t('storyLockedMessage')}</p>
                    <p>
                      {t('storyWillUnlockLabel')}
                      <LockedUntilMesssage lockObj={lockObj} />
                    </p>
                  </Text4>
                </Body>
                <Handle>{isOpen ? t('hideLabel') : t('explainLockingLabel')}</Handle>
              </Drawer>
            )}
            <Row>
              {isUnlockable
                ? (
                  <React.Fragment>
                    <Primary onClick={handleProceedToUnlock}>{t('editAndUnlockBtnLabel', { ns: 'labels' })}</Primary>
                    <Secondary onClick={handleModalClose}>{t('cancelBtnLabel', { ns: 'labels' })}</Secondary>
                  </React.Fragment>
                )
                : (
                  <ThemeProvider theme={{ primaryColor: yellow10 }}>
                    <Primary onClick={handleModalClose}>{t('closeBtnLabel', { ns: 'labels' })}</Primary>
                  </ThemeProvider>
                )}
            </Row>
          </Stack>
        </ModalType>
      </MessageContainer>
    </Modal>
  );
};

export default StoryLocked;

StoryLocked.propTypes = {
  isActive: bool,
  lockObj: object,
  isUnlockable: bool,
  handleClose: func,
  handleUnlock: func,
  width: string,
};

LockedByMessage.propTypes = {
  lockObj: object,
};

LockedUntilMesssage.propTypes = {
  lockObj: object,
};
