import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, number, func, string, object } from 'prop-types';

// Eden
import Row from '@churchofjesuschrist/eden-row';

// Components
import { SaveStoryButton } from '../Buttons/SaveStoryButton';
import { SaveAndPublishStoryBtn } from '../Buttons/SaveAndPublishStoryBtn';
import { SaveAndSubmitStoryBtn } from '../Buttons/SaveAndSubmitStoryBtn';
import PreviewStoryButton from '../Buttons/PreviewStoryButton';

import {
  objectArrayIsRequired,
  userType,
} from '../../utils/appConstants/propTypesConstants';

import { pageNames } from '../../utils/appConstants/appConstants';
import { User } from 'src/models/User';

const { managePublishPageName } = pageNames;

interface IProps { currentPage?, isCreatePage?, index?, user?: User, currentPageName?, attachmentFiles?, handleFormValidation?, handleAutoSave?, onSave, autoSave?, handleSaveStory?, imageFiles?, story?, activeUnitObj?, changesToSave?, disabled?};

export const StoryFormButtonBar = (props: IProps) => {
  const {
    // currentPage,
    // isCreatePage,
    index,
    user,
    story,
    currentPageName,
    activeUnitObj,
    changesToSave,
    handleFormValidation,
    handleSaveStory,
    autoSave,
    onSave,
    disabled
  } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Row gapSize="16" align="start">
        {!props.isCreatePage &&
          (props.user?.isApprover
            ? (
              <SaveAndPublishStoryBtn
                user={user}
                index={index}
                story={story}
                currentPageName={currentPageName}
                activeUnitObj={activeUnitObj}
                disabled={disabled}
                handleFormValidation={handleFormValidation}
              />
            )
            : (
              <SaveAndSubmitStoryBtn
                user={user}
                index={index}
                story={story}
                activeUnitObj={activeUnitObj}
                disabled={disabled}
                handleFormValidation={handleFormValidation}
              />
            ))}

        {!props.isCreatePage &&
          <PreviewStoryButton
            btnLabel={t('previewBtnLabel', { ns: 'labels' })}
            user={user}
            index={index}
            story={story}
            handleSaveStory={handleSaveStory}
            changesToSave={changesToSave}
            disabled={disabled}
          />}

        {props.currentPageName !== managePublishPageName && (
          <SaveStoryButton
            currentPageName={currentPageName}
            user={user}
            index={index}
            story={story}
            handleSaveStory={handleSaveStory}
            changesToSave={changesToSave}
            disabled={disabled}
            onSave={onSave}
            autoSave={autoSave}
            handleFormValidation={handleFormValidation}
          />
        )}
      </Row>
    </div>
  );
}

export default StoryFormButtonBar;

StoryFormButtonBar.propTypes = {
  attachmentFiles: objectArrayIsRequired,
  currentPageName: string.isRequired,
  handleFormValidation: func.isRequired,
  handleSaveStory: func.isRequired,
  handleAutoSave: func,
  imageFiles: objectArrayIsRequired,
  index: number.isRequired,
  story: object,
  user: userType,
  activeUnitObj: object,
  autoSave: bool,
  isCreatePage: bool,
};
