import styled from 'styled-components';
import Card from '@churchofjesuschrist/eden-card';
import {
  grey3,
  spacing8,
  spacing16,
  white,
  black,
  sans as zoram,
} from '@churchofjesuschrist/eden-style-constants';

const BaseFont = styled.div`
  font-family: ${zoram};
`;

export const flexColumn = styled(BaseFont)`
  display: flex;
  flex-direction: column;
`;

export const flexRow = styled(BaseFont)`
  display: flex;
  flex-direction: row;
`;

export const MCard = styled(Card)`
  font-family: ${zoram};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${white};
  border: 1px solid ${grey3};
  border-radius: 2px;
  box-shadow: 1px 1px 5px 1px rgba(74, 74, 74, 0.25);
  max-width: 100%;
`;

export const Tag = styled(flexRow)`
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 5px;
  padding: 0 12px;
  border: 1px solid ${black};
  height: 24px;
  width: auto;
  min-width: 85px;
  border-radius: 12px;
  background: linear-gradient(169.51deg, rgba(33, 34, 37, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
`;

export const Thumbnail = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* width: 205px; */
  height: 128px;
  padding: ${spacing8};
  cursor: pointer;
  /* margin-right: ${spacing16}; */

  img {
    height: unset;
    width: unset;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const FlexRowStart = styled(flexRow)`
  justify-content: flex-start;
`;

export const FlexChildFix = styled.div`
  min-width: 0; /* NOTE: this is crucial. It resolves the child width bug of children in a flex container. */
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
`;
