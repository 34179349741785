import React from 'react';

import Card from '@churchofjesuschrist/eden-card';
import Stack from '@churchofjesuschrist/eden-stack';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { spacing4, spacing8, spacing16 } from '@churchofjesuschrist/eden-style-constants';
import { Secondary } from '@churchofjesuschrist/eden-buttons';
import { setExamplesURL } from 'src/utils/AppFunctions/setUrls';

import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AddStoryCard from './AddStoryCard';
import styled from 'styled-components';

const FlexBoxFull = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ExampleStoryCard = ({ description, button, action }) => {
  return (
    <Card style={{ margin: `${spacing4} 0 0` }}>
      <div
        style={{
          padding: `${spacing8} ${spacing16} ${spacing16}`,
        }}
      >
        <Stack gapSize="8">
          <Text4 style={{ textAlign: 'center' }}>{description}</Text4>
          {action && (
            <div style={{ textAlign: 'center' }}>
              <Secondary onClick={() => action()}>{button}</Secondary>
            </div>
          )}
        </Stack>
      </div>
    </Card>
  );
};

export const AddStoryExampleCard = () => {
  const { t } = useTranslation();
  const router = useHistory();
  return <ExampleStoryCard
    description={t('addStoryDesc')}
    button={t('addStoryButton')}
    action={() => router.push(setExamplesURL())}
  />
}
export const HomeAddStoryCard = () =>
  <FlexBoxFull>
    <AddStoryCard />
  </FlexBoxFull>

export default ExampleStoryCard;

ExampleStoryCard.propTypes = {
  description: string,
  button: string,
  action: func,
};
