/** Converts b64 encoded data to file blob. */
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const buff = Buffer.from(b64Data, 'base64'); // atob deprecated
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < buff.length; offset += sliceSize) {
    const slice = buff.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.at(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

/**
 * Uploads file content as chunk to api.
 * @param file current file chunk contents.
 * @param fileName name of file to be uploaded.
 * @param offset bytes to offset chunk.
 * @param complete chunk is last to be uploaded for file.
 * @param uploadId unique identifier (GUID) for current upload.
 * @param otherProps additional properties to be uploaded along with file.
 * @returns string
 */
export const uploadChunkForm = async (file: Blob, fileName: string, offset: number, complete: boolean, uploadId: string, otherProps = {}) => {
  const chunk = {
    name: fileName,
    offset,
    complete,
    uploadId,
    ...otherProps
  };
  const formData = new FormData();
  for (const p in chunk) {
    formData.append(p, chunk[p]);
  }
  formData.append('contents', file);
  const result = await fetch('api/upload/annual', {
    method: 'POST',
    body: formData,
    headers: {
      Accept: '*/*',
      filename: fileName
      // content-type will be determined by browser
    }
  });
  if (result.ok) {
    return await result.json() as string;
  }
  const err = await result.text();
  throw new Error(`${result.statusText}:${result.status} - ${err}`);
}

export const uploadComplete = async (fileNames: string[], email: string) => {
  const request = {
    fileNames,
    recipient: email
  };
  const result = await fetch('api/upload/complete', {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*'
    }
  });
  if (result.ok) {
    return await result.json() as string;
  }
  const err = await result.text();
  throw new Error(`${result.statusText}:${result.status} - ${err}`);
}
