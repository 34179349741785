import i18next from 'i18next';

import { Story } from 'src/models/Story';
import { UnitType } from 'src/models/Unit';

// NB: This is the content that is used for the example stories

// Note: t must be passed in
export const getLeadershipStories = (t = i18next.t) => {
  return <Story[]> [
    {
      id: 'leadershipGood1',
      dateStart: '2021-12-15T09:00:00Z',
      dateEnd: '2021-12-15T09:00:00Z',
      title: t('leadershipStory1Title'), // December Service Projects
      authors: [
        {
          accountId: 18401924161,
          name: 'Adom Musah and Asante Owusu',
          email: 'ah.test.user.com@gmail.com',
          position: 'BRANCH_PRESIDENT',
        },
      ],
      body: t('leadershipStory1Body'),
      organizations: [
        {
          name: t('RELIEF_SOCIETY'),
          uuid: 'f9be4c99-1014-41da-bee9-6ace333051a8',
          unitUuid: '',
          unitName: '',
          type: '',
          unitType: UnitType.Ward,
          unitTypeName: 'Sample Ward',
        },
      ],
      // topics: [],
      // mainImageSource: '/static/images/adult-literacy-class.jpg',
      images: [
        {
          id: 'e6485027-5fa2-4d4e-9825-88569fef5f73',
          sources: ['/static/images/adult-literacy-class.jpg'],
          alt: t('leadershipStory1Image1Alt'),
          preview: null
        },
        {
          id: 'thumb_e6485027-5fa2-4d4e-9825-88569fef5f73',
          sources: ['/static/images/adult-literacy-class.jpg'],
          alt: t('leadershipStory1Image1Alt'),
          preview: true,
        },
        {
          id: '4ab2b491-cade-4717-85bb-97685e581ccf',
          sources: ['/static/images/literacy-class-in-new-meetinghouse.jpg'],
          alt: t('leadershipStory1Image2Alt'),
          preview: null,
        },
        {
          id: 'thumb_4ab2b491-cade-4717-85bb-97685e581ccf',
          sources: ['/static/images/literacy-class-in-new-meetinghouse.jpg'],
          alt: t('leadershipStory1Image2Alt'),
          preview: true,
        },
        {
          id: '5c5cb61a-e37d-4ff6-9636-8777d6a77fdc',
          sources: ['/static/images/service-project-hohoe-main-station.jpg'],
          alt: t('leadershipStory1Image3Alt'),
          preview: null,
        },
        {
          id: 'thumb_5c5cb61a-e37d-4ff6-9636-8777d6a77fdc',
          sources: ['/static/images/service-project-hohoe-main-station.jpg'],
          alt: t('leadershipStory1Image3Alt'),
          preview: true,
        },
      ],
      attachments: [],
      personTags: [],
      parentUnit: {
        id: 524735,
        name: 'Savaii Samoa Fagamalo Stake',
        type: 'STAKE',
      },
      unit: {
        id: 56030,
        name: 'Fagamalo 1st Ward',
        type: 'WARD',
      },
      confidential: false,
      status: 'PUBLISHED',
      created: '2022-01-15T21:19:51.963358Z',
      modified: '2022-01-15T22:50:33.127975Z',
      submitted: '2022-01-15T21:20:07.824242Z',
      published: '2022-01-15T22:50:33.127969Z',
    }
  ]
};

export const getEventStories = (t = i18next.t) => {
  return <Story[]> [
    {
      id: 'eventGood1',
      dateStart: '2022-06-19T07:00:00Z',
      dateEnd: '',
      title: t('leadershipStory3Title'), // Miracle in June with Bower family
      authors: [
        {
          accountId: 18401924161,
          name: 'Wade Crossley',
          email: 'ah.test.user.com@gmail.com',
          position: 'BISHOPRIC_FIRST_COUNSELOR',
        },
      ],
      body: t('leadershipStory3Body'),
      organizations: [
        {
          name: t('BISHOPRIC'),
          uuid: '48bb8911-d7bf-ed11-83ff-000d3a34329a',
          unitUuid: '',
          unitName: '',
          type: '',
          unitType: UnitType.Ward,
          unitTypeName: 'Sample Ward',
        },
      ],
      // topics: [],
      // mainImageSource: '/static/images/nancy-and-jim-bower-at-home.jpg',
      images: [
        {
          id: '4ab2b491-cade-4717-85bb-97685e581ccf',
          sources: ['/static/images/nancy-and-jim-bower-at-home.jpg'],
          alt: 'Nancy and Jim Bower at Home',
          preview: null,
        },
        {
          id: 'thumbnail_4ab2b491-cade-4717-85bb-97685e581ccf',
          sources: ['/static/images/nancy-and-jim-bower-at-home.jpg'],
          alt: 'Nancy and Jim Bower at Home',
          preview: true,
        },
      ],
      attachments: [],
      personTags: [],
      parentUnit: {
        id: 524735,
        name: 'Savaii Samoa Fagamalo Stake',
        type: 'STAKE',
      },
      unit: {
        id: 56030,
        name: 'Fagamalo 1st Ward',
        type: 'WARD',
      },
      confidential: false,
      status: 'PUBLISHED',
      created: '2022-07-26T21:19:51.963358Z',
      modified: '2022-07-26T22:50:33.127975Z',
      submitted: '2022-07-26T21:20:07.824242Z',
      published: '2022-07-26T22:50:33.127969Z',
    },
    {
      id: 'eventBad1',
      dateStart: '2020-02-23T07:00:00Z',
      dateEnd: '',
      title: 'Relief Society Service',
      authors: [
        {
          accountId: 18401924161,
          name: 'Bonnie Goldsmith',
          email: 'ah.test.user.com@gmail.com',
          position: 'RELIEF_SOCIETY_PRESIDENT',
        },
      ],
      body:
      '<h2>Meaningful Event, Bad example</h2> <p>The sisters came together on a weekend in the summer <em>(vague, specific date is better)</em> to help Sister Melinda Ainsworth. She recently had stage 4 pancreatic cancer <em>(specific personal health information)</em> and has been getting a lot of financial help from the bishop <em>(Church welfare assistance)</em> to cover rent and groceries.</p> <p>We used $60, or half of our compassionate service budget <em>(discloses Church finances)</em> to buy flowers to put in her planters. The first counselor had warned us in Ward Council that she might be “ornery” given all the pain she is in <em>(information shared in a Church leadership meeting, may cause embarrassment to the people named in the story),</em> but we braced ourselves and went to work in spite of her protests.</p> <p>Rosa Withers used her expertise as a native Maori <em>(privacy laws: do not disclose racial or ethnic background)</em> to arrange the flowers.</p> <p>One would think that with her retirement income of over $2,000 per month <em>(personal financial information)</em> that Sister Ainsworth could afford to keep the place up better. But we spruced it up and left her home’s exterior a lot better than we’d found it <em>(may cause embarrassment to the people named in the story).</em> I wish our service was better received.</p> <p>&nbsp;</p>',
      organizations: [],
      personTags: [],
      // topics: [],
      // mainImageSource: '',
      images: [
        {
          id: 'e6485027-5fa2-4d4e-9825-88569fef5f73',
          sources: ['/static/images/no-image.svg'],
          alt: 'No image',
        },
      ],
      attachments: [],
      parentUnit: {
        id: 524735,
        name: 'Savaii Samoa Fagamalo Stake',
        type: 'STAKE',
      },
      unit: {
        id: 56030,
        name: 'Fagamalo 1st Ward',
        type: 'WARD',
      },
      confidential: false,
      status: 'PUBLISHED',
      created: '2022-11-26T23:11:25.714077Z',
      modified: '2022-11-26T23:12:50.568420Z',
      submitted: '2022-11-26T23:11:34.136468Z',
      published: '2022-11-26T23:12:50.568419Z',
    },
  ];
}
