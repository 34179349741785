/****** ldsxrm_churchunit ******
 * Auto-generated - do not modify.
 * To regenerate, run 'npm run enums'
 */

export const enum ldsxrm_unitentitytypes {
  FRD_Finance_Records_Department = 0,
  Ecclesiastical_Entity = 1,
  Church_Education_System = 2,
  Audit_Entity = 4,
  Church_Business_Entity = 6,
  Church_Headquarters_Entity = 7,
  Correlation_Entity = 11,
  Directors_for_Temporal_Affairs_Entity = 13,
  Family_History_Entity = 14,
  First_Presidency_Entity = 16,
  Human_Resource_Entity = 17,
  Information_and_Communications_Systems_Entity = 18,
  LDS_Family_Services_Entity = 21,
  Materials_Management_Entity = 23,
  Member_and_Statistical_Records_Entity = 24,
  Missionary_Entity = 25,
  Perpetual_Education_Fund_Entity = 28,
  Meetinghouse_Facilities_Entity = 29,
  Presiding_Bishopric_Entity = 30,
  Priesthood_Entity = 31,
  Quorum_of_Seventy_Entity = 33,
  Quorum_of_the_Twelve_Entity = 34,
  Temple_Entity = 36,
  Welfare_Entity = 38,
  Ecclesiastical_Ward_Branch = 50,
  Ecclesiastical_Stake = 51,
  Ecclesiastical_District = 52,
  Church_History_Entity = 2693,
  University_College_Entity = 2694,
  GSC_Entity = 2695,
  Special_Projects = 2697,
}
export const enum ldsxrm_unittypes {
  Area = 1,
  Mission = 3,
  Stake = 5,
  District = 6,
  Ward = 7,
  Branch = 8,
  Temple = 10,
  Area_Administrative_Office = 13,
  Area_MSR_Office = 14,
  MSR_Office = 16,
  First_Presidency = 17,
  Quorum_of_the_Twelve_Apostles = 18,
  Presidency_of_the_Seventy = 19,
  First_Quorum_of_the_Seventy = 20,
  Second_Quorum_of_the_Seventy = 21,
  Presiding_Bishopric = 22,
  Missionary_Training_Center = 26,
  CHQ_Department = 28,
  Visitors_Center = 30,
  Historic_Site = 31,
  S_I_Area = 33,
  S_I_Administrator = 34,
  S_I_Institute = 36,
  S_I_Country = 37,
  Church_Board_of_Education = 38,
  Seminary_Institute_Dept = 39,
  S_I_Coordinator = 41,
  Church_School_Primary_and_Secondary = 43,
  Family_History_Center = 44,
  S_I_Seminary = 53,
  S_I_Seminary_Home_Study = 54,
  S_I_Seminary_Released_Time = 55,
  Third_Quorum_of_the_Seventy = 56,
  Fourth_Quorum_of_the_Seventy = 57,
  Fifth_Quorum_of_the_Seventy = 58,
  Temporal_Affairs_Office = 59,
  Welfare_Employment_Resource_Center = 60,
  Welfare_Vol_Employment_Resource_Center = 61,
  MSR_Processing_Center = 64,
  General_Young_Women_Organization = 79,
  General_Primary_Organization = 80,
  General_Sunday_School_Organization = 81,
  General_Young_Men_Organization = 82,
  General_Relief_Society_Organization = 83,
  LDS_Family_Services = 84,
  LDSFS_Commissioner_Staff = 85,
  LDS_Family_Services_Office = 87,
  Area_Welfare_Services_Finance_Office = 147,
  Area_Project_Management_Office = 170,
  Wel_Bishops_Central_Storehouse = 188,
  Welfare_Bishops_Storehouse = 189,
  Welfare_Processing_Facility = 190,
  Welfare_Cannery = 191,
  Welfare_Home_Storage_Center = 192,
  Welfare_Ranch = 193,
  Welfare_Farm = 194,
  Welfare_Grain_Storage_Facility = 195,
  Welfare_Large_Cannery_Technical_Office = 208,
  Welfare_Senior_Technical_Office = 213,
  Welfare_Dist_Small_Canneries_Office = 216,
  Welfare_Proc_Large_Canneries_Office = 217,
  Welfare_Quality_Assurance_Lab = 218,
  Service_Center = 307,
  Area_Finance_Office = 367,
  Welfare_Transitional_Services = 407,
  Financial_Services_Office = 448,
  FRD_Division = 467,
  Welfare_DI_Region_Office = 468,
  Welfare_DI_Thrift_Store = 470,
  MSS_Department_Executive_Direction = 547,
  MSS_Dept_In_Field_Representative_Office = 549,
  DMBA_Division = 567,
  MSS_Division = 701,
  MFD_Division = 702,
  HRD_Division = 703,
  Welfare_Division = 704,
  ICS_Division = 705,
  MMD_Division = 706,
  Welfare_Country_Office = 707,
  Sixth_Quorum_of_the_Seventy = 727,
  Area_Real_Estate_Office = 730,
  Area_Translation_Office = 732,
  Area_Technical_Support_Services_Office = 733,
  Area_Planning_Office = 734,
  Welfare_Humanitarian_Center = 735,
  Seventh_Quorum_of_the_Seventy = 745,
  Eighth_Quorum_of_the_Seventy = 746,
  Recreation_Camp = 748,
  Area_Welfare_Office = 787,
  Pageant = 794,
  Student_Living_Center = 812,
  Headquarters_Mission = 815,
  Mission_Region = 867,
  Area_Help_Desk = 888,
  Priesthood_Dept_Division = 904,
  Priesthood_Dept_Office = 905,
  MSS_Office = 927,
  Welfare_Bishops_Storehouse_Volunteer = 947,
  College_President_s_Office = 968,
  Recreation_Property = 989,
  Senior_Missionary_Supervision = 990,
  Senior_Missionary_Coordination = 992,
  S_I_Programs = 993,
  Curriculum_Division = 997,
  MSS_Referral_Center = 1036,
  PFD_Construction = 1115,
  PEF_Division = 1117,
  Welfare_Services_Accounting_Office = 1124,
  Area_Purchasing_Division = 1125,
  Area_Fleet_Division = 1126,
  Financial_Services_Treasury_Office = 1143,
  Financial_Services_Accounting_Office = 1144,
  Financial_Services_Mission_Accounting_Office = 1145,
  Financial_Services_Accounts_Payable_Office = 1146,
  Financial_Services_Reconciliation_Office = 1147,
  Quorum_Office = 1153,
  S_I_Administration_Department = 1154,
  Correlation_Division = 1160,
  FH_Division = 1161,
  Temple_Division = 1162,
  Wel_Humanitarian_Branch_Office = 1163,
  Area_Operations_Office = 1167,
  Curriculum_Office = 1169,
  Audit_Division = 1172,
  Welfare_Humanitarian_Service_Room = 1174,
  Welfare_PD_Field_Manager_Office = 1178,
  Area_Architect_and_Engineer_Office = 1191,
  Church_History_Division = 1192,
  GSC_Division = 1221,
  Property_Reserve = 1229,
  Church_Membership_Information_System = 1233,
  Area_Media_Committee = 1244,
  Temple_Housing = 1270,
  MSS_Area_Book_Application_Office = 1272,
  Handcart_Trek = 1460,
  Welfare_Development_Counseling_Services_Office = 1740,
  Welfare_Bishops_Storehouse_Delivery = 2100,
  Inner_City_Project_Division = 1239,
  SPD_Division = 1242,
}
export const enum ldsxrm_churchunit_statecode {
  Active = 0,
  Inactive = 1,
}
export const enum ldsxrm_churchunit_statuscode {
  Active = 1,
  Discontinued = 2,
  Removed = 100000000,
  Pending_Unit = 881930000,
}
