export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const determineValidityState = (validityState) => {
  const valid = validityState?.valid;
  return valid === true ? 'success' : valid === false ? 'error' : 'unknown';
};

export const changeHandlerGlobal = (event, formData, setFormData) => {
  const input = event.target;
  let theValue = input.value;

  if (input.type === 'checkbox') {
    theValue = input.checked ? input.value : '';
    // if the value is `true` then the checkbox is driving a boolean value and return the boolean `true` or `false` instead of a string
    if (input.value === 'true') {
      theValue = input.checked;
    }
  }
  setFormData({
    ...formData,
    [input.name]: theValue,
  });
};

export function generateGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
