import React, { useState, useEffect } from 'react';

// components
import UnitBadge from '../../components/UnitBadge';
import StoryLocked from '../../components/Modals/StoryLocked/StoryLocked';
import { getPageRedirect } from '../../components/Stories/utils/RedirectPages';

// styles
import { PageTitle } from '../../utils/appCSS/appStyles';

// utilities, constants
import { pageNames, STORY_STATUSES } from '../../utils/appConstants/appConstants';
import { addPageAnalytics, CATEGORY_TYPE_EDIT } from 'src/utils/analytics';
import StoryForm from '../../components/Create/Form/StoryForm';
import { useStory } from '../../components/Stories/utils/storyHooks';
import { useTranslation } from 'react-i18next';
import { withStory } from 'src/contexts/StoryContext';
import { useUserPermissions } from 'src/hooks/useUserPermissions';
import { compose } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { setUnitOrgs } from 'src/reducers/unitsReducer';
import { getOrganizationsQuery } from 'src/utils/graphQL_Queries/appQueries';
import { withPage } from 'src/contexts/PageContext';
import { useHistory } from 'react-router-dom';

const {
  PUBLISHED_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
  REQUESTED_STATUS,
  CREATED_STATUS,
  RETURNED_STATUS,
  PENDING_INVITE
} = STORY_STATUSES;

const { homePageName, editPageName } = pageNames;

const EditStoryPage = () => {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const prevPage = searchParams.get('prev_page');
  const [proceedToEdit, setProceedToEdit] = useState(false);

  const { story, isLocked, inRework, isUnlockable, badOrMissingStory } = useStory();
  const { user } = useUserPermissions();
  const history = useHistory();

  const { t, i18n } = useTranslation('strings');

  useEffect(() => {
    const addAnalytics = () => {
      addPageAnalytics({
        infoName: editPageName, // always use English
        activePage: editPageName, // used to set primary category
        breadCrumbs: [homePageName, editPageName],
        language: i18n.language, // app language
        categoryType: CATEGORY_TYPE_EDIT,
        userInfo: user,
      });
    }

    addAnalytics();
  }, []);

  useQuery(getOrganizationsQuery, {
    onCompleted(data) {
      dispatch(setUnitOrgs(data?.organizations));
    },
  });

  const handleLockRedirect = () => {
    const params = { storyId: story.id };
    history.push(getPageRedirect(prevPage, params));
  };

  const handleUnlockRedirect = () => setProceedToEdit(true);

  return <>
    {isLocked && !proceedToEdit
      ? <StoryLocked
        isActive={true}
        lockObj={story?.lock}
        isUnlockable={isUnlockable}
        handleClose={handleLockRedirect}
        handleUnlock={handleUnlockRedirect}
      />
      : null}

    <PageTitle paddingTop={inRework ? '12px' : undefined}>
      <div>
        <UnitBadge unit={story?.unit}></UnitBadge>
      </div>
      {t('editStoryLabel')}
    </PageTitle>
    {badOrMissingStory
      ? (
        <div>{t('retrieveStoryErrorMessage')}</div>
      )
      : user?.isApprover ||
        (user?.isContributor &&
          [
            RETURNED_STATUS,
            DRAFT_STATUS,
            REQUESTED_STATUS,
            CREATED_STATUS,
            PENDING_INVITE
          ].includes(story.status))
        ? (
          <StoryForm
            isCreatePage={false}
            prevPageName={prevPage}
          />
        )
        : story.status === PUBLISHED_STATUS
          ? (
            <div>{t('alreadyPublishedNoEdit')}</div>
          )
          : story.status === PENDING_STATUS
            ? (
              <div>{t('alreadySubmittedNoEdit')}</div>
            )
            : (
              <div>{t('noEditRights')}</div>
            )}
  </>
};

export default compose(withStory, withPage({ pageName: editPageName, pageTitle: 'editStoryPageTitle' }))(EditStoryPage) as (otherProps: any) => JSX.Element;
