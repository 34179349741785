import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// Eden
import Row from '@churchofjesuschrist/eden-row';
import Stack from '@churchofjesuschrist/eden-stack';
import { ProgressBar, ProgressSection } from '@churchofjesuschrist/eden-progress';
import { Text4 } from '@churchofjesuschrist/eden-text';
import { Done, Clear, CircleNotStarted, CircleStarted } from '@churchofjesuschrist/eden-icons';
import { Icon } from '@churchofjesuschrist/eden-buttons';
import { green30 } from '@churchofjesuschrist/eden-style-constants';
import {
  Input,
  FormField,
  Label,
  Select
} from '@churchofjesuschrist/eden-form-parts';
import { Info } from '@churchofjesuschrist/eden-alert';

// Components
import UploadWrapper from 'src/components/Upload/UploadWrapper';
import FieldValidityHint from 'src/components/Form/FieldValidityHint';

// styles
import styled from 'styled-components';
import Form from 'src/components/Form/Form';
import { Box, PageTitle } from '../../utils/appCSS/appStyles';

// reducers
import { selectAreas } from 'src/reducers/unitsReducer';
import { selectUser } from 'src/reducers/authReducer';
import { selectUploadCompleteEmail, selectUploadFile, selectUploading, selectUploadPercent, uploadComplete } from 'src/reducers/uploadReducer';

// context
import { withPage } from 'src/contexts/PageContext';

// utilitiles
import { determineValidityState } from 'src/utils/formUtil';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { useAppDispatch } from 'src/utils/appStore';

// constants
import { pageNames } from 'src/utils/appConstants/appConstants';

const { uploadPageName } = pageNames;

const BoldUnderlinedLabel = styled(Label)`
  font-weight: 700;
  border-bottom: 1px solid #bcb8b8;
  color: #212225;
  padding-bottom: 5px;
`;

const GreenCheck = styled(Done)`
  color: ${green30};
`;

const Upload = () => {
  const uploading = useSelector(selectUploading);
  const uploadPercent = useSelector(selectUploadPercent);
  const uploadFile = useSelector(selectUploadFile);
  const areas = useSelector(selectAreas);
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const { sent, sending, error } = useSelector(selectUploadCompleteEmail);
  const { startUpload, stageFiles, files, allComplete, completedFiles, fileIndex } = useFileUpload();
  const [formData, setFormData] = useState({
    area: '',
    email: user?.email
  });

  const { t } = useTranslation('strings');

  useEffect(() => {
    if (allComplete) {
      dispatch(uploadComplete({
        fileNames: completedFiles,
        email: formData.email
      }))
    }
  }, [dispatch, allComplete, formData, completedFiles]);

  useEffect(() => {
    if (sent || error) {
      let path = '/upload/complete';
      if (error) {
        path += `?err=${error.message}`;
      }
      window.location.href = path;
    }
  }, [sent, error]);

  const tryUpload = async (formData: any) => {
    const uploadData = { ...formData }; // copy to avoid modifying original
    if (uploadData.area) {
      uploadData.area = areas.find(a => a.id === formData.area)?.name;
    }
    await startUpload(uploadData);
  }

  const removeFile = (name: string) => {
    stageFiles([...files.filter(f => f.name !== name)]);
  }

  return (
    <Container>
      <Info> NOTE: This is not the Unit History tool. Only use this site if uploading your 2023 stake annual history. Do not use this to upload individual ward or stake stories, images, or PDFs. To use the Unit History tool, please follow this <a target='_blank' rel='noopener noreferrer' href='https://unithistory.churchofjesuschrist.org/'>link.</a> </Info>
      <PageTitle>{t('uploadHistoryLabel')}</PageTitle>
      <Form
        formData={formData}
        setFormData={setFormData}
        fieldList={[
          'area',
          'email'
        ]}
        primaryDisabled={!files?.length || uploading}
        onSubmit={tryUpload}
        hideSuccessNotification
      >
        {({ commonFormProps, validity }) => (
          <Stack>
            <div>
              <Text4>Use <i>preferred</i> file formats:</Text4>
              <ul>
                <li><Text4>PDF (for documents)</Text4></li>
                <li><Text4>DOC and DOCX (for documents)</Text4></li>
                <li><Text4>JPEG (for images)</Text4></li>
                <li><Text4>MP3 (for audio)</Text4></li>
                <li><Text4>MP4 (for video)</Text4></li>
              </ul>
              <Text4>When submitting multiple files, all the files should be zipped (compressed). Single files may also be zipped to reduce their size. Name the zip folder with the unit name, unit number, and year. Example: Bountiful Utah Stake_502081_2020.zip. To zip a file:</Text4>
              <ul>
                <li><Text4>If using a Windows operating system, right-click the folder and send it to a compressed zipped folder.</Text4></li>
                <li><Text4>If using a Mac operating system, right-click the folder and compress the named folder.</Text4></li>
              </ul>
              <Text4>We recommend you keep a copy of your annual history. You will be notified by email that your submission was successfully received.</Text4>
            </div>
            <div>
              <BoldUnderlinedLabel>
              Select Region
              </BoldUnderlinedLabel>
              <FormField validityState={determineValidityState(validity.area)}>
                <Select
                  name="area"
                  {...commonFormProps}
                  required
                  disabled={uploading}
                >
                  <option value=""></option>
                  {areas.map(a =>
                    <option key={a.id} value={a.id}>{a.name}</option>
                  )}
                </Select>
                <FieldValidityHint field={validity.area} />
              </FormField>
            </div>
            <div>
              <BoldUnderlinedLabel>
                Your Email Address
              </BoldUnderlinedLabel>
              <FormField validityState={determineValidityState(validity.email)}>
                <Input
                  name="email"
                  {...commonFormProps}
                  required
                  type="email"
                  value={formData.email}
                  disabled={uploading}
                />
                <FieldValidityHint field={validity.email} />
              </FormField>
            </div>
            <div>
              <BoldUnderlinedLabel>
                Files
              </BoldUnderlinedLabel>
              {files?.length
                ? <Box pl={25} pt={15}>
                  {files.map((f, i) =>
                    <Row key={f.name} align="start">
                      <Row verticalAlign="center" gapSize="4">
                        {i > fileIndex || !uploading
                          ? <CircleNotStarted size="1.5rem" />
                          : i === fileIndex
                            ? <CircleStarted size="1.5rem" />
                            : <GreenCheck size="1.5rem" />
                        }
                        <Text4>{f.name}</Text4>
                      </Row>
                      <Icon disabled={uploading} onClick={() => removeFile(f.name)}>
                        <Clear />
                      </Icon>
                    </Row>
                  )}
                </Box>
                : null
              }
              {sending && <ProgressSection>{t('finishing_up')}</ProgressSection>}
            </div>
            <UploadWrapper onDocumentSelect={stageFiles} loading={uploading}>
              {uploading && (typeof uploadPercent === 'number'
                ? <Box pb={5}>
                  {t('uploadingLabel')} {uploadFile}... {Math.round(uploadPercent * 100)}%
                  <ProgressBar value={uploadPercent}></ProgressBar>
                </Box>
                : <Box pb={5}>
                  {t('uploadingLabel')} {uploadFile}
                  <ProgressBar></ProgressBar>
                </Box>)
              }
            </UploadWrapper>
          </Stack>
        )}
      </Form>
    </Container>
  );
}

export default withPage({ pageName: uploadPageName, pageTitle: 'uploadPageTitle' })(Upload);
