import React, { Fragment } from 'react';
import { UNIT_BRANCH, UNIT_GROUP, UNIT_WARD } from 'src/utils/appConstants/appConstants';
import { WardDescriptor, StakeDescriptor } from './UnitBadge.style';
import { useTranslation } from 'react-i18next';

const getSingleUnitFromUnit = unit => {
  if (!unit || !unit.type) {
    return '';
  }
  return unit.type.slice(0, 1) + unit.type.slice(1).toLowerCase();
};

export const UnitBadge = ({ unit }: { unit }) => {
  const { t } = useTranslation('labels');
  const singleUnit = getSingleUnitFromUnit(unit).toUpperCase();
  // Note: singleUnit will be "Ward", "Branch", "Stake", "SomeOtherUnit", etc.
  // The reason for `${singleUnit}` below is so that the unit (whatever it is),
  // will be sent to the translation function: t. If a translation label doesn't
  // exist, the yet to be defined unit e.g. "SomeOtherUnit" will be used.

  return singleUnit
    ? (
      [UNIT_WARD, UNIT_BRANCH, UNIT_GROUP].includes(singleUnit)
        ? (
          <WardDescriptor className="unit-badge"> {t(`${singleUnit}`)} </WardDescriptor>
        )
        : (
          <StakeDescriptor className="unit-badge"> {t(`${singleUnit}`)} </StakeDescriptor>
        )
    )
    : <Fragment />;
};

export default UnitBadge;
