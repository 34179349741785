import React, { Fragment, useState } from 'react';
import { OperationVariables, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { userType } from 'src/utils/appConstants/propTypesConstants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUnitOrgs } from 'src/reducers/unitsReducer';
import { UserUnit } from 'src/models/Unit';

// eden
import Stack from '@churchofjesuschrist/eden-stack';
import LoadingSpinner from 'src/utils/componentFunctions/loading-spinner';
import Row from '@churchofjesuschrist/eden-row';
import { Label, Input, TextArea } from '@churchofjesuschrist/eden-form-parts';
import { Text4 } from '@churchofjesuschrist/eden-text';

// components
import SendInviteButton from 'src/components/Buttons/SendInviteButton';
import DateSelector from 'src/components/Create/DateSelector';
import SelectPerson from 'src/components/AddToStory/SelectPerson';
import AddOrganizationsToStory from 'src/components/Organizations/AddOrganizationsToStory';
import InContextHelp from 'src/components/InContextHelp/InContextHelp';
import { InviteHeader } from 'src/components/Invite/InviteHeader';
import ErrorModal from 'src/components/Modals/ErrorModal/ErrorModal';

// styles
import { Line } from 'src/utils/appCSS/appStyles';
import { FormError, TitleSection } from 'src/components/Invite/Invite.style';
import { CharacterCounter, SpaceBetweenContainer } from '../Create/Form/Form.style';

// queries
import { getAuthorsQuery } from 'src/utils/graphQL_Queries/appQueries';

// functions
import {
  convertDate,
} from 'src/utils/AppFunctions/appFunctions';
import {
  determineValidityState,
  moveEndDateToStartDate,
  useFormFunctions,
  validateForm,
} from 'src/utils/AppFunctions/formFunctions';

// constants
import { pageNames, formValidityState } from 'src/utils/appConstants/appConstants';
import { object } from 'prop-types';

const { invitePageName } = pageNames;
const { validityStateUnknownLabel, validityStateErrorLabel } = formValidityState;
const textLimit = 5000;

export const InviteForm = ({ user, activeUnitObj }: { user, activeUnitObj: UserUnit }) => {
  const { unitType } = activeUnitObj;
  const { t } = useTranslation();
  const { formatPersonObj } = useFormFunctions();

  const fullOrgList = useSelector(selectUnitOrgs);
  const filteredOrgList = fullOrgList?.filter(o => o.unitType === unitType);

  const [invite, setInvite] = useState<{
    authors: any[]
    body: string
    notes: string
    dateEnd: any
    dateStart: any
    organizations: any[]
    textLength: number
    title: string
    unit: any
  }>({
    authors: [],
    body: '',
    notes: '',
    dateEnd: null,
    dateStart: null,
    organizations: [],
    textLength: 0,
    title: '',
    unit: activeUnitObj
  });
  const [formInput, setFormInput] = useState({
    dateValidityState: validityStateUnknownLabel,
    authorValidityState: validityStateUnknownLabel,
    titleValidityState: validityStateUnknownLabel,
    summaryValidityState: validityStateUnknownLabel,
  });
  const [reset, setReset] = useState(false);

  const resetForm = () => {
    setReset(true);
    setInvite({
      authors: [],
      body: '',
      notes: '',
      dateEnd: null,
      dateStart: null,
      organizations: [],
      textLength: 0,
      title: '',
      unit: activeUnitObj
    });
    setFormInput({
      dateValidityState: validityStateUnknownLabel,
      authorValidityState: validityStateUnknownLabel,
      titleValidityState: validityStateUnknownLabel,
      summaryValidityState: validityStateUnknownLabel,
    });

    setTimeout(() => {
      setReset(false);
    }, 100);
  }

  /** *************** Adding Recipients Of Invite ********************************/

  const updateAuthors = authorsToAdd => {
    setInvite(invite => ({
      ...invite,
      authors: authorsToAdd
    }));

    setFormInput(formInput => ({
      ...formInput,
      authorValidityState: determineValidityState(authorsToAdd.length > 0),
    }));
  }

  const handleTitleChange = event => {
    const newTitle = event.target.value;
    const updatedInvite = {
      ...invite,
      title: newTitle,
    };

    setInvite(updatedInvite);

    setFormInput(formInput => ({
      ...formInput,
      titleValidityState: determineValidityState(!!updatedInvite.title),
    }));
  }

  const handleStartDateChange = e => {
    const dateStart = e.target.value;
    const dateEnd = !invite.dateEnd ? dateStart : invite.dateEnd;
    const isValid = dateStart <= dateEnd;
    const validityState = determineValidityState(isValid);

    setInvite(invite => ({
      ...invite,
      dateStart,
    }));

    setFormInput(formInput => ({
      ...formInput,
      dateValidityState: validityState,
    }));
  }

  const handleEndDateChange = e => {
    const dateEnd = e.target.value;
    const dateStart = !invite.dateStart ? dateEnd : invite.dateStart;
    const isValid =
      dateStart === dateEnd ||
      Date.parse(dateStart) < Date.parse(dateEnd) ||
      (dateStart && !dateEnd);
    const validityState = determineValidityState(isValid);

    setInvite(invite => ({
      ...invite,
      dateStart,
      dateEnd,
    }));

    setFormInput(formInput => ({
      ...formInput,
      dateValidityState: validityState,
    }));
  }

  const handleOrganizationChange = organizations => {
    setInvite(invite => ({
      ...invite,
      organizations: organizations.map(o => ({ uuid: o })),
    }));
  }

  const handleBodyChange = e => {
    const newBody = e.target.value;
    const length = newBody.length;
    const validityState = determineValidityState(length > 0);

    setInvite(invite => ({
      ...invite,
      notes: newBody,
      textLength: length,
    }));

    setFormInput(formInput => ({
      ...formInput,
      summaryValidityState: validityState,
    }));
  }

  const handleFormValidation = invite => {
    const { dateEnd, dateStart } = invite;

    const adjustedDate = moveEndDateToStartDate(dateStart, dateEnd);

    const updatedInvite = {
      ...invite,
      dateStart: convertDate(adjustedDate.dateStart) as any,
      dateEnd: convertDate(adjustedDate.dateEnd) as any,
    };

    setInvite(updatedInvite);

    setFormInput(validateForm(updatedInvite, 'invite'));
  }

  const { authors, notes, dateEnd, dateStart, textLength, title } = invite;
  const organizations = invite.organizations?.map(o => o.uuid);
  const {
    authorValidityState,
    titleValidityState,
    dateValidityState,
    summaryValidityState,
  } = formInput;
  const isMissingRequireFields =
    authorValidityState === validityStateErrorLabel ||
    titleValidityState === validityStateErrorLabel ||
    summaryValidityState === validityStateErrorLabel;
  const isDateError = dateValidityState === validityStateErrorLabel;

  return (
    <Fragment>
      <InviteHeader />

      <Stack gapSize="32">
        <section id="addRecipients">
          <Query
            displayName="Invite-getAuthors-Query"
            query={getAuthorsQuery}
            notifyOnNetworkStatusChange
          >
            {({ loading, error, data }: QueryResult<any, OperationVariables>) => {
              if (loading) return <LoadingSpinner />;
              if (error) {
                return (
                  <ErrorModal
                    title={t('errorGettingAuthorsLabel', { ns: 'strings' })}
                    onRetry={() => window.location.reload()}
                    errors={error}
                    isActive
                  />
                );
              }

              if (!loading && data) {
                const displayCallingList = formatPersonObj(data.authors, unitType, true);

                return (
                  <SelectPerson
                    errorMessage={t('formErrorMessageRecipient', { ns: 'strings' })}
                    fullAuthorList={data.authors}
                    handleUpdatePeople={updateAuthors}
                    isMissingPerson={
                      authorValidityState === validityStateErrorLabel
                    }
                    pageName={invitePageName}
                    displayCallingList={displayCallingList}
                    selectedPeople={authors}
                    reset={reset}
                    activeUnitObj={activeUnitObj}
                  />
                );
              } else {
                return <div />;
              }
            }}
          </Query>
        </section>

        <TitleSection id="addTitle">
          <Label id="title" validityState={titleValidityState}>
            {t('titleFieldLabel', { ns: 'strings' }) + ' ' + t('requiredLabel', { ns: 'strings' })}
            <Input
              type="text"
              name="title"
              value={title}
              placeholder={t('storyTitlePlaceHolderLabel', { ns: 'strings' })}
              onChange={handleTitleChange}
              validityState={titleValidityState}
              maxLength="140"
            />
          </Label>
          {titleValidityState === validityStateErrorLabel
            ? (
              <FormError>{t('formErrorMessageTitle', { ns: 'strings' })}</FormError>
            )
            : null}
        </TitleSection>

        <section id="addDates">
          <DateSelector
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            dateStart={dateStart}
            dateEnd={dateEnd}
            dateValidityState={dateValidityState}
          />

          <FormError>
            {dateValidityState === validityStateErrorLabel ? t('formFieldDateEndErrorMessage', { ns: 'strings' }) : ' '}
          </FormError>
        </section>

        <section id="addOrganizations">
          <AddOrganizationsToStory
            fullOrgList={filteredOrgList}
            handleUpdateOrganizations={handleOrganizationChange}
            checkedOrganizations={organizations}
            unitType={unitType}
          />
        </section>

        <section id="addNote">
          <Label id="addNoteLabel" validityState={dateValidityState}>
            <Row
              gapSize="4"
              columnGapSize="4"
              verticalAlign="center"
              style={{ margin: '-0.25rem 0 0.25rem 0' }} // to fix the -4px left margin and the 4px bottom margin
            >
              {t('invitationRichTextEditorLabel', { ns: 'strings' })}
              <InContextHelp index={6}>{t('calloutInvitationText', { ns: 'strings' })}</InContextHelp>
            </Row>
          </Label>

          {!reset && (
            <TextArea
              name="body"
              value={notes}
              onChange={handleBodyChange}
              placeholder={t('invitationFormPlaceholderText', { ns: 'strings' })}
              style={{ height: '215px' }}
              maxLength={textLimit.toString()}
              validityState={summaryValidityState}
              required
            />
          )}
          <SpaceBetweenContainer>
            <Text4>&nbsp;</Text4>
            <CharacterCounter
              textLength={textLength}
              textLimit={textLimit}
              sizeError={textLength >= textLimit}
            />
          </SpaceBetweenContainer>
        </section>

        <br />
      </Stack>

      <Line />

      {(isMissingRequireFields || isDateError) && (
        <Fragment>
          <FormError validityState="error">
            {isMissingRequireFields ? t('missingRequiredFieldsLabel', { ns: 'strings' }) : ' '}
            {isMissingRequireFields && isDateError ? '.  ' : ''}
            {isDateError ? t('formFieldDateEndErrorMessage', { ns: 'strings' }) : ' '}
          </FormError>

          <br />
        </Fragment>
      )}

      <SendInviteButton
        invitation={invite}
        user={user}
        validateForm={handleFormValidation}
        clearForm={resetForm}
      />
    </Fragment>
  );
}

export default InviteForm;

(InviteForm as any).propTypes = {
  user: userType.isRequired,
  activeUnitObj: object.isRequired,
};
